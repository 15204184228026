<template>
  <div class="contacts">
    <div class="filter">
      <div class="section-header">
        <el-row>
          <span>Contacts</span>
          <div class="case-filter">
            <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="saveContacts()">Save</el-button>
          </div>
        </el-row>
      </div>
    </div>
    <div class="contacts-contents">
      <div v-for="(item, index) in allContacts" v-bind:key="index">
        <div class="title">Contact {{ index + 1 }}</div>
        <el-form label-width="150px" :model="item">
          <el-form-item label="Name">
            <el-input :disabled="!canModify" v-model="item.name"></el-input>
          </el-form-item>
          <el-form-item label="Description 1">
            <el-input :disabled="!canModify" v-model="item.description1"></el-input>
          </el-form-item>
          <el-form-item label="Description 2">
            <el-input :disabled="!canModify" v-model="item.description2"></el-input>
          </el-form-item>
          <el-form-item label="Phone">
            <el-input :disabled="!canModify" v-model="item.phone"></el-input>
          </el-form-item>
          <el-form-item label="Email">
            <el-input :disabled="!canModify" v-model="item.email"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/restClient";
import auth from "../auth";

import { Loading } from "element-ui";

export default {
  name: "icontacts",
  data: function() {
    return {
      canModify: false,
      contact1: {},
      contact2: {},
      contact3: {}
    };
  },
  computed: {
    allContacts: function() {
      return [this.contact1, this.contact2, this.contact3];
    }
  },
  methods: {
    loadContacts: function() {
      let vm = this;
      let loadingInstance = Loading.service({ fullscreen: true });
      console.log("loading");
      api.getContacts(response => {
        console.log(response);
        let contacts = response.body || [];
        vm.contact1 = contacts[0] || {};
        vm.contact2 = contacts[1] || {};
        vm.contact3 = contacts[2] || {};
        loadingInstance.close();
      });
    },
    saveContacts: function() {
      let loadingInstance = Loading.service({ fullscreen: true });
      api.postContacts(this.contact1, this.contact2, this.contact3, response => {
        this.loadContacts();
        loadingInstance.close();
      });
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
    this.loadContacts();
  }
};
</script>

<style scope>
.contacts {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-header > .el-row {
  z-index: 10;
}

.contacts-contents {
  position: absolute;
  top: 2em;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
}

.contacts-contents .title {
  font-weight: bold;
  font-size: 1em;
}

.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
  z-index: 11;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
</style>
