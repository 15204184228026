<template>
  <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Username -->
      <el-form-item label="Username" prop="username">
        <el-input type="text" v-model="formModel.username" :disabled="!isCreate">
          <template v-if="isCreate" slot="prepend">{{ org }}.</template>
        </el-input>
      </el-form-item>
      <!-- Password -->
      <el-form-item v-if="isCreate" label="Password" prop="password">
        <el-input type="password" v-model="formModel.password"></el-input>
      </el-form-item>
      <!-- Confirm Password -->
      <el-form-item v-if="isCreate" label="Confirm Password" prop="confirmPassword">
        <el-input type="password" v-model="formModel.confirmPassword"></el-input>
      </el-form-item>
      <!-- Email -->
      <el-form-item label="Email" prop="email">
        <el-input type="email" v-model="formModel.email"></el-input>
      </el-form-item>
      <!-- First Name -->
      <el-form-item label="First Name" prop="firstName">
        <el-input type="text" v-model="formModel.firstName"></el-input>
      </el-form-item>
      <!-- Last Name -->
      <el-form-item label="Last Name" prop="lastName">
        <el-input type="text" v-model="formModel.lastName"></el-input>
      </el-form-item>
      <!-- Status -->
      <el-form-item v-if="isCreate" label="Status" prop="status">
        <el-radio-group v-model="formModel.status">
          <el-radio-button :label="true">Active</el-radio-button>
          <el-radio-button :label="false">Inactive</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- Facility -->
      <el-form-item label="Facility" prop="_selectedFacilities" :rules="[{ required: true, validator: validateFacilities, message: 'This field is required' }]">
        <el-select multiple v-model="formModel._selectedFacilities" placeholder="Not Selected" style="width: 100%">
          <el-option v-for="facility in availableFacilities" :key="facility.id" :label="facility.displayText" :value="facility.id">
            <span>{{ facility.displayText }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- Roles -->
      <el-form-item label="Roles" prop="availableRoles" :rules="[{ required: true, validator: validateRoles, message: 'This field is required' }]">
        <el-collapse>
          <template v-for="role in availableRolesArray">
            <el-collapse-item :key="role.role" :name="role.role">
              <pre class="role-description">{{ role.description }}</pre>
              <template slot="title">
                <el-checkbox v-model="formModel._selectedRolesHash[role.role]" />
                <span class="role-title">{{ role.displayText }}</span>
              </template>
            </el-collapse-item>
          </template>
        </el-collapse>
      </el-form-item>
      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">{{ submitText }}</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback(new Error("This field cannot be empty"));
  }
  callback();
};
const validateEmail = (rule, value, callback) => {
  const pattern = /\S+@\S+\.\S+/;
  if (!value || !pattern.test(value)) {
    callback("This field must contain a valid email");
  }
  callback();
};

const validateUsername = (rule, value, callback) => {
  if (!value) {
    callback("This field is required.");
  } else if (value.length < 4) {
    callback("Username must be at least 4 characters.");
  }
  callback();
};

import auth from "../../auth";
export default {
  name: "uam-editor",
  props: ["title", "values", "submitText", "isCreate", "availableRolesArray", "availableRoles"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      roles: [],
      availableFacilities: [],
      formRules: {
        username: [
          {
            required: true,
            validator: validateUsername,
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            validator: this.validatePassword,
            trigger: "blur"
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.validateConfirmPassword,
            trigger: "blur"
          }
        ],
        facilities: [
          {
            required: true,
            validator: validateNotEmpty,
            message: "This field is required",
            trigger: "blur"
          }
        ]
      },
      formModel: {
        username: null,
        email: null,
        password: null,
        confirmPassword: null,
        firstName: null,
        lastName: null,
        facilities: [],
        roles: [],
        _selectedFacilities: [],
        _selectedRolesHash: {},
        status: true
      }
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
    "formModel._selectedRolesHash": {
      handler: function(val) {
        this.formModel.roles = Object.entries(this.formModel._selectedRolesHash)
          .filter(([key, value]) => value)
          .map(([key, value]) => key);
      },
      deep: true
    },
    "formModel._selectedFacilities": {
      handler: function(selectedFacilities) {
        // the user being edited may have facilities that the current user does not; these are hidden & unchanged
        const hiddenFacilities = this.formModel.facilities.filter(facId => !this.availableFacilities[facId]);
        this.formModel.facilities = [...hiddenFacilities, ...selectedFacilities];
      }
    }
  },
  methods: {
    validateRoles(rule, value, cb) {
      if (!this.formModel.roles.length) {
        cb(new Error("This field is required"));
      }
      cb();
    },
    validateFacilities(rule, value, cb) {
      if (!this.formModel.facilities.length) {
        cb(new Error("This field is required"));
      }
      cb();
    },
    validatePassword(rule, value, cb) {
      if (!value) {
        cb(new Error("This field is required"));
      } else if (value.length < 8) {
        cb(new Error("Password must contain at least 8 characters."));
      } else if (value.length > 20) {
        cb(new Error("Password must contain at most 20 characters."));
      } else {
        if (this.formRules.confirmPassword !== "") {
          this.$refs.editorForm.validateField("confirmPassword");
        }
        cb();
      }
    },
    validateConfirmPassword(rule, value, cb) {
      if (!value) {
        cb(new Error("This field is required"));
      } else if (value.length < 8) {
        cb(new Error("Password must contain at least 8 characters."));
      } else if (value.length > 20) {
        cb(new Error("Password must contain at most 20 characters."));
      } else if (value !== this.formModel.password) {
        cb(new Error("Passwords must match."));
      }
      cb();
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    async commitForm() {
      this.$refs.editorForm.validate(async valid => {
        if (valid) {
          this.dialogBusy = true;
          // if creating a user prepend org to username
          const user = {
            ...this.formModel,
            username: this.isCreate ? `${this.org}.${this.formModel.username}` : this.formModel.username
          };
          try {
            // if creating a user -> /users; updating a user -> /users/:id
            const url = `${window.CONFIG.uam_api}/users${this.isCreate ? "" : `/${this.formModel.id}`}`;
            this.isCreate ? await this.$http.post(url, JSON.stringify(user)) : await this.$http.put(url, JSON.stringify(user));
            this.$emit("editor-save");
          } catch (error) {
            let message = "An error has occurred. Please try again later.";
            if (error.status === 409) {
              message = "Username and Email must be unique.";
            } else if (error.status === 403) {
              message = "Your account does not have the required permissions to perform this action.";
            }
            this.$emit("editor-error", message);
            this.dialogBusy = false;
          }
        } else {
          return false;
        }
      });
    },
    handleRoleSelectionChange(roles) {
      const roleNames = roles.map(role => role.role);
      this.formModel.roles = roleNames;
    },
    setupForm(values) {
      this.formModel = {
        ...values,
        _selectedFacilities: values.facilities.filter(facId => this.availableFacilities[facId]),
        _selectedRolesHash: Object.fromEntries(this.availableRolesArray.map(role => [role.role, values.roles.includes(role.role)]))
      };
    }
  },
  async created() {
    // availableFacilities are the current user's facilities (determines which are shown/interactable on other users)
    this.availableFacilities = Object.fromEntries(this.$configStore.data.sites.map(site => [site.id, site]));
    this.org = auth.userInfo().org;
    if (this.values) {
      this.setupForm(this.values);
    }
    this.dialogBusy = false;
  }
};
</script>
<style scoped>
.role-description {
  font-family: sans-serif;
}
.role-title {
  margin: 0 0 0 10px;
}
</style>
