<template>
  <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="resolutionForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Resolution Date -->
      <el-form-item v-if="isResolve" label="Resolution Date" prop="resolvedDate">
        <el-date-picker v-model="formModel.resolvedDate" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Select date" :picker-options="pickerOptions"> </el-date-picker>
      </el-form-item>
      <p style="color: red" v-else>Are you sure you want to reopen this Outbreak</p>
      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Confirm</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
export default {
  name: "outbreak-editor",
  props: ["outbreak"],
  data() {
    return {
      title: "",
      dialogVisibility: true,
      dialogBusy: true,
      formRules: {},
      formModel: {
        resolvedDate: null,
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
  },
  computed: {
    pickerOptions() {
      // declared date <-> today
      const minDateTime = new Date(this.outbreak.declaredDate).getTime();
      const maxDateTime = new Date().getTime();
      return {
        disabledDate: (time) => {
          const currentTime = time.getTime();
          return currentTime < minDateTime || currentTime > maxDateTime;
        },
      };
    },
  },
  methods: {
    setupForm() {
      if (this.isResolve) {
        this.title = "Resolve Outbreak";
        this.formRules.resolvedDate = [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }];
      } else {
        this.title = "Unresolve Outbreak";
        delete this.formRules.resolvedDate;
      }
    },
    cancelForm() {
      this.$emit("resolve-close");
    },
    async commitForm() {
      this.$refs.resolutionForm.validate(async (valid) => {
        if (valid) {
          this.dialogBusy = true;
          try {
            // setup request body
            const raw = { ...this.formModel };
            // send required fields along with updated resolution date
            const resolvedDate = this.isResolve ? raw.resolvedDate : null;
            const outbreak = {
              facId: this.outbreak.facId,
              scope: this.outbreak.scope,
              scopeDetail: this.outbreak.scopeDetail,
              declaredDate: this.outbreak.declaredDate,
              resolvedDate,
            };
            await this.$http.patch(`${window.CONFIG.om_api}/${this.outbreak.id}`, outbreak);
            this.$emit("resolve-save");
          } catch (error) {
            this.$emit("resolve-error");
          }
          this.dialogBusy = false;
        } else {
          return false;
        }
      });
    },
  },
  async created() {
    // isResolve controls dialog behaviour: resolve vs unresolve an outbreak
    this.isResolve = this.outbreak.resolvedDate ? false : true;
    this.setupForm();
    this.dialogBusy = false;
  },
};
</script>