<template>
  <div class="location-filter">
    <el-row class="filter-group-label">Location Filter:</el-row>
    <el-row class="filter-section">
      <label v-if="hasFacilityTypeFilter()"
        ><span class="filter-title-label">Type</span>
        <el-select v-model="selectedFacType" placeholder="Type" class="filter-section-input" v-on:change="updateFilter">
          <el-option value="LTC"></el-option>
          <el-option value="RET"></el-option>
        </el-select>
      </label>
      <label
        ><span class="filter-title-label">{{ category ? category.subCategoryText : "" }}</span>
        <el-select v-model="selectedCategory" placeholder="" class="filter-section-input" v-on:change="updateFilter" value-key="id">
          <el-option label="All" :value="0"> </el-option>
          <el-option v-for="item in categoryList" :label="item.displayText" v-bind:key="item.id" :value="item"> </el-option>
        </el-select>
      </label>
      <label v-if="selectedCategory != 0 && selectedCategory.subCategory && selectedCategory.subCategory.length > 0"
        ><span class="filter-title-label">{{ selectedCategory.subCategoryText }}</span>
        <el-select v-model="selectedSubCategory" placeholder="" class="filter-section-input" v-on:change="updateFilter" value-key="id">
          <el-option label="All" :value="0"> </el-option>
          <el-option v-for="item in subCategoryList" :label="item.displayText" v-bind:key="item.id" :value="item"> </el-option>
        </el-select>
      </label>
      <label
        ><span class="filter-title-label">Facility</span>
        <el-select v-model="selectedSite" filterable placeholder="Facility" class="filter-section-input" v-on:change="updateFilter" value-key="id">
          <el-option label="All" :value="0"> </el-option>
          <el-option v-for="item in filteredSites" :label="item.displayText" v-bind:key="item.id" :value="item">
            <span style="float: left">{{ item.displayText }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </label>
      <label v-if="!hideUnit"
        ><span class="filter-title-label">Unit</span>
        <el-select v-model="selectedUnit" filterable placeholder="Type" class="filter-section-input" v-on:change="updateFilter" value-key="id">
          <el-option label="All" :value="0"> </el-option>
          <el-option v-for="item in filteredUnits" :label="item.displayText" v-bind:key="item.id" :value="item">
            <span style="float: left">{{ item.displayText }} - {{ item.siteDisplayText }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </label>
    </el-row>
  </div>
</template>

<script>
import auth from "../../auth";

export default {
  name: "location-filter",
  props: ["hideUnit"],
  components: {},
  data: function() {
    return {
      hasRET: false,
      selectedFacType: "LTC",
      selectedCategory: 0,
      selectedSubCategory: 0,
      selectedUnit: 0,
      selectedSite: 0,
      filteredSites: [],
      filteredUnits: [],
      organization: [],
      category: {
        subCategory: []
      }
    };
  },
  watch: {
    selectedCategory: function(v) {
      this.selectedSubCategory = 0;
      this.selectedSite = 0;
      this.selectedUnit = 0;

      this.updateFacilityList();
      this.updateFilter();
    },
    selectedSubCategory: function(v) {
      this.selectedSite = 0;
      this.selectedUnit = 0;
      this.updateFacilityList();
      this.updateFilter();
    },
    selectedFacType: function(v) {
      var vm = this;
      this.selectedSite = 0;
      this.selectedUnit = 0;
      this.selectedCategory = 0;
      this.selectedSubCategory = 0;
      vm.$nextTick(function() {
        this.updateFacilityList();
        this.updateFilter();
      });
    },
    selectedSite: function(v) {
      this.selectedUnit = 0;
      this.updateFacilityList();
      this.updateFilter();
    }
  },
  computed: {
    categoryList: function() {
      const vm = this;
      const hasTypeFilter = vm.hasFacilityTypeFilter();
      const hasUnitsForSelectedType = category => {
        return !!category.refIds.filter(refId => {
          const site = vm.getSiteWithId(refId);
          return site.unitType === vm.selectedFacType;
        }).length;
      };
      if (!hasTypeFilter) {
        return vm.category.subCategory;
      }
      return vm.category.subCategory.filter(subCat => {
        if (subCat.subCategory.length) {
          return !!subCat.subCategory.filter(subSubCat => {
            return hasUnitsForSelectedType(subSubCat);
          }).length;
        } else {
          return hasUnitsForSelectedType(subCat);
        }
      });
    },
    subCategoryList: function() {
      if (this.selectedCategory !== 0 && this.selectedCategory.subCategory && this.selectedCategory.subCategory.length > 0) {
        console.log(this.selectedCategory.subCategory);
        let vm = this;
        return this.selectedCategory.subCategory.filter(x => {
          for (let refId of x.refIds || []) {
            var s = vm.getSiteWithId(refId);
            if (s) {
              if (vm.hasFacilityTypeFilter()) {
                // var isRET = s.displayText.includes(' RET ')
                var isRET = s.unitType === "RET";
                if (vm.selectedFacType === "RET" && isRET) {
                  return true;
                } else if (vm.selectedFacType !== "RET" && !isRET) {
                  return true;
                }
              } else {
                return true;
              }
            }
          }

          return false;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    hasFacilityTypeFilter() {
      return auth.userInfo().roles.indexOf("ROLE_LTC") >= 0 && auth.userInfo().roles.indexOf("ROLE_RET") >= 0;
    },
    updateFacilityList: function() {
      var vm = this;
      var facs = [];
      var loadFacs = function(refIds) {
        if (!refIds) {
          return;
        }

        refIds.forEach(function(refId) {
          var s = vm.getSiteWithId(refId);
          if (s) {
            if (vm.selectedFacType !== 0 && vm.hasFacilityTypeFilter()) {
              // var isRET = s.displayText.includes(' RET ')
              var isRET = s.unitType === "RET";
              if (vm.selectedFacType === "RET" && isRET) {
                facs.push(s);
                vm.hasRET = true;
              } else if (vm.selectedFacType !== "RET" && !isRET) {
                facs.push(s);
              }
            } else {
              facs.push(s);
            }
          }
        });
      };

      if (this.selectedCategory === 0) {
        this.sites.forEach(function(s) {
          if (vm.selectedFacType !== 0 && vm.hasFacilityTypeFilter()) {
            var isRET = s.unitType === "RET";
            if (vm.selectedFacType === "RET" && isRET) {
              facs.push(s);
            } else if (vm.selectedFacType !== "RET" && !isRET) {
              facs.push(s);
            }
          } else {
            facs.push(s);
          }
        });
      } else if (this.selectedSubCategory === 0) {
        loadFacs(this.selectedCategory.refIds);
        this.selectedCategory.subCategory.forEach(function(sub) {
          loadFacs(sub.refIds);
          sub.subCategory.forEach(function(subSub) {
            loadFacs(subSub.refIds);
          });
        });
      } else {
        loadFacs(this.selectedSubCategory.refIds);
      }
      vm.filteredSites = facs.sort((a, b) => {
        return a.displayText.localeCompare(b.displayText);
      });

      var tempUnits = [];
      facs.forEach(function(f) {
        if (vm.selectedSite !== 0 && vm.selectedSite.id !== f.id) {
          return;
        }
        vm.units.forEach(function(u) {
          if (u.siteId === f.id) {
            tempUnits.push(u);
          }
        });
      });
      vm.filteredUnits = tempUnits.sort((a, b) => {
        return a.displayText.localeCompare(b.displayText);
      });
    },
    getSiteWithId: function(id) {
      var site = null;
      this.sites.forEach(function(s) {
        if (s.id === id) {
          site = s;
        }
      });
      return site;
    },
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    filterFn() {
      let vm = this;
      if (!vm.dataReady) {
        return;
      }

      let units = [];
      if (vm.selectedUnit !== 0) {
        console.log(vm.selectedUnit);
        units = [vm.selectedUnit.id];
      } else {
        units = 0;
      }

      let facs = [];
      if (vm.selectedSite !== 0) {
        facs = [vm.selectedSite.id];
      } else {
        if (vm.filteredSites.length > 0) {
          vm.filteredSites.forEach(f => {
            facs.push(f.id);
          });
        } else {
          facs = 0;
        }
      }
      console.log("emit update:filter");
      vm.$emit("update:filter", {
        site: vm.selectedSite === 0 ? 0 : vm.selectedSite.id,
        sites: facs,
        unit: vm.selectedUnit === 0 ? 0 : vm.selectedUnit.id,
        units: units
      });
    }
  },
  created: function() {
    let vm = this;
    vm.organization = this.$configStore.data.organization;
    if (vm.organization) {
      vm.sites = vm.organization[0].sites || [];
      vm.units = vm.$configStore.data.units;
      vm.filteredUnits = vm.units;
    }
    console.log(vm.organization[0].sites);
    this.updateFilter = this.$_.debounce(this.filterFn.bind(this), 500);
  },
  mounted: function() {
    var vm = this;
    vm.organization = this.$configStore.data.organization;
    if (vm.organization) {
      vm.sites = vm.organization[0].sites || [];
      vm.units = vm.$configStore.data.units;
      vm.filteredUnits = vm.units;

      vm.category = vm.organization[0].category;

      if (vm.category) {
        vm.category.subCategory = (vm.category.subCategory || []).sort((a, b) => {
          return a.displayText.localeCompare(b.displayText);
        });

        vm.category.subCategory.forEach(s => {
          if (s.subCategory) {
            s.subCategory = s.subCategory.sort((a, b) => {
              return a.displayText.localeCompare(b.displayText);
            });
          }
        });
      }
    } else {
      vm.organization = [];
    }

    let userInfo = auth.userInfo();
    console.log(userInfo);
    if (userInfo && userInfo.defaultSites && userInfo.defaultSites.length > 0) {
      let site = this.getSiteWithId(userInfo.defaultSites[0]);
      console.log(site);

      // find sub category with this site id
      if (vm.category) {
        for (let c of vm.category.subCategory) {
          if (c.subCategory) {
            for (let c2 of c.subCategory) {
              console.log(c2);
              if (c2.refIds.indexOf(site.id) > -1) {
                vm.selectedCategory = c;
                console.log("vm.selectedCategory", vm.selectedCategory);
                vm.$nextTick(function() {
                  vm.selectedSubCategory = c2;
                  console.log("vm.selectedSubCategory", vm.selectedSubCategory);
                });
                break;
              }
            }

            if (vm.selectedSubCategory !== 0) {
              break;
            }
          }

          if (c.refIds.indexOf(site.id) > -1) {
            vm.selectedCategory = c;
            break;
          }
        }
      }
    }

    vm.dataReady = true;
    this.updateFacilityList();
    this.$nextTick(() => {
      this.filterFn();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-picker-panel__sidebar {
  width: 120px;
}
</style>
<style scoped>
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: red;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: green;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 9px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0;
}

.graph-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 9px;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filter {
  width: 150px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input,
.filter-section .el-input-number,
.filter-section .el-checkbox {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section .el-checkbox {
  padding: 10px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}

.case-page-size-filter {
  float: left;
}

.el-form .filter-section-input {
  width: 100%;
}

.el-form {
  padding-right: 10%;
}
</style>
