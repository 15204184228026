<template>
  <el-tooltip effect="dark" :content="description" placement="bottom">
    <span class="icon" :class="iconKey"></span>
  </el-tooltip>
</template>

<script>
export default {
  name: "icon",
  props: {
    iconKey: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.icon {
  display: inline-block;
  background: center/100% no-repeat content-box;
  width: 16px;
  height: 16px;
}
.addCircle {
  background-image: url("../../assets/icons/addCircle.svg");
}
.checklist {
  background-image: url("../../assets/icons/checklist.svg");
}
.delete {
  background-image: url("../../assets/icons/delete.svg");
}
.edit {
  background-image: url("../../assets/icons/edit.svg");
}
.generateNote {
  background-image: url("../../assets/icons/generateNote.svg");
}
.link {
  background-image: url("../../assets/icons/link.svg");
}
.unlink {
  background-image: url("../../assets/icons/unlink.svg");
}
.resolve {
  background-image: url("../../assets/icons/resolve.svg");
}
.unresolve {
  background-image: url("../../assets/icons/unresolve.svg");
}
.toggleOn {
  background-image: url("../../assets/icons/toggleOn.svg");
}
.toggleOff {
  background-image: url("../../assets/icons/toggleOff.svg");
}
.key {
  background-image: url("../../assets/icons/key.svg");
}
.info {
  background-image: url("../../assets/icons/info.svg");
}
</style>
