<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" title="Change Infection Site" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <h4>Current Infection Site</h4>
      <el-row>
        <el-col :span="4">Infection Site:</el-col>
        <el-col :span="16">{{ infectionCase.infectionSite }}</el-col>
      </el-row>
      <el-row v-if="infectionCase.subtypeString">
        <el-col :span="4">Type:</el-col>
        <el-col :span="16">{{ infectionCase.subtypeString }}</el-col>
      </el-row>
      <el-row v-if="infectionCase.symptoms.length">
        <el-col :span="4">Symptoms:</el-col>
        <el-col :span="16">
          <el-tag v-for="d in sortedSymptoms(infectionCase.symptoms)" :key="d.displayText" :type="tagType(d)">{{ d.displayText }}</el-tag>
        </el-col>
      </el-row>
      <br /><br />
      <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
        <!-- Infection Site -->
        <h4>New Infection Site</h4>
        <el-form-item label="Infection Site" prop="infectionSite">
          <el-select
            v-model="formModel.infectionSite"
            v-on:change="
              configureSymptoms();
              formModel.symptoms = [];
              formModel.subtypeString = null;
            "
          >
            <el-option v-for="symptomType in symptomTypes" :key="symptomType.id" :label="symptomType.displayText" :value="symptomType.type"></el-option>
          </el-select>
        </el-form-item>
        <!-- Infection Site Sub Type -->
        <el-form-item label="Type" prop="subtypeString">
          <el-select v-model="formModel.subtypeString" :disabled="!formModel.infectionSite">
            <el-option v-for="item in subTypes" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <!-- Symptoms -->
        <el-form-item v-if="showSymptoms" label="Symptoms" prop="symptoms" :disabled="!formModel.symptomType">
          <el-select v-model="formModel.symptoms" value-key="id" placeholder="Select" multiple>
            <el-option v-for="item in filteredSymptoms" :label="item.displayText" :key="item.id" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <div class="dialog-footer">
          <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Change Infection Site</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const validateArrayNotEmpty = (rule, value, callback) => {
  if (!value.length) {
    callback("This field cannot be empty");
  }
  callback();
};
import auth from "../../auth";

export default {
  name: "infection-site-editor",
  props: ["infectionCase"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      showSymptoms: false,
      symptomTypes: [],
      symptomType: null,
      subTypes: [],
      symptoms: [],
      filteredSymptoms: [],
      showSymptomsList: ["GI", "Respiratory", "UTI", "Skin"],
      formRules: {
        infectionSite: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        symptoms: [],
      },
      formModel: {
        infectionSite: "",
        subtypeString: "",
        symptoms: [],
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
  },
  methods: {
    cancelForm() {
      this.$emit("infection-site-editor-cancel");
    },
    async commitForm() {
      console.log(this.formModel);
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          this.dialogBusy = true;
          const raw = { ...this.formModel };
          // setup infection site update body
          const infectionSiteUpdate = {
            symptomIds: raw.symptoms.map((s) => s.id),
            symptomType: this.symptomType.type,
            infectionSite: raw.infectionSite,
            subtypeString: raw.subtypeString,
          };
          if (!this.symptomType.allowedToMobile) {
            infectionSiteUpdate.symptomType = null;
            infectionSiteUpdate.infectionSite = this.symptomType.type;
          }

          // update infection case
          await this.$http.patch(`${window.CONFIG.infection_api}/${this.infectionCase.id}/infection-site`, JSON.stringify(infectionSiteUpdate));
          this.$emit("infection-site-editor-save", { ...infectionSiteUpdate, symptoms: raw.symptoms });
        } else {
          return false;
        }
      });
    },
    configureSymptoms() {
      const { infectionSite } = this.formModel;
      if (!infectionSite) {
        return;
      }
      // configure symptomType
      const symptomType = this.symptomTypes.find((item) => infectionSite === item.type);
      this.symptomType = symptomType;

      // configure symptoms
      this.showSymptoms = this.showSymptomsList.includes(infectionSite);
      this.filteredSymptoms = this.symptoms.filter((item) => item.classType === infectionSite);
      if (symptomType?.requireSymptoms) {
        this.formRules.symptoms.push({ required: true, validator: validateArrayNotEmpty, message: "This field  is required", trigger: "blur" });
      } else {
        this.formRules.symptoms = [];
      }
      // configure symptom type sub types
      this.subTypes = symptomType?.subTypes || [];
    },
    sortedSymptoms(symptoms) {
      if (!symptoms || symptoms.length <= 1) {
        return symptoms;
      }
      let sortedSymptoms = [];
      symptoms.forEach((s) => {
        sortedSymptoms.push(s);
      });
      sortedSymptoms.sort(function (a, b) {
        let compA = a.classType || "";
        let compB = b.classType || "";

        if (compA === "GI") compA = "a" + a.displayText;
        else if (compA === "Respiratory") compA = "b" + a.displayText;
        else if (compA === "UTI") compA = "c" + a.displayText;
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a" + b.displayText;
        else if (compB === "Respiratory") compB = "b" + b.displayText;
        else if (compB === "UTI") compB = "c" + b.displayText;
        else compB = "d" + b.displayText;

        let x = compA.toLowerCase();
        let y = compB.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      return sortedSymptoms;
    },
    tagType: function (type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
  },
  async created() {
    // setup auth
    this.canModify = auth.canModify();
    this.isAdminStaff = auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    this.symptoms = this.$configStore.data.symptoms || [];
    this.symptomTypes = (this.$configStore.data.symptomTypes || []).filter((s) => s.type !== "Common");
    this.siteAndTypeOptions = this.$configStore.data.siteAndTypeOptions;
    this.dialogBusy = false;
  },
  mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  },
};
</script>