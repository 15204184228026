<template>
  <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Scope -->
      <el-form-item label="Scope" prop="scope">
        <el-select v-model="formModel.scope">
          <el-option label="Facility" value="Facility"></el-option>
          <el-option label="Unit" value="Unit"></el-option>
        </el-select>
      </el-form-item>
      <!-- Facility -->
      <el-form-item v-if="facilities.length > 1" label="Facility" prop="facility">
        <el-select v-model="formModel.facility" value-key="id" filterable :disabled="isEdit">
          <el-option v-for="item in facilities" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Unit -->
      <el-form-item v-if="formModel.scope === 'Unit'" label="Unit" prop="units">
        <el-select v-model="formModel.units" value-key="displayText" multiple filterable :disabled="!formModel.facility">
          <el-option v-for="item in units" :key="item.displayText" :label="item.displayText" :value="item.displayText"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Infection Site -->
      <el-form-item label="Infection Site" prop="typeValue">
        <el-select v-model="formModel.typeValue" :disabled="isEdit">
          <el-option v-for="e in infectionSites" :key="e.id" :label="e.label" :value="e.label"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Causative Organism -->
      <el-form-item label="Causative Organisms" prop="organism">
        <el-select v-model="formModel.organism" filterable multiple clearable>
          <el-option v-for="e in organisms" :key="e.id" :label="e.name" :value="e.name"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Facility Status -->
      <el-form-item label="Facility Status" prop="facStatus">
        <el-select v-model="formModel.facStatus">
          <el-option v-for="e in facStatuses" :key="e" :label="e" :value="e"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Declared Date -->
      <el-form-item label="Declared Date" prop="declaredDate">
        <el-date-picker v-model="formModel.declaredDate" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Select date" :picker-options="pickerOptions"> </el-date-picker>
      </el-form-item>
      <!-- Confirmed -->
      <el-form-item label="Confirmed" prop="confirmed">
        <el-switch v-model="formModel.confirmed" on-text="Yes" off-text="No" on-color="#13ce66" off-color="#ff4949"> </el-switch>
      </el-form-item>
      <!-- Case Number -->
      <el-form-item label="Case Number" prop="caseNumber">
        <el-input placeholder="Case Number" v-model="formModel.caseNumber"></el-input>
      </el-form-item>
      <!-- Case Definition -->
      <el-form-item label="Case Definition" prop="caseDefinition">
        <el-input type="textarea" :rows="3" placeholder="Case Definition" :maxlength="caseDefinitionMaxLength" v-model="formModel.caseDefinition"> </el-input>
        <character-count :limit="caseDefinitionMaxLength" :count="formModel.caseDefinition.length" />
      </el-form-item>
      <!-- Notes -->
      <el-form-item label="Notes" prop="notes">
        <el-input type="textarea" :rows="3" placeholder="Notes" :maxlength="notesMaxLength" v-model="formModel.notes"> </el-input>
        <character-count :limit="notesMaxLength" :count="formModel.notes.length" />
      </el-form-item>
      <div class="dialog-footer">
        <el-row>
          <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button type="primary" @click="commitForm(false)" :disabled="dialogBusy">{{ submitButton }}</el-button>
        </el-row>
        <el-row v-if="!isEdit">
          <el-button type="primary" @click="commitForm(true)" :disabled="dialogBusy">Create Outbreak Record and Manage Cases</el-button>
        </el-row>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const validateArrayNotEmpty = (rule, value, callback) => {
  if (!value.length) {
    callback("This field cannot be empty");
  }
  callback();
};
import CharacterCount from "../Shared/CharacterCount.vue";
import moment from "moment";

export default {
  name: "outbreak-editor",
  props: ["title", "outbreak"],
  components: {
    "character-count": CharacterCount,
  },
  data() {
    return {
      isEdit: false,
      submitButton: "",
      showManageButton: true,
      caseDefinitionMaxLength: 1000,
      notesMaxLength: 1000,
      dialogVisibility: true,
      dialogBusy: true,
      facilities: [],
      units: [],
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return time.getTime() > today.getTime();
        },
      },
      formRules: {
        scope: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        facility: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        typeValue: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        declaredDate: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
      },
      formModel: {
        scope: "",
        facility: {},
        units: [],
        typeValue: "",
        organism: [],
        facStatus: "",
        confirmed: false,
        declaredDate: "",
        caseNumber: "",
        caseDefinition: "",
        notes: "",
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
    "formModel.scope"(val) {
      if (val === "Unit") {
        this.formRules.units = [{ required: true, validator: validateArrayNotEmpty, message: "This field  is required", trigger: "blur" }];
      } else {
        delete this.formRules.units;
        this.formModel.units = [];
      }
    },
    "formModel.facility"(val) {
      this.units = (val.units || []).filter((unit) => unit.code !== "PLACE_HOLDER");
      this.formModel.units = [];
    },
  },
  methods: {
    setupForm() {
      // setup form either for editing an existing outbreak or creating a new outbreak
      if (this.isEdit) {
        this.submitButton = "Update Outbreak Record";
        this.formModel.scope = this.outbreak.scope;
        this.formModel.facility = this.facilities.find((fac) => fac.id === this.outbreak.facId);
        this.formModel.typeValue = this.infectionSites.find((e) => this.outbreak.typeValue === e.label).label;
        const organisms = this.getOrganismsFromConfig(this.outbreak.organism);
        this.formModel.organism = organisms;
        this.formModel.facStatus = this.outbreak.facStatus || "";
        this.formModel.declaredDate = this.outbreak.declaredDate ? moment.utc(this.outbreak.declaredDate).local(true).toDate() : "";
        this.formModel.confirmed = this.outbreak.confirmed || false;
        this.formModel.caseNumber = this.outbreak.caseNumber || "";
        this.formModel.caseDefinition = this.outbreak.caseDefinition || "";
        this.formModel.notes = this.outbreak.notes || "";
        // 'formModel.facility' reactive logic conflicts with unit assignment; $nextTick ensures formModel.units is set after facility handler is called
        this.$nextTick(() => {
          if (this.outbreak.scope === "Unit") {
            this.formModel.units = this.outbreak.scopeDetail;
          }
        });
      } else {
        this.submitButton = "Create Outbreak Record";
        this.formModel.facility = this.facilities.length === 1 ? this.facilities[0] : {};
      }
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    getOrganismsFromConfig(names) {
      const nameSet = new Set(names);
      return this.organisms.filter((organism) => nameSet.has(organism.name)).map((organism) => organism.name);
    },
    async getChecklistConfig() {
      const response = await this.$http.get(`${window.CONFIG.api}/outbreakconfigs`);
      return response.body[0].checkList;
    },
    async commitForm(manageCases) {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogBusy = true;
            // setup request body
            const raw = { ...this.formModel };
            const outbreak = {
              facId: raw.facility.id,
              scope: raw.scope,
              scopeDetail: raw.scope === "Facility" ? [raw.facility.displayText] : raw.units,
              typeValue: raw.typeValue,
              organism: raw.organism,
              facStatus: raw.facStatus || null,
              confirmed: raw.confirmed,
              declaredDate: raw.declaredDate,
              caseNumber: raw.caseNumber || null,
              caseDefinition: raw.caseDefinition || null,
              notes: raw.notes || null,
              checklist: this.checklist || [],
            };
            // create or update outbreak
            let result;
            if (this.isEdit) {
              result = await this.$http.patch(`${window.CONFIG.om_api}/${this.outbreak.id}`, outbreak);
            } else {
              result = await this.$http.post(`${window.CONFIG.om_api}`, outbreak);
            }
            this.dialogBusy = false;
            this.$emit("editor-save");
            if (manageCases) {
              this.$emit("editor-manage-cases", result.data);
            }
          } catch (error) {
            this.$emit("editor-error");
          }
        } else {
          return false;
        }
      });
    },
  },
  async created() {
    this.isEdit = this.outbreak.id ? true : false;
    this.infectionSites = this.$configStore.data.siteAndTypeOptions.filter((e) => e.label !== "All Infection Sites");
    this.organisms = this.$configStore.data.organisms;
    this.facilities = this.$configStore.data.sites;
    this.facStatuses = this.$configStore.data.config.FacilityStatus || ["Open", "Home Closed", "Unit Closed"];
    this.checklist = await this.getChecklistConfig();
    if (this.outbreak?.facId) {
      this.units = (this.facilities.find((fac) => fac.id === this.outbreak.facId).units || []).filter((unit) => unit.code !== "PLACE_HOLDER");
    }
    this.setupForm();
    this.dialogBusy = false;
  },
};
</script>