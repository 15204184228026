<template>
  <div>
    <div v-for="section in sections" v-bind:key="section.name">
      <el-row class="filter-group-label" v-if="section.name !== null">{{ section.name }}:</el-row>
      <el-row class="filter-section">
        <label v-for="filter in section.filters" v-bind:key="filter.name">
          <span class="filter-title-label">{{ filter.name }}</span>
          <el-date-picker v-if="filter.type === 'daterange'" class="filter-section-input" label="Date Range" v-model="filter.selection" type="daterange" placeholder="Date Range" :format="$configStore.datePickerDateFormat()" range-separator=" ~ " :picker-options="pickerOptions" :clearable="false" small v-on:change="updateFilter">
            <template slot="prepend"></template>
          </el-date-picker>
          <el-select v-if="filter.type === 'options'" v-model="filter.selection" :placeholder="filter.name" class="filter-section-input" v-on:change="updateFilter">
            <el-option v-for="option in filter.options" :label="option.name" v-bind:key="option.value" :value="option.value"> </el-option>
          </el-select>
          <el-cascader v-if="filter.type === 'subOptions'" class="filter-section-input" :class="{ 'is-open': fixClick[filter.name] }" @click="fixClick[filter.name] = !fixClick[filter.name]" :options="filter.options" v-model="filter.selection" :placeholder="filter.name" @change="updateFilter"></el-cascader>
        </label>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "inf-filter",
  props: ["sections", "pickerOptions"],
  data: function() {
    return {
      filterKeys: [],
      filtersByKey: {},
      fixClick: {}
    };
  },
  watch: {
    sections: function(v) {
      console.log("sections loaded");
      this.loadSection(v);
    }
  },
  methods: {
    loadSection: function(v) {
      let filtersByKey = {};
      let filterKeys = [];
      if (v && Array.isArray(v)) {
        v.forEach(section => {
          let filters = section ? section.filters : null;
          if (filters && Array.isArray(filters)) {
            filters.forEach(filter => {
              if (filter && filter.selectionKey) {
                filterKeys.push(filter.selectionKey);
                filtersByKey[filter.selectionKey] = filter;
              }
            });
          } else {
            console.log("no filters");
          }
        });
      }

      this.filterKeys = filterKeys;
      this.filtersByKey = filtersByKey;
    },
    updateFilter: function() {
      console.log(this.filtersByKey);
      let keyValues = {};
      this.filterKeys.forEach(k => {
        let value = this.filtersByKey[k].selection;
        keyValues[k] = value;
      });
      this.$emit("update:filter", keyValues);
    },
    loadFilters: function() {
      this.loadSection(this.sections);
      console.log("loadFilters");
    }
  },
  created: function() {
    this.loadFilters();
  },
  mounted: function() {
    this.loadFilters();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-picker-panel__sidebar {
  width: 120px;
}
</style>
<style scoped>
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: red;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: green;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 9px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0;
}

.graph-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 9px;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filter {
  width: 150px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input,
.filter-section .el-input-number,
.filter-section .el-checkbox {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section .el-checkbox {
  padding: 10px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}

.case-page-size-filter {
  float: left;
}

.el-form .filter-section-input {
  width: 100%;
}

.el-form {
  padding-right: 10%;
}
</style>
