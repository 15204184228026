<template>
  <div class="invalid-user-page">
    <p>User not registered. Please contact your administrator.</p>
    <p v-if="loggedIn()"><el-button type="primary" size="large" @click="logout">Logout</el-button></p>
  </div>
</template>

<script>
import auth from "../auth";

export default {
  props: ["idp_type"],
  data: function() {
    return {
      idp: null
    };
  },
  methods: {
    loggedIn() {
      return auth.loggedIn() || this.idp === "azure_ad_saml";
    },
    logout() {
      if (this.idp === "azure_ad_saml") {
        window.location = "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0";
      } else {
        auth.logout();
      }
    }
  },
  mounted: function() {
    console.log(this.idp_type);
    this.idp = this.idp_type;
  }
};
</script>

<style scoped>
.invalid-user-page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  font-size: 1.2rem;
}
</style>
