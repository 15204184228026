<template>
  <div class="help-page">
    Help Page Contents
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help-page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
}
</style>
