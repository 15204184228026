<template>
  <div class="surveillance-labs">
    <h3>Linked Labs</h3>
    <el-table v-if="rendered" class="table" :data="linkedLabs">
      <el-table-column label="Primary" width="100">
        <template scope="scope">
          <el-checkbox :checked="isPrimary(scope.row)" :disabled="!allowPrimary(scope.row)" @change="primarySelected($event, scope.row, scope.$index)"></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column label="Culture Date" width="125">
        <template scope="scope">
          {{ scope.row.cultureDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Results" width="100">
        <template scope="scope">
          {{ scope.row.result }}
        </template>
      </el-table-column>
      <el-table-column label="Organism" width="160">
        <template scope="scope">
          {{ scope.row.organism }}
        </template>
      </el-table-column>
      <el-table-column label="Procedure" width="160">
        <template scope="scope">
          {{ scope.row.procedure }}
        </template>
      </el-table-column>
      <el-table-column label="Source" width="130">
        <template scope="scope">
          {{ scope.row.source }}
        </template>
      </el-table-column>
      <el-table-column label="Reason" width="130">
        <template scope="scope">
          {{ scope.row.reason }}
        </template>
      </el-table-column>
      <el-table-column label="Collection Date" width="125">
        <template scope="scope">
          {{ scope.row.collectionDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Lab ID" width="150">
        <template scope="scope">
          {{ scope.row.labId }}
        </template>
      </el-table-column>
      <el-table-column label="Notes" width="350">
        <template scope="scope">
          {{ scope.row.notes }}
        </template>
      </el-table-column>

      <el-table-column label="" width="100" align="right" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button :disabled="!canModify" type="info" @click="generateNote(scope.row)" size="small">
              <Icon :iconKey="'generateNote'" :description="'Generate note'" />
            </el-button>
            <el-button @click="toggleLinkToCaseId(scope.row, caseId)" size="small">
              <Icon :iconKey="'unlink'" :description="'Unlink'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <h3>Lab History</h3>
    <el-row type="flex" justify="space-between">
      <el-col>
        Culture Date Range:

        <el-date-picker type="daterange" placeholder="All Dates" v-model="filterDateRange" :format="$configStore.datePickerDateFormat()" :range-separator="datepickerDelimiter" :picker-options="pickerOptions" :clearable="false" small v-on:change="dateRangeUpdated">
          <template slot="prepend">Http://</template>
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-button :disabled="isEditing !== -1 || !canModify" @click="createLab()">+ New Lab Result</el-button>
      </el-col>
    </el-row>
    <el-table v-if="rendered" class="table" :data="labHistoryItems">
      <el-table-column label="Culture Date" width="125">
        <template scope="scope">
          {{ scope.row.cultureDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Results" width="100">
        <template scope="scope">
          {{ scope.row.result }}
        </template>
      </el-table-column>
      <el-table-column label="Organism" width="160">
        <template scope="scope">
          {{ scope.row.organism }}
        </template>
      </el-table-column>
      <el-table-column label="Procedure" width="160">
        <template scope="scope">
          {{ scope.row.procedure }}
        </template>
      </el-table-column>
      <el-table-column label="Source" width="130">
        <template scope="scope">
          {{ scope.row.source }}
        </template>
      </el-table-column>
      <el-table-column label="Reason" width="130">
        <template scope="scope">
          {{ scope.row.reason }}
        </template>
      </el-table-column>
      <el-table-column label="Collection Date" width="125">
        <template scope="scope">
          {{ scope.row.collectionDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Lab ID" width="150">
        <template scope="scope">
          {{ scope.row.labId }}
        </template>
      </el-table-column>
      <el-table-column label="Notes" width="350">
        <template scope="scope">
          {{ scope.row.notes }}
        </template>
      </el-table-column>

      <el-table-column label="" width="195" align="right" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button :disabled="!canModify" type="info" @click="generateNote(scope.row)" size="small">
              <Icon :iconKey="'generateNote'" :description="'Generate note'" />
            </el-button>
            <el-button v-if="isEditing === -1" :disabled="!canModify || scope.row._isLinked" @click="toggleLinkToCaseId(scope.row, caseId)" size="small">
              <Icon :iconKey="'link'" :description="'Link'" />
            </el-button>
            <el-button :disabled="!canModify" v-if="isEditing === -1" @click="editAction(scope.row)" size="small">
              <Icon :iconKey="'edit'" :description="'Edit'" />
            </el-button>
            <el-button :disabled="!canModify" v-if="isEditing === -1" @click="addOrganism(scope.row)" size="small">
              <Icon :iconKey="'addCircle'" :description="'Add Organism'" />
            </el-button>
            <el-button :disabled="!canModify" v-if="isEditing === -1" @click="deleteAction(scope.$index, scope.row)" size="small">
              <Icon :iconKey="'delete'" :description="'Delete'" />
            </el-button>
            <el-button v-if="editing(scope.row)" :disabled="!isValid(scope.row) || !canModify" @click="saveAction(scope.$index, scope.row)" size="small"> Save </el-button>
            <el-button :disabled="!canModify" v-if="editing(scope.row)" @click="cancelAction(scope.$index, scope.row)" size="small"> Cancel </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <LabResultsWizard v-if="showLabResultsWizard" v-on:wizard-closed="labWizardClosed" v-on:lab-created="labCreatedEvent" v-on:lab-updated="labUpdatedEvent" :labResultData="selectedLabResults" :mode="labResultsWizardMode"> </LabResultsWizard>

    <div ref="noteDialogContainer">
      <el-dialog :visible="generatedNoteVisibility" title="Generated Note" :close-on-click-modal="false" :close-on-press-escape="false">
        <template>
          <textarea v-model="generatedNoteString" style="font: 12px/1.5em 'Menlo', 'Consolas', monospace; height: 300px; width: 100%; overflow: auto"> </textarea>
          <el-button @click="copyNote()">Copy to Clipboard</el-button>
        </template>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                this.generatedNoteVisibility = false;
              }
            "
            >Close</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import api from "../services/restClient";
import auth from "../auth";
import moment from "moment";
import { Loading } from "element-ui";
import LabResultsWizard from "../components/LabResultsWizard";
import Icon from "../components/Shared/Icon.vue";

export default {
  name: "surveillance-lab-results",
  props: ["caseId", "case"],
  components: {
    LabResultsWizard,
    Icon,
  },
  data: function () {
    return {
      generatedNoteVisibility: false,
      generatedNoteString: "",
      datepickerDelimiter: " ~ ",
      canModify: false,
      rendered: true,
      primaryOrganism: undefined,
      isEditing: -1,
      reports: [],
      procedures: [],
      sources: [],
      organisms: [],
      labHistoryItems: [],
      linkedLabs: [],
      showLabResultsWizard: false,
      selectedLabResults: null,
      labResultsWizardMode: "",
      validPrimaryResults: ["Positive", "Indeterminate", "Preliminary Positive - POC Device", "Preliminary Indeterminate - POC Device"],
      pickerOptions: {
        shortcuts: [
          {
            text: "Last 7 Days",
            onClick(picker) {
              const start = moment().subtract(6, "days").toDate();
              const end = moment().toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 30 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(30, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 90 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(90, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = moment().weekday(-1).toDate();
              const start = moment().weekday(-7).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const lastMonth = moment().subtract(1, "months");
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const lastYear = moment().subtract(1, "years");
              const end = lastYear.endOf("year").toDate();
              const start = lastYear.startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current week",
            onClick(picker) {
              const end = moment().weekday(6).toDate();
              const start = moment().weekday(0).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current month",
            onClick(picker) {
              const lastMonth = moment();
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current year",
            onClick(picker) {
              const end = moment().endOf("year").toDate();
              const start = moment().startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      filterDateRange: [moment().subtract(30, "days").toDate(), moment().toDate()],
    };
  },
  watch: {
    caseId: function (value) {
      if (value > 0) {
        this.reload();
      }
    },
    case: function (value) {
      console.log(value);
      if (value && value.primaryOrganism) {
        this.primaryOrganism = value.primaryOrganism;
        if (this.rendered) {
          let vm = this;
          this.rendered = false;
          this.$nextTick(() => {
            vm.rendered = true;
          });
        }
      }
    },
  },
  methods: {
    dateRangeUpdated: function (value) {
      if (!value) {
        this.filterDateRange = ["", ""];
      } else {
        let [start, end] = value.split(this.datepickerDelimiter);
        this.filterDateRange[0] = moment(start);
        this.filterDateRange[1] = moment(end);
      }
      this.loadLabHistory();
    },
    isPrimary(row) {
      return !!this.primaryOrganism && row.organism === this.primaryOrganism;
    },
    async toggleLinkToCaseId(row, caseId) {
      var loadingInstance = Loading.service({ fullscreen: true });
      const shouldLink = row.caseId ? false : true;
      row.caseId = shouldLink ? caseId : null;
      const jsonData = JSON.stringify(row);
      this.$http.put(`${window.CONFIG.api}/labs/${row.id}`, jsonData).then(
        async (response) => {
          console.log(response.body);
          // When linking or unlinking, update primary if valid
          await this.updatePrimaryIfValid(row, shouldLink);
          await this.loadLinkedLabs();
          await this.loadLabHistory();
          this.rendered = false;
          this.$nextTick(() => {
            this.rendered = true;
            loadingInstance.close();
          });
        },
        (response) => {}
      );
    },
    async updatePrimaryIfValid(lab, shouldLink = true) {
      if (shouldLink && !this.primaryOrganism && this.allowPrimary(lab)) {
        await this.updatePrimary(lab.organism);
      } else if (!shouldLink && this.isPrimary(lab)) {
        await this.updatePrimary("");
      }
    },
    // only allow setting a lab as primary when it has an organism and its result has a valid value
    allowPrimary(lab) {
      return this.validPrimaryResults.includes(lab.result) && lab.organism;
    },
    showLabResultsForm(row) {
      this.selectedLabResults = JSON.parse(JSON.stringify(row));
      this.selectedLabResults.client = JSON.parse(JSON.stringify(this.case.client));
      this.selectedLabResults._presetClient = true;
      this.showLabResultsWizard = true;
    },
    primarySelected(event, row) {
      let vm = this;
      this.rendered = false;
      this.$nextTick(() => {
        if (vm.isPrimary(row) && !event.target.checked) {
          // prevent unchecking
          event.preventDefault();
          event.target.checked = true;
          vm.rendered = true;
          return;
        } else if (event.target.checked) {
          vm.primaryOrganism = row.organism;
        }

        vm.rendered = true;
        if (vm.isEditing !== row.id) {
          vm.updatePrimary(vm.primaryOrganism || "");
        }
      });
    },
    deleteAction(index, row) {
      // let vm = this
      this.$confirm("Delete this record?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          var loadingInstance = Loading.service({ fullscreen: true });
          if (this.isPrimary(row)) {
            let counter = 0;
            for (let report of this.linkedLabs) {
              if (this.isPrimary(report)) {
                counter = counter + 1;
              }
            }

            if (counter === 1) {
              this.updatePrimary("");
            }
          }
          let url = `${window.CONFIG.api}/labs/${row.id}`;
          this.$http.delete(url).then(
            (response) => {
              loadingInstance.close();
              this.labHistoryItems = this.labHistoryItems.filter((l) => {
                return l.id !== row.id;
              });
            },
            (error) => {
              throw Error(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editAction(row) {
      this.labResultsWizardMode = "edit";
      this.showLabResultsForm(row);
    },
    async updatePrimary(organism) {
      console.log("updatePrimary", organism);
      const promise = new Promise((resolve, reject) => {
        api.postPrimaryOrganism(this.caseId, organism, (response) => {
          console.log(response);
          this.primaryOrganism = organism;
          this.case.primaryOrganism = organism;
          resolve();
        });
      });
      return promise;
    },
    saveAction(index, row) {
      var loadingInstance = Loading.service({ fullscreen: true });
      this.isEditing = -1;
      let data = JSON.parse(JSON.stringify(row));
      let dString = data.cultureDate;
      if (dString) {
        let d = new Date(dString);
        data.cultureDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
      }
      console.log(data);
      if (data.id === 0) {
        delete data.id;
      }
      let vm = this;
      api.postLabReport(this.caseId, data, (response) => {
        console.log(response);
        loadingInstance.close();
        vm.reload();
      });

      if (row.organism !== "Other" && (this.reports || []).length === 1) {
        this.primaryOrganism = row.organism || "";
      }

      this.updatePrimary(this.primaryOrganism || "");
    },
    cancelAction(index, row) {
      console.log("cancel");
      this.primaryOrganism = this.case.primaryOrganism;
      this.reload();
    },
    editing(record) {
      return record && this.isEditing !== -1 && this.isEditing === record.id;
    },
    isValid(record) {
      // no longer require lab id // record.labId &&
      /**
       * Please change the requirements rules around adding lab results in the labs tab
       * when editing/updating a case – do not make it a hard requirement for any information related to
       * Lab ID Number, Source or Procedure. Display “Not Selected” in the Source and Procedure drop downs
       * if nothing is selected by the user. Require the users to enter a date – default to today’s date (same as current).
       * Require users to enter a Result (Positive or Negative) if they choose an Organism.
       * If no Organism is chosen do not require an Organism to be selected (currently working).
       */
      // return record.source && record.procedure &&
      return record.cultureDate && (record.organism || record.result === "Negative") && record.result;
    },
    reload() {
      this.originalValue = null;
      this.isEditing = -1;
      this.reports = [];
      this.config = this.$configStore.data.config || {};

      // load sources
      let srcs = this.config.LabSource || [];
      let srcOptions = [
        {
          label: "Not Selected",
          value: "",
        },
      ];
      srcs.sort((a, b) => {
        if (a.toLowerCase() === "unknown") {
          return -1;
        } else if (b.toLowerCase() === "unknown") {
          return 1;
        } else {
          return a > b ? 1 : -1;
        }
      });
      srcs.forEach((d) => {
        srcOptions.push({
          label: d,
          value: d,
        });
      });
      this.sources = srcOptions;
      console.log(this.config);

      // load procedures
      let proc = this.config.LabProcedures || [];
      let procOptions = [
        {
          label: "Not Selected",
          value: "",
        },
      ];
      proc = proc.sort((a, b) => {
        if (a.toLowerCase() === "unknown") {
          return -1;
        } else if (b.toLowerCase() === "unknown") {
          return 1;
        } else {
          return a > b ? 1 : -1;
        }
      });
      proc.forEach((l) => {
        procOptions.push({ label: l, value: l });
      });
      this.procedures = procOptions;

      // load list
      // let vm = this
      // api.getLabReport(this.caseId, (response) => {
      //   console.log(response)
      //   if (response) {
      //     vm.reports = response.body || []
      //   }
      // })
    },
    loadLabHistory() {
      let url = `${window.CONFIG.api}/labs/?clientId=${this.case.client.id}`;
      if (this.filterDateRange[0]) {
        url += `&start=${new Date(this.filterDateRange[0])}`;
      }
      if (this.filterDateRange[1]) {
        url += `&end=${new Date(this.filterDateRange[1])}`;
      }
      return this.$http.get(url).then(
        (response) => {
          this.labHistoryItems = response.body.content
            .map((item) => {
              item._isLinked = item.caseId === this.caseId;
              return item;
            })
            .sort((a, b) => a.id - b.id); // stabilize the sort
          // console.log(JSON.parse(JSON.stringify(this.labHistoryItems)))
        },
        (error) => {
          throw Error(error);
        }
      );
    },
    loadLinkedLabs() {
      let url = `${window.CONFIG.api}/labs/?caseId=${this.caseId}`;
      return this.$http.get(url).then(
        (response) => {
          this.linkedLabs = response.body.content;
        },
        (error) => {
          throw Error(error);
        }
      );
    },
    addOrganism(row) {
      this.labResultsWizardMode = "addOrganism";
      row.caseId = null;
      this.showLabResultsForm(row);
    },
    createLab() {
      this.labResultsWizardMode = "new";
      this.showLabResultsForm({});
    },
    async labCreatedEvent(lab) {
      // auto-link new lab to case
      this.labWizardClosed();
      await this.toggleLinkToCaseId(lab, this.caseId);
    },
    async labUpdatedEvent(lab) {
      this.labHistoryItems = this.labHistoryItems.map((l) => {
        if (l.id === lab.id) {
          return lab;
        } else {
          return l;
        }
      });
      this.labWizardClosed();
      const loadingInstance = Loading.service({ fullscreen: true });
      await this.updatePrimaryIfValid(lab);
      await this.loadLinkedLabs();
      await this.loadLabHistory();
      this.rendered = false;
      this.$nextTick(() => {
        this.rendered = true;
        loadingInstance.close();
      });
    },
    labWizardClosed() {
      this.showLabResultsWizard = false;
    },
    formatDate(timestamp) {
      return timestamp ? moment(timestamp).format(this.$configStore.dateFormat()) : "";
    },
    generateNote(data) {
      const { collectionDate, procedure, result, organism } = data;
      const { client } = this.case;
      this.generatedNoteString = `${client.firstName} ${client.lastName} was swabbed on ${this.formatDate(collectionDate)}. `;
      if (result === "Refused") {
        this.generatedNoteString += "The test was refused.";
      } else if (result === "Test Not Performed") {
        this.generatedNoteString += "The test was not performed.";
      } else {
        this.generatedNoteString += `A ${procedure} determined a ${result} ${organism} result.`;
      }
      this.generatedNoteVisibility = true;
      if (this.$refs.noteDialogContainer) {
        document.body.append(this.$refs.noteDialogContainer);
      }
    },
    copyNote() {
      navigator.clipboard.writeText(this.generatedNoteString).then(
        () => {
          this.$message("Note successfully copied!");
        },
        () => {
          this.$message.error("Note could not be copied");
        }
      );
    },
  },
  mounted() {
    this.canModify = auth.canModify();
    console.log("mounted immunizations");
    var vm = this;
    this.primaryOrganism = this.case.primaryOrganism;
    this.$http.get(window.CONFIG.api + "/organisms/").then(
      (response) => {
        let organisms = response.body["_embedded"].organisms || [];
        organisms.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x === "other") {
            x = "0";
          }
          if (y === "other") {
            y = "0";
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        vm.organisms = organisms;
        console.log(vm.organisms);
      },
      (response) => {
        // error callback
      }
    );
    this.reload();
    this.loadLabHistory();
    this.loadLinkedLabs();
  },
};
</script>
<style scoped>
.table {
  margin-top: 1em;
}
</style>
