<template>
  <div class="outbreak-management">
    <!-- Infection Case Filters -->
    <filters-section v-if="global.dateRangeLoaded" :showLocation="true" :isOutbreakDetail="true" :defaultValues="global.filterDefaults" :filterDisplayKeys="getFilterKeys()" :dateRangeStart="global.defaultDateStart" :dateRangeEnd="global.defaultDateEnd" v-on:change="formatFilters"> </filters-section>
    <!-- Outbreak Table -->
    <page-section title="Outbreak Summary" v-loading="outbreakSection.loading">
      <el-table :data="[outbreakSection.data]">
        <el-table-column fixed label="Status" width="80">
          <template scope="scope">
            <div style="padding: 0.5em">
              <img class="status-icon" src="/static/outbreak_confirmed.png" v-if="scope.row.confirmed" />
              <img class="status-icon" src="/static/outbreak_suspected.png" v-else />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="facility" fixed label="Facility" width="120" v-if="global.facilities.length !== 1"> </el-table-column>
        <el-table-column fixed label="Scope" width="120">
          <template scope="scope">
            <div style="padding: 0.5em">
              {{ scope.row.scopeFormatted }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="typeValue" label="Outbreak Type" width="140">
          <template slot-scope="scope">
            <span class="color-code-label-0" v-if="scope.row.typeValue === 'GI'"></span>
            <span class="color-code-label-2" v-else-if="scope.row.typeValue === 'UTI'"></span>
            <span class="color-code-label-1" v-else-if="scope.row.typeValue === 'Respiratory'"></span>
            <span class="color-code-label-3" v-else></span>
            {{ scope.row.typeValue }}
          </template>
        </el-table-column>
        <el-table-column prop="organismFormatted" label="Causative Organisms" width="200"> </el-table-column>
        <el-table-column prop="facStatus" label="Facility Status" width="140"> </el-table-column>
        <el-table-column prop="declaredDateFormatted" label="Date Declared" width="140"> </el-table-column>
        <el-table-column prop="resolvedDateFormatted" label="Date Resolved" width="140"> </el-table-column>
        <el-table-column prop="firstCaseDateFormatted" label="First Case Date" width="140"> </el-table-column>
        <el-table-column prop="daysInOutbreak" label="Days In Outbreak" width="140"> </el-table-column>
        <el-table-column prop="totalCases" label="# of Cases" width="140"> </el-table-column>
        <el-table-column prop="residentCases" label="# of Resident Cases" width="140"> </el-table-column>
        <el-table-column prop="staffCases" label="# of Staff Cases" width="140"> </el-table-column>
        <el-table-column prop="deathCases" label="# of Deaths" width="140"> </el-table-column>
        <el-table-column prop="updatedDateFormatted" label="Updated" width="140"> </el-table-column>
        <el-table-column prop="updatedBy" label="Updated By" width="140"> </el-table-column>
        <el-table-column fixed="right" label="" prop="active" width="150">
          <template scope="scope">
            <el-button :disabled="!canModify" :plain="true" size="mini" @click="editOutbreak(scope.row)">
              <Icon :iconKey="'edit'" :description="'Edit Details'" />
            </el-button>
            <el-button :plain="true" size="mini" @click="viewCheckList(scope.row)">
              <Icon :iconKey="'checklist'" :description="'View Checklist'" />
            </el-button>
            <el-button :disabled="!canModify" v-if="!scope.row.resolvedDate" :plain="true" size="mini" @click="resolveOutbreak">
              <Icon :iconKey="'resolve'" :description="'Resolve Outbreak'" />
            </el-button>
            <el-button :disabled="!canModify" v-else :plain="true" size="mini" @click="resolveOutbreak">
              <Icon :iconKey="'unresolve'" :description="'Unresolve Outbreak'" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <page-section title="Outbreak Details" v-loading="graphSection.loading">
      <template slot="title">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Day"></el-radio-button>
          <el-radio-button label="Week"></el-radio-button>
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
      </template>
      <div style="margin: 10px">
        <stacked-column-chart :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true" :configuration="graphSection.customizations"></stacked-column-chart>
      </div>
    </page-section>
    <!-- Infection Cases Table -->
    <page-section :title="`Case Details (${infectionCaseSection.pagination.total})`" v-loading="infectionCaseSection.loading">
      <template slot="title">
        <el-button :disabled="$store.getters.reportsIsBusy" :plain="true" type="primary" size="mini" @click="lineListReport()">Surveillance Report</el-button>
        <el-button :plain="true" type="primary" size="mini" @click="exportInfectionCases()">Export Data</el-button>
        <el-button :disabled="!canModify" type="warning" size="mini" @click="manageInfectionCases()">Manage Infection Cases</el-button>
        <el-button :disabled="!canModify" type="info" size="mini" @click="createInfectionCase()">+ New Case</el-button>
        <el-pagination
          @size-change="
            (size) => {
              infectionCaseSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              infectionCaseSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 50]"
          :page-size="infectionCaseSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="infectionCaseSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="infectionCaseSection.data" v-on:row-click="editInfectionCase">
        <el-table-column fixed label=" " width="100">
          <template slot-scope="scope">
            <div style="padding: 0.5em">
              <div :span="4" class="profile-row-photo" v-if="scope.row.client"><img :src="getClientPhoto(scope.row.client)" /></div>
              <div :span="4" class="profile-row-photo" v-else><img src="../../assets/user.png" /></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name (MRN)" fixed width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.client">{{ scope.row.nameFormatted }}<br />({{ scope.row.mrn }})</span>
            <span v-else>{{ scope.row.nameFormatted }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Infection Site/Type" width="250">
          <template slot-scope="scope">
            <span class="color-code-label-0" v-if="scope.row.infectionSite === 'GI'"></span>
            <span class="color-code-label-2" v-else-if="scope.row.infectionSite === 'UTI'"></span>
            <span class="color-code-label-1" v-else-if="scope.row.infectionSite === 'Respiratory'"></span>
            <span class="color-code-label-3" v-else></span>
            {{ scope.row.infectionSite }} {{ scope.row.infectionType ? " / " + scope.row.infectionType : "" }}
          </template>
        </el-table-column>
        <el-table-column label="Status" width="80">
          <template scope="scope">
            <img class="symptom-status-image" :src="statusImage(scope.row)" />
          </template>
        </el-table-column>
        <el-col :span="8">Symptoms:</el-col>
        <el-table-column label="Symptoms" width="200">
          <template scope="scope">
            <el-tag v-for="symptom in scope.row.symptoms" :key="symptom.id" :type="tagType(symptom)">{{ symptom.displayText }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Precautions" width="200">
          <template scope="scope">
            <el-tag :type="precautionIsActive(d) ? 'danger' : 'gray'" :key="d.id" v-for="d in filterPrecautions(scope.row.precautions)">{{ precautionById(d.precautionId) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Onset Location" width="140"
          ><template scope="scope"
            ><div>{{ scope.row.client.location }}</div></template
          >
        </el-table-column>
        <el-table-column label="Outcome" width="200">
          <template scope="scope">
            <div>{{ scope.row.outcome }}{{ scope.row.outcomeSubType ? ` / ${scope.row.outcomeSubType}` : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="isStaff" label="Staff" width="140"> </el-table-column>
        <el-table-column fixed="right" sortable label="Onset Date" prop="onsetDateFormatted" width="120"> </el-table-column>
        <el-table-column fixed="right" label="" prop="active" width="60">
          <template scope="scope">
            <el-button :disabled="!canModify" :plain="true" size="mini" @click="unlinkInfectionCase(scope.row.id)">
              <Icon :iconKey="'unlink'" :description="'Unlink Infection Case'" />
            </el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="" prop="active" width="60">
          <template scope="scope">
            <el-button :disabled="!canModify" v-if="!scope.row.resolutionDate" :plain="true" size="mini" @click="resolveInfectionCase(scope.row)">
              <Icon :iconKey="'resolve'" :description="'Resolve Infection Case'" />
            </el-button>
            <el-button :disabled="!canModify" v-else :plain="true" size="mini" @click="unresolveInfectionCase(scope.row)">
              <Icon :iconKey="'unresolve'" :description="'Unresolve Infection Case'" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <!-- Checklist Dialog -->
    <el-dialog title="Checklist" v-model="checklistSection.visible" :close-on-click-modal="false" :close-on-press-escape="false">
      <outbreak-checklist :outbreak="checklistSection.outbreak" v-on:close="closeChecklist" v-on:checklist-close="closeChecklist" v-on:checklist-save="updateChecklist"></outbreak-checklist>
    </el-dialog>

    <!-- Outbreak Editor -->
    <outbreak-editor
      v-if="outbreakSection.editor.visible"
      :title="outbreakSection.editor.title"
      :outbreak="outbreakSection.editor.data"
      v-on:close="closeOutbreakEditor"
      v-on:editor-close="closeOutbreakEditor"
      v-on:editor-error="
        closeOutbreakEditor();
        setError('There was an error updating the outbreak. Please click refresh to try again.');
      "
      v-on:editor-save="
        closeOutbreakEditor();
        getOutbreak(global.outbreakId);
      "
    ></outbreak-editor>

    <!-- Resolve Outbreak -->
    <resolve-outbreak
      v-if="outbreakSection.resolve.visible"
      :outbreak="outbreakSection.data"
      v-on:resolve-close="cancelResolveOutbreak"
      v-on:resolve-error="
        cancelResolveOutbreak();
        setError('There was an error updating the outbreak. Please click refresh to try again.');
      "
      v-on:resolve-save="
        cancelResolveOutbreak();
        getOutbreak(global.outbreakId);
      "
    ></resolve-outbreak>

    <!-- Infection Case Creator -->
    <infection-case-creator
      v-if="infectionCaseSection.creator.visible"
      :facId="outbreakSection.data.facId"
      :outbreakId="outbreakSection.data.id"
      :infectionSite="outbreakSection.data.typeValue"
      v-on:creator-close="cancelInfectionCaseCreator"
      v-on:creator-save="
        cancelInfectionCaseCreator();
        refreshAllData();
      "
      v-on:creator-error="
        cancelInfectionCaseCreator();
        setError('There was an error creating the infection case. Please click refresh to try again.');
      "
    ></infection-case-creator>
    <!-- Infection Case Detail View -->
    <el-dialog
      class="infection-case-detail-dialog"
      top="75px"
      v-on:close="
        cancelInfectionCaseEditor();
        getInfectionCases();
      "
      :title="infectionCaseSection.editor.data.nameFormatted"
      v-if="infectionCaseSection.editor.data"
      v-model="infectionCaseSection.editor.visible"
      size="large"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <infection-case-detail
        :clientName="infectionCaseSection.editor.data.nameFormatted"
        :selectedCase="infectionCaseSection.editor.data"
        :isOutbreakDetail="true"
        @dismiss="
          cancelInfectionCaseEditor();
          getInfectionCases();
        "
      ></infection-case-detail>
    </el-dialog>
    <!-- Manage Infection Cases -->
    <manage-infection-cases v-if="infectionCaseSection.manager.visible" :outbreak="outbreakSection.data" v-on:manager-close="cancelInfectionCaseManager"></manage-infection-cases>

    <!-- Line List Report Progress -->
    <progress-overlay v-if="surveillanceSection.excelExportResultsVisible" :progress="surveillanceSection.excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>

    <!-- Infection Case Export Progress -->
    <progress-overlay v-if="infectionCaseSection.export.exporting" :progress="infectionCaseSection.export.progress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>

    <!-- Resolve Infection Case -->
    <resolve-infection-case v-if="infectionCaseSection.resolution.visible" :infectionCase="infectionCaseSection.resolution.data" v-on:resolution-save="closeResolveInfectionCase" v-on:resolution-cancel="cancelResolveInfectionCase" v-on:resolution-close="closeResolveInfectionCase"></resolve-infection-case>

    <!-- Unresolve Infection Case -->
    <unresolve-infection-case v-if="infectionCaseSection.unresolve.visible" :infectionCase="infectionCaseSection.unresolve.data" v-on:unresolve-save="closeUnresolveInfectionCase" v-on:unresolve-cancel="cancelUnresolveInfectionCase"></unresolve-infection-case>

    <!-- Export Error Dialog -->
    <el-dialog title="Error, please try again" v-model="infectionCaseSection.export.errorVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      {{ infectionCaseSection.export.errorMessage }}
      <div class="dialog-footer">
        <el-button @click="infectionCaseSection.export.errorVisible = false">Close</el-button>
      </div>
    </el-dialog>

    <!-- Error Dialog -->
    <el-dialog title="Error, please try again" v-model="errorSection.visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      {{ errorSection.message }}
      <div class="dialog-footer">
        <el-button
          @click="
            errorSection.visible = false;
            errorSection.message = 'An error occurred. Please click refresh to try again.';
            refreshAllData();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
import util from "../../util";
import transformer from "../../transformer";
import moment from "moment";
import { initLineListReportGenerator } from "../../services/reportClient";
import ProgressOverlay from "../../components/ProgressBarOverlay";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";
import Icon from "../Shared/Icon";
import XLSX from "xlsx";
import Checklist from "./Checklist.vue";
import StackedColumnChart from "../Shared/StackedColumnChart";
import CreateInfectionCase from "../IS/CreateInfectionCase.vue";
import OutbreakEditor from "./EditOutbreak.vue";
import ManageInfectionCases from "./ManageInfectionCases.vue";
import ResolveOutbreak from "./ResolveOutbreak.vue";
import InfectionCaseDetail from "../InfectionCaseDetail";
import ResolveInfectionCase from "../IS/ResolveInfectionCase";
import UnresolveInfectionCase from "../IS/UnresolveInfectionCase";

export default {
  props: ["outbreakId"],
  name: "outbreak-summary",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "outbreak-checklist": Checklist,
    "stacked-column-chart": StackedColumnChart,
    "infection-case-creator": CreateInfectionCase,
    "manage-infection-cases": ManageInfectionCases,
    "infection-case-detail": InfectionCaseDetail,
    "outbreak-editor": OutbreakEditor,
    "resolve-outbreak": ResolveOutbreak,
    "resolve-infection-case": ResolveInfectionCase,
    "unresolve-infection-case": UnresolveInfectionCase,
    Icon,
  },
  watch: {
    "infectionCaseSection.pagination.size"() {
      this.getInfectionCases();
    },
    "infectionCaseSection.pagination.page"() {
      this.getInfectionCases();
    },
  },
  data() {
    return {
      canModify: false,
      global: {
        filters: {},
        filterDefaults: {
          facilities: 0, // set in created()
        },
        facilities: [],
        dateRangeLoaded: false,
        defaultDateStart: moment().subtract("3", "months").toDate(),
        defaultDateEnd: new Date(),
      },
      errorSection: {
        visible: false,
        message: "An error occurred. Please click refresh to try again.",
      },
      checklistSection: {
        outbreak: {},
        visible: false,
        loadingErrorDialog: false,
      },
      outbreakSection: {
        loading: true,
        loadingErrorDialog: false,
        data: {},
        editor: {
          visible: false,
          data: {},
          updateErrorDialog: false,
        },
        resolve: {
          visible: false,
        },
      },
      graphSection: {
        loading: true,
        loadingErrorDialog: false,
        groupOptions: {
          "Individual Type": "individualType",
        },
        groupBy: "Individual Type",
        timeScale: "Day",
        series: [],
        title: "Epi-curve",
        yAxis: "",
        xAxis: [],
        plotLines: [],
        customizations: {
          title: {
            text: "Epi-Curve",
            align: "left",
            verticalAlign: "top",
          },
          yAxis: [
            {
              title: {
                text: "New Infection Cases",
              },
              allowDecimals: false,
            },
            {
              title: {
                text: "Total Infection Cases",
              },
              allowDecimals: false,
              opposite: true,
            },
          ],
          legend: {
            align: "center",
            verticalAlign: "top",
            borderWidth: 0,
            enabled: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                format: "{y}",
              },
            },
            column: {
              stacking: undefined,
            },
          },
        },
      },
      infectionCaseSection: {
        export: {
          exporting: false,
          progress: 0,
          errorVisible: false,
          errorMessage: "",
        },
        resolution: {
          data: null,
          visible: false,
        },
        unresolve: {
          data: null,
          visible: false,
        },
        creator: {
          data: null,
          visible: false,
        },
        manager: {
          visible: false,
        },
        editor: {
          data: null,
          visible: false,
        },
        loading: true,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        loadingErrorDialog: false,
        data: [],
      },
      surveillanceSection: {
        excelExportResultsVisible: false,
        excelExportResultsProgress: 0,
        cancelExcelExportProgress: false,
      },
    };
  },
  methods: {
    getFilterKeys() {
      let filters = ["infectionOnsetDate", "resolutions", "caseStatus"];
      if (this.isAdminStaff()) {
        filters.push("clientType");
      }
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "resolutions":
                k = "outcomes";
                v = v[0] === 0 ? ["all"] : [v[0]];
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.onsetDateFrom = moment(filterValues.infectionOnsetDate[0]).startOf("day").format();
      this.global.filters.onsetDateTo = moment(filterValues.infectionOnsetDate[1]).endOf("day").format();
      if (rawFilters.resolutions && rawFilters.resolutions[1]) this.global.filters.outcomeSubTypes = [rawFilters.resolutions[1]];

      delete this.global.filters.infectionOnsetDate;

      this.getInfectionCases();
      this.getSummary();
    },
    async getOutbreak(id) {
      this.outbreakSection.loading = true;
      try {
        const result = await this.$http.get(`${window.CONFIG.om_api}/${id}`);
        this.outbreakSection.data = this.generateOutbreaksTableData(result.body);
      } catch (err) {
        this.setError("There was an error loading the outbreak. Please click refresh to try again.");
      }
      this.outbreakSection.loading = false;
    },
    generateOutbreaksTableData(record) {
      let model = { ...record };
      model.declaredDateFormatted = record.declaredDate ? moment.utc(record.declaredDate).format("MM-DD-YYYY") : "";
      model.resolvedDateFormatted = record.resolvedDate ? moment.utc(record.resolvedDate).format("MM-DD-YYYY") : "";
      model.firstCaseDateFormatted = record.firstCaseDate ? moment(record.firstCaseDate).format("MM-DD-YYYY") : "";
      model.createdDateFormatted = moment(record.created).format(`${this.$configStore.dateFormat()} HH:mm`);
      model.updatedDateFormatted = record.updated ? moment(record.updated).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.facId))[0].displayText;
      model.scopeFormatted = record.scope === "Facility" && this.global.facilities.length === 1 ? record.scope : `${record.scope} - ${record.scopeDetail.join(", ")}`;
      model.organismFormatted = record.organism.join(", ");
      return model;
    },
    generateInfectionCasesTableData(record) {
      let model = { ...record };
      model.mrn = this.isStaff(record.client) ? record.client.staffId : this.$configStore.patientIdType() ? record.client[this.$configStore.patientIdType()] : record.client.patientId;
      model.infectionSiteWithType = `${model.infectionType} / ${model.infectionSite}`;
      model.isStaff = this.isStaff(record.client) ? "Yes" : "No";
      model.nameFormatted = `${record.client.firstName} ${record.client.middleName ? record.client.middleName + " " : ""}${record.client.lastName}`;
      model.onsetDateFormatted = record.onsetDate ? moment(record.onsetDate).format("MM-DD-YYYY") : "";
      model.createdDateFormatted = record.created ? moment(record.created).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.updatedDateFormatted = record.updated ? moment(record.updated).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.isolationStartDateFormatted = record.isolationStart ? moment(record.isolationStart).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.isolationEndDateFormatted = record.isolationEnd ? moment(record.isolationEnd).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.resolutionDateFormatted = record.resolutionDate ? moment(record.resolutionDate).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      return model;
    },
    async getInfectionCases() {
      this.infectionCaseSection.loading = true;
      const filters = {
        ...this.global.filters,
        outbreakIds: [this.outbreakId],
        page: this.infectionCaseSection.pagination.page,
        size: this.infectionCaseSection.pagination.size,
      };
      try {
        const infectionCaseResponse = await this.$http.post(`${window.CONFIG.infection_api}/search`, filters);
        this.infectionCaseSection.pagination.total = infectionCaseResponse.body.totalElements;
        this.infectionCaseSection.data = await Promise.all(
          infectionCaseResponse.body.content.map(async (infectionCase) => {
            const precautionDetailResponse = await this.$http.get(window.CONFIG.precaution_api, {
              params: {
                symptomDetailId: infectionCase.id,
              },
            });
            infectionCase.precautions = precautionDetailResponse.body.content;
            return this.generateInfectionCasesTableData(infectionCase);
          })
        );
      } catch (err) {
        this.infectionCaseSection.pagination.total = 0;
        this.infectionCaseSection.data = [];
        this.setError("There was an error loading infection cases. Please click refresh to try again.");
      }
      this.infectionCaseSection.loading = false;
    },
    async refreshAllData() {
      this.global.dateRangeLoaded = false;
      await this.getOutbreak(this.global.outbreakId);
      this.getInfectionCases();
      this.getSummary();
      this.setDefaultDateRange();
      this.global.dateRangeLoaded = true;
    },
    async getInfectionCasesForExport() {
      const filters = {
        ...this.global.filters,
        outbreakIds: [this.outbreakId],
        page: 1,
        size: this.infectionCaseSection.pagination.total || 500,
      };
      try {
        const response = await this.$http.post(`${window.CONFIG.infection_api}/export`, filters);
        const data = response.body.content.map(this.generateInfectionCasesTableData);
        return data;
      } catch (err) {
        // requested size is higher than allowed limit
        if (err?.status === 422) {
          this.infectionCaseSection.export.errorMessage = err?.body;
        } else {
          this.infectionCaseSection.export.errorMessage = "An error occurred, please reload the page and try again.";
        }
        this.infectionCaseSection.export.errorVisible = true;
        this.infectionCaseSection.export.exporting = false;
        return [];
      }
    },
    resolveOutbreak() {
      this.outbreakSection.resolve.visible = true;
    },
    cancelResolveOutbreak() {
      this.outbreakSection.resolve.visible = false;
    },
    resolveInfectionCase(infectionCase) {
      this.infectionCaseSection.resolution.data = infectionCase;
      this.infectionCaseSection.resolution.visible = true;
    },
    cancelResolveInfectionCase() {
      this.infectionCaseSection.resolution.data = null;
      this.infectionCaseSection.resolution.visible = false;
    },
    closeResolveInfectionCase(resolution) {
      this.infectionCaseSection.resolution.data = null;
      this.infectionCaseSection.resolution.visible = false;
      // temporary workaround to prop mutation within surveillance component
      if (resolution && this.infectionCaseSection.editor.data) {
        this.infectionCaseSection.editor.data.resolved = resolution.resolved;
        this.infectionCaseSection.editor.data.resolutionDate = resolution.resolutionDate;
        this.infectionCaseSection.editor.data.outcome = resolution.outcome;
      } else {
        this.refreshAllData();
      }
    },
    unresolveInfectionCase(infectionCase) {
      this.infectionCaseSection.unresolve.data = infectionCase;
      this.infectionCaseSection.unresolve.visible = true;
    },
    cancelUnresolveInfectionCase() {
      this.infectionCaseSection.unresolve.data = null;
      this.infectionCaseSection.unresolve.visible = false;
    },
    closeUnresolveInfectionCase() {
      this.infectionCaseSection.unresolve.data = null;
      this.infectionCaseSection.unresolve.visible = false;
      this.refreshAllData();
    },
    setError(message) {
      this.errorSection.message = message;
      this.errorSection.visible = true;
    },
    editOutbreak(outbreak) {
      this.outbreakSection.editor.title = "Edit Outbreak";
      this.outbreakSection.editor.data = outbreak;
      this.outbreakSection.editor.visible = true;
    },
    closeOutbreakEditor() {
      this.outbreakSection.editor.data = null;
      this.outbreakSection.editor.visible = false;
    },
    editInfectionCase(row, event, column) {
      if (column.property === "active") {
        return;
      }
      this.infectionCaseSection.editor.data = transformer.mapInfectionCaseToLegacyModel(JSON.parse(JSON.stringify(row)));
      this.infectionCaseSection.editor.visible = true;
    },
    cancelInfectionCaseEditor() {
      this.infectionCaseSection.editor.data = null;
      this.infectionCaseSection.editor.visible = false;
    },
    createInfectionCase() {
      this.infectionCaseSection.creator.data = null;
      this.infectionCaseSection.creator.visible = true;
    },
    cancelInfectionCaseCreator() {
      this.infectionCaseSection.creator.data = null;
      this.infectionCaseSection.creator.visible = false;
    },
    manageInfectionCases() {
      this.infectionCaseSection.manager.visible = true;
    },
    cancelInfectionCaseManager() {
      this.infectionCaseSection.manager.visible = false;
      this.refreshAllData();
    },
    async unlinkInfectionCase(id) {
      this.global.loading = true;
      try {
        await this.$http.delete(`${window.CONFIG.om_api}/${this.outbreakSection.data.id}/cases/${id}`);
        this.refreshAllData();
      } catch (err) {
        this.setError("There was an error unlinking the infection case. Please click refresh to try again.");
      }
    },
    viewCheckList(outbreak) {
      this.checklistSection.outbreak = JSON.parse(JSON.stringify(outbreak));
      this.$nextTick(() => {
        this.checklistSection.visible = true;
      });
    },
    closeChecklist() {
      this.checklistSection.visible = false;
    },
    async updateChecklist(checklist) {
      this.outbreakSection.loading = true;
      this.checklistSection.visible = false;
      try {
        await this.$http.put(`${window.CONFIG.om_api}/${this.checklistSection.outbreak.id}/checklist`, checklist);
        await this.getOutbreak(this.global.outbreakId);
      } catch (err) {
        this.setError("There was an error updating the checklist. Please click refresh to try again.");
      }
      this.outbreakSection.loading = false;
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Day: (record) => `${moment({ year: record.year }).dayOfYear(record.dategroup).format("MM-DD")}`,
        Week: (record) => `W${record.dategroup}-${record.year}`,
        Month: (record) => `${record.year}-${`${record.dategroup}`.padStart(2, "0")}`,
        Quarter: (record) => `Q${record.dategroup}-${record.year}`,
        Year: (record) => `${record.year}`,
      };

      const allTimePeriods = new Map();
      const groupKey = this.graphSection.groupOptions[this.graphSection.groupBy];

      const convertAndGroup = (record) => {
        const row = {
          time: formatTime[timeScale](record),
          group: record[groupKey],
          count: record.total,
          data: record,
        };
        allTimePeriods.set(row.time, { count: 0 });
        return row;
      };
      const orderedCounts = summary.map(convertAndGroup);
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      let groupedTotals = new Map();
      const cumulative = getSummaryDefaults();
      let cumulativeCount = {};

      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || { count: 0 };
        values[row.time].count += row.count;
        groupedTotals.set(row.group, values);

        cumulativeCount[row.time] = (cumulativeCount[row.time] || 0) + row.count;
        cumulative[row.time] = cumulative[row.time] || { count: 0 };
        cumulative[row.time].count = cumulativeCount[row.time];
      });

      const timePeriods = Object.keys(cumulative);
      if (timePeriods.length > 0) {
        let runningTotal = 0;
        for (const time of timePeriods) {
          runningTotal += cumulativeCount[time];
          cumulative[time].count = runningTotal;
        }
        groupedTotals.set("cumulative", cumulative);
      }

      return [groupedTotals, timePeriods];
    },
    async getSummary() {
      this.graphSection.loading = true;
      const filters = {
        ...this.global.filters,
        outbreakIds: [this.outbreakId],
        groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
        timeScale: this.graphSection.timeScale.toLowerCase(),
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      try {
        const response = await this.$http.post(`${window.CONFIG.infection_api}/outbreak/summary`, filters);
        const [groups, periods] = this.createSummary(response.body);
        this.generateGraphData(groups, periods);
      } catch (err) {
        this.setError("There was an error loading the outbreak summary. Please click refresh to try again.");
      }
      this.graphSection.loading = false;
    },
    generateGraphData(groupedTotals, timePeriods) {
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      this.graphSection.title = ``;
      const colorPalate = {
        Resident: "blue",
        Staff: "green",
        cumulative: "red",
      };
      const nameMapping = {
        Resident: "New Resident Cases",
        Staff: "New Staff Cases",
        cumulative: "Total Infections",
      };
      this.graphSection.series = orderedTotals.flatMap(([name, periods]) => {
        const color = colorPalate[name];
        const isCumulativeSeries = name === "cumulative";
        const series = {
          name: nameMapping[name],
          color,
          yAxis: isCumulativeSeries ? 1 : 0,
          type: isCumulativeSeries ? "spline" : "column",
          marker: {
            enabled: false,
          },
          data: timePeriods.map((period) => periods[period].count),
          dataLabels: {
            enabled: false,
          },
        };
        return series;
      });
    },
    async exportInfectionCases() {
      this.infectionCaseSection.export.progress = 0;
      this.infectionCaseSection.export.exporting = true;

      let medicationsLookup = {};
      const config = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then((resp) => resp.json());
      medicationsLookup = util.arrayToObj([...config.medications, ...config.archivedMedications], "id");

      const fetchDataForColumns = async () => {
        this.infectionCaseSection.export.progress = 0;
        const cases = await this.getInfectionCasesForExport();
        for (let i = 0; i < cases.length; i++) {
          const labRecordsResponse = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${cases[i].id}/labs`);
          cases[i].labRecords = labRecordsResponse.body;

          const treatmentDetailsResponse = await this.$http
            .get(`${window.CONFIG.treatement_api}/prescriptions`, {
              params: {
                clientId: cases[i].client.id,
                symptomDetailId: cases[i].id,
              },
            })
            .then((resp) => resp.json());
          cases[i].medicineDetails = treatmentDetailsResponse.content;

          const precautionDetailResponse = await this.$http
            .get(window.CONFIG.precaution_api, {
              params: {
                symptomDetailId: cases[i].id,
              },
            })
            .then((resp) => resp.json());
          cases[i].precautions = precautionDetailResponse.content;

          this.infectionCaseSection.export.progress = Math.round(100 * ((i + 1) / cases.length));
        }
        return cases;
      };
      const clientStatusMap = {
        D: "discharged",
        C: "active",
        T: "transferred",
      };
      const formatDate = (timestamp) => moment(timestamp).format(this.$configStore.dateFormat());
      const getPrimary = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0];
      const getLabResult = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0]?.result || "";
      const getNonPrimary = (_) => _.labRecords.filter((r) => r.organism !== _.primaryOrganism);
      const getCultureDate = (_) => {
        const p = getPrimary(_);
        return p ? formatDate(p.cultureDate) : "";
      };
      const getTreatments = (_) => {
        const values = [
          _.medicineDetails
            .map((med) => {
              const { frequency, dosage, medicationId } = med;
              const medication = medicationId && medicationsLookup[medicationId] ? medicationsLookup[medicationId]?.displayText : "";

              return `${medication} ${dosage} ${frequency}`.trim();
            })
            .join(", "),
          _.medicineDetails.map((med) => (med.startDate ? formatDate(med.startDate) : "")).join(", "),
          _.medicineDetails.map((med) => (med.endDate ? formatDate(med.endDate) : "")).join(", "),
        ];
        return values;
      };
      const columns = [
        "Infection Case ID",
        "Client ID",
        "First Name",
        "Middle Name",
        "Last Name",
        "Onset Location",
        "Current Location",
        "Infection Site",
        "Infection Site Type",
        "Onset Date",
        "Case Status",
        "Symptoms",
        "Precautions",
        "Origin",
        "Contact",
        "Primary Organism",
        "Result",
        "Culture Date",
        "Additional Organisms",
        "Treatment",
        "Treatment Start Date",
        "Treatment End Date",
        "Outcome",
        "Outcome Detail",
        "Outcome Date",
        "Family Notified",
        "Manager Notified",
        "Physician/Public Health Notified",
        "Staff Case Report to MoL",
        "Staff",
        "Staff Position",
        "Client Status",
        "Staff Isolation",
        "Staff Isolation Start Date",
        "Staff Isolation End Date",
        "Notes",
        "Created By",
        "Created Date",
        "Updated By",
        "Updated Date",
      ];
      const convertObjectsToRows = (record) => {
        return [
          record.id,
          record.clientId,
          record.client.firstName,
          record.client.middleName,
          record.client.lastName,
          record.location,
          record.client.location,
          record.infectionSite,
          record.infectionType,
          record.onsetDateFormatted,
          record.status ? "confirmed" : "suspected",
          record.symptoms.map((s) => s.displayText).join(", "),
          this.filterPrecautions(record.precautions)
            .map((p) => this.precautionById(p.precautionId))
            .join(", "),
          record.origin,
          record.contact,
          record.primaryOrganism,
          getLabResult(record),
          getCultureDate(record),
          getNonPrimary(record)
            .map((lr) => lr.organism)
            .join(", "),
          ...getTreatments(record),
          record.outcome,
          record.outcomeSubType,
          record.resolutionDateFormatted,
          record.familyNotified,
          record.nextOfNotified,
          record.physicianNotified,
          record.staffCaseNotified,
          this.isStaff(record.client) ? true : false,
          record.client.employeeType,
          clientStatusMap[record.client.status] || "",
          record.staffIsolation,
          record.isolationStartDateFormatted,
          record.isolationEndDateFormatted,
          record.notes.map((n) => n.content).join(", "),
          record.createdBy,
          record.createdDateFormatted,
          record.updatedBy,
          record.updatedDateFormatted,
        ];
      };
      const result = await fetchDataForColumns();
      if (!result?.length) {
        return;
      }
      this.createExcelFile("infection_cases.xlsx", columns, result.map(convertObjectsToRows));
      this.infectionCaseSection.export.progress = 100;
      this.infectionCaseSection.export.exporting = false;
    },
    createExcelFile(fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    async lineListReport() {
      this.surveillanceSection.excelExportResultsVisible = true;
      this.surveillanceSection.excelExportResultsProgress = 0;
      this.surveillanceSection.cancelExcelExportProgress = false;
      let medicationsLookup = {};

      const config = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then((resp) => resp.json());
      medicationsLookup = util.arrayToObj([...config.medications, ...config.archivedMedications], "id");

      const fetchDataForColumns = async (cases) => {
        const extendedCases = [];
        this.surveillanceSection.excelExportResultsProgress = 0;
        for (let i = 0; i < cases.length; i++) {
          const c = { ...cases[i] };
          const labRecordsResponse = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${c.id}/labs`);
          c.labRecords = labRecordsResponse.body;

          c.medicineDetails = [];

          const treatmentDetailsResponse = await this.$http
            .get(`${window.CONFIG.treatement_api}/prescriptions`, {
              params: {
                clientId: c.client.id,
                symptomDetailId: c.id,
              },
            })
            .then((resp) => resp.json());
          c.medicineDetails = treatmentDetailsResponse.content;

          const precautionDetailResponse = await this.$http
            .get(window.CONFIG.precaution_api, {
              params: {
                symptomDetailId: cases[i].id,
              },
            })
            .then((resp) => resp.json());
          c.precautions = precautionDetailResponse.content;

          this.surveillanceSection.excelExportResultsProgress = Math.round(100 * ((i + 1) / cases.length));

          extendedCases.push(c);
        }
        return extendedCases;
      };
      const formatDate = (timestamp) => (timestamp ? moment(timestamp).format(this.$configStore.dateFormat()) : "");
      const formatFac = (_) => (this.isStaff(_.client) ? _.client.facilities.map(this.facilityNameByFacilityId.bind(this)).join(", ") : this.facilityNameByFacilityId(_.facId));
      const getPrimary = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0];
      const getLabResult = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0]?.result || "";
      const getCultureDate = (_) => {
        const p = getPrimary(_);
        return p ? formatDate(p.cultureDate) : "";
      };

      const renderMedications = (_) => {
        return _.medicineDetails
          .map((t) => {
            const { startDate, endDate, frequency, dosage, medicationId } = t;
            const medication = medicationsLookup[medicationId]?.displayText; // medicationId might be missing due to legacy treatment API
            return `${medication || ""} ${dosage || ""} ${frequency || ""}\n${formatDate(startDate)} - ${formatDate(endDate)}`;
          })
          .join("\n");
      };

      const convertDataToColumns = (_) => {
        const c = _.client;
        const patientIdLookupKey = this.$configStore.patientIdType() || "patientId";
        return {
          reportDate: formatDate(Date.now()),
          facility: formatFac(_),
          outbreakScope: `${outbreak.scope} - ${outbreak.scopeDetail}`,
          declaredDate: formatDate(outbreak.declaredDate),
          outbreakId: outbreak.caseNumber,
          infectionSite: `${_.infectionSite} ${_.infectionType || ""}`.trim(),
          caseDefinition: outbreak.caseDefinition,
          notes: _.notes.map((n) => n.content).join("\n\n"),
          infectionType: `${outbreak.type} - ${outbreak.typeValue}`,
          outbreakOrganism: outbreak.organism.join(", "),
          name: `${c.firstName} ${c.lastName}`,
          id: this.isStaff(c) ? c.staffId : c[patientIdLookupKey],
          gender: c.gender,
          dob: c.dateOfBirth ? formatDate(c.dateOfBirth) : "",
          location: c.location,
          origin: _.origin === "Not Selected" ? "" : _.origin,
          onsetDate: _.onsetDate ? formatDate(_.onsetDate) : "",
          outcome: _.outcome,
          resolvedDate: outbreak.resolvedDate ? formatDate(outbreak.resolvedDate) : "",
          resolutionDate: _.resolutionDate ? formatDate(_.resolutionDate) : "",
          status: _.status ? "confirmed" : "suspected",
          precautions: this.filterPrecautions(_.precautions)
            .map((p) => this.precautionById(p.precautionId))
            .join(", "),
          symptoms: _.symptoms.map((s) => s.displayText).join(", "),
          cultureDate: getCultureDate(_),
          result: getLabResult(_),
          organism: _.primaryOrganism,
          treatments: renderMedications(_),
          staffIsolation: this.isStaff(c) ? _.staffIsolation : "",
          staffIsolationStart: this.isStaff(c) ? formatDate(_.isolationStart) : "",
          staffIsolationEnd: this.isStaff(c) ? formatDate(_.isolationEnd) : "",
        };
      };
      let data = [];
      const outbreak = JSON.parse(JSON.stringify(this.outbreakSection.data));
      data = await fetchDataForColumns(this.infectionCaseSection.data);
      data = data.map(convertDataToColumns);
      this.surveillanceSection.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onSetDate - a.onSetDate);
      if (!this.surveillanceSection.cancelExcelExportProgress) {
        const template = this.$configStore.getLineListReports().outbreakManagement;
        initLineListReportGenerator(template, data);
      }
    },
    cancelExcelExport() {
      this.surveillanceSection.cancelExcelExportProgress = true;
    },
    tagType(type) {
      if (type.colorCode === 0) {
        return "success";
      } else if (type.colorCode === 1) {
        return "primary";
      } else if (type.colorCode === 2) {
        return "warning";
      } else if (type.colorCode === 3) {
        return "danger";
      }
      return "gray";
    },
    statusImage(infectionCase) {
      const imageMap = {
        Respiratory: "bug1",
        GI: "bug2",
        UTI: "bug3",
        Skin: "bug4",
      };
      let name = imageMap[infectionCase.infectionSite] || "bug4";

      if (!infectionCase.status) {
        name += "s";
      }

      const path = `/static/${name}.png`;

      return path;
    },
    filterPrecautions(precautions) {
      return (precautions || []).filter((p) => this.precautionById(p.precautionId));
    },
    precautionIsActive(precautionDetail) {
      const { startDate, endDate } = precautionDetail;
      const now = new Date();
      return !endDate || (now >= new Date(startDate) && now <= new Date(endDate));
    },

    precautionById(id) {
      return this.global.precautions.find((p) => p.id === id).displayText;
    },
    facilityNameByFacilityId(facId) {
      return this.global.facilities.filter((s) => s.id === facId).map((s) => s.displayText)[0];
    },
    getClientPhoto(client) {
      if (client && client.photoUrl) {
        var f = client.photoUrl;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "../static/" + (client.photoUrl ? client.photoUrl : "user") + ".png";
      }

      return "../static/user.png";
    },
    setDefaultDateRange() {
      const outbreak = this.outbreakSection.data;
      if (outbreak.firstCaseDate) {
        this.global.defaultDateStart = new Date(outbreak.firstCaseDate);
      } else if (outbreak.declaredDate) {
        this.global.defaultDateStart = new Date(outbreak.declaredDate);
      }
      if (outbreak.resolvedDate) {
        this.global.defaultDateEnd = new Date(outbreak.resolvedDate);
      }
    },
    isStaff(client) {
      if (client) {
        return client.staffId || false;
      } else {
        return false;
      }
    },
    isAdminStaff() {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
  },
  async created() {
    console.clear();
    this.canModify = auth.canModify();
    this.global.facilities = this.$configStore.data.sites;
    this.global.precautions = this.$configStore.data.precautions || [];
    this.global.outbreakId = this.$route.params.outbreakId;
    await this.getOutbreak(this.global.outbreakId);
    const facility = this.$configStore.data.sites.find((site) => site.id === this.outbreakSection.data.facId);
    if (facility) {
      this.global.filterDefaults.facilities = facility;
    }

    this.setDefaultDateRange();
    this.global.dateRangeLoaded = true;
  },
};
</script>

<style>
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.outbreak-management .infection-case-detail-dialog .el-dialog.el-dialog--large {
  width: 80% !important;
}
.outbreak-management .status-icon {
  width: 1.8em;
  height: 1.8em;
}
.el-tag {
  margin-right: 0.5em;
}
div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>