<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" :title="caseData.client.firstName + ' ' + caseData.client.lastName" :top="'5%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" label-width="150px">
        <el-form-item label="Medication" prop="medicationId" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.medicationId" filterable>
            <el-option v-for="item in formData.medications" :key="item.id" :label="item.displayText" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Prophylactic" prop="prophylactic">
          <el-checkbox v-model="formModel.prophylactic" label="Yes"></el-checkbox>
        </el-form-item>

        <el-form-item label="Start Date" prop="startDate" :rules="[{ required: true, message: 'This field is required' }]">
          <el-date-picker v-model="formModel.startDate" type="date" placeholder="Select date" :format="$configStore.datePickerDateFormat()"></el-date-picker>
        </el-form-item>

        <el-form-item label="End Date" prop="endDate" :rules="[{ required: !formModel.prophylactic, message: 'This field is required' }]">
          <el-date-picker v-model="formModel.endDate" type="date" placeholder="Select date" :format="$configStore.datePickerDateFormat()"></el-date-picker>
        </el-form-item>

        <el-form-item label="Dosage" prop="dosage" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.dosage">
            <el-option v-for="item in formData.dosages" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Frequency" prop="frequency" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.frequency">
            <el-option v-for="item in formData.frequencies" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Route" prop="route" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.route">
            <el-option v-for="item in formData.routes" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Provider" prop="provider" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.provider">
            <el-option v-for="item in formData.providers" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Order Reviewed?" prop="isReviewed">
          <el-checkbox v-model="isReviewed" label="Yes"></el-checkbox>
        </el-form-item>

        <template v-if="isReviewed">
          <el-form-item label="Reviewed By" prop="reviewedBy" :rules="[{ required: true, message: 'This field is required' }]">
            <el-input v-model="formModel.reviewedBy" />
          </el-form-item>

          <el-form-item label="Reviewed Date" prop="reviewed" :rules="[{ required: true, message: 'This field is required' }]">
            <el-date-picker v-model="formModel.reviewed" type="date" placeholder="Select date" :format="$configStore.datePickerDateFormat()"></el-date-picker>
          </el-form-item>
        </template>

        <el-form-item label="Notes" prop="notes">
          <el-input type="textarea" v-model="formModel.notes"></el-input>
          <character-count :limit="1000" :count="formModel.notes.length" />
        </el-form-item>

        <div class="dialog-footer">
          <el-button @click="cancelForm" :disabled="dialogBusy">
            Cancel
          </el-button>
          <el-button type="primary" @click="saveForm" :disabled="dialogBusy">
            Save
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import auth from "../../auth";
// import moment from "moment";

import CharacterCount from "../Shared/CharacterCount";

export default {
  name: "treatment-editor",
  props: ["treatmentData", "caseData"],
  components: {
    "character-count": CharacterCount
  },
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      dialogTitle: "",
      formData: {
        medications: [],
        dosages: [],
        frequencies: [],
        routes: [],
        providers: []
      },
      isReviewed: false,
      isNew: true,
      formModel: {
        facId: null,
        clientId: null,
        symptomDetailId: null,
        medicationId: null,
        createdBy: "",
        created: "",
        updatedBy: "",
        updated: "",
        reviewedBy: "",
        reviewed: "",
        provider: "",
        startDate: "",
        endDate: "",
        customMedication: "",
        removed: false,
        prophylactic: false,
        route: "",
        dosage: "",
        frequency: "",
        daysOfTherapy: null,
        notes: ""
      }
    };
  },
  methods: {
    editTreatment(treatment) {
      this.isNew = false;
      this.formModel = { ...this.formModel, ...treatment, ...{ notes: treatment.notes || "" } };
      this.formModel.prophylactic = !!this.formModel.prophylactic; // if null, cast to false
      this.isReviewed = !!this.formModel.reviewed;
    },
    createTreatment() {
      this.isNew = true;
      this.isReviewed = false;
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    saveForm() {
      this.dialogBusy = true;
      const formData = { ...this.formModel };
      this.$refs.editorForm.validate(async valid => {
        if (valid) {
          if (this.isNew) {
            await this.$http
              .post(`${window.CONFIG.treatement_api}/prescriptions`, JSON.stringify(formData))
              .then(resp => {
                this.$emit("editor-save");
              })
              .catch(err => {
                this.$message.error("There was error processing this information");
                this.dialogBusy = false;
              });
          } else {
            await this.$http
              .put(`${window.CONFIG.treatement_api}/prescriptions/${formData.id}`, JSON.stringify(formData))
              .then(resp => {
                this.$emit("editor-save");
              })
              .catch(err => {
                this.$message.error("There was error processing this information");
                this.dialogBusy = false;
              });
          }
        } else {
          this.dialogBusy = false;
        }
      });
    }
  },
  async created() {
    const {
      facId,
      client: { id: clientId },
      id: symptomDetailId
    } = this.caseData;
    this.formData = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then(resp => resp.json());
    this.formData.providers = await this.$http
      .get(`${window.CONFIG.api}/clients/search`, {
        params: {
          providerType: "Yes",
          status: "C",
          clientType: "Staff",
          facId
        }
      })
      .then(resp => resp.json())
      .then(json => json.map(p => `${p.firstName} ${p.lastName} (${p.staffId})`));

    this.isReviewed = false;
    this.formModel = { ...this.formModel, facId, clientId, symptomDetailId };
    if (this.treatmentData) {
      this.editTreatment(this.treatmentData);
    } else {
      this.createTreatment();
    }
    this.dialogBusy = false;
  },
  async mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  }
};
</script>
