<template>
  <div class="container">
    <!--<svg></svg>-->
    <button class="export_button" v-on:click="exportGraph()">Export Graph</button>
  </div>
</template>

<script>
import FileSaver from "file-saver";

export default {
  name: "inf-case-bar-graph",
  props: ["range", "period", "sites", "subSiteType", "origin", "confirmed", "unitId", "mode", "config", "showValue", "selectedClientStatus", "selectedClientType", "selectedTreatment", "selectedOrganism", "selectedContact", "selectedOutcome", "selectedPrecaution", "selectedIsoltation", "units", "facIds"],
  data: function() {
    return {
      _svg: null,
      _d3Data: null,
      _d3Mounted: false,
      _period: "month",
      _start: Date(),
      _end: Date(),
      _sites: { GI: true, Respiratory: true, UTI: true },
      _subSiteType: 0,
      _types: [],
      colors: { GI: "#00D560", Respiratory: "#0080FF", UTI: "#FFDE25" },
      _confirmed: null,
      _origin: null,
      _config: null,
      _unitId: 0,
      _selectedClientStatus: 1,
      _selectedClientType: 0,
      _selectedPrecaution: 0,
      _selectedOrganism: 0,
      _selectedTreatment: 0,
      _selectedContact: 0,
      _selectedOutcome: 0,
      _selectedIsoltation: 0,
      _mode: "site" // site, unit
    };
  },
  watch: {
    range: function(value) {
      if (value && value.length > 1) {
        this._start = value[0];
        this._end = value[1];
      }
      this.reDraw();
    },
    period: function(value) {
      if (value) {
        this._period = value;
      }
      this.reDraw();
    },
    sites: function(value) {
      this._types = value;
      this.reDraw();
    },
    subSiteType: function(value) {
      this._subSiteType = value || 0;
    },
    origin: function(value) {
      this._origin = value;
      this.reDraw();
    },
    confirmed: function(value) {
      this._confirmed = value;
      this.reDraw();
    },
    config: function(value) {
      // console.log('config: ' + value)
      this._config = value;
    },
    unitId: function(value) {
      // console.log('unitId: ' + value)
      this._unitId = value;
      this.reDraw();
    },
    facIds: function(v) {
      this._facIds = v || 0;
      this.reDraw();
    },
    mode: function(value) {
      // console.log('mode: ' + value)
      this._mode = value;
      this.reDraw();
    },
    showValue: function() {
      this.reDraw();
    },
    selectedClientStatus: function(v) {
      this._selectedClientStatus = v || 1;
      this.reDraw();
    },
    selectedClientType: function(v) {
      this._selectedClientType = v || 0;
      this.reDraw();
    },
    selectedOrganism: function(v) {
      this._selectedOrganism = v || 0;
      this.reDraw();
    },
    selectedTreatment: function(v) {
      this._selectedTreatment = v || 0;
      this.reDraw();
    },
    selectedContact: function(v) {
      this._selectedContact = v || 0;
      this.reDraw();
    },
    selectedPrecaution: function(v) {
      this._selectedPrecaution = v || 0;
      this.reDraw();
    },
    selectedOutcome: function(v) {
      this._selectedOutcome = v || 0;
      this.reDraw();
    },
    selectedIsoltation: function(v) {
      this._selectedIsoltation = v || 0;
      this.reDraw();
    }
  },
  methods: {
    reDraw: function() {
      this._svg.remove();
      this.randerGraph();
    },
    loadData: function(callback) {
      if (!this._start) {
        return;
      }
      var vm = this;
      var url = window.CONFIG.api + "/symptomDetail/byDays?start=" + this._start + "&end=" + this._end;
      if (this._confirmed > 0) {
        url = url + "&confirmed=" + (this._confirmed === 1);
      }

      if (this._origin) {
        url = url + "&origin=" + this._origin;
      }

      if (this._unitId > 0) {
        url = url + "&unit=" + this._unitId;
      }

      if (this._selectedClientStatus !== 0) {
        url = url + "&clientStatus=" + this._selectedClientStatus;
      }

      if (this._selectedClientType > 0) {
        url = url + "&clientType=" + (this._selectedClientType === 1 ? "Client" : "Staff");
      }

      if (this._selectedOrganism !== 0) {
        url = url + "&organism=" + this._selectedOrganism;
      }

      if (this._selectedTreatment !== 0) {
        url = url + "&treatmentId=" + this._selectedTreatment;
      }

      if (this._selectedContact !== 0) {
        url = url + "&contact=" + this._selectedContact;
      }

      if (this._selectedPrecaution !== 0) {
        url = url + "&precaution=" + this._selectedPrecaution;
      }

      if (this._selectedOutcome === -1) {
        url = url + "&resolved=false";
      } else if (this._selectedOutcome !== 0) {
        url = url + "&outcome=" + this._selectedOutcome;
      }

      if (this._selectedIsoltation !== 0) {
        url = url + "&staffIsolation=" + this._selectedIsoltation;
      }

      if (this._facIds !== 0 && Array.isArray(this._facIds)) {
        url = url + "&facIds=" + this._facIds;
      }

      console.log(url);
      this.$http.get(url).then(
        response => {
          // console.log(JSON.stringify(response.body, ' ', 2))
          var data = response.body;
          vm._d3Data = data;
          callback(data);
        },
        response => {}
      );
      // this.$d3.json(url, function (data) {
      //   vm._d3Data = data
      //   callback(data)
      // })
    },
    getDateHandler: function() {
      var vm = this;
      var d3 = this.$d3;

      var handler = {
        formatter: function(d) {
          var formatted = d3.timeFormat("%W-%Y")(d);
          return formatted;
        },
        parser: function(d) {
          var date = d3.timeParse("%W-%Y")(d);
          return date; // d3.timeDay.offset(date, 7)
        },
        ticks: d3.timeMonday.every(1),
        tickFormat: d3.timeFormat("W%W-%Y"),
        xDomain: function(minDate, maxDate) {
          return [minDate, maxDate];
        }
      };

      if (vm._period === "year") {
        handler = {
          formatter: d3.timeFormat("%Y"),
          parser: d3.timeParse("%Y"),
          ticks: d3.timeYear.every(1),
          tickFormat: d3.timeFormat("%Y"),
          xDomain: function(minDate, maxDate) {
            return [minDate, maxDate];
          }
        };
      } else if (vm._period === "quarter") {
        var quarterFormatter = function(date) {
          var q = Math.ceil((date.getMonth() + 0.5) / 3);
          return "Q" + q + "-" + date.getFullYear();
        };

        var quarterParser = function(d) {
          var splitted = d.split("-");
          var quarterEndMonth = splitted[0].charAt(1) * 3 - 2;
          var parser = d3.timeParse("%m %Y");
          return parser(quarterEndMonth + " " + splitted[1]);
        };

        handler = {
          formatter: function(date) {
            return quarterFormatter(date);
          },
          parser: function(d) {
            return quarterParser(d);
          },
          ticks: d3.timeMonth.every(3),
          tickFormat: function(date, i) {
            return quarterFormatter(date);
          },
          xDomain: function(minDate, maxDate) {
            // var r = [reparse(d3.timeMonth.offset(minDate, -3)), reparse(d3.timeMonth.offset(maxDate, 3))]
            return [minDate, maxDate];
          }
        };
      } else if (vm._period === "month") {
        handler = {
          formatter: d3.timeFormat("%Y-%m"),
          parser: d3.timeParse("%Y-%m"),
          ticks: d3.timeMonth.every(1),
          tickFormat: d3.timeFormat("%b-%Y"),
          xDomain: function(minDate, maxDate) {
            // return [d3.timeMonth.offset(minDate, -1), d3.timeMonth.offset(maxDate, 1)]
            return [minDate, maxDate];
          }
        };
      } else if (vm._period === "week") {
      }

      return handler;
    },
    randerGraph: function() {
      var vm = this;
      var d3 = this.$d3;
      var margin = { top: 45, right: 30, bottom: 60, left: 50 };
      var dateHandler = vm.getDateHandler();
      var dateFormatter = dateHandler.formatter;
      var dateParser = dateHandler.parser;

      this._svg = d3.select(this.$el).insert("svg");

      var svg = this._svg;
      var width = this.$el.clientWidth - margin.left - margin.right;
      var height = this.$el.clientHeight - margin.top - margin.bottom;
      svg.attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom);

      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      var parseTime = d3.timeParse("%Y-%m-%d");

      var x = d3.scaleTime().rangeRound([0, width]);

      var y = d3.scaleLinear().rangeRound([height, 0]);

      // console.log(JSON.stringify(this._config, ' ', 2))

      this.loadData(function(data) {
        var filteredData = data.filter(function(d) {
          var validType = false;
          if (!vm._types) {
            vm._types = [];
          }
          validType = vm._types.length === 0;

          vm._types.forEach(function(t) {
            // console.log(t + ', ' + JSON.stringify(d, ' ', 2))
            if (t === (d.symptomType || d.infectionSite)) {
              validType = true;
            }
          });

          return validType && (vm._subSiteType === 0 ? true : d.subtypeString === vm._subSiteType);
        });
        // console.log(JSON.stringify(groupedByDate, ' ', 2))

        var freqByType = d3
          .nest()
          .key(function(d) {
            d.onSetDate = parseTime(d.onSetDate);

            if (vm._mode === "site") {
              return d.symptomType || d.infectionSite;
            } else if (vm._mode === "unit") {
              return d.unitId;
            }

            return d.symptomType || d.infectionSite;
          })
          .entries(filteredData);

        // console.log(JSON.stringify(freqByType, ' ', 2))

        g.append("text")
          .attr("text-anchor", "middle") // this makes it easy to centre the text as the transform is applied to the anchor
          .attr("transform", "translate(" + -30 + "," + height / 2 + ")rotate(-90)") // text is drawn off the screen top left, move down and out and rotate
          .text("Frequency");

        g.append("text")
          .attr("text-anchor", "middle") // this makes it easy to centre the text as the transform is applied to the anchor
          .attr("transform", "translate(" + width / 2 + "," + (height + margin.bottom - 15) + ")") // centre below axis
          .text("Onset Date");

        g.append("text")
          .attr("text-anchor", "middle") // this makes it easy to centre the text as the transform is applied to the anchor
          .attr("transform", "translate(" + width / 2 + ",-20)") // centre below axis
          .text("Infection Case Frequency")
          .attr("font-size", "1.8em");

        var processedData = [];
        var colorScale = d3.scaleOrdinal(d3.schemeCategory20);
        freqByType.forEach(function(dataGroup, index) {
          // console.log(JSON.stringify(dataGroup, ' ', 2))
          // console.log(index)
          var data = d3
            .nest()
            .key(function(d) {
              return dateFormatter(new Date(d.onSetDate));
            })
            .rollup(function(v) {
              return d3.sum(v, function(d) {
                return d.count;
              });
            })
            .entries(dataGroup.values);

          data.sort(function(x, y) {
            return d3.ascending(x.onSetDate, y.onSetDate);
          });

          var groupColor = vm.colors[dataGroup.key];
          if (!groupColor) {
            vm.colors[dataGroup.key] = colorScale(index);
            groupColor = vm.colors[dataGroup.key];
          }

          var dataLabel = dataGroup.key;
          if (vm._mode === "unit") {
            vm._config.units.forEach(function(u) {
              // console.log(u.displayText + ', ' + dataGroup.key + ', ' + u.id)
              // console.log(dataGroup.key)
              // console.log(u.id)
              if (u.id === parseInt(dataGroup.key)) {
                // console.log(u)
                dataLabel = u.displayText;
              }
            });
          }

          var legend = g
            .append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "end")
            .attr("transform", "translate(0," + index * 25 + ")");

          legend
            .append("rect")
            .attr("x", width - 19)
            .attr("width", 18)
            .attr("height", 18)
            .attr("fill", groupColor)
            .style("stroke", "black")
            .style("stroke-width", 1);

          legend
            .append("text")
            .attr("x", width - 24)
            .attr("y", 9.5)
            .attr("dy", "0.32em")
            .text(dataLabel);

          data.forEach(function(d) {
            d.type = dataGroup.key;
            d.index = index;
            d.color = groupColor;
            var tDate = dateParser(d.key);
            d.date = tDate;

            if (!processedData[d.type]) {
              processedData[d.type] = {};
              processedData[d.type]["values"] = [];
              processedData[d.type].color = groupColor;
            }
            processedData[d.type]["values"].push(d);
          });
        });

        var minDate = new Date();
        var maxDate = new Date();

        if (vm.range && vm.range[0] && vm.range[1]) {
          minDate = vm.range[0];
          maxDate = vm.range[1];
        }

        var paddedDate = 5;
        if (vm._period === "year") {
          paddedDate = 170;
        } else if (vm._period === "month") {
          paddedDate = 20;
        } else if (vm._period === "quarter") {
          paddedDate = 45;
        }
        minDate = dateParser(dateFormatter(minDate));
        minDate = minDate.setDate(minDate.getDate() - paddedDate);
        maxDate = dateParser(dateFormatter(maxDate));
        maxDate = maxDate.setDate(maxDate.getDate() + paddedDate);

        x.domain(dateHandler.xDomain(minDate, maxDate));

        var maxValue = 0;
        for (var p in processedData) {
          if (processedData[p] && processedData[p].values) {
            var tMax = d3.max(processedData[p].values, function(d) {
              return d.value;
            });

            if (tMax > maxValue) {
              maxValue = tMax;
            }
          }
        }

        var newData = {};
        x.ticks(dateHandler.ticks).map(function(bucket) {
          var key = dateFormatter(bucket);
          var i = 0;
          for (p in processedData) {
            if (processedData[p] && processedData[p].values) {
              var init = processedData[p].values[0];
              var hasValue = false;
              var found = init;

              if (!newData[p]) {
                newData[p] = {};
                newData[p]["values"] = [];
                newData[p].color = vm.colors[init.type];
                if (!newData[p].color) {
                  vm.colors[init.type] = colorScale(i);
                  newData[p].color = vm.colors[init.type];
                }
              }

              processedData[p].values.forEach(function(d) {
                if (d.key === key) {
                  hasValue = true;
                  found = d;
                }
              });

              if (!hasValue) {
                var nD = {
                  key: key,
                  type: init.type,
                  index: init.index,
                  color: init.color,
                  date: dateParser(key),
                  value: 0
                };

                newData[p].values.push(nD);
              } else {
                newData[p].values.push(found);
              }
            }
            i++;
          }

          return bucket;
        });

        var yMax = maxValue + maxValue / 2;
        if (yMax < 5) {
          yMax = 5;
        }
        y.domain([0, yMax]);

        for (p in processedData) {
          if (processedData[p] && processedData[p].values) {
            processedData[p].values.sort(function(x, y) {
              return d3.ascending(x.date, y.date);
            });
          }
        }
        processedData = newData;

        g.append("g")
          .attr("class", "x-axis")
          .attr("transform", "translate(0," + height + ")")
          .call(
            d3
              .axisBottom(x)
              .ticks(dateHandler.ticks)
              .tickFormat(dateHandler.tickFormat)
          );

        g.append("g")
          .call(d3.axisLeft(y).ticks(3))
          .append("text")
          .attr("fill", "#000")
          .attr("x", 6)
          .attr("dy", "-1em")
          .attr("text-anchor", "center");

        // gridlines in x axis function
        function makeXgridlines() {
          return d3.axisBottom(x).ticks(10);
        }

        // gridlines in y axis function
        function makeYgridlines() {
          return d3.axisLeft(y).ticks(10);
        }

        g.append("g")
          .attr("class", "grid")
          .attr("transform", "translate(0," + height + ")")
          .style("opacity", "0.1")
          .call(
            makeXgridlines()
              .tickSize(-height)
              .tickFormat("")
          );

        g.append("g")
          .attr("class", "grid")
          .style("opacity", "0.1")
          .call(
            makeYgridlines()
              .tickSize(-width)
              .tickFormat("")
          );

        var currTicks = x.ticks(dateHandler.ticks).length;
        var tickWidth = width / currTicks;
        var i = 0;
        var barIndex = {};
        for (var prop in processedData) {
          barIndex[prop] = i;
          i++;
        }

        var padding = tickWidth * 0.1;
        var barWidth = (tickWidth - padding * 2) / i;
        var barPadding = barWidth * 0.25;

        for (prop in processedData) {
          // console.log(prop)
          processedData[prop].values.forEach(function(d) {
            var barHeight = height - y(d.value);
            var textLabel = g
              .append("text")
              .text(function() {
                return d.value;
              })
              .attr("x", function() {
                // console.log(this.getBBox().width)
                return x(d.date) + padding + barWidth * barIndex[prop] - tickWidth * 0.5 + barWidth * 0.5 - this.getBBox().width * 0.5;
              })
              .attr("y", y(d.value) - 10)
              .style("opacity", vm.showValue ? 1 : 0);

            g.append("rect")
              .attr("class", "rect")
              // g.append('circle')
              // .attr('class', 'circle')
              .filter(function() {
                return d.date >= minDate && d.date <= maxDate;
              })
              .attr("x", x(d.date) + padding + barWidth * barIndex[prop] - tickWidth * 0.5 + barPadding)
              .attr("y", y(d.value))
              .attr("width", barWidth - barPadding * 2)
              .attr("fill", d.color)
              .style("stroke", d.color)
              .style("stroke-width", 1)
              .attr("height", barHeight === 0 ? 1 : barHeight)
              .on("mouseover", function() {
                console.log(this);
                d3.select(this).attr("fill", "red");
                d3.select(this).style("stroke", "red");
                textLabel.style("opacity", 1);
              })
              .on("mouseout", function() {
                console.log(d);
                d3.select(this).attr("fill", d.color);
                d3.select(this).style("stroke", d.color);
                textLabel.style("opacity", vm.showValue ? 1 : 0);
              });
          });
        }

        // Alternate the tick label text to match up with the tick length
        var maxTicks = Math.floor(width / 60);

        if (currTicks > maxTicks) {
          var factor = Math.ceil(currTicks / maxTicks);
          d3.selectAll("g.x-axis g.tick").each(function(d, i) {
            if (i % factor > 0) {
              // even
              this.remove();
            }
          });
        }
      });
    },
    exportGraph: function() {
      var d3 = this.$d3;
      var svgNode = d3.select("svg");
      var width = svgNode.attr("width");
      var height = svgNode.attr("height");
      var svgString = this.getSVGString(this.$el.querySelector("svg"));
      this.svgString2Image(svgString, 2 * width, 2 * height, "png", save); // passes Blob and filesize String to the callback

      function save(dataBlob, filesize) {
        var timeFormatter = d3.timeFormat("%Y-%m-%d-%H-%M-%S");
        FileSaver.saveAs(dataBlob, "graph-" + timeFormatter(new Date()) + ".png"); // FileSaver.js function
      }
    },
    getSVGString: function(svgNode) {
      svgNode.setAttribute("xlink", "http://www.w3.org/1999/xlink");
      var cssStyleText = getCSSStyles(svgNode);
      console.log(cssStyleText);
      console.log(FileSaver);
      // appendCSS(cssStyleText, svgNode)

      var serializer = new window.XMLSerializer();
      var svgString = serializer.serializeToString(svgNode);
      svgString = svgString.replace(/(\w+)?:?xlink=/g, "xmlns:xlink=");
      svgString = svgString.replace(/NS\d+:href/g, "xlink:href");
      console.log(svgString);
      return svgString;

      function getCSSStyles(parentElement) {
        var selectorTextArr = [];

        // Add Parent element Id and Classes to the list
        selectorTextArr.push("#" + parentElement.id);
        console.log(parentElement.classList);
        if (parentElement.classList) {
          for (var c = 0; c < parentElement.classList.length; c++) {
            if (!contains("." + parentElement.classList[c], selectorTextArr)) {
              selectorTextArr.push("." + parentElement.classList[c]);
            }
          }
        }

        // Add Children element Ids and Classes to the list
        var nodes = parentElement.getElementsByTagName("*");

        for (var i = 0; i < nodes.length; i++) {
          var id = nodes[i].id;
          if (!contains("#" + id, selectorTextArr)) {
            selectorTextArr.push("#" + id);
          }

          var classes = nodes[i].classList || [];
          for (c = 0; c < classes.length; c++) {
            if (!contains("." + classes[c], selectorTextArr)) {
              selectorTextArr.push("." + classes[c]);
            }
          }
        }

        // Extract CSS Rules
        var extractedCSSText = "";

        for (i = 0; i < document.styleSheets.length; i++) {
          var s = document.styleSheets[i];
          try {
            if (!s.cssRules) continue;
          } catch (e) {
            if (e.name !== "SecurityError") throw e; // for Firefox
            continue;
          }

          var cssRules = s.cssRules;
          for (var r = 0; r < cssRules.length; r++) {
            if (contains(cssRules[r].selectorText, selectorTextArr)) {
              extractedCSSText += cssRules[r].cssText;
            }
          }
        }
        return extractedCSSText;
      }

      function contains(str, arr) {
        return arr.indexOf(str) !== -1; // ? false : true
      }
      /*
      function appendCSS (cssText, element) {
        var styleElement = document.createElement('style')
        styleElement.setAttribute('type', 'text/css')
        styleElement.innerHTML = cssText
        var refNode = element.hasChildNodes() ? element.children[0] : null
        element.insertBefore(styleElement, refNode)
      }
*/
    },
    svgString2Image: function(svgString, width, height, format, callback) {
      if (!format) {
        format = "png";
      }

      var imgsrc = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;

      var image = new window.Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        context.clearRect(0, 0, width, height);
        context.drawSvg(svgString);

        var userAgent = window.navigator.userAgent;
        var isIE = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
        if (isIE) {
          var blob = canvas.msToBlob();
          var filesize = Math.round(blob.length / 1024) + " KB";
          if (callback) {
            if (callback) callback(blob, filesize);
          }
        } else {
          canvas.toBlob(function(blob) {
            var filesize = Math.round(blob.length / 1024) + " KB";
            if (callback) callback(blob, filesize);
          });
        }
      };
      image.src = imgsrc;
    },
    handleResize: function() {
      this.reDraw();
    }
  },
  mounted: function() {
    console.log(this.$d3.select("svg").node());
    this._start = this.range[0];
    this._end = this.range[1];
    this._config = this.config;
    this._types = this.sites;
    this._subSiteType = this.subSiteType || 0;
    this._confirmed = this.confirmed;
    this._mode = this.mode;
    this._period = this.period;
    this._selectedClientStatus = this.selectedClientStatus || 1;
    this._selectedPrecaution = this.selectedPrecaution || 0;
    this._selectedClientType = this.selectedClientType || 0;
    this._selectedOrganism = this.selectedOrganism || 0;
    this._selectedTreatment = this.selectedTreatment || 0;
    this._selectedContact = this.selectedContact || 0;
    this._selectedOutcome = this.selectedOutcome || 0;
    this._selectedIsoltation = this.selectedIsoltation || 0;
    this.randerGraph();
    this._d3Mounted = true;
    console.log(this.range);
  },
  created: function() {
    this._period = "month";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.export_button {
  display: none;
}
/* 
div.container {
  // border: 1px solid #444;
}

.container > .export_button {
  // position: absolute;
  // top: 1em;
  // right: 1em;
  // border: 0;
  // box-shadow:none;
  // border-radius: 0px;
  // display: block;
  // background: #444;
  // color: white;
  // font-size: 0.7em;
  // padding: 10px;
} */
</style>
