<template>
  <div class="filters">
    <page-section title="Filters">
      <template slot="title">
        <slot name="title"></slot>
        <el-button size="small" @click="resetAllFilters">Reset All Filters</el-button>
        <el-popover ref="moreFilters" placement="bottom" width="300" trigger="click">
          <div class="moreFilterGrid">
            <template v-if="moreFilters.includes('clientStatus')">
              <label>Individual Status</label>
              <el-switch v-model="toggledFilters.clientStatus" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('infectionSites')">
              <label>Infection Site</label>
              <el-switch v-model="toggledFilters.infectionSites" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('resolutions')">
              <label>Outcome</label>
              <el-switch v-model="toggledFilters.resolutions" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('primaryOrganisms')">
              <label>Primary Organism</label>
              <el-switch v-model="toggledFilters.primaryOrganisms" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('caseStatus')">
              <label>Case Status</label>
              <el-switch v-model="toggledFilters.caseStatus" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('medications')">
              <label>Medication</label>
              <el-switch v-model="toggledFilters.medications" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('origins')">
              <label>Origin</label>
              <el-switch v-model="toggledFilters.origins" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('employeeTypes')">
              <label>Employee Type</label>
              <el-switch v-model="toggledFilters.employeeTypes" on-text="" off-text=""></el-switch>
            </template>
            <template v-if="moreFilters.includes('precautions')">
              <label>Precaution</label>
              <el-switch v-model="toggledFilters.precautions" on-text="" off-text=""></el-switch>
            </template>
          </div>
        </el-popover>
        <el-button style="margin-left: 10px" v-popover:moreFilters v-if="moreFilters.length" size="small"> More Filters </el-button>
      </template>
      <template slot="default">
        <div>
          <el-form class="filterGrid" :data-column-count="filterColumnCount" v-model="formState" label-width="120px">
            <template v-for="filterKey in processedFilterKeys">
              <template v-if="filterKey === 'separator'">
                <div class="separator" :key="filterKey"></div>
              </template>

              <template v-if="filterKey === 'type' && sites.length > 1">
                <el-form-item label="Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" @change="locationTypeChangeHandler">
                    <el-option value="LTC"></el-option>
                    <el-option value="RET"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'region' && sites.length > 1">
                <el-form-item :label="regionsLabel" :key="filterKey">
                  <el-select v-model="filters[filterKey]" value-key="id" @change="locationRegionChangeHandler">
                    <el-option label="All" :value="0"></el-option>
                    <el-option v-for="item in processedRegions" :key="item.id" :label="item.displayText" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'subregion' && sites.length > 1 && processedSubregions.length">
                <el-form-item :label="subRegionsLabel" :key="filterKey" v-if="filters.region">
                  <el-select v-model="filters[filterKey]" value-key="id" @change="locationSubRegionChangeHandler">
                    <el-option label="All" :value="0"></el-option>
                    <el-option v-for="item in processedSubregions" :key="item.id" :label="item.displayText" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'facilities' && sites.length > 1">
                <el-form-item label="Facility" :key="filterKey">
                  <el-select v-model="filters[filterKey]" value-key="id" filterable @change="locationFacilityChangeHandler">
                    <el-option label="All" :value="0"></el-option>
                    <el-option v-for="item in processedFacilities" :key="item.id" :label="item.displayText" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'units'">
                <el-form-item label="Unit" :key="filterKey">
                  <el-select v-model="filters[filterKey]" value-key="id" filterable>
                    <el-option label="All" :value="0"></el-option>
                    <el-option v-for="item in processedUnits" :key="item.id" :label="`${item.displayText}${filters.facilities ? `` : ` - ${item.site.displayText}`}`" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'auditType'">
                <el-form-item label="Audit Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" value-key="id" multiple>
                    <el-option v-for="item in auditTypes" :key="item.id" :label="item.displayText" :value="item.displayText"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'hcwType'">
                <el-form-item label="HCW Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" value-key="id" multiple>
                    <el-option v-for="item in healthcareWorkers" :key="item.id" :label="item.displayText" :value="item.displayText"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'observers'">
                <el-form-item label="Observer" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" multiple>
                    <el-option v-for="item in observers" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'momentBySection'">
                <el-form-item label="Moment Group" :key="filterKey">
                  <el-select v-model="filters[filterKey]">
                    <el-option :value="0" label="All"></el-option>
                    <el-option v-for="item in momentsGroups" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'moments'">
                <el-form-item label="Moment" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" multiple>
                    <el-option v-for="item in hhMoments" :key="item.id" :label="item.displayText" :value="item.displayText"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'immunizationTypes'">
                <el-form-item label="Immunization Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" multiple>
                    <el-option v-for="item in immunizationTypes" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'vaccineTypes'">
                <el-form-item label="Vaccine Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" multiple>
                    <el-option v-for="item in vaccineTypes" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'collectionDate'">
                <el-form-item label="Date Range" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'medicationDate'">
                <el-form-item label="Medication Start Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'infectionOnsetDate'">
                <el-form-item label="Infection Onset Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                  <slot :name="`${filterKey}-append`"></slot>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'createdDate'">
                <el-form-item label="Created Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                  <slot :name="`${filterKey}-append`"></slot>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'outbreakDeclaredDate'">
                <el-form-item label="Declared Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'immunizationDate'">
                <el-form-item label="Administration Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                  <slot :name="`${filterKey}-append`"></slot>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'expiryDate'">
                <el-form-item label="Expiry Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                  <slot :name="`${filterKey}-append`"></slot>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'precautionDate'">
                <el-form-item label="Precautions Date" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'firstName'">
                <el-form-item label="First Name" :key="filterKey">
                  <el-input placeholder="First Name" v-model="filters[filterKey]" icon="search"></el-input>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'lastName'">
                <el-form-item label="Last Name" :key="filterKey">
                  <el-input placeholder="Last Name" v-model="filters[filterKey]" icon="search"></el-input>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'username'">
                <el-form-item label="Username" :key="filterKey">
                  <el-input placeholder="Username" v-model="filters[filterKey]" :on-icon-click="emitFiltersChanged" @keyup.native.enter="emitFiltersChanged" icon="search"></el-input>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'primaryOrganisms'">
                <el-form-item label="Primary Organism:" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple clearable>
                    <el-option v-for="o in organisms" :key="o.id" :label="o.name" :value="o.name"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'causativeOrganisms'">
                <el-form-item label="Causative Organism:" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple clearable>
                    <el-option v-for="o in organisms" :key="o.id" :label="o.name" :value="o.name"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'results'"> </template>

              <template v-if="filterKey === 'source'"> </template>

              <template v-if="filterKey === 'procedure'"> </template>

              <template v-if="filterKey === 'reason'"> </template>

              <template v-if="filterKey === 'recentLabs'"> </template>

              <template v-if="filterKey === 'cultureDate'"> </template>

              <template v-if="filterKey === 'dateRange'">
                <el-form-item label="Date Range" :key="filterKey">
                  <el-date-picker v-model="filters[filterKey]" type="daterange" placeholder="All Dates" range-separator=" ~ " :format="$configStore.datePickerDateFormat()" :picker-options="datepickerShortcuts" :clearable="false" small>
                    <template slot="prepend">Http://</template>
                  </el-date-picker>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'infectionSites'">
                <el-form-item label="Infection Site" :key="filterKey">
                  <el-cascader v-model="filters[filterKey]" :options="siteAndTypeOptions" placeholder="Select Infection Site" clearable></el-cascader>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'basicInfectionSites'">
                <el-form-item label="Infection Site" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple clearable>
                    <el-option v-for="e in siteAndTypeOptions" :key="e.value.id" :label="e.label" :value="e.label"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'readonlyInfectionSite'">
                <el-form-item label="Infection Site" :key="filterKey">
                  <el-input v-model="filters[filterKey]" disabled></el-input>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'resolutions'">
                <el-form-item label="Outcome" :key="filterKey">
                  <el-cascader v-model="filters[filterKey]" :options="outcomeOptions" placeholder="Select Outcome" clearable></el-cascader>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'declined'">
                <el-form-item label="Declined?" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" value="all"> </el-option>
                    <el-option label="Yes" :value="true"> </el-option>
                    <el-option label="No" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'assigned'">
                <el-form-item label="Assigned?" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" value="all"> </el-option>
                    <el-option label="Yes" :value="true"> </el-option>
                    <el-option label="No" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'oneRecordOnly'">
                <el-form-item label="One Record Only" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="Yes" :value="true"> </el-option>
                    <el-option label="No" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'outbreakResolved'">
                <el-form-item label="Resolved" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" value="all"> </el-option>
                    <el-option label="Resolved" :value="true"> </el-option>
                    <el-option label="Not Resolved" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'caseStatus'">
                <el-form-item label="Case Status" :key="filterKey" multi>
                  <el-select v-model="filters[filterKey]" clearable placeholder="Select">
                    <el-option label="Confirmed" :value="true"> </el-option>
                    <el-option label="Suspected" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'outbreakStatus'">
                <el-form-item label="Outbreak Status" :key="filterKey" multi>
                  <el-select v-model="filters[filterKey]" clearable placeholder="Select">
                    <el-option label="Confirmed" :value="true"> </el-option>
                    <el-option label="Suspected" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'activePrecautions'">
                <el-form-item label="Active Isolation" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable clearable>
                    <el-option label="Yes" :value="true"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'uniquePrecautionCount'">
                <el-form-item label="Unique Count" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="On" :value="true"></el-option>
                    <el-option label="Off" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'status'"> </template>

              <template v-if="filterKey === 'residentStatus'"> </template>

              <template v-if="filterKey === 'contact'"> </template>

              <template v-if="filterKey === 'origins'">
                <el-form-item label="Origin" :key="filterKey">
                  <el-select v-model="filters[filterKey]" clearable multiple placeholder="Select">
                    <el-option v-for="o in origins" :key="o.label" :label="o.label" :value="o.value"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'staffisolation'"> </template>

              <template v-if="filterKey === 'treatments'"> </template>

              <template v-if="filterKey === 'precautions'">
                <el-form-item label="Precaution" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple clearable>
                    <el-option v-for="p in precautions" :key="p.id" :label="p.displayText" :value="p.id"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'linkedTreatmentsOnly'">
                <el-form-item label="Linked Treatments Only" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="No" :value="false"></el-option>
                    <el-option label="Yes" :value="true"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'showDeleted'">
                <el-form-item label="Show Deleted" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="Active only" value="active"></el-option>
                    <el-option label="Deleted only" value="deleted"></el-option>
                    <el-option label="Active and Deleted" value="active_and_deleted"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'userStatus'">
                <el-form-item label="Status" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="Active only" value="active"></el-option>
                    <el-option label="Inactive only" value="inactive"></el-option>
                    <el-option label="Active and Inactive" value="active_and_inactive"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'outcomes'">
                <el-form-item label="Outcome" :key="filterKey">
                  <el-select v-model="filters[filterKey]" placeholder="All" multiple>
                    <el-option label="Washed" :value="'Wash'"></el-option>
                    <el-option label="Rubbed" :value="'Rub'"></el-option>
                    <el-option label="Missed" :value="'Missed'"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'compliance'">
                <el-form-item label="Compliant" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" :value="0"></el-option>
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'medications'">
                <el-form-item label="Medication" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple>
                    <el-option v-for="med in medications" :key="med.id" :label="med.displayText" :value="med.id"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'providers'">
                <el-form-item label="Provider" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple>
                    <el-option v-for="item in providers" :key="item.id" :label="`${item.firstName} ${item.lastName} (${item.staffId})`" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'clientType'">
                <el-form-item label="Individual Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" :value="0"></el-option>
                    <el-option label="Resident" value="Client"></el-option>
                    <el-option label="Staff" value="Staff"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'employeeTypes'">
                <el-form-item label="Employee Type" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple>
                    <el-option v-for="item in employeeTypes" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'positions'">
                <el-form-item label="Position" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable multiple>
                    <el-option v-for="item in employeeTypes" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-form-item>
              </template>

              <template v-if="filterKey === 'clientStatus'">
                <el-form-item label="Individual Status" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" :value="0"></el-option>
                    <el-option label="Active" value="C"></el-option>
                    <el-option label="Transferred" value="T"></el-option>
                    <el-option label="Discharged" value="D"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-if="filterKey === 'staffStatus'">
                <el-form-item label="Client Status" :key="filterKey">
                  <el-select v-model="filters[filterKey]" filterable>
                    <el-option label="All" :value="0"></el-option>
                    <el-option label="Active" value="C"></el-option>
                    <el-option label="Inactive" value="D"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </template>
          </el-form>
        </div>
      </template>
    </page-section>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import auth from "../../auth";
import util from "../../util";
// import api from '../../services/restClient'
import LocationFilter from "../../components/LocationFilter/LocationFilter";
import ProgressOverlay from "../../components/ProgressBarOverlay";
import PageSection from "../Shared/PageSection";
import SectionTitle from "../Shared/SectionTitle";

export default {
  name: "page-filters",
  components: { SectionTitle, PageSection, LocationFilter, ProgressOverlay },
  props: {
    showLocation: {
      type: Boolean,
      default() {
        return true;
      },
    },
    filterDisplayKeys: {
      type: Array,
      default() {
        return ["type", "region", "subregion", "facilities", "units", "firstName", "lastName"];
      },
    },
    dateRangeStart: {
      type: Date,
      default() {
        return moment().subtract(30, "days").toDate();
      },
    },
    dateRangeEnd: {
      type: Date,
      default() {
        return moment().toDate();
      },
    },
    isHandHygiene: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isOutbreakDetail: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showPlaceholderUnits: {
      type: Boolean,
      default() {
        return false;
      },
    },
    includeUnits: {
      type: Boolean,
      default() {
        return true;
      },
    },
    readonlyInfectionSite: {
      type: String,
      default() {
        return "";
      },
    },
    moreFilters: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultValues: {
      type: Object,
      default() {
        return {};
      },
    },
    modifiedDatepickerShortcuts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      filterColumnCount: 4,
      sites: [],
      organization: [],
      fullOrgTree: {},
      formState: {},
      healthcareWorkers: [],
      auditTypes: [],
      observers: [],
      momentsGroups: [],
      hhMoments: [],
      providers: [],
      immunizationTypes: [],
      origins: [],
      immunizationConfig: {},
      filters: {},
      medications: [],
      toggledFilters: {},
      siteAndTypeOptions: [],
      outcomeOptions: [],
      organisms: [],
      precautions: [],
      employeeTypes: this.$configStore.employeeTypes(),
      filterDefaults: {
        type: "LTC",
        region: 0,
        subregion: 0,
        facilities: 0,
        units: 0,
        dateRange: [],
        collectionDate: [],
        medicationDate: [],
        immunizationDate: [],
        expiryDate: [],
        precautionDate: [],
        outbreakDeclaredDate: [],
        createdDate: [],
        clientStatus: 0,
        staffStatus: 0,
        origins: [],
        precautions: [],
        status: 0,
        outbreakStatus: "",
        treatment: 0,
        organisms: 0,
        primaryOrganisms: [],
        causativeOrganisms: [],
        resolved: "Not Resolved",
        clientType: 0,
        employeeTypes: [],
        positions: [],
        staffIsolation: [],
        vaccineTypes: [],
        immunizationTypes: [],
        site: 0,
        firstName: "",
        lastName: "",
        username: "",
        contact: 0,
        hcwType: [],
        auditType: [],
        observers: [],
        momentBySection: 0,
        showDeleted: "active",
        userStatus: "active_and_inactive",
        moments: [],
        compliance: 0,
        outcomes: [],
        medications: [],
        linkedTreatmentsOnly: false,
        providers: [],
        resolutions: [],
        caseStatus: null,
        outbreakResolved: false,
        declined: false,
        assigned: "all",
        oneRecordOnly: true,
        activePrecautions: "",
        uniquePrecautionCount: true,
        infectionSites: [],
        basicInfectionSites: [],
        readonlyInfectionSite: "",
      },
      datepickerShortcuts: {
        shortcuts: util.datepickerShortcuts(),
      },
    };
  },
  computed: {
    processedRegions() {
      const selectedType = this.filters.type;
      return this.fullOrgTree.subCategories.filter((category) => category.types[selectedType]).sort((a, b) => a.displayText.localeCompare(b.displayText));
    },
    processedSubregions() {
      const selectedType = this.filters.type;
      if (this.filters.region !== 0) {
        return this.filters.region.subCategories.filter((category) => category.types[selectedType]).sort((a, b) => a.displayText.localeCompare(b.displayText));
      }
      return [];
    },
    processedFacilities() {
      const isSingleFacOrg = this.fullOrgTree.sites.length === 1;
      let facilities = [];
      if (this.filters.region !== 0) {
        facilities = this.filters.region.sites;
        if (this.filters.subregion !== 0) {
          facilities = this.filters.subregion.sites;
        }
      } else {
        if (isSingleFacOrg) {
          facilities = [...this.fullOrgTree.sites];
        } else {
          facilities = this.fullOrgTree.sites;
        }
      }
      const facilitiesFilteredByType = isSingleFacOrg ? facilities : facilities.filter((fac) => fac.unitType === this.filters.type).sort((a, b) => a.displayText.localeCompare(b.displayText));
      return facilitiesFilteredByType;
    },
    processedUnits() {
      let facilities = this.filters.facilities === 0 ? this.processedFacilities : [this.filters.facilities];
      let units = [];
      units = facilities.map((fac) => fac.units).reduce((acc, val) => [...acc, ...val], []);
      if (this.showPlaceholderUnits === false) {
        units = units.filter((unit) => unit.code !== "PLACE_HOLDER");
      }
      return units;
    },
    processedFilterKeys() {
      let locationKeys = [];
      if (this.showLocation) {
        const isMultiFacility = this.organization.sites.length > 1;
        const hasFacType = auth.userInfo().roles.indexOf("ROLE_LTC") >= 0 && auth.userInfo().roles.indexOf("ROLE_RET") >= 0;
        // outbreaks are already scoped to a facility; org/fac level location filters aren't needed
        if (isMultiFacility && !this.isOutbreakDetail) {
          if (hasFacType) {
            locationKeys = ["type"];
          }
          locationKeys = [...locationKeys, "region", "subregion", "facilities"];
          if (this.includeUnits) {
            locationKeys = [...locationKeys, "units", "separator"];
          }
        } else if (this.includeUnits) {
          locationKeys = ["units"];
        }
      }
      const additional = Object.entries(this.toggledFilters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
      return [...locationKeys, ...this.filterDisplayKeys, ...additional];
    },
    vaccineTypes() {
      const { immunizationTypes } = this.filters;
      const common = ["Other", "Unknown", "Not Applicable"];

      // vaccine types based on the selected immunization types, or all if none are selected
      let vaccineTypes = [];
      if (immunizationTypes?.length) {
        vaccineTypes = immunizationTypes.flatMap((immunizationType) => this.immunizationConfig[immunizationType] || []);
      } else {
        vaccineTypes = Object.values(this.immunizationConfig).flat();
      }

      // filter out the common entries and reinsert at the beginning
      const unique = vaccineTypes.filter((vaccineType) => !common.includes(vaccineType));
      const combined = [...new Set([...common, ...unique])];

      return combined;
    },
    regionsLabel() {
      return this.fullOrgTree.subCategoryLabel;
    },
    subRegionsLabel() {
      return this.filters.region.subCategoryLabel;
    },
  },
  methods: {
    locationTypeChangeHandler() {
      this.filters.region = 0;
      this.filters.subregion = 0;
      this.filters.facilities = 0;
      this.filters.units = 0;
    },
    locationRegionChangeHandler() {
      this.filters.subregion = 0;
      this.filters.facilities = 0;
      this.filters.units = 0;
    },
    locationSubRegionChangeHandler() {
      this.filters.facilities = 0;
      this.filters.units = 0;
    },
    locationFacilityChangeHandler() {
      this.filters.units = 0;
    },
    emitFiltersChanged() {
      let processedFilters = JSON.parse(JSON.stringify(this.filters));

      if (processedFilters.facilities !== 0) {
        processedFilters.facilities = [processedFilters.facilities.id];
      } else {
        processedFilters.facilities = this.processedFacilities.map((site) => site.id);
      }

      if (processedFilters.units !== 0) {
        processedFilters.units = [processedFilters.units.id];
      } else {
        delete processedFilters.units;
      }

      // prune all unused keys except facilities
      for (const key in processedFilters) {
        if (key !== "facilities" && this.processedFilterKeys.indexOf(key) === -1) {
          delete processedFilters[key];
        }
        if (processedFilters[key] === "" || processedFilters[key] === null || processedFilters[key] === undefined) {
          delete processedFilters[key];
        }
        if (Array.isArray(processedFilters[key]) && processedFilters[key].length === 0) {
          delete processedFilters[key];
        }
      }

      delete processedFilters.type;
      delete processedFilters.region;
      delete processedFilters.subregion;

      console.log({ processedFilters });
      this.$emit("change", processedFilters);
      this.updateUrl();
    },
    resetAllFilters() {
      Object.entries(this.filterDefaults).forEach((pair) => {
        const [key, value] = pair;
        this.filters[key] = value;
      });
      this.updateUrl();
    },
    updateUrl() {
      const relevantFilters = Object.fromEntries(this.processedFilterKeys.map((f) => [f, this.filters[f]]));
      this.$router.replace({ path: window.location.pathname, query: { sectionFilters: JSON.stringify(relevantFilters) } });
    },
    emitMoreFilterToggle() {
      this.$emit("togggleMoreFilters");
    },
  },
  created() {
    this.organization = this.$configStore.data.organization[0];
    this.sites = this.$configStore.data.sites;
    let sites = {};
    this.organization.sites.forEach((s) => {
      sites[s.id] = s;
    });
    if (this.modifiedDatepickerShortcuts?.length) {
      this.datepickerShortcuts.shortcuts = [...this.modifiedDatepickerShortcuts];
    }
    const debouncedFilteringEvent = _.debounce(() => {
      this.emitFiltersChanged();
    }, 250);

    const availableUnitTypes = new Set();
    const generateFullOrgTree = (obj, tree) => {
      tree.displayText = obj.displayText;

      tree.id = obj.id;
      tree.types = {};
      tree.sites = [];
      tree.subCategories = [];
      if (obj.refIds && obj.refIds.length) {
        tree.sites = obj.refIds.map((siteId) => {
          const site = sites[siteId];
          tree.types[site.unitType] = true;
          availableUnitTypes.add(site.unitType, true);
          return site;
        });
        return tree;
      } else if (obj.subCategory) {
        tree.subCategoryLabel = obj.subCategoryText;
        tree.subCategories = obj.subCategory.map((o) => generateFullOrgTree(o, {}));
        tree.subCategories.forEach((child) => {
          tree.types = { ...tree.types, ...child.types };
          tree.sites = [...tree.sites, ...child.sites];
        });
        return tree;
      } else {
        tree.sites = obj.sites;
        return tree;
      }
    };

    // PPE
    if (this.isHandHygiene === false) {
      if (this.filterDisplayKeys.includes("hcwType")) {
        this.$http.get(`${window.CONFIG.ppe_api}/hcw`).then((response) => {
          this.healthcareWorkers = response.body;
        });
      }

      if (this.filterDisplayKeys.includes("observers")) {
        this.$http.get(`${window.CONFIG.ppe_api}/observers`).then((response) => {
          this.observers = response.body;
        });
      }

      if (this.filterDisplayKeys.includes("auditType")) {
        this.$http.get(`${window.CONFIG.ppe_api}/audit-types`).then((response) => {
          this.auditTypes = response.body;
        });
      }
    }

    // HH
    if (this.isHandHygiene === true) {
      this.$http.get(`${window.CONFIG.api}/handhygiene/settings?facId=1`).then((response) => {
        const hhSettings = response.body;
        if (this.filterDisplayKeys.includes("hcwType")) {
          this.healthcareWorkers = hhSettings.workers.map((text) => {
            return {
              id: text,
              displayText: text,
            };
          });
        }
        if (this.filterDisplayKeys.includes("moments")) {
          this.hhMoments = hhSettings.moments.map((text) => {
            return {
              id: text,
              displayText: text,
            };
          });
        }
      });

      if (this.filterDisplayKeys.includes("observers")) {
        this.$http.get(`${window.CONFIG.hh_api}/observers`).then((response) => {
          this.observers = response.body;
        });
      }
    }

    if (this.filterDisplayKeys.includes("momentBySection")) {
      this.$http.get(`${window.CONFIG.ppe_api}/config/ops`).then((response) => {
        this.momentsGroups = response.body.data.ops.sections.map((s) => s.name);
      });
    }

    if (this.filterDisplayKeys.includes("medications") || this.moreFilters.includes("medications")) {
      this.$http
        .get(`${window.CONFIG.treatement_api}/config`)
        .then((resp) => resp.json())
        .then((json) => (this.medications = json.medications));
    }

    if (this.filterDisplayKeys.includes("immunizationTypes") || this.filterDisplayKeys.includes("vaccineTypes")) {
      this.$http
        .get(`${window.CONFIG.immunization_api}/config`)
        .then((resp) => resp.json())
        .then((json) => {
          this.immunizationTypes = Object.keys(json);
          this.immunizationConfig = json;
        });
    }

    if (this.filterDisplayKeys.includes("providers")) {
      this.$http
        .get(`${window.CONFIG.api}/clients/search`, {
          params: {
            providerType: "Yes",
            status: "C",
            clientType: "Staff",
          },
        })
        .then((resp) => resp.json())
        .then((json) => (this.providers = json));
    }

    if (this.filterDisplayKeys.includes("resolutions")) {
      this.$http
        .get(`${window.CONFIG.infection_api}/outcomes`)
        .then((resp) => resp.json())
        .then((json) => {
          const outcomeOptions = [
            {
              label: "All Outcomes",
              value: 0,
            },
          ];
          for (const outcome in json) {
            const item = {
              label: outcome,
              value: outcome,
            };
            const outcomeSubTypes = json[outcome];
            if (outcomeSubTypes?.length) {
              item.children = outcomeSubTypes.map((outcomeSubType) => {
                return {
                  label: outcomeSubType,
                  value: outcomeSubType,
                };
              });
              item.children.unshift({ value: 0, label: "All Types" });
            }
            outcomeOptions.push(item);
          }
          outcomeOptions.push({
            label: "Not Resolved",
            value: "Not Resolved",
          });
          this.outcomeOptions = outcomeOptions;
        });
    }

    this.fullOrgTree = generateFullOrgTree(this.organization.category || this.organization, {});

    this.filterDefaults.dateRange = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.collectionDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.medicationDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.immunizationDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.expiryDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.precautionDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.outbreakDeclaredDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.infectionOnsetDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.createdDate = [this.dateRangeStart, this.dateRangeEnd];
    this.filterDefaults.type = [...availableUnitTypes.values()][0];
    this.filterDefaults.readonlyInfectionSite = this.readonlyInfectionSite || "";
    this.filterDefaults = { ...this.filterDefaults, ...this.defaultValues };
    this.siteAndTypeOptions = this.$configStore.data.siteAndTypeOptions || [];
    this.origins = this.$configStore.data.origins || [];
    this.precautions = this.$configStore.data.precautions || [];
    this.organisms = this.$configStore.data.organisms || [];

    const defaults = this.filterDefaults;
    const filters = Object.assign({}, this.filterDefaults);
    const handler = {
      set: (obj, prop, value) => {
        obj[prop] = value;
        const labelEl = document.querySelector(`[data-filter-label="${prop}"]`);
        if (labelEl) {
          if (JSON.stringify(value) !== JSON.stringify(defaults[prop])) {
            labelEl.classList.add("isDirty");
          } else {
            labelEl.classList.remove("isDirty");
          }
        }
        debouncedFilteringEvent();
        return true;
      },
    };
    this.filters = new Proxy(filters, handler);

    // set filters via sectionFilters query param
    const { sectionFilters } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (sectionFilters) {
      Object.entries(JSON.parse(sectionFilters)).forEach(([k, v]) => {
        this.filters[k] = v;
      });
    }
  },
  mounted() {
    this.emitFiltersChanged();
  },
};
</script>

<style scoped>
@media print {
  .filters {
    display: none;
  }
}
.filterGrid {
  padding: 10px;
  display: grid;
  grid-gap: 10px;
}
.filterGrid .el-form-item {
  margin: 0;
}
.filterGrid .el-form-item .el-form-item__label {
  font-size: 0.8em;
}
.filterGrid[data-column-count="4"] {
  grid-template-columns: repeat(4, 1fr);
}
.filterGrid[data-column-count="5"] {
  grid-template-columns: repeat(5, 1fr);
}
.filterGrid .separator {
  grid-column: 1 / -1;
}
.filterGrid .el-select,
.filterGrid .el-cascader {
  width: 100%;
}
.moreFilterGrid {
  display: grid;
  grid-template-columns: 1fr 50px;
  row-gap: 10px;
}
.createDateToggle {
  float: right;
  line-height: 24px;
  font-size: 0.8em;
  margin-top: 4px;
}

@media screen and (max-width: 1400px) {
  .filterGrid[data-column-count="4"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .filterGrid[data-column-count="5"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .filterGrid .separator {
    display: none;
  }
}
</style>
