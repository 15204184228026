import Vue from "vue";
import ConfigurationService from "./services/ConfigurationService";

/* globals localStorage */
export default {
  updateTokens(accessToken, refreshToken, callback) {
    localStorage.token = accessToken;
    localStorage.refresh_token = refreshToken;
    console.log("token updated");

    // update user
    let userInfo = this.parseJwt(accessToken);
    // console.log(JSON.stringify(userInfo, ' ', 2))
    let roles = userInfo.realm_access.roles;
    let username = userInfo.preferred_username;
    this.updateUser({
      username: username,
      roles: roles,
      org: userInfo.org,
      idp: userInfo.idp || null,
      defaultSites: userInfo.default_sites
    });

    if (callback) {
      callback();
    }
  },
  getToken() {
    return localStorage.token;
  },
  parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  },
  clearToken() {
    delete localStorage.token;
    delete localStorage.user;
    delete localStorage.refresh_token;
  },
  logout(cb) {
    console.log("logout called");
    let redirectUrl = window.CONFIG.host + window.CONFIG.host_port;
    console.log("this.userInfo()", this.userInfo());
    redirectUrl = ConfigurationService.logoutRedirect() || redirectUrl;

    delete localStorage.token;
    delete localStorage.user;
    delete localStorage.refresh_token;

    // window.location = window.CONFIG.authHost + '/logout.do?redirect=' + window.CONFIG.host + window.CONFIG.host_port
    let idp = Vue.cookie.get("idp");
    console.log("idp", idp);
    if (idp && (idp === "revera" || idp === "test")) {
      redirectUrl = "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0";
    }
    window.location = window.CONFIG.authHost + "/protocol/openid-connect/logout?redirect_uri=" + redirectUrl;
  },
  loggedIn() {
    return !!localStorage.token;
  },
  updateUser(user) {
    localStorage.user = JSON.stringify(user);
  },
  userInfo() {
    return localStorage.user ? JSON.parse(localStorage.user) : {};
  },
  canModify() {
    return this.userInfo() && this.userInfo().roles.indexOf("ROLE_READ_ONLY_USER") < 0 && this.userInfo().roles.indexOf("ROLE_USER") >= 0;
  },
  onChange() {}
};
