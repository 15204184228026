<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" title="Manage Infection Cases" class="infection-case-manager" :top="'5%'" size="large" :close-on-click-modal="false" :close-on-press-escape="false">
      <filters-section :showLocation="true" :isOutbreakDetail="true" :defaultValues="global.filterDefaults" :readonlyInfectionSite="outbreak.typeValue" :filterDisplayKeys="getFilterKeys()" :dateRangeStart="global.defaultDateStart" :includeUnits="outbreak.scope === 'Unit'" v-on:change="formatFilters"> </filters-section>
      <page-section :title="`Case Details (${infectionCaseSection.pagination.total})`" v-loading="infectionCaseSection.loading">
        <template slot="title">
          <el-pagination
            @size-change="
              (size) => {
                infectionCaseSection.pagination.size = size;
              }
            "
            @current-change="
              (page) => {
                infectionCaseSection.pagination.page = page;
              }
            "
            :page-sizes="[5, 10, 20]"
            :page-size="infectionCaseSection.pagination.size"
            layout="sizes, prev, pager, next"
            :total="infectionCaseSection.pagination.total"
          >
          </el-pagination>
        </template>
        <el-table :data="infectionCaseSection.data" @row-click="infectionCaseSection.editing = true">
          <el-table-column prop="name" label="Name (MRN)" fixed width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.client">{{ scope.row.nameFormatted }}<br />({{ scope.row.mrn }})</span>
              <span v-else>{{ scope.row.nameFormatted }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Infection Site/Type" width="250">
            <template slot-scope="scope">
              <span class="color-code-label-0" v-if="scope.row.infectionSite === 'GI'"></span>
              <span class="color-code-label-2" v-else-if="scope.row.infectionSite === 'UTI'"></span>
              <span class="color-code-label-1" v-else-if="scope.row.infectionSite === 'Respiratory'"></span>
              <span class="color-code-label-3" v-else></span>
              {{ scope.row.infectionSite }} {{ scope.row.infectionType ? " / " + scope.row.infectionType : "" }}
            </template>
          </el-table-column>
          <el-table-column label="Status" width="80">
            <template scope="scope">
              <img class="symptom-status-image" :src="statusImage(scope.row)" />
            </template>
          </el-table-column>
          <el-col :span="8">Symptoms:</el-col>
          <el-table-column label="Symptoms" width="400">
            <template scope="scope">
              <el-tag v-for="symptom in scope.row.symptoms" :key="symptom.id" :type="tagType(symptom)">{{ symptom.displayText }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="location" label="Onset Location" min-width="140"> </el-table-column>
          <el-table-column fixed="right" sortable label="Onset Date" prop="onsetDateFormatted" width="120"> </el-table-column>
          <el-table-column fixed="right" label="" prop="active" width="60">
            <template scope="scope">
              <el-button v-if="!scope.row.isLinked" :plain="true" size="mini" @click="toggleInfectionCaseLink(scope.row.id, 'link')">
                <Icon v-if="!scope.row.isLinked" :iconKey="'link'" :description="'Link Infection Case'" />
              </el-button>
              <el-button v-else :plain="true" size="mini" @click="toggleInfectionCaseLink(scope.row.id, 'unlink')">
                <Icon :iconKey="'unlink'" :description="'Unlink Infection Case'" />
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </page-section>
      <div class="dialog-footer">
        <el-button @click="cancelManager">Close</el-button>
        <el-button type="primary" v-if="showOutbreakNav" @click="goToOutbreak">Close and Enter Outbreak Record</el-button>
      </div>
    </el-dialog>
    <!-- Infection Case Table Error -->
    <el-dialog title="Error, please try again" v-model="infectionCaseSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading infection cases. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            infectionCaseSection.loadingErrorDialog = false;
            getInfectionCases();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
import moment from "moment";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";
import Icon from "../Shared/Icon";

export default {
  name: "manage-infection-cases",
  props: ["outbreak", "showOutbreakNav"],
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    Icon,
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelManager();
      }
    },
    "infectionCaseSection.pagination.size"() {
      this.getInfectionCases();
    },
    "infectionCaseSection.pagination.page"() {
      this.getInfectionCases();
    },
  },
  data() {
    return {
      dialogVisibility: true,
      global: {
        filters: {},
        filterDefaults: {
          facilities: 0, // set in created()
        },
        defaultDateStart: moment().subtract(3, "months").toDate(),
        defaultDateEnd: new Date(),
        facilities: [],
      },
      infectionCaseSection: {
        loading: true,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        loadingErrorDialog: false,
        data: [],
      },
    };
  },

  methods: {
    getFilterKeys() {
      let filters = ["infectionOnsetDate", "readonlyInfectionSite"];
      if (this.isAdminStaff()) {
        filters.push("clientType");
      }
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.infectionSites = [filterValues.readonlyInfectionSite];
      delete this.global.filters.readonlyInfectionSite;
      this.global.filters.onsetDateFrom = moment(filterValues.infectionOnsetDate[0]).startOf("day").toDate().toISOString();
      this.global.filters.onsetDateTo = moment(filterValues.infectionOnsetDate[1]).endOf("day").toDate().toISOString();
      delete this.global.filters.infectionOnsetDate;
      this.getInfectionCases();
    },
    generateInfectionCasesTableData(record) {
      let model = { ...record };
      model.mrn = this.isStaff(record.client) ? record.client.staffId : this.$configStore.patientIdType() ? record.client[this.$configStore.patientIdType()] : record.client.patientId;
      model.infectionSiteWithType = `${model.infectionType} / ${model.infectionSite}`;
      model.isStaff = this.isStaff(record.client) ? "Yes" : "No";
      model.isLinked = record.outbreakIds.includes(this.outbreak.id);
      model.nameFormatted = `${record.client.firstName} ${record.client.middleName ? record.client.middleName + " " : ""}${record.client.lastName}`;
      model.onsetDateFormatted = record.onsetDate ? moment(record.onsetDate).format(this.$configStore.dateFormat()) : "";
      return model;
    },
    async getInfectionCases() {
      this.infectionCaseSection.loading = true;
      const filters = {
        ...this.global.filters,
        facIds: [this.outbreak.facId],
        page: this.infectionCaseSection.pagination.page,
        size: this.infectionCaseSection.pagination.size,
      };
      try {
        const response = await this.$http.post(`${window.CONFIG.infection_api}/search`, filters);
        this.infectionCaseSection.pagination.total = response.body.totalElements;
        this.infectionCaseSection.data = response.body.content.map(this.generateInfectionCasesTableData);
      } catch (err) {
        if (err.status === 0) {
          this.infectionCaseSection.loadingErrorDialog = true;
        }
        this.infectionCaseSection.pagination.total = 0;
        this.infectionCaseSection.data = [];
      }
      this.infectionCaseSection.loading = false;
    },
    setDefaultDateRange() {
      if (this.outbreak.declaredDate) {
        this.global.defaultDateStart = moment(this.outbreak.declaredDate).subtract("10", "days").toDate();
      }
      if (this.outbreak.resolvedDate) {
        this.global.defaultDateEnd = new Date(this.outbreak.resolvedDate);
      }
    },
    statusImage(infectionCase) {
      const imageMap = {
        Respiratory: "bug1",
        GI: "bug2",
        UTI: "bug3",
        Skin: "bug4",
      };

      let name = imageMap[infectionCase.infectionSite] || "bug4";

      if (!infectionCase.status) {
        name += "s";
      }

      const path = `/static/${name}.png`;

      return path;
    },
    isStaff(client) {
      if (client) {
        return client.staffId || false;
      } else {
        return false;
      }
    },
    tagType(type) {
      if (type.colorCode === 0) {
        return "success";
      } else if (type.colorCode === 1) {
        return "primary";
      } else if (type.colorCode === 2) {
        return "warning";
      } else if (type.colorCode === 3) {
        return "danger";
      }
      return "gray";
    },
    cancelManager() {
      this.$emit("manager-close");
    },
    goToOutbreak() {
      this.$emit("manager-go-to-outbreak");
    },
    async toggleInfectionCaseLink(id, action) {
      this.infectionCaseSection.loading = true;
      try {
        if (action === "link") {
          await this.$http.post(`${window.CONFIG.om_api}/${this.outbreak.id}/cases/${id}`);
        } else if (action === "unlink") {
          await this.$http.delete(`${window.CONFIG.om_api}/${this.outbreak.id}/cases/${id}`);
        }
        await this.getInfectionCases();
        this.infectionCaseSection.loading = false;
      } catch (err) {
        this.loadingErrorDialog = true;
      }
    },
    isAdminStaff() {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
  },
  created() {
    console.clear();
    const facility = this.$configStore.data.sites.find((site) => site.id === this.outbreak.facId);
    if (facility) {
      this.global.filterDefaults.facilities = facility;
    }
    this.global.filters.readonlyInfectionSite = this.outbreak.typeValue;
    this.setDefaultDateRange();
    this.global.dateRangeLoaded = true;
  },
  async mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  },
};
</script>

<style>
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.infection-case-manager .el-dialog.el-dialog--large {
  width: 90vw !important;
}
.infection-case-manager .el-dialog__body {
  padding: 5px;
}
.infection-case-manager .el-dialog__body .el-row {
  line-height: normal;
}
</style>