<template>
  <div class="surveillance-personal-info">
    <!--{{ clientData }}-->
    <h2>Individual Information</h2>
    <el-row>
      <el-col :span="5"><span>First Name: </span></el-col>
      <el-col :span="16">
        {{ clientData.firstName }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Middle Name: </span></el-col>
      <el-col :span="16">
        {{ clientData.middleName }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Last Name: </span></el-col>
      <el-col :span="16">
        {{ clientData.lastName }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Date of Birth: </span></el-col>
      <el-col :span="16">
        {{ clientData.date_of_birth }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Staff: </span></el-col>
      <el-col :span="16" v-if="isStaff()"> Yes </el-col>
      <el-col :span="16" v-else> No </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Gender: </span></el-col>
      <el-col :span="16">
        {{ clientData.gender }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Address 1: </span></el-col>
      <el-col :span="16">
        {{ clientData.address1 }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Address 2: </span></el-col>
      <el-col :span="16">
        {{ clientData.address2 }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6"><span>City: </span></el-col>
      <el-col :span="6">
        {{ clientData.city }}
      </el-col>
      <el-col :span="6">
        <span>Prov/State:</span>
      </el-col>
      <el-col :span="6" v-if="clientData.province">
        {{ clientData.province }}
      </el-col>
      <el-col :span="6" v-if="clientData.state">
        {{ clientData.state }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5"><span>Postal Code: </span></el-col>
      <el-col :span="16">
        {{ clientData.postalCode }}
      </el-col>
    </el-row>

    <div>
      <h2 style="float: left">Individual Notes</h2>
      <el-button @click="addNewNote()" style="float: right; margin-top: 0.83em" :disabled="editing || !canModify">+ New</el-button>
    </div>
    <el-table :data="allNotes" border style="width: 100%">
      <el-table-column label="Date" prop="created" width="120">
        <template scope="scope">
          {{ scope.row.created | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Content">
        <template scope="scope">
          <div v-if="scope.row.isNew">
            <el-input type="textarea" ref="newnote" autosize autofocus placeholder="Please input" v-model="scope.row.content"> </el-input>
          </div>
          <div v-else class="case-note-content linebreak">{{ scope.row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Action" width="150">
        <template scope="scope">
          <el-button-group>
            <el-button v-if="scope.row.isNew" @click="saveAction(scope.$index, scope.row)" :disabled="!scope.row.content" size="small"> Save </el-button>
            <el-button v-if="scope.row.isNew" @click="cancelAction(scope.$index, scope.row)" size="small"> Cancel </el-button>
          </el-button-group>
          <el-button :disabled="!canModify" v-if="!scope.row.isNew" @click="deleteAction(scope.$index, scope.row)" size="small" icon="delete"> </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import auth from "../auth";

export default {
  name: "surveillance-personal-info",
  props: ["clientData"],
  data: function () {
    return {
      canModify: false,
      newNotes: [],
      allNotes: [],
      editing: false,
    };
  },
  watch: {
    clientData: function (value) {
      this.newNotes = [];
      this.allNotes = value.notes;
    },
    newNotes: function (value) {
      this.allNotes = value.concat(this.clientData.notes);
      console.log(JSON.stringify(this.allNotes, " ", 2));
    },
  },
  methods: {
    symptomDetail(symptomType) {
      return symptomType + " detail text goes here";
    },
    isStaff() {
      return this.clientData.clientType === "Staff";
    },
    addNewNote: function () {
      var now = new Date();
      var newNote = {
        created: now.getTime(),
        content: "",
        isNew: true,
      };
      this.newNotes.splice(0, 0, newNote);
      let vm = this;
      vm.$nextTick(() => {
        let inputs = vm.$refs["newnote"];
        inputs.$refs.textarea.select();
      });
      this.editing = true;
    },
    cancelAction: function (index, data) {
      console.log(JSON.stringify(data, " ", 2));
      this.newNotes.splice(index, 1);
      this.editing = false;
    },
    saveAction: function (index, data) {
      var vm = this;
      var loadingInstance = Loading.service({ fullscreen: true });
      var newNote = {
        created: data.created,
        content: data.content,
        refId: this.clientData.id,
      };
      console.log(JSON.stringify(newNote));
      console.log(JSON.stringify(this.caseData));

      this.$http.post(window.CONFIG.api + "/client/new/note", newNote).then(
        (response) => {
          console.log(JSON.stringify(response, 2, " "));
          if (response.status === 200) {
            vm.clientData.notes.splice(0, 0, newNote);
            vm.newNotes.splice(index, 1);
          }
          vm.$emit("change", newNote);
          loadingInstance.close();
          vm.editing = false;
        },
        (response) => {
          // error callback
          vm.editing = false;
        }
      );
    },
    deleteConfirmed: function (index, data) {
      var vm = this;
      var loadingInstance = Loading.service({ fullscreen: true });

      this.$http
        .post(window.CONFIG.api + "/client/delete/note", data)
        // this.$http.post(window.CONFIG.api + '/client/new/note', newNote)
        .then(
          (response) => {
            console.log(JSON.stringify(response, 2, " "));
            if (response.status === 200) {
              vm.clientData.notes = response.body;
              vm.newNotes.splice(index, 1);
            }
            loadingInstance.close();
            this.editing = false;
          },
          (response) => {
            // error callback
            this.editing = false;
          }
        );
    },
    deleteAction: function (index, data) {
      var vm = this;
      this.$confirm("Delete this note?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        vm.deleteConfirmed(index, data);
      });
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    console.log("mounted!");
    this.allNotes = this.clientData.notes;
  },
};
</script>
<style scoped>
div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
  margin-bottom: 1em;
}
.linebreak {
  white-space: pre-line;
}
</style>
