<template>
  <el-dialog class="ppe_editor" top="75px" v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Facility -->
      <el-form-item label="Facility" prop="facility">
        <el-select v-model="formModel.facility" value-key="id" filterable>
          <el-option v-for="item in sites" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Unit -->
      <el-form-item label="Unit" prop="unit">
        <el-select v-model="formModel.unit" value-key="id" filterable :disabled="!formModel.facility">
          <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Observer -->
      <el-form-item label="Observer" prop="observer">
        <el-select v-model="formModel.observer" filterable>
          <el-option v-for="item in scopedObservers" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Collection Date -->
      <el-form-item label="Collection Date" prop="collectionDate">
        <el-date-picker v-model="formModel.collectionDate" type="datetime" placeholder="Select date & time"> </el-date-picker>
      </el-form-item>
      <!-- Audit Type -->
      <el-form-item label="Audit Type" prop="auditType">
        <el-select v-model="formModel.auditType" value-key="id">
          <el-option v-for="item in auditTypes" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- HCW Type -->
      <el-form-item label="HCW Type" prop="workerType">
        <el-select v-model="formModel.workerType" value-key="id">
          <el-option v-for="item in healthcareWorkers" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Comments" prop="comments">
        <el-input type="textarea" v-model="formModel.comments"></el-input>
      </el-form-item>
    </el-form>

    <el-form v-if="!dialogBusy" ref="editorFormObservations" :model="formModel" :rules="formRules" label-position="top">
      <!-- Moments & Observation Points -->

      <el-collapse accordion>
        <template v-for="op in observationPoints">
          <el-collapse-item :key="op.name" :title="op.name" :name="op.name">
            <div v-for="question in op.questions" :key="question.id" class="observations">
              <el-form-item :label="question.observationPoint">
                <div class="form_item_content">
                  <el-radio-group v-model="formModel.observationPoints[question.observationPoint].observation">
                    <el-radio-button v-for="option in question.options" :key="option.id" :label="option.label"></el-radio-button>
                  </el-radio-group>
                  <template v-if="question.tag === 'HH' && formModel.observationPoints[question.observationPoint].observation === 'Yes'">
                    <el-radio-group v-model="formModel.observationPoints[question.observationPoint].outcome">
                      <el-radio-button label="Wash"></el-radio-button>
                      <el-radio-button label="Rub"></el-radio-button>
                    </el-radio-group>
                    <template v-if="formModel.observationPoints[question.observationPoint].outcome !== null">
                      <span>Seconds</span>
                      <el-input-number v-model="formModel.observationPoints[question.observationPoint].seconds" :min="0"></el-input-number>
                    </template>
                  </template>
                </div>
              </el-form-item>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>

      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Save</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const observationMap = {
  Yes: 1,
  No: 2,
  "Not Observed": 3,
};
import auth from "../../auth";
import util from "../../util";

export default {
  name: "observation-editor",
  props: ["title", "values"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      observers: [],
      organization: null,
      sites: [],
      healthcareWorkers: [],
      auditTypes: [],
      customTypes: {},
      ppeConfig: null,
      observationPoints: [],
      formRules: {
        observer: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        facility: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        unit: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        auditType: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        workerType: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        collectionDate: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
      },
      formModel: {
        id: null,
        observer: null,
        facility: null,
        unit: null,
        auditType: null,
        workerType: null,
        collectionDate: null,
        observationPoints: {},
        comments: null,
      },
    };
  },
  computed: {
    units() {
      if (this.formModel.facility) {
        return this.formModel.facility.units;
      }
      return [];
    },
    scopedObservers() {
      // TODO: the list of observers should be filtered to a facility
      return this.observers;
    },
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
  },
  methods: {
    transformValuesToFormModel({ id, data }) {
      const { facilityId, unitId, hcwId, observer, recordedLocalTimestamp, comments, auditTypeId } = data;
      const facility = this.sites.find((f) => f.id === facilityId);
      this.formModel = {
        ...this.formModel,
        ...{
          id,
          observer,
          comments,
          facility,
        },
        ...{
          unit: facility.units.find((u) => u.id === unitId),
          collectionDate: recordedLocalTimestamp,
          auditType: this.auditTypes.find((t) => t.id === auditTypeId),
          workerType: this.healthcareWorkers.find((hcw) => hcw.id === hcwId),
        },
      };
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    async commitForm() {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          this.dialogBusy = true;
          const { id, facility, unit, collectionDate, observer, workerType, auditType, comments, observationPoints } = this.formModel;
          const formData = {};
          if (id !== null) {
            formData.id = id;
            formData.auditIdToBeUpdated = id;
          }
          formData.configId = this.ppeConfig.data.configId;
          formData.facility = facility.displayText;
          formData.facilityId = facility.id;
          formData.unit = unit.displayText;
          formData.unitId = unit.id;
          formData.observer = observer;
          formData.recordedLocalTimestamp = new Date(collectionDate).toISOString();
          formData.hcw = workerType.displayText;
          formData.hcwId = workerType.id;
          formData.auditType = auditType.displayText;
          formData.auditTypeId = auditType.id;
          formData.comments = comments;
          formData.sections = window.structuredClone(this.ppeConfig.data.sections).map((s) => {
            s.questions.forEach((q) => {
              const { outcome, observation, seconds } = observationPoints[q.observationPoint];
              q.response = Object.assign({ label: observation, optionId: observationMap[observation] });
              if (outcome && observation === "Yes") {
                q.response.meta = {
                  label: outcome,
                  value: seconds || 0,
                };
              }
            });
            return s;
          });
          if (id !== null) {
            await this.$http.put(`${window.CONFIG.ppe_api}/audit`, JSON.stringify(formData));
          } else {
            await this.$http.post(`${window.CONFIG.ppe_api}/audit`, JSON.stringify(formData));
          }
          this.$emit("editor-save");
        } else {
          return false;
        }
      });
    },
  },
  async created() {
    console.clear();
    this.organization = this.$configStore.data.organization[0];
    this.sites = this.$configStore.data.sites;
    this.observers = await this.$http
      .get(`${window.CONFIG.ppe_api}/observers`)
      .then((response) => response.body)
      .then((observers) => {
        return Array.from(new Set([...observers, ...[auth.userInfo().username]]).keys());
      });
    this.auditTypes = await this.$http.get(`${window.CONFIG.ppe_api}/audit-types`).then((response) => response.body);
    this.healthcareWorkers = await this.$http.get(`${window.CONFIG.ppe_api}/hcw`).then((response) => response.body);

    if (this.values !== null) {
      this.ppeConfig = this.values;
      this.transformValuesToFormModel(this.values);
    } else {
      const ppeConfig = await this.$http.get(`${window.CONFIG.ppe_api}/config/ops`).then((response) => response.body);
      this.ppeConfig = ppeConfig;
      this.ppeConfig.data = {
        ...ppeConfig.data.ops,
        ...{ configId: ppeConfig.id },
      };
    }

    util.log(this.ppeConfig);

    this.observationPoints = this.ppeConfig.data.sections;

    this.formModel.observationPoints = Object.fromEntries(
      this.ppeConfig.data.sections
        .flatMap((s) => s.questions)
        .map((q) => [
          q.observationPoint,
          {
            observation: q.response.label,
            outcome: q.response.meta ? q.response.meta.label : null,
            seconds: q.response.meta ? q.response.meta.value : 0,
          },
        ])
    );

    this.dialogBusy = false;
  },
};
</script>
<style>
.ppe_editor .form_item_content {
  display: flex;
}
.ppe_editor .form_item_content > :nth-child(2) {
  margin-left: 10px;
}
.ppe_editor .form_item_content > :nth-child(3) {
  margin: 0 10px;
}
</style>
