<template>
  <div class="clients" v-loading.fullscreen.lock="loading">
    <div class="filter">
      <div class="section-header">
        <el-row
          ><span>Residents</span>
          <div class="case-filter"></div>
        </el-row>
      </div>
      <!-- FILTER -->
      <location-filter v-if="sites.length > 1" @update:filter="locationFilterUpdated"> </location-filter>
      <el-row class="filter-group-label" v-if="sites.length > 1">Residents Filter:</el-row>
      <el-row class="filter-section">
        <label v-if="sites.length <= 1"
          ><span class="filter-title-label">Facility</span>
          <el-select v-model="selectedSite" placeholder="Facility" class="filter-section-input" v-on:change="filterUpdated">
            <el-option label="All Facilities" :value="0"> </el-option>
            <el-option v-for="item in sites" :label="item.displayText" :key="item.id" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label v-if="sites.length <= 1"
          ><span class="filter-title-label">Unit:</span>
          <el-select v-model="filteredUnit" placeholder="Unit" class="filter-section-input" v-on:change="filterUpdated">
            <el-option label="All Units" :value="0"> </el-option>
            <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Assigned:</span>
          <el-select v-model="assigned" class="filter-section-input" placeholder="Status" v-on:change="filterUpdated">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="Yes" :value="1"> </el-option>
            <el-option label="No" :value="2"> </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Client Status:</span>
          <el-select v-model="resStatus" class="filter-section-input" placeholder="Status" v-on:change="filterUpdated">
            <el-option label="All" value=""> </el-option>
            <el-option label="Active" value="C"> </el-option>
            <el-option label="Transferred" value="T"> </el-option>
            <el-option label="Discharged" value="D"> </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Name:</span>
          <el-input placeholder="Name" v-model="selectedName" icon="search" :on-icon-click="filterUpdated"></el-input>
        </label>
      </el-row>
    </div>
    <div class="filter">
      <div class="section-header">
        <el-row
          ><span>Residents - Total: {{ clientsTotal }}</span>
          <div class="case-filter">
            <el-button :disabled="!canModify" v-if="!$configStore.adtEnabled()" class="case-filter-button" :plain="true" type="primary" size="mini" @click="newResident()">+ New Resident</el-button>
            <el-button :disabled="!canModify" v-if="canModifyImmunizations" class="case-filter-button" :plain="true" type="primary" size="mini" @click="batchImmunizations()">Batch Immunizations</el-button>
            <el-tooltip :disabled="this.availableSites.length === 1" effect="dark" content="Please select a single facility" placement="top">
              <el-button class="case-filter-button" v-bind:class="{ disabled: this.availableSites.length !== 1 }" :plain="true" type="primary" size="mini" @click="exportExcel()">Export Immunizations Data</el-button>
            </el-tooltip>
            <el-select class="case-filter-select" size="mini" v-model="sortedBy" placeholder="Status" v-on:change="filterUpdated">
              <el-option label="Sort by First Name" value="firstName"> </el-option>
              <el-option label="Sort by Last Name" value="lastName"> </el-option>
              <el-option label="Sort by ID" value="patientId"> </el-option>
              <el-option label="Sort by Location" value="bed.fullDesc"> </el-option>
            </el-select>
            <el-select class="case-filter-select" size="mini" v-model="currentPageSize" placeholder="Choose tags for your article">
              <el-option v-for="item in pageSizeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-pagination small layout="prev, pager, next" :current-page="currentPage" :page-count="currentPageTotal" v-on:current-change="loadClients"> </el-pagination>
          </div>
        </el-row>
      </div>
    </div>
    <el-table :data="clients" v-on:row-click="updateResident" style="width: 100%">
      <el-table-column fixed label=" " width="100">
        <template scope="scope">
          <div style="padding: 0.5em">
            <div :span="4" class="profile-row-photo" v-if="scope.row.photo"><img :src="photoUrl(scope.row.photo)" /></div>
            <div :span="4" class="profile-row-photo" v-else><img src="../assets/user.png" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name" fixed width="180">
        <template scope="scope">
          <span style="word-break: normal">{{ clientName(scope.row) }}</span
          ><br />
          ({{ $configStore.patientIdType() ? scope.row[$configStore.patientIdType()] : scope.row.patientId }})
        </template>
      </el-table-column>
      <el-table-column label="Facility" width="180">
        <template scope="scope">
          {{ facilityById(scope.row.facId).displayText }}
        </template>
      </el-table-column>
      <el-table-column label="Location" width="180">
        <template scope="scope">
          <span v-if="scope.row.bed">
            <!-- {{ unitById(scope.row.unitId) }} {{ beds[scope.row.id].displayText }} -->
            {{ scope.row.bed.fullDesc }}
          </span>
          <span v-else> Not assigned </span>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="140">
        <template scope="scope">
          <span v-if="scope.row.status == 'D'">Discharged</span>
          <span v-if="scope.row.status == 'T'">Transferred</span>
          <span v-if="scope.row.status !== 'D' && scope.row.status !== 'T'">Active</span>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="140">
        <template scope="scope">
          <span v-if="scope.row.gender">{{ scope.row.gender }}</span>
          <span v-else>Not Entered</span>
        </template>
      </el-table-column>
      <el-table-column label="DOB" prop="dateOfBirth" :width="$configStore.adtEnabled() ? '' : '180'">
        <template scope="scope">
          <span v-if="scope.row.dateOfBirth">{{ offsetDate(scope.row.dateOfBirth) | moment($configStore.dateFormat()) }}</span>
          <span v-else>Not Entered</span>
        </template>
      </el-table-column>
      <el-table-column v-if="!$configStore.adtEnabled()" fixed="right" label="" prop="active" width="230">
        <template scope="scope">
          <el-button :plain="true" size="mini" :disabled="scope.row.bed" @click="assignClient(scope.row)">Assign</el-button>
          <el-button :plain="true" size="mini" :disabled="!scope.row.bed" @click="unassignClient(scope.row)">Unassign</el-button>
          <el-button :plain="true" size="mini" :disabled="scope.row.status == 'D'" @click="discharge(scope.row)">Discharge</el-button>
          <!-- <el-tooltip :disabled="scope.row.canDelete" content="Unable to delete resident as s/he has associated cases." placement="bottom" effect="light">
            <div style="display: inline-block; position: relative; margin-left:0.5em;">
              <el-button :plain="true" size="mini" icon="delete" :disabled="!scope.row.canDelete" @click="deleteClient(scope.row)"></el-button>
              <div style="position:absolute; top: 0; width: 24px; height: 2px"></div>
            </div>
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="clientName(selectedClient)" v-model="dialogBedAssignDialog" v-if="selectedClient" class="delete-dialog">
      <div>
        <el-row>
          <el-col :span="5"><span>Unit: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedUnit" placeholder="Unit" class="filter-section-input" value-key="id" v-on:change="selectUnitForClient">
              <el-option v-for="item in units" v-bind:key="item.id" v-if="item.siteId == selectedClient.facId" :label="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="selectedUnit">
          <el-col :span="5"><span>Room: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedRoom" placeholder="Room" class="filter-section-input" :disabled="!selectedUnit" value-key="id">
              <el-option v-if="selectedUnit" v-for="item in selectedUnit.rooms" :key="item.displayText" :label="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="selectedRoom">
          <el-col :span="5"><span>Bed: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedBed" placeholder="Bed" class="filter-section-input" :disabled="!selectedRoom" value-key="id">
              <el-option v-for="item in selectedRoom.beds" v-if="selectedUnit" :key="item.displayText" :label="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                <span v-if="item.occId != 0">&nbsp; (Occupied)</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAssignment()">Cancel</el-button>
        <el-button :disabled="!canModify" type="primary" @click="confirmAssignment(selectedClient, selectedBed)">Update</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="clientModalTitle(newClient)" v-model="newResidentDialogFormVisible" size="large">
      <div>
        <el-form ref="newClientForm" :model="newClient" label-width="120px" v-if="isNewRes">
          <el-form-item label="First Name" prop="firstName" :rules="[{ required: true, message: 'First Name is required' }]">
            <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Middle Name" prop="middleName">
            <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.middleName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" prop="lastName" :rules="[{ required: true, message: 'Last Name is required' }]">
            <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.lastName"></el-input>
          </el-form-item>
          <el-form-item
            label="Patient ID"
            v-if="!$configStore.adtEnabled()"
            prop="patientId"
            :rules="[
              { required: true, message: 'Patient ID is required' },
              { type: 'number', message: 'Patient ID must be a number' },
            ]"
          >
            <el-input v-model.number="newClient.patientId"></el-input>
          </el-form-item>
          <el-form-item label="Patient ID" v-else prop="patientId">
            <el-input :disabled="$configStore.adtEnabled()" v-model="newClient[$configStore.patientIdType()]"></el-input>
          </el-form-item>
          <el-form-item label="Facility" :rules="[{ required: true, message: 'Facility is required' }]" prop="facId">
            <el-select :disabled="sites.length == 1 || !isNewRes" v-model="resFacId" placeholder="Facility">
              <el-option v-for="item in sites" v-bind:key="item.id" :label="item.displayText" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Gender" prop="gender">
            <el-select :disabled="$configStore.adtEnabled()" v-model="newClient.gender" placeholder="Gender">
              <el-option label="Male" value="MALE"> </el-option>
              <el-option label="Female" value="FEMALE"> </el-option>
              <el-option label="Other" value="UNKNOWN"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="DOB" prop="dateOfBirth">
            <el-date-picker :disabled="$configStore.adtEnabled()" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Pick a date" v-model="newClient.dateOfBirth"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" v-if="isNewRes">
        <el-button
          @click="
            newClient = { gender: '', dateOfBirth: '' };
            newResidentDialogFormVisible = false;
            isNewRes = false;
          "
          >Cancel</el-button
        >
        <el-button :disabled="!canModify" v-if="isNewRes" type="primary" @click="confirmNewResident('newClientForm')">Add</el-button>
        <el-button :disabled="!canModify" v-else type="primary" @click="confirmNewResident('newClientForm')">Update</el-button>
      </span>
      <el-tabs v-model="resActiveTab" type="card" v-if="!isNewRes">
        <el-tab-pane label="Resident Info" name="1">
          <div>
            <el-form ref="clientForm" :model="newClient" label-width="120px">
              <el-form-item label="Photo">
                <el-upload ref="upload" :file-list="fileList" :on-success="uploadSuccess" :show-upload-list="false" :multiple="false" :action="uploadUrl" :auto-upload="false">
                  <el-button slot="trigger" size="small" type="primary">Select</el-button>
                  <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">Upload</el-button>
                  <div style="padding: 0.5em" class="dialog-photo-image">
                    <div :span="4" class="profile-row-photo" v-if="newClient.photo"><img :src="photoUrl(newClient.photo)" width="8em" height="8em" /></div>
                    <div :span="4" class="profile-row-photo" v-else><img src="../assets/user.png" width="8em" height="8em" /></div>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="First Name" prop="firstName" :rules="[{ required: true, message: 'First Name is required' }]">
                <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.firstName"></el-input>
              </el-form-item>
              <el-form-item label="Middle Name" prop="middleName">
                <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.middleName"></el-input>
              </el-form-item>
              <el-form-item label="Last Name" prop="lastName" :rules="[{ required: true, message: 'Last Name is required' }]">
                <el-input :disabled="$configStore.adtEnabled()" v-model="newClient.lastName"></el-input>
              </el-form-item>
              <el-form-item
                v-if="!$configStore.adtEnabled()"
                label="Patient ID"
                prop="patientId"
                :rules="[
                  { required: true, message: 'Patient ID is required' },
                  { type: 'number', message: 'Patient ID must be a number' },
                ]"
              >
                <el-input v-model.number="newClient.patientId"></el-input>
              </el-form-item>
              <el-form-item label="Patient ID" v-else prop="patientId">
                <el-input :disabled="$configStore.adtEnabled()" v-model="newClient[$configStore.patientIdType()]"></el-input>
              </el-form-item>
              <el-form-item label="Facility" :rules="[{ required: true, message: 'Facility is required' }]" prop="facId">
                <el-select :disabled="sites.length == 1 || !isNewRes" v-model="resFacId" placeholder="Facility">
                  <el-option v-for="item in sites" v-bind:key="item.id" :label="item.displayText" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Gender" prop="gender">
                <el-select :disabled="$configStore.adtEnabled()" v-model="newClient.gender" placeholder="Gender">
                  <el-option label="Male" value="MALE"> </el-option>
                  <el-option label="Female" value="FEMALE"> </el-option>
                  <el-option label="Other" value="UNKNOWN"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="DOB" prop="dateOfBirth">
                <el-date-picker :disabled="$configStore.adtEnabled()" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Pick a date" v-model="newClient.dateOfBirth"></el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Location" name="2">
          <surveillance-location v-if="resActiveTab === '2'" :locations="locations" :beds="bedMap"></surveillance-location>
        </el-tab-pane>
        <el-tab-pane label="Immunizations" name="3" v-if="newClient">
          <immunizations-tab v-if="resActiveTab === '3'" :isStaff="false" :clientName="clientModalTitle(newClient)" :clientId="newClient.id" :showActions="true"> </immunizations-tab>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer" v-if="resActiveTab === '1' && !isNewRes">
        <el-button
          @click="
            newClient = { gender: '', dateOfBirth: '' };
            newResidentDialogFormVisible = false;
            isNewRes = false;
            resActiveTab = '1';
          "
          >Cancel</el-button
        >
        <el-button :disabled="!canModify" v-if="isNewRes" type="primary" @click="confirmNewResident('clientForm')">Add</el-button>
        <el-button v-if="!isNewRes && hasADT() === false" type="primary" @click="confirmNewResident('clientForm')">Update</el-button>
      </span>
    </el-dialog>

    <immunizations-editor v-if="showImmunizationsEditor" :title="'Batch Immunizations'" :immunization="{}" :isBatch="true" :isStaff="false" v-on:immunization-editor-save="batchImmunizationsClose" v-on:immunization-editor-close="batchImmunizationsClose"> </immunizations-editor>
    <progress-overlay v-if="excelExportResultsVisible" :progress="excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>
  </div>
</template>

<script>
import moment from "moment";
import SurveillanceCommunication from "../components/SurveillanceCommunication";
import SurveillanceBiology from "../components/SurveillanceBiology";
import SurveillanceDevice from "../components/SurveillanceDevice";
import SurveillanceSymptoms from "../components/SurveillanceSymptoms";
import SurveillanceTreatment from "../components/SurveillanceTreatment";
import SurveillancePersonalInfo from "../components/SurveillancePersonalInfo";
import SurveillancePrecaution from "../components/SurveillancePrecaution";
import SurveillanceLocation from "../components/SurveillanceLocation";
import ImmunizationsEditor from "./Immunizations/Editor";
import ImmunizationsTab from "../components/Immunizations/Tab";
import LocationFilter from "../components/LocationFilter/LocationFilter";
import ProgressOverlay from "../components/ProgressBarOverlay";
import auth from "../auth";
import api from "../services/restClient";
import XLSX from "xlsx";

export default {
  name: "clients",
  components: {
    "surveillance-communication": SurveillanceCommunication,
    "surveillance-biology": SurveillanceBiology,
    "surveillance-device": SurveillanceDevice,
    "surveillance-symptoms": SurveillanceSymptoms,
    "surveillance-treatment": SurveillanceTreatment,
    "surveillance-personal-info": SurveillancePersonalInfo,
    "surveillance-precaution": SurveillancePrecaution,
    "surveillance-location": SurveillanceLocation,
    "immunizations-tab": ImmunizationsTab,
    "location-filter": LocationFilter,
    "immunizations-editor": ImmunizationsEditor,
    "progress-overlay": ProgressOverlay,
  },
  data: function () {
    return {
      canModifyImmunizations: false,
      showImmunizationsEditor: false,
      cancelExcelExportProgress: false,
      excelExportResultsVisible: false,
      excelExportResultsProgress: 0,
      canModify: false,
      dateFormat: "",
      resFacId: 0,
      resActiveTab: "1",
      currentDate: new Date(),
      sites: [],
      cases: [],
      precautions: [],
      beds: {},
      bedMap: {},
      symptomTypes: [],
      availableUnits: [],
      availableSites: [],
      searchNameInput: "",
      units: [],
      clients: [],
      fileList: [],
      currentPage: 1,
      currentPageSize: 10,
      currentPageTotal: 0,
      pageSizeOptions: [
        {
          label: "5 items per page",
          value: 5,
        },
        {
          label: "10 items per page",
          value: 10,
        },
        {
          label: "20 items per page",
          value: 20,
        },
      ],
      clientsTotal: 0,
      organization: [],
      loading: true,
      selectedClient: null,
      selectedUnit: null,
      selectedSite: 0,
      selectedName: "",
      filteredUnit: 0,
      assigned: 1,
      selectedRoom: null,
      selectedBed: null,
      dialogBedAssignDialog: false,
      sortedBy: "firstName",
      resStatus: "C",
      newResidentDialogFormVisible: false,
      isNewRes: false,
      newClient: {},
      uploadUrl: "",
      locations: [],
      firstRun: true,
      unitMap: {},
    };
  },
  watch: {
    resFacId: function (v) {
      if (this.newClient) {
        this.newClient.facId = v;
      }
    },
    currentPageSize: function (v) {
      if (!this.firstRun) {
        this.page = 1;
        this.loadClients();
      }
    },
  },
  methods: {
    selectUnitForClient: async function (val) {
      console.log(val);
      if (this.unitMap[val.id]) {
        this.selectedUnit = this.unitMap[val.id];
      } else {
        let response = await this.$http.get(`${window.CONFIG.api}/units/${val.id}`);
        if (response.body) {
          const selectedUnitWithSortedRooms = { ...response.body };
          selectedUnitWithSortedRooms.rooms.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { numeric: true }));
          this.unitMap[val.id] = selectedUnitWithSortedRooms;
          this.selectedUnit = selectedUnitWithSortedRooms;
        }
      }
    },
    uploadSuccess: function (response, file, fileList) {
      this.fileList = [];
      console.log(this.newClient);
      console.log(file);
      this.reload();
      this.newClient.photo = "clients/clientphoto/" + this.newClient.id + "/" + file.name;
    },
    submitUpload: function () {
      this.$refs.upload.headers["X-CSRF-TOKEN"] = auth.getToken();
      this.$refs.upload.headers["Authorization"] = "Bearer " + auth.getToken();
      this.$refs.upload.submit();
    },
    loadPrecautions: function () {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/precautions/").then(
        (response) => {
          vm.precautions = response.body["_embedded"].precautions;
          vm.loadClients();
        },
        (response) => {
          // error callback
        }
      );
    },
    clientName: function (clientData) {
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      if (this.sortedBy === "lastName") {
        return lastName + ", " + firstName + middleName;
      } else {
        return firstName + middleName + lastName;
      }
    },
    loadClients: async function (page) {
      if (page && this.currentPage !== page) {
        this.currentPage = page;
      }
      console.log("loadClients " + this.currentPage);
      var vm = this;
      if (!vm.loading) {
        vm.loading = true;
      }

      var url = window.CONFIG.api + "/clients/list/unit/" + this.filteredUnit + "/page/" + (this.currentPage - 1) + "?clientType=Client&size=" + this.currentPageSize;
      url = url + "&sort=" + this.sortedBy;

      if (this.selectedName !== "") {
        url = url + "&name=" + this.selectedName;
      }

      if (this.resStatus !== "") {
        url = url + "&status=" + this.resStatus;
      }

      console.log(this.assigned);
      if (this.assigned > 0) {
        url = url + "&active=" + (this.assigned === 1 ? "true" : "false");
      }

      if (this.selectedUnit !== 0 && this.availableUnits.length > 0) {
        url = url + "&units=" + this.availableUnits;
      }

      if (this.availableSites.length > 0) {
        url = url + "&facIds=" + this.availableSites;
      }
      console.log(url);
      let clients = [];
      await this.$http.get(url).then((response) => {
        if (response.body) {
          clients = response.body.content;
          vm.currentPageTotal = response.body.totalPages;
          vm.clientsTotal = response.body.totalElements;
        } else {
          console.log("no content");
        }
      });
      // await Promise.all(
      //   clients.map(client => {
      //     this.$http.get(`${window.CONFIG.api}/symptomDetail/client/${client.id}`).then(response => {
      //       client.canDelete = response.body.length <= 0
      //       return client
      //     })
      //   })
      // )
      vm.clients = clients;
      vm.loading = false;
      vm.firstRun = false;
    },
    photoUrl: function (f) {
      if (f.indexOf("/") !== -1) {
        return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
      }

      return "/static/" + f + ".png";
    },
    facilityById: function (facId) {
      let result = null;
      if (this.sites && Array.isArray(this.sites)) {
        for (let i = 0; i < this.sites.length; i++) {
          if (this.sites[i].id === facId) {
            result = this.sites[i];
            break;
          }
        }
      }
      return result;
    },
    precautionById: function (precautionId) {
      var name = "";
      this.precautions.forEach(function (p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    unitById: function (unitId) {
      var unitName = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    nameFilter: function (clients) {
      var vm = this;
      return clients.filter(function (client) {
        return vm.clientName(client).toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    cancelAssignment: function () {
      this.selectedRoom = null;
      this.selectedUnit = null;
      this.selectedBed = null;
      this.dialogBedAssignDialog = false;
    },
    confirmAssignment: function (client, bed) {
      var vm = this;
      bed.occId = client.id;
      this.$http.post(window.CONFIG.api + "/client/" + client.id + "/bed/assign", JSON.stringify(bed)).then(
        (response) => {
          vm.cancelAssignment();
          vm.loading = true;
          this.$configStore.loadAll(() => {
            vm.bedMap = vm.$configStore.data.bedMap;
            vm.reload();
          });
        },
        (response) => {
          // error callback
        }
      );
    },
    assignClient: function (client) {
      console.log(JSON.stringify(client));
      this.selectedRoom = null;
      this.selectedUnit = null;
      this.selectedBed = null;

      if (this.beds[client.id]) {
        var vm = this;
        vm.units.forEach(function (unit) {
          unit.rooms.forEach(function (room) {
            var beds = room.beds;
            beds.forEach(function (bed) {
              if (bed.occId === client.id) {
                vm.selectedRoom = room;
                vm.selectedUnit = unit;
                vm.selectedBed = bed;
              }
            });
          });
        });
      }
      this.selectedClient = client;
      this.dialogBedAssignDialog = true;
    },
    sortArray: function (arrayValue) {
      if (arrayValue) {
        return arrayValue.sort(function (a, b) {
          return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
        });
      }
    },
    unassignClient: function (client) {
      this.selectedClient = client;
      var vm = this;
      this.$http.delete(window.CONFIG.api + "/client/" + client.id + "/bed/unassign").then(
        (response) => {
          vm.loading = true;
          this.$configStore.loadAll(() => {
            vm.bedMap = vm.$configStore.data.bedMap;
            vm.reload();
          });
        },
        (response) => {
          // error callback
        }
      );
    },
    deleteClient: function (client) {
      var vm = this;
      this.$confirm("You will no longer be able to retrieve associated cases. Are you sure you want to remove " + this.clientName(client) + "?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http.delete(window.CONFIG.api + "/clients/" + client.id + "/delete").then(
          (response) => {
            vm.reload();
          },
          (response) => {
            // error callback
          }
        );
      });
    },
    discharge: function (client) {
      var vm = this;
      this.$confirm("Do you want to discharge " + this.clientName(client) + "?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http.delete(window.CONFIG.api + "/clients/" + client.id + "/discharge").then(
          (response) => {
            vm.reload();
          },
          (response) => {
            // error callback
          }
        );
      });
    },
    newResident: function () {
      this.locations = [];
      this.newClient = { gender: "", dateOfBirth: "" };
      this.newClient.facId = this.sites[0].id;
      this.resFacId = this.newClient.facId;
      if (this.$refs["newClientForm"]) {
        this.$refs["newClientForm"].resetFields();
      }

      this.isNewRes = true;
      this.newResidentDialogFormVisible = true;
    },
    updateResident: function (resident, event, column) {
      if (column.property === "active") {
        return;
      }

      this.fileList = [];
      if (this.$refs["clientForm"]) {
        this.$refs["clientForm"].resetFields();
      }

      if (!resident.gender) {
        resident.gender = "";
      }

      this.isNewRes = false;
      this.resFacId = resident.facId;
      console.log("------------------------");
      console.log(JSON.stringify(resident));
      this.newClient = JSON.parse(JSON.stringify(resident));
      if (!resident.dateOfBirth) {
        this.newClient.dateOfBirth = "";
      } else {
        this.newClient.dateOfBirth = this.offsetDate(resident.dateOfBirth);
      }
      this.newResidentDialogFormVisible = true;
      this.resActiveTab = "1";
      this.uploadUrl = window.CONFIG.api + "/clients/photo/" + this.newClient.id;
      let vm = this;
      vm.locations = [];
      api.getLocationsFromOcc(this.newClient.id, (res) => {
        console.log("----- locations -----");
        console.log(res.body);
        vm.locations = res.body;
      });
    },
    confirmNewResident: function (formName) {
      var vm = this;
      console.log(this.$refs);
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          vm.newResidentDialogFormVisible = false;
          vm.loading = true;
          var clientCopy;
          if (vm.isNewRes) {
            delete vm.newClient.bed;
            vm.isNewRes = false;
            clientCopy = JSON.parse(JSON.stringify(vm.newClient));
            if (clientCopy.gender === "") {
              delete clientCopy.gender;
            }

            if (clientCopy.dateOfBirth === "") {
              delete clientCopy.dateOfBirth;
            }
            clientCopy.facId = vm.resFacId;
            clientCopy.status = "C";

            this.$http.post(window.CONFIG.api + "/clients/new", JSON.stringify([clientCopy])).then(
              (response) => {
                vm.reload();
              },
              (response) => {
                // error callback
              }
            );
          } else {
            delete vm.newClient.symptoms;
            delete vm.newClient.precautionDetails;
            delete vm.newClient.notes;
            delete vm.newClient.symptomDetails;

            clientCopy = JSON.parse(JSON.stringify(vm.newClient));
            if (clientCopy.gender === "") {
              delete clientCopy.gender;
            }

            if (clientCopy.dateOfBirth === "") {
              delete clientCopy.dateOfBirth;
            }

            this.$http.put(window.CONFIG.api + "/clients/" + vm.newClient.id, clientCopy).then(
              (response) => {
                vm.reload();
              },
              (response) => {
                // error callback
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    locationFilterUpdated: function (o) {
      this.selectedUnit = o.unit;
      this.selectedSite = o.site;
      this.availableUnits = o.units || [];
      this.availableSites = o.sites || [];
      console.log(o);
      console.log(JSON.stringify(this.availableSites));
      this.filterUpdated();
    },
    filterUpdated: function () {
      this.currentPage = 1;
      this.reload();
    },
    reload: function () {
      var vm = this;
      vm.loading = true;
      vm.beds = {};
      vm.organization = this.$configStore.data.organization;
      if (vm.organization) {
        vm.sites = vm.organization[0].sites || [];
        if (vm.sites.length > 1) {
          vm.filteredSites = vm.sites;
        }
        var u = [];
        vm.sites.forEach(function (s) {
          var su = s.units || [];
          su.forEach(function (uu) {
            var unit = uu;
            unit.siteId = s.id;
            unit.siteDisplayText = s.displayText;
            u.push(unit);
          });
        });
        vm.units = vm.$configStore.data.units;
        vm.graphConfig = { units: vm.units };

        if (vm.selectedUnit === null && vm.units && vm.units.length > 0) {
          vm.selectedUnit = vm.units[0].value;
        }

        // vm.units.forEach(function (unit) {
        //   unit.rooms = vm.sortArray(unit.rooms)
        //   unit.rooms.forEach(function (room) {
        //     room.beds = vm.sortArray(room.beds)
        //     var beds = room.beds
        //     beds.forEach(function (bed) {
        //       vm.beds[bed.occId] = {
        //         displayText: room.displayText + '-' + bed.displayText,
        //         bed: bed
        //       }
        //     })
        //   })
        // })
      } else {
        vm.organization = [];
      }

      this.loadPrecautions();
    },
    exportExcel: async function () {
      if (this.availableSites.length !== 1) {
        return;
      }
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const facilityName = this.facilityById(this.availableSites[0]).displayText;
      const columns = ["Name", "ID", "Facility", "Location", "Status", "Date Given", "Immunization Type", "Declined?", "Reason", "Location", "Vaccine Type", "Lot Number", "Notes"];
      const status = (status) => (status === "D" ? "Discharged" : status === "T" ? "Trasferred" : "Active");
      const fetchDataForColumns = async (page) => {
        const payload = [];
        const params = {
          name: this.selectedName ? this.selectedName : null,
          status: this.resStatus ? this.resStatus : null,
          providerType: this.providerType ? this.providerType : null,
          employeeType: this.employeeType ? this.employeeType : null,
          facIds: this.availableSites,
          sort: "lastName",
          clientType: "Client",
          size: "10",
        };
        const paramsString = Object.entries(params)
          .filter((pair) => pair[1] !== null)
          .map((pair) => `${pair[0]}=${pair[1]}`)
          .join("&");
        const clientsResponse = await this.$http.get(`${window.CONFIG.api}/clients/list/unit/${this.filteredUnit}/page/${page}?${paramsString}`);
        const clients = clientsResponse.body.content;
        for (let i = 0; i < clients.length; i++) {
          const immunizationResponse = await this.$http.get(`${window.CONFIG.api}/clients/${clients[i].id}/immunizations`);
          const immunizations = immunizationResponse.body.length ? immunizationResponse.body : [{}];
          immunizations.forEach((immunization) => {
            payload.push({ ...clients[i], ...{ immunization: immunization } });
          });
        }
        return {
          totalPages: clientsResponse.body.totalPages,
          hasMore: !clientsResponse.body.last,
          data: payload,
        };
      };
      const patientIdType = this.$configStore.patientIdType();
      const convertDataToColumns = (_) => {
        let fields = [_.middleName ? `${_.firstName} ${_.middleName} ${_.lastName}` : `${_.firstName} ${_.lastName}`, patientIdType ? _[patientIdType] : _.patientId, facilityName, _.bed ? _.bed.fullDesc : "", status(_.status)];
        if (_.immunization.id) {
          fields.push(_.immunization.date == undefined || _.immunization.date == null || _.immunization.date == "" ? "" : moment(this.offsetDate(_.immunization.date)).format(this.$configStore.dateFormat()));
          fields.push(_.immunization.description);
          fields.push(_.immunization.declined ? "Yes" : "No");
          fields.push(_.immunization.declinedReason || "");
          fields.push(_.immunization.location);
          fields.push(_.immunization.vaccineType || "");
          fields.push(_.immunization.lotNumber || "");
          fields.push(_.immunization.note || "");
        }
        return fields;
      };
      let data = [];
      let currentPage = 0;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        data = [...data, ...json.data.map(convertDataToColumns)];
        hasMore = json.hasMore;
        currentPage++;
        this.excelExportResultsProgress = Math.round(100 * (currentPage / json.totalPages));
      }
      this.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onSetDate - a.onSetDate);
      if (!this.cancelExcelExportProgress) {
        this.createExcelFile("report.xlsx", columns, data);
      }
    },
    createExcelFile: function (fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelExcelExport() {
      this.cancelExcelExportProgress = true;
    },
    batchImmunizations: function () {
      this.showImmunizationsEditor = true;
    },
    batchImmunizationsClose: function () {
      this.showImmunizationsEditor = false;
    },
    hasADT: function () {
      return this.$configStore.adtEnabled();
    },
    clientModalTitle(model) {
      const { firstName, lastName } = model;
      return firstName ? `${firstName} ${lastName}` : "Resident";
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    if (!this.$configStore.adtEnabled()) {
      this.assigned = 0;
    }
    this.canModifyImmunizations = this.canModify && this.$configStore.adtEnabled() === false;
    const existingSites = this.$configStore.data.sites || [];
    if (existingSites.length === 1) {
      this.availableSites = [existingSites[0].id];
    }
    this.reload();
    this.bedMap = this.$configStore.data.bedMap;
    console.log(this.bedMap);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-button--primary.is-plain.disabled {
  color: lightgray;
}
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}
.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

.el-dialog__body div.profile-row-photo {
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 5em;
}

.el-dialog__body img.profile-row-photo {
  height: 100%;
  width: auto;
  transform: translateX(-50%);
  margin-left: 50%;
}

.profile-photo img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-filter-select {
  width: 160px;
  margin-right: 1em;
}
.case-filter > .case-filter-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-section > label {
  display: inline-block;
}
.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}
.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.case-filter-select {
  float: left;
}

.dialog-photo-image img {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
}
</style>
