<template>
  <el-dialog v-model="dialogVisibility" title="Reset Password" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="passwordResetForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Password -->
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="formModel.password"></el-input>
      </el-form-item>
      <!-- Confirm Password -->
      <el-form-item label="Confirm Password" prop="confirmPassword">
        <el-input type="password" v-model="formModel.confirmPassword"></el-input>
      </el-form-item>
      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Reset Password</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: "uam-password-reset",
  props: ["userId"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      formRules: {
        password: [
          {
            required: true,
            validator: this.validatePassword,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.validateConfirmPassword,
            trigger: "blur",
          },
        ],
      },
      formModel: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
  },
  methods: {
    validatePassword(rule, value, cb) {
      if (!value) {
        cb(new Error("This field is required"));
      } else if (value.length < 8) {
        cb(new Error("Password must contain at least 8 characters."));
      } else if (value.length > 20) {
        cb(new Error("Password must contain at most 20 characters."));
      } else {
        if (this.formRules.confirmPassword !== "") {
          this.$refs.passwordResetForm.validateField("confirmPassword");
        }
        cb();
      }
    },
    validateConfirmPassword(rule, value, cb) {
      if (!value) {
        cb(new Error("This field is required"));
      } else if (value.length < 8) {
        cb(new Error("Password must contain at least 8 characters."));
      } else if (value.length > 20) {
        cb(new Error("Password must contain at most 20 characters."));
      } else if (value !== this.formModel.password) {
        cb(new Error("Passwords must match."));
      }
      cb();
    },
    cancelForm() {
      this.$emit("password-reset-close");
    },
    async commitForm() {
      this.$refs.passwordResetForm.validate(async (valid) => {
        if (valid) {
          this.dialogBusy = true;
          try {
            const url = `${window.CONFIG.uam_api}/users/${this.userId}/password`;
            const body = { password: this.formModel.password };
            await this.$http.put(url, JSON.stringify(body));
            this.$emit("password-reset-save");
          } catch (error) {
            let message = "An error has occurred. Please try again later.";
            if (error.status === 403) {
              message = "Your account does not have the required permissions to perform this action.";
            }
            this.$emit("password-reset-error", message);
            this.dialogBusy = false;
          }
        } else {
          return false;
        }
      });
    },
  },
  async created() {
    this.dialogBusy = false;
  },
};
</script>
