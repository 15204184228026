<template>
  <div class="surveillance-communication">
    <el-row type="flex" justify="end">
      <el-button ref="newNoteButton" :disabled="editing || !canModify" @click="addNewNote()">+ New</el-button>
    </el-row>
    <el-table border :data="allNotes" style="width: 100%; margin-top: 1em" :default-sort="{ prop: 'created', order: 'descending' }">
      <el-table-column label="Date" prop="created" width="120">
        <template scope="scope">
          {{ scope.row.created | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Content">
        <template scope="scope">
          <div v-if="scope.row.isNew">
            <el-input :disabled="!canModify" :maxlength="1000" type="textarea" autosize ref="newnote" placeholder="Please input" v-model="scope.row.content"> </el-input>
            <character-count :limit="1000" :count="scope.row.content.length" />
          </div>
          <div v-else class="case-note-content linebreak">{{ scope.row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column label="" width="150">
        <template scope="scope">
          <el-button-group>
            <el-button v-if="scope.row.isNew" :disabled="!scope.row.content" @click="saveAction(scope.$index, scope.row)" size="small"> Save </el-button>
            <el-button v-if="scope.row.isNew" @click="cancelAction(scope.$index, scope.row)" size="small"> Cancel </el-button>
          </el-button-group>
          <el-button :disabled="!canModify" v-if="!scope.row.isNew" @click="deleteAction(scope.$index, scope.row)" size="small" icon="delete"> </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import auth from "../auth";
import CharacterCount from "./Shared/CharacterCount.vue";

export default {
  name: "surveillance-communication",
  props: ["notes", "caseData"],
  components: {
    "character-count": CharacterCount,
  },
  data: function () {
    return {
      canModify: false,
      newNotes: [],
      allNotes: [],
      editing: false,
    };
  },
  watch: {
    notes: function (value) {
      this.newNotes = [];
      this.allNotes = value;
    },
    newNotes: function (value) {
      this.allNotes = value.concat(this.notes);
    },
  },
  methods: {
    addNewNote: function () {
      var now = new Date();
      var newNote = {
        created: now.getTime(),
        content: "",
        isNew: true,
      };
      console.log("new note added");
      this.newNotes.splice(0, 0, newNote);
      let vm = this;
      vm.$nextTick(() => {
        let inputs = vm.$refs["newnote"];
        inputs.$refs.textarea.select();
      });
      this.editing = true;
    },
    cancelAction: function (index, data) {
      this.newNotes = [];
      this.editing = false;
    },
    saveAction: function (index, data) {
      var vm = this;
      var loadingInstance = Loading.service({ fullscreen: true });
      var newNote = {
        created: data.created,
        content: data.content,
        refId: this.caseData.id,
      };

      this.$http.post(window.CONFIG.api + "/symptomDetail/new/note", newNote).then(
        (response) => {
          if (response.status === 200) {
            vm.caseData.notes = response.body;
            vm.newNotes = [];
          }
          loadingInstance.close();
          this.editing = false;
        },
        (response) => {
          // error callback
          this.editing = false;
        }
      );
    },
    deleteConfirmed: function (index, data) {
      var vm = this;
      var loadingInstance = Loading.service({ fullscreen: true });

      this.$http.post(window.CONFIG.api + "/symptomDetail/delete/note", data).then(
        (response) => {
          if (response.status === 200) {
            vm.caseData.notes = response.body;
            vm.newNotes = [];
          }
          loadingInstance.close();
          this.editing = false;
        },
        (response) => {
          // error callback
          this.editing = false;
        }
      );
    },
    deleteAction: function (index, data) {
      var vm = this;
      this.$confirm("Delete this note?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        vm.deleteConfirmed(index, data);
      });
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    this.allNotes = this.notes;
  },
};
</script>
<style scoped>
.linebreak {
  white-space: pre-line;
}
</style>
