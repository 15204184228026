<template>
  <div class="page-section">
    <section-title :title="title">
      <slot name="title"></slot>
    </section-title>
    <div class="section-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle.vue";

export default {
  name: "page-section",
  components: { SectionTitle },
  props: {
    title: String,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
.page-section {
}
</style>
