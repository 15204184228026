import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reporting: {
      isSurveillanceReportInFlight: false
    }
  },
  getters: {
    reportsIsBusy: state => state.reporting.isSurveillanceReportInFlight
  },
  mutations: {
    asyncReportStarted(state) {
      state.reporting.isSurveillanceReportInFlight = true;
    },
    asyncReportCompleted(state) {
      state.reporting.isSurveillanceReportInFlight = false;
    }
  },
  actions: {
    initAsyncReport({ commit }, { url, payload }) {
      commit("asyncReportStarted");
      const handler = event => {
        const link = document.createElement("a");
        link.href = event.detail.message.url;
        link.download = `LineListReport.pdf`;
        link.dispatchEvent(new MouseEvent("click"));
        document.removeEventListener("message:ReportGenerated", handler);
        commit("asyncReportCompleted");
      };
      document.addEventListener("message:ReportGenerated", handler);
      Vue.http.post(url, payload).catch(() => {
        document.removeEventListener("message:ReportGenerated", handler);
      });
    }
  }
});
