<template>
  <div class="thresholds-details">
    <el-row class="filter-section">
      <label>
        <span class="filter-title-label">Type:</span>
        <el-select :disabled="!canModify" v-model="selectedType" placeholder="Threshold Type" class="filter-section-input">
          <el-option label="Not Selected" :value="0"> </el-option>
          <el-option v-for="item in types" :label="item.text" :key="item.text" :value="item.value">
            <span style="float: left">{{ item.text }}</span>
          </el-option>
        </el-select>
      </label>
      <div v-if="selectedType !== 0">
        <label>
          <span class="filter-title-label">Scope:</span>
          <el-select :disabled="!canModify" v-model="selectedScope" placeholder="Threshold Type" class="filter-section-input">
            <el-option label="Not Selected" :value="0"> </el-option>
            <el-option v-for="item in scopes" :label="item.text" :key="item.text" :value="item.value">
              <span style="float: left">{{ item.text }}</span>
            </el-option>
          </el-select>
        </label>
        <div v-if="selectedScope == 'Facility'">
          <label>
            <span class="filter-title-label">Facility:</span>
            <el-select :disabled="!canModify" v-model="selectedSite" placeholder="Facility" class="filter-section-input" v-on:change="update(selectedSite)" value-key="id">
              <el-option label="Not Selected" :value="0"> </el-option>
              <el-option v-for="item in organization.sites" :label="item.displayText" :key="item.id" :value="item"> </el-option>
            </el-select>
          </label>
        </div>
        <div v-if="selectedScope == 'Unit'">
          <span class="filter-title-label">Unit:</span>
          <el-select :disabled="!canModify" v-model="selectedUnit" placeholder="Unit" class="filter-section-input" v-on:change="update(selectedSite)" value-key="id">
            <el-option label="Not Selected" :value="0"> </el-option>
            <el-option v-for="item in allUnits()" :key="item.key" :label="item.displayText + ' - ' + item.site.displayText" :value="item"> </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="selectedScope !== 0 && (selectedSite !== 0 || selectedUnit !== 0) && selectedType == 'Organism'">
        <label>
          <span class="filter-title-label">Organisms:</span>
          <el-select :disabled="!canModify" v-model="selectedOrganism" placeholder="Organisms" class="filter-section-input" v-on:change="update(selectedOrganism)" value-key="id">
            <el-option v-for="item in organisms" :key="item.id" :label="item.name" :value="item"> </el-option>
          </el-select>
        </label>
      </div>
      <div v-if="selectedScope !== 0 && (selectedSite !== 0 || selectedUnit !== 0) && selectedType == 'InfectionSite'">
        <label>
          <span class="filter-title-label">Infection Site:</span>
          <el-select :disabled="!canModify" v-model="selectedInfectionSite" placeholder="Infection Site" class="filter-section-input" v-on:change="update(selectedInfectionSite)" value-key="id">
            <el-option v-for="item in symptomTypes" v-if="item !== 'Common'" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </label>
      </div>
      <div>
        <br />
      </div>
    </el-row>
    <el-row>
      <el-col :span="5">
        <span class="filter-title-label">Days:</span>
      </el-col>
      <el-col :span="16" :offset="1">
        <el-slider :disabled="!canModify" v-model="days" :min="1" :max="31" :step="1" v-on:change="updateNumbers()" show-input> </el-slider>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="5">
        <span class="filter-title-label">Occurrence:</span>
      </el-col>
      <el-col :span="16" :offset="1">
        <el-slider :disabled="!canModify" v-model="occurrence" :min="1" :max="31" :step="1" v-on:change="updateNumbers()" show-input> </el-slider>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import auth from "../auth";

export default {
  name: "thresholds-details",
  props: ["threshold", "organisms", "symptomTypes", "organization"],
  data: function() {
    return {
      canModify: false,
      types: [
        { text: "Infection Site", value: "InfectionSite" },
        { text: "Organism", value: "Organism" }
      ],
      scopes: [
        { text: "Facility", value: "Facility" },
        { text: "Unit", value: "Unit" }
      ],
      selectedType: 0,
      selectedScope: 0,
      selectedSite: 0,
      selectedUnit: 0,
      selectedOrganism: [],
      selectedInfectionSite: [],
      occurrence: 1,
      days: 1,
      thresholdCopy: {}
    };
  },
  watch: {
    threshold: function(v) {
      var value = v || {};
      // console.log('threshold: ' + JSON.stringify(value, 2, ' '))

      // if (!value.type) {
      //   this.selectedType = value.type
      // }
      this.selectedType = value.type || 0;
      this.selectedScope = value.scope || 0;
      if (!value.site) {
        this.selectedSite = value.site;
      }

      this.occurrence = value.occurrence || 1;
      this.days = value.days || 1;
      console.log(value.site);
    },
    selectedType: function(value) {
      this.$emit("change", null);
      this.selectedScope = 0;
      this.selectedUnit = 0;
      this.selectedScope = 0;
      this.selectedSite = 0;
      this.selectedOrganism = [];
      this.selectedInfectionSite = [];
    }
  },
  methods: {
    resetValues: function(value) {
      this.selectedType = value.type || 0;
      this.selectedScope = value.scope || 0;
      this.selectedSite = value.site || 0;
      this.occurrence = value.occurrence || 1;
      this.days = value.days || 1;
      this.$emit("change", null);
    },
    updateNumbers: function() {
      if (this.threshold !== {} && this.threshold !== null) {
        var thresholdCopy = JSON.parse(JSON.stringify(this.threshold));
        thresholdCopy.occurrence = this.occurrence;
        thresholdCopy.days = this.days;
        this.$emit("change", thresholdCopy);
      }
    },
    update: function(value) {
      if (value !== 0) {
        var threshold = {
          site: this.selectedSite === 0 ? null : this.selectedSite,
          unit: this.selectedUnit === 0 ? null : this.selectedUnit,
          organism: this.selectedOrganism === [] ? null : this.selectedOrganism,
          symptomType: this.selectedInfectionSite === [] ? null : this.selectedInfectionSite,
          type: this.selectedType,
          scope: this.selectedScope,
          occurrence: this.occurrence,
          days: this.days
        };

        console.log(JSON.stringify(threshold, 2, " "));
        this.$emit("change", threshold);
      }
    },
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    allUnits: function() {
      return this.$configStore.data.units;
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.filter-title-label {
  width: 100px;
  display: inline-block;
}
</style>

<style>
.thresholds-details .el-slider__button.el-tooltip {
  vertical-align: unset;
}
</style>
