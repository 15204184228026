<template>
  <el-dialog v-model="dialogVisibility" :title="title" v-on:close="resetForm">
    <el-form ref="bedForm" :model="formModel" :rules="formRules" label-width="120px">
      <el-form-item label="Facility" prop="facility">
        <el-select :disabled="facilities.length === 1" v-model="formModel.facility" value-key="id" filterable v-on:change="onFacilityChange">
          <el-option v-for="item in facilities" :key="item.id" :label="item.displayText" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formModel.facility" label="Unit" prop="unit">
        <el-select v-model="formModel.unit" value-key="id" filterable :disabled="!formModel.facility" v-on:change="onUnitChange">
          <el-option v-for="item in units" :key="item.displayText" :label="item.displayText" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formModel.unit" label="Room" prop="room">
        <el-select v-loading="unitMapLoading" v-model="formModel.room" value-key="id" filterable :disabled="!formModel.unit" v-on:change="onRoomChange">
          <el-option v-for="item in rooms" :key="item.displayText" :label="item.displayText" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formModel.room" label="Bed" prop="bed">
        <el-select v-loading="unitMapLoading" v-model="formModel.bed" value-key="id" filterable :disabled="!formModel.room">
          <el-option v-for="item in beds" :key="item.displayText" :label="item.occId ? `${item.displayText} (Occupied)` : item.displayText" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <div class="dialog-footer">
        <el-row>
          <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Submit</el-button>
        </el-row>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};

export default {
  name: "BedAssignment",
  props: ["resident", "title"],
  data() {
    return {
      dialogVisibility: true,
      formModel: {
        facility: null,
        unit: null,
        room: null,
        bed: null,
      },
      formRules: {
        facility: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        unit: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        room: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        bed: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
      },
      dialogBusy: false,
      unitMapLoading: false,
      unitMap: {},
      facilities: [],
      units: [],
      rooms: [],
      beds: [],
    };
  },
  watch: {
    dialogVisibility(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
  methods: {
    onFacilityChange(facility) {
      if (facility) {
        this.units = facility.units.filter((unit) => unit.code !== "PLACE_HOLDER");
        this.formModel.unit = null;
        this.formModel.room = null;
        this.formModel.bed = null;
        this.rooms = [];
        this.beds = [];
      }
    },
    async onUnitChange(unit) {
      const unitId = unit?.id;
      if (unitId) {
        let expandedUnit = this.unitMap[unitId];
        if (!expandedUnit) {
          expandedUnit = await this.getExpandedUnit(unitId);
          this.unitMap[unitId] = expandedUnit;
        }
        this.formModel.bed = null;
        this.formModel.room = null;
        this.rooms = expandedUnit.rooms || [];
        this.beds = [];
      }
    },
    onRoomChange(room) {
      if (room && room.id) {
        this.formModel.bed = null;
        this.beds = room.beds || [];
      }
    },
    async getExpandedUnit(unitId) {
      this.unitMapLoading = true;
      const response = await this.$http.get(`${window.CONFIG.api}/units/${unitId}`);
      let unit = {};
      if (response.body) {
        unit = { ...response.body };
        unit.rooms.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { numeric: true }));
        unit.rooms.forEach((room) => room.beds.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { numeric: true })));
      }
      this.unitMapLoading = false;
      return unit;
    },
    cancelForm() {
      this.$refs.bedForm.resetFields();
      this.$emit("bed-assignment-close");
    },
    async commitForm() {
      this.$refs.bedForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogBusy = true;
            const residentId = this.resident.id;
            const bed = { ...this.formModel.bed };
            bed.occId = residentId;
            await this.$http.post(`${window.CONFIG.api}/client/${residentId}/bed/assign`, JSON.stringify(bed));
            this.dialogBusy = false;
            this.$emit("bed-assignment-save");
          } catch (err) {
            console.log({ err });
            this.$emit("bed-assignment-error");
          }
        }
      });
    },
    resetForm() {
      this.formModel = {
        facility: null,
        unit: null,
        room: null,
        bed: null,
      };
      this.units = [];
      this.rooms = [];
      this.beds = [];
    },
  },
  async created() {
    this.facilities = this.$configStore.data.sites;
    console.log({ res: this.resident });
    const facility = this.facilities.find((fac) => fac.id === this.resident.facId);
    this.onFacilityChange(facility);
    this.formModel.facility = facility;
  },
};
</script>
