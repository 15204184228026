<template>
  <div class="outbreak-setup">
    <div class="filter">
      <div class="section-header">
        <el-row
          ><span>Outbreak Checklist</span>
          <div class="case-filter">
            <el-button class="case-filter-button" :plain="true" type="primary" size="mini" :disabled="adding || !canModify" @click="addCheckList">+ New</el-button>
            <el-button :disabled="!canModify" class="case-filter-button" :plain="true" type="primary" size="mini" @click="saveCheckList">Save</el-button>
          </div>
        </el-row>
      </div>
    </div>
    <el-table :data="checklist">
      <el-table-column label="Checklist Items">
        <template scope="scope">
          <div v-if="scope.row.new || scope.row.editing">
            {{ scope.$index + 1 }}.
            <el-input style="width: 85%" type="textarea" :autosize="true" v-model="scope.row.text"></el-input>
            <character-count :limit="checklistItemMaxLength" :count="scope.row.text.length" />
          </div>
          <div v-else class="linebreak">{{ scope.$index + 1 }}. {{ scope.row.text }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="active" width="70">
        <template scope="scope">
          <el-button-group v-if="!scope.row.new">
            <el-button :disabled="!canModify" v-if="!scope.row.editing" @click="editItem(scope.$index, true)" size="small">Edit</el-button>
            <el-button :disabled="!canModify || scope.row.text.length > checklistItemMaxLength" v-else @click="editItem(scope.$index, false)" size="small">Done</el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column prop="active" width="160">
        <template scope="scope">
          <!-- <el-button @click="deleteThreshold(scope.$index, scope.row)" icon="delete"></el-button> -->
          <el-button-group>
            <el-button :disabled="!canModify" @click="orderDown(scope.$index, scope.row)" icon="arrow-down" size="small"></el-button>
            <el-button :disabled="!canModify" @click="orderUp(scope.$index, scope.row)" icon="arrow-up" size="small"></el-button>
          </el-button-group>
          <el-button-group>
            <el-button @click="deleteChecklistItem(scope.$index, scope.row)" icon="delete" size="small" :disabled="scope.row.editing || !canModify"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <!-- dialogs -->
    <el-dialog title="Delete" v-model="dialogConfirmDeleteVisible" v-if="selectedItemIndex >= 0" class="delete-dialog">
      <div>
        <h3>Delete this item?</h3>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogConfirmDeleteVisible = false;
            selectedItemIndex = -1;
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="confirmDeleteChecklistItem()">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../auth";
import { Loading } from "element-ui";
import CharacterCount from "./Shared/CharacterCount.vue";

export default {
  name: "outbreak-setup",
  components: {
    "character-count": CharacterCount,
  },
  data: function () {
    return {
      canModify: false,
      checklistItemMaxLength: 1000,
      errorMessage: "",
      user: {},
      outbreakconfig: {},
      checklist: [],
      adding: false,
      dialogConfirmDeleteVisible: false,
      selectedItemIndex: -1,
    };
  },
  watch: {
    outbreakconfig: function (v) {
      var vm = this;
      if (v.checkList) {
        v.checkList.forEach(function (c) {
          vm.checklist.push({ text: c, new: false });
        });
      }
    },
  },
  methods: {
    loadConfigs: function (callback) {
      var vm = this;

      this.$http.get(window.CONFIG.api + "/outbreakconfigs").then((response) => {
        vm.outbreakconfig = {};
        if (response.body && response.body[0]) {
          vm.outbreakconfig = response.body[0];
        }
        vm.adding = false;
        vm.checklist = [];
        if (callback) {
          callback();
        }
      });
    },
    orderDown: function (index, item) {
      var checkList = this.checklist;
      if (checkList[index] && checkList.length - 1 > index) {
        var temp1 = checkList[index];
        var temp2 = checkList[index + 1];
        this.$set(this.checklist, index, temp2);
        this.$set(this.checklist, index + 1, temp1);
      }
      console.log(this.checklist);
    },
    orderUp: function (index, item) {
      var checkList = this.checklist;
      if (checkList[index] && index > 0) {
        var temp1 = checkList[index];
        var temp2 = checkList[index - 1];
        this.$set(this.checklist, index, temp2);
        this.$set(this.checklist, index - 1, temp1);
      }
      console.log(this.checklist);
    },
    editItem: function (index, done) {
      if (this.checklist[index] && index >= 0) {
        var item = this.checklist[index];
        item.editing = done;
        this.$set(this.checklist, index, item);
      }
    },
    deleteChecklistItem: function (index, item) {
      if (item.new) {
        this.adding = false;
      }

      this.selectedItemIndex = index;
      this.dialogConfirmDeleteVisible = true;
    },
    addCheckList: function () {
      this.adding = true;
      this.checklist.splice(this.checklist.length, 0, { text: "", new: true });
    },
    saveCheckList: function () {
      var vm = this;
      var checklist = [];
      this.checklist.forEach(function (c) {
        if (c.text) {
          checklist.push(c.text);
        }
      });
      var loadingInstance = Loading.service({ fullscreen: true });
      var newOutbreakConfig = JSON.parse(JSON.stringify(this.outbreakconfig));
      newOutbreakConfig.checkList = checklist;
      this.$http.put(window.CONFIG.api + "/outbreakconfigs/" + newOutbreakConfig.id, newOutbreakConfig).then(
        (response) => {
          vm.outbreakconfig = response.body;
          vm.loadConfigs(function () {
            loadingInstance.close();
          });
        },
        (response) => {
          loadingInstance.close();
        }
      );
    },
    confirmDeleteChecklistItem: function () {
      this.checklist.splice(this.selectedItemIndex, 1);
      this.selectedItemIndex = -1;
      this.dialogConfirmDeleteVisible = false;
      this.saveCheckList();
    },
  },
  created: function () {
    this.canModify = auth.canModify();
    var user = auth.userInfo() || {};
    console.log(JSON.stringify(user));
    this.user = user;
    this.loadConfigs();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.case-filter > .case-filter-button {
  float: left;
  margin-right: 0.5em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
.linebreak {
  white-space: pre-line;
}
</style>
