import store from "../store";
import getMessagingService from "./messagingService";

export const initLineListReportGenerator = async function(templateName, data) {
  const wss = await getMessagingService();
  const connectionId = wss.getConnectionId();
  let payload = { data };
  payload.lang = "en";
  payload.format = "pdf";

  store.dispatch("initAsyncReport", {
    url: `${window.CONFIG.report_api}/${templateName}/${connectionId}`,
    payload
  });
};
