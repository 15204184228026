<template>
  <span :class="{ warning: limitReached }">Characters: {{ count }} / {{ limit }}</span>
</template>

<script>
export default {
  name: "character-counter",
  props: {
    count: {
      type: Number,
      default() {
        return 0;
      }
    },
    limit: {
      type: Number,
      default() {
        return 500;
      }
    }
  },
  watch: {
    count(val) {
      this.limitReached = val >= this.limit;
    },
    limit(val) {
      this.limitReached = val >= this.count;
    }
  },
  data() {
    return {
      limitReached: false
    };
  }
};
</script>

<style scoped>
.warning {
  color: red;
}
</style>
