<template>
  <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Facility -->
      <el-form-item label="Facility" prop="facility">
        <el-select v-model="formModel.facility" value-key="id" filterable>
          <el-option v-for="item in sites" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Unit -->
      <el-form-item label="Unit" prop="unit">
        <el-select v-model="formModel.unit" value-key="id" filterable :disabled="!formModel.facility">
          <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item"> </el-option>
        </el-select>
      </el-form-item>
<!-- Observer -->
      <el-form-item label="Observer" prop="observer">
        <el-select v-model="formModel.observer" filterable>
          <el-option v-for="item in scopedObservers" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>

      <!-- HCW Type -->
      <el-form-item label="HCW Type" prop="workerType">
        <el-select v-model="formModel.workerType">
          <el-option v-for="item in healthcareWorkers" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Moment -->
      <el-form-item label="Moment" prop="moment">
        <el-select v-model="formModel.moment">
          <el-option v-for="item in hhMoments" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Outcome + Seconds -->
      <el-form-item label="Outcome" prop="outcome">
        <el-radio-group v-model="formModel.outcome">
          <el-radio-button label="Wash"></el-radio-button>
          <el-radio-button label="Rub"></el-radio-button>
          <el-radio-button label="Missed"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-if="formModel.outcome === 'Wash' || formModel.outcome === 'Rub'">
        <el-form-item label="Seconds" prop="outcomeSeconds">
          <el-input-number v-model="formModel.outcomeSeconds" :min="0"></el-input-number>
        </el-form-item>
      </template>
      <!-- Collection Date -->
      <el-form-item label="Collection Date" prop="collectionDate">
        <el-date-picker v-model="formModel.collectionDate" type="datetime" placeholder="Select date and time"> </el-date-picker>
      </el-form-item>
      <!-- Custom Type  -->
      <el-form-item label="Custom Types" prop="customTypes">
        <el-checkbox-group v-model="formModel.customTypes">
          <el-checkbox v-for="type in customTypes" :key="type" :label="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- Comments  -->
      <el-form-item label="Comments" prop="comments">
        <el-input type="textarea" v-model="formModel.comments"></el-input>
      </el-form-item>
      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Save</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
import auth from "../../auth";
export default {
  name: "observation-editor",
  props: ["title", "values"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      observers: [],
      organization: null,
      sites: [],
      healthcareWorkers: [],
      hhMoments: [],
      customTypes: {},
      formRules: {
        observer: [{ required: true, message: "This field is required", trigger: "blur" }],
        facility: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        unit: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        workerType: [{ required: true, message: "This field  is required", trigger: "blur" }],
        moment: [{ required: true, message: "This field  is required", trigger: "blur" }],
        collectionDate: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        outcome: [{ required: true, message: "This field  is required", trigger: "blur" }]
      },
      formModel: {
        id: null,
        created: null,
        createdBy: null,
        active: true,
        observer: null,
        facility: null,
        unit: null,
        workerType: null,
        moment: null,
        outcome: null,
        outcomeSeconds: 0,
        collectionDate: null,
        customTypes: [],
        comments: null
      }
    };
  },
  computed: {
    units() {
      if (this.formModel.facility) {
        return this.formModel.facility.units;
      }
      return [];
    },
    scopedObservers() {
      // TODO: the list of observers should be filtered to a facility
      return this.observers
    }
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    }
  },
  methods: {
    setupForm(values) {
      this.formModel.id = values.id;
      this.formModel.created = values.created;
      this.formModel.createdBy = values.createdBy;
      this.formModel.active = values.active;
      this.formModel.observer = values.observerName;
      this.formModel.facility = this.sites.filter(s => s.id === Number(values.facId))[0];
      this.formModel.unit = this.formModel.facility.units.filter(u => u.id === Number(values.unitId))[0];
      this.formModel.outcome = values.outcome.outcome;
      this.formModel.outcomeSeconds = values.outcome.seconds;
      this.formModel.workerType = values.workerType;
      this.formModel.moment = values.moment;
      this.formModel.comments = values.comments;
      this.formModel.collectionDate = values.collectionDate;
      this.formModel.customTypes = Object.entries(values.customTypes)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    async commitForm() {
      this.$refs.editorForm.validate(async valid => {
        if (valid) {
          this.dialogBusy = true;
          const raw = { ...this.formModel };
          const formData = {};
          if (raw.id !== null) {
            formData.id = raw.id;
          }
          formData.outcome = {
            outcome: raw.outcome,
            seconds: raw.outcome === "Missed" ? 0 : raw.outcomeSeconds
          };
          formData.created = raw.created;
          formData.createdBy = raw.createdBy;
          formData.active = raw.active;
          formData.observerName = raw.observer;
          formData.collectionDate = new Date(raw.collectionDate).getTime();
          formData.facId = raw.facility.id;
          formData.unitId = raw.unit.id;
          formData.unitName = raw.unit.displayText;
          formData.workerType = raw.workerType;
          formData.moment = raw.moment;
          formData.comments = raw.comments;
          formData.customTypes = this.customTypes.map(type => {
            return {
              type: type,
              checked: raw.customTypes.includes(type)
            };
          });
          if (formData.id) {
            await this.$http.put(window.CONFIG.api + "/handhygiene/observations/" + formData.id, JSON.stringify(formData));
          } else {
            await this.$http.post(window.CONFIG.api + "/handhygiene/observations", JSON.stringify([formData]));
          }
          this.$emit("editor-save");
        } else {
          return false;
        }
      });
    }
  },
  async created() {
    this.observers = await this.$http
      .get(`${window.CONFIG.hh_api}/observers`)
      .then(response => response.body)
      .then(observers => {
        return Array.from(new Set([...observers, ...[auth.userInfo().username]]).keys())
      });
    const hhSettings = await this.$http.get(`${window.CONFIG.api}/handhygiene/settings?facId=1`).then(response => response.body);
    this.healthcareWorkers = hhSettings.workers;
    this.hhMoments = hhSettings.moments;
    this.customTypes = hhSettings.customTypes;
    this.organization = this.$configStore.data.organization[0];
    this.sites = this.$configStore.data.sites;
    if (this.values) {
      this.setupForm(this.values);
    }
    this.dialogBusy = false;
  }
};
</script>
