<template>
  <div class="stacked-column-chart" style="margin: 10px 10px 0 10px">
    <highcharts :options="highcharts" :callback="chartCreated"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import ExportingHighcharts from "highcharts/modules/exporting";
import { Chart } from "highcharts-vue";

ExportingHighcharts(Highcharts);

export default {
  name: "stacked-column-chart",
  components: {
    highcharts: Chart,
  },
  props: {
    series: Array,
    xAxisCategories: Array,
    yAxisTitle: String,
    title: String,
    usePerColumnLabels: Boolean,
    yAxisPlotLines: Array,
    configuration: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    usePerColumnLabels(val) {
      this.defaultConfig.plotOptions.series.dataLabels.enabled = !val;
    },
    title(val) {
      this.defaultConfig.title.text = val;
    },
    series(val) {
      this.defaultConfig.series = val;
    },
    yAxisTitle(val) {
      this.defaultConfig.yAxis.title.text = val;
    },
    xAxisCategories(val) {
      this.defaultConfig.xAxis.categories = val;
    },
    yAxisPlotLines(val) {
      this.defaultConfig.yAxis.plotLines = val;
    },
  },
  computed: {
    highcharts: function () {
      return Object.assign({}, this.defaultConfig, this.configuration);
    },
  },
  data() {
    return {
      defaultConfig: {
        credits: {
          enabled: false,
        },
        title: {
          align: "center",
          style: { fontSize: "12px" },
          text: this.title,
          useHTML: true,
        },
        legend: {
          enabled: false,
        },
        chart: {
          type: "column",
        },
        exporting: {
          sourceWidth: 1600,
          sourceHeight: 800,
          allowHTML: true,
          chartOptions: {},
        },
        xAxis: {
          categories: this.xAxisCategories,
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
          plotLines: this.yAxisPlotLines,
          stackLabels: {
            enabled: true,
            overflow: "allow",
            crop: false,
            align: "left",
            verticalAlign: "bottom",
            useHTML: true,
            formatter: function () {
              const style = `
              font-weight: normal;
              position: absolute;
              bottom: 0;
              left: -1.5em;
              top: 0;
              -webkit-transform-origin: 0 0;
              -webkit-transform: rotateZ(-90deg);
              transform-origin: 0 0;
              transform: rotateZ(-90deg);
              `;
              return `<div style="${style}">${this.stack}</div>`;
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "percent",
          },
          series: {
            groupPadding: 0.1,
            pointPadding: 0.3,
            dataLabels: {
              enabled: true,
              align: "center",
              verticalAlign: "top",
              useHTML: true,
              inside: true,
              color: "#000",
              style: {
                fontWeight: "normal",
              },
              format: "{y}%",
            },
          },
        },
        series: this.series,
      },
    };
  },
  methods: {
    chartCreated(chart) {
      // force chart reflow when going from screen <-> print and back
      const mql = window.matchMedia("print");
      mql.addEventListener("change", (e) => {
        if (e.matches) {
          chart.reflow();
        } else {
          chart.reflow();
        }
      });
    },
  },
};
</script>
<style>
.highcharts-title {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.highcharts-title span {
  content: "";
  height: 0;
  width: 0;
  margin: 0 5px 0 10px;
  border-radius: 50%;
  border: 7px solid;
}
.placeholder {
  pointer-events: none;
}
</style>
