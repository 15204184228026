export let _Vue;

const ClientService = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          clients: []
        };
      },
      created() {
        // this.loadClients()
        // console.log('mixin called for clientservice')
      },
      methods: {
        loadClients: function(callback) {
          var vm = this;

          this.$http.get(window.CONFIG.api + "/clients").then(
            response => {
              if (response.body) {
                vm.clients = response.body["_embedded"].clients;
                console.log(JSON.stringify(vm.clients, " ", 2));
              } else {
                console.log("no content");
                vm.clients = [];
              }

              if (callback) {
                callback();
              }
            },
            response => {
              // error callback
            }
          );
        }
      }
    });
  }
};

export default ClientService;
