import auth from "../auth";

class WebSocketClient {
  connectionId = null;

  constructor() {
    const JWT = auth.getToken();
    const wss = new WebSocket(`${window.CONFIG.wss_api}?jwt=${JWT}`);
    wss.onopen = event => {
      const payload = {
        action: "GetConnection"
      };
      wss.send(JSON.stringify(payload));
    };

    wss.onmessage = ({ data }) => {
      // TODO: switch to Vuex
      const resp = JSON.parse(data);
      const { topic, message } = resp;

      switch (topic) {
        case "GetConnection":
          this.connectionId = message.connectionId;
          break;
      }
      const event = new CustomEvent(`message:${topic}`, {
        detail: { message }
      });
      document.dispatchEvent(event);
    };

    wss.onclose = event => {
      console.log("connection closed");
      console.log(event);
    };

    wss.onerror = error => {
      console.log("connection error");
      console.log(error);
      wss.close();
    };
  }

  waitForId() {
    if (!this.connectionId) {
      return new Promise(resolve => {
        const handler = () => {
          document.removeEventListener("message:GetConnection", handler);
          resolve(this);
        };
        document.addEventListener("message:GetConnection", handler);
      });
    } else {
      return Promise.resolve(this);
    }
  }

  getConnectionId() {
    return this.connectionId;
  }
}

const getMessagingService = async () => {
  let sym = Symbol.for("messagingServiceSingleton");
  if (!window[sym]) {
    window[sym] = new WebSocketClient();
  }
  return window[sym].waitForId();
};

export { getMessagingService as default };
