<template>
  <div class="dashboard">
    <el-row :gutter="100">
      <el-col :span="16" :offset="4">
        <el-input placeholder="Search by Name" v-model="searchNameInput"></el-input>
      </el-col>
    </el-row>
    <br />
    <br />
    <el-row :gutter="100">
      <el-col :span="16" v-for="o in nameFilter(cases)" :offset="4" :key="o.id">
        <el-card>
          <div style="padding: 14px;">
            <div slot="header" class="clearfix">
              <el-col :span="8"><span>Name: </span></el-col>
              <el-col :span="12">{{ o.name }}</el-col>
              <el-col :span="4" class="profile-photo" v-if="photos[o.clientId]"><img :src="photos[o.clientId]"/></el-col>
              <el-col :span="4" class="profile-photo" v-else><img src="../assets/user.png"/></el-col>
            </div>
            <el-row>
              <el-col :span="8"><span>Infection Site: </span></el-col>
              <el-col :span="16"
                >{{ o.symptomType }}
                <span class="color-code-label-0" v-if="o.symptomType === 'GI'"></span>
                <span class="color-code-label-2" v-else-if="o.symptomType === 'UTI'"></span>
                <span class="color-code-label-1" v-else></span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Organism:</el-col>
              <el-col :span="16">
                <el-dropdown>
                  <span class="el-dropdown-link"> Not Selected<i class="el-icon-caret-bottom el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>Organism 1</el-dropdown-item>
                    <el-dropdown-item>Organism 2</el-dropdown-item>
                    <el-dropdown-item>Organism 3</el-dropdown-item>
                    <el-dropdown-item divided>Organism 4</el-dropdown-item>
                    <el-dropdown-item>Organism 5</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Origin:</el-col>
              <el-col :span="16">
                <el-select v-model="o.origin" placeholder="Select">
                  <el-option :key="item.value" v-for="item in origins" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Onset Date:</el-col>
              <el-col :span="16">
                <el-date-picker v-model="o.onSetDate" type="date" readonly editable clearable :format="$configStore.datePickerDateFormat()"> </el-date-picker>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Symptoms:</el-col>
              <el-col :span="16">
                <el-tag v-for="d in o.symptoms" :key="d.id" :type="tagType(d)">{{ d.displayText }}</el-tag>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Norification:</el-col>
              <el-col :span="5"><el-checkbox v-model="o.familyNotified">Family Notified</el-checkbox></el-col>
              <el-col :span="5"><el-checkbox v-model="o.nextOfNotified">Manager Notified</el-checkbox></el-col>
              <el-col :span="5"><el-checkbox v-model="o.physicianNotified">Public Health/Physician Notified</el-checkbox></el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "dashboard",
  data: function() {
    return {
      currentDate: new Date(),
      cases: [],
      searchNameInput: "",
      photos: {},
      origins: [
        {
          value: "Not Selected",
          label: "Not Selected"
        },
        {
          value: "HAI",
          label: "HAI"
        },
        {
          value: "Community Acquired",
          label: "Community Acquired"
        },
        {
          value: "Other",
          label: "Other"
        }
      ]
    };
  },
  methods: {
    loadCases: function() {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/symptomDetails/").then(
        response => {
          vm.cases = response.body["_embedded"].symptomDetails;
          vm.cases.forEach(function(c) {
            if (c.origin == null) {
              c.origin = "Not Selected";
            }
            c.symptoms.sort(function(a, b) {
              return a.colorCode > b.colorCode;
            });
            vm.loadImage(c);
          });
        },
        response => {
          // error callback
        }
      );
    },
    nameFilter: function(cases) {
      var vm = this;
      return cases.filter(function(detail) {
        return detail.name.toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    loadImage: function(detail) {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/clients/" + detail.clientId).then(
        response => {
          if (response.body["photo"]) {
            var photos = vm.photos;
            photos[detail.clientId] = "/static/" + response.body["photo"] + ".png";
            vm.$set(vm.photos, photos);
            console.log(vm.photos[detail.clientId]);
            vm.$forceUpdate();
          }
        },
        response => {
          // error
        }
      );
    },
    tagType: function(type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    }
  },
  created: function() {
    this.loadCases();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-card {
  margin-bottom: 1em;
}

.profile-photo img {
  width: 5em;
  height: 5em;
  float: right;
  border-radius: 50%;
}

.el-card div {
  line-height: 3em;
}
.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-button {
  width: 100%;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
</style>
