<template>
  <div class="outbreak-management">
    <filters-section :showLocation="true" :filterDisplayKeys="getFilterKeys()" :dateRangeStart="global.defaultDateStart" :includeUnits="false" v-on:change="formatFilters"> </filters-section>
    <page-section :title="`Outbreaks - Total: ${outbreakSection.pagination.total}`" v-loading="outbreakSection.loading">
      <template slot="title">
        <el-button :disabled="outbreakSection.pagination.total > 500" :plain="true" size="mini" type="primary" @click="exportOutbreaks()">Export Data</el-button>
        <el-button :disabled="!canModify" :plain="true" size="mini" type="primary" @click="createOutbreak">+ New Outbreak</el-button>
        <el-pagination
          @size-change="
            (size) => {
              outbreakSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              outbreakSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20]"
          :page-size="outbreakSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="outbreakSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-tooltip content="Click on Outbreak record to view details" placement="top" :hide-after="2000" :visible-arrow="false"></el-tooltip>
      <el-table :data="outbreakSection.data" @row-click="goToOutbreak">
        <el-table-column fixed label="Status" width="100">
          <template scope="scope">
            <div style="padding: 0.5em">
              <img class="status-icon" src="/static/outbreak_confirmed.png" v-if="scope.row.confirmed" />
              <img class="status-icon" src="/static/outbreak_suspected.png" v-else />
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed label="Scope" width="150">
          <template scope="scope">
            <div style="padding: 0.5em">
              {{ scope.row.scopeFormatted }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="facility" label="Facility" width="140" v-if="global.facilities.length !== 1"> </el-table-column>
        <el-table-column prop="typeValue" label="Infection Site" width="140">
          <template slot-scope="scope">
            <span class="color-code-label-0" v-if="scope.row.typeValue === 'GI'"></span>
            <span class="color-code-label-2" v-else-if="scope.row.typeValue === 'UTI'"></span>
            <span class="color-code-label-1" v-else-if="scope.row.typeValue === 'Respiratory'"></span>
            <span class="color-code-label-3" v-else></span>
            {{ scope.row.typeValue }}
          </template>
        </el-table-column>
        <el-table-column prop="organismFormatted" label="Causative Organisms" width="200"> </el-table-column>
        <el-table-column prop="facStatus" label="Facility Status" width="140"> </el-table-column>
        <el-table-column prop="declaredDateFormatted" label="Date Declared" width="140"> </el-table-column>
        <el-table-column prop="resolvedDateFormatted" label="Date Resolved" width="140"> </el-table-column>
        <el-table-column prop="firstCaseDateFormatted" label="First Case Date" width="140"> </el-table-column>
        <el-table-column prop="daysInOutbreak" label="Days In Outbreak" width="140"> </el-table-column>
        <el-table-column prop="totalCases" label="# of Cases" width="140"> </el-table-column>
        <el-table-column prop="residentCases" label="# of Resident Cases" width="140"> </el-table-column>
        <el-table-column prop="staffCases" label="# of Staff Cases" width="140"> </el-table-column>
        <el-table-column prop="deathCases" label="# of Deaths" width="140"> </el-table-column>
        <el-table-column prop="activeCases" label="# of Active Cases" width="140"> </el-table-column>
        <el-table-column prop="hospitalizedCases" label="# of Hospitalizations" width="140"> </el-table-column>
        <el-table-column prop="updatedDateFormatted" label="Updated" width="140"> </el-table-column>
        <el-table-column prop="updatedBy" label="Updated By" width="140"> </el-table-column>
        <el-table-column fixed="right" label="" prop="active" width="220">
          <template scope="scope">
            <el-button :disabled="!canModify" :plain="true" size="mini" @click="editOutbreak(scope.row)">
              <Icon :iconKey="'edit'" :description="'Edit Outbreak'" />
            </el-button>
            <el-button :plain="true" size="mini" @click="viewCheckList(scope.row)">
              <Icon :iconKey="'checklist'" :description="'View Checklist'" />
            </el-button>
            <el-button :disabled="!canModify" v-if="!scope.row.resolvedDate" :plain="true" size="mini" @click="resolveOutbreak(scope.row)">
              <Icon :iconKey="'resolve'" :description="'Resolve Outbreak'" />
            </el-button>
            <el-button :disabled="!canModify" v-else :plain="true" size="mini" @click="resolveOutbreak(scope.row)">
              <Icon :iconKey="'unresolve'" :description="'Unresolve Outbreak'" />
            </el-button>
            <el-button :disabled="!canModify" :plain="true" size="mini" @click="showDeleteOutbreak(scope.row.id)">
              <Icon :iconKey="'delete'" :description="'Delete Outbreak'" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <el-dialog title="Checklist" v-model="checklistSection.visible" :close-on-click-modal="false" :close-on-press-escape="false">
      <outbreak-checklist :outbreak="checklistSection.outbreak" v-on:close="closeChecklist" v-on:checklist-close="closeChecklist" v-on:checklist-save="updateChecklist"></outbreak-checklist>
    </el-dialog>

    <!-- Outbreak Editor -->
    <outbreak-editor
      v-if="outbreakSection.editor.visible"
      :title="outbreakSection.editor.title"
      :outbreak="outbreakSection.editor.data"
      v-on:close="closeOutbreakEditor"
      v-on:editor-close="closeOutbreakEditor"
      v-on:editor-error="
        closeOutbreakEditor();
        setError('There was an error creating the outbreak. Please click refresh to try again.');
      "
      v-on:editor-save="
        closeOutbreakEditor();
        getOutbreaks();
      "
      v-on:editor-manage-cases="manageInfectionCases"
    ></outbreak-editor>

    <!-- Resolve Outbreak -->
    <resolve-outbreak
      v-if="outbreakSection.resolve.visible"
      :outbreak="outbreakSection.resolve.data"
      v-on:resolve-close="cancelResolveOutbreak"
      v-on:resolve-error="
        cancelResolveOutbreak();
        setError('There was an error updating the outbreak. Please click refresh to try again.');
      "
      v-on:resolve-save="
        cancelResolveOutbreak();
        getOutbreaks();
      "
    ></resolve-outbreak>

    <!-- Manage Infection Cases -->
    <manage-infection-cases v-if="infectionCaseSection.manager.visible" :showOutbreakNav="true" :outbreak="infectionCaseSection.manager.data" v-on:manager-close="closeInfectionCaseManager" v-on:manager-go-to-outbreak="closeManagerAndGoToOutbreak(infectionCaseSection.manager.data)"></manage-infection-cases>

    <!-- Export Progress -->
    <progress-overlay v-if="outbreakSection.exportingOutbreaks" :progress="outbreakSection.exportProgress" v-on:progress-cancelled="cancelOutbreakExport" title="Download In Progress"></progress-overlay>

    <!-- Delete Outbreak -->
    <el-dialog title="Delete" v-model="outbreakSection.delete.visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      Delete this outbreak?
      <div class="dialog-footer">
        <el-button @click="cancelDeleteOutbreak">Cancel</el-button>
        <el-button type="primary" @click="deleteOutbreak">Confirm</el-button>
      </div>
    </el-dialog>

    <!-- Error Dialog -->
    <el-dialog title="Error, please try again" v-model="errorSection.visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      {{ errorSection.message }}
      <div class="dialog-footer">
        <el-button
          @click="
            errorSection.visible = false;
            errorSection.message = 'An error occurred. Please click refresh to try again.';
            getOutbreaks();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
import moment from "moment";
import ProgressOverlay from "../../components/ProgressBarOverlay";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";
import Icon from "../Shared/Icon";
import XLSX from "xlsx";
import Checklist from "./Checklist.vue";
import OutbreakEditor from "./EditOutbreak.vue";
import ResolveOutbreak from "./ResolveOutbreak.vue";
import ManageInfectionCases from "./ManageInfectionCases.vue";

export default {
  name: "outbreak-summary",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "outbreak-checklist": Checklist,
    "outbreak-editor": OutbreakEditor,
    "resolve-outbreak": ResolveOutbreak,
    "manage-infection-cases": ManageInfectionCases,
    Icon,
  },
  watch: {
    "outbreakSection.pagination.size"() {
      this.getOutbreaks();
    },
    "outbreakSection.pagination.page"() {
      this.getOutbreaks();
    },
  },
  data() {
    return {
      global: {
        filters: {},
        defaultDateStart: moment().subtract(3, "months").toDate(),
        facilities: [],
      },
      errorSection: {
        visible: false,
        message: "An error occurred. Please click refresh to try again.",
      },
      checklistSection: {
        outbreak: {},
        visible: false,
        updateErrorDialogue: false,
      },
      infectionCaseSection: {
        manager: {
          visible: false,
          data: {},
        },
      },
      outbreakSection: {
        editor: {
          visible: false,
          data: {},
          createErrorDialogue: false,
        },
        delete: {
          visible: false,
          id: null,
        },
        resolve: {
          data: {},
          visible: false,
        },
        exportingOutbreaks: false,
        exportProgress: 0,
        exportErrorDialog: false,
        loading: true,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        loadingErrorDialog: false,
        data: [],
      },
    };
  },
  methods: {
    getFilterKeys() {
      let filters = ["outbreakDeclaredDate", "basicInfectionSites", "causativeOrganisms", "outbreakStatus", "outbreakResolved"];
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "causativeOrganisms":
                k = "organisms";
                break;
              case "outbreakStatus":
                k = "confirmed";
                break;
              case "outbreakResolved":
                k = "resolved";
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      if (filterValues.resolved === "all") {
        delete this.global.filters.resolved;
      }
      this.global.filters.declaredDateRangeStart = moment(filterValues.outbreakDeclaredDate[0]).startOf("day").toDate().toISOString();
      this.global.filters.declaredDateRangeEnd = moment(filterValues.outbreakDeclaredDate[1]).endOf("day").toDate().toISOString();
      delete this.global.filters.outbreakDeclaredDate;

      if (filterValues.basicInfectionSites && !filterValues.basicInfectionSites.includes("All Infection Sites")) {
        this.global.filters.infectionSites = filterValues.basicInfectionSites;
      }
      delete this.global.filters.basicInfectionSites;
      this.getOutbreaks();
    },
    async getOutbreaks() {
      this.outbreakSection.loading = true;
      const body = {
        ...this.global.filters,
        page: this.outbreakSection.pagination.page,
        size: this.outbreakSection.pagination.size,
      };
      try {
        const result = await this.$http.post(`${window.CONFIG.om_api}/search`, body);
        this.outbreakSection.pagination.total = result.body.totalElements;
        this.outbreakSection.data = result.body.content.map(this.generateOutbreaksTableData);
      } catch (err) {
        console.error(err);
        this.setError("There was an error loading outbreaks. Please click refresh to try again");
        this.outbreakSection.pagination.page = 0;
        this.outbreakSection.pagination.totalElements = 0;
        this.outbreakSection.data = [];
      }
      this.outbreakSection.loading = false;
    },
    async getOutbreaksForExport() {
      const body = {
        ...this.global.filters,
        page: this.outbreakSection.pagination.page,
        size: this.outbreakSection.pagination.size,
      };
      return this.$http.post(`${window.CONFIG.om_api}/export`, body);
    },
    generateOutbreaksTableData(record) {
      let model = { ...record };
      model.declaredDateFormatted = record.declaredDate ? moment.utc(record.declaredDate).format("MM-DD-YYYY") : "";
      model.resolvedDateFormatted = record.resolvedDate ? moment.utc(record.resolvedDate).format("MM-DD-YYYY") : "";
      model.firstCaseDateFormatted = record.firstCaseDate ? moment(record.firstCaseDate).format("MM-DD-YYYY") : "";
      model.createdDateFormatted = moment(record.created).format(`${this.$configStore.dateFormat()} HH:mm`);
      model.updatedDateFormatted = record.updated ? moment(record.updated).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.daysInOutbreak = moment(model.resolvedDateFormatted || moment(new Date()).format("MM-DD-YYYY")).diff(moment(model.declaredDateFormatted), "days") + 1;
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.facId))[0].displayText;
      model.scopeFormatted = record.scope === "Facility" && this.global.facilities.length === 1 ? record.scope : `${record.scope} - ${record.scopeDetail}`;
      model.activeCases = record.staffActiveCases + record.residentActiveCases;
      model.hospitalizedCases = record.staffHospitalizedCases + record.residentHospitalizedCases;
      model.organismFormatted = record.organism.join(", ");
      return model;
    },
    manageInfectionCases(outbreak) {
      this.infectionCaseSection.manager.data = outbreak;
      this.infectionCaseSection.manager.visible = true;
    },
    closeInfectionCaseManager() {
      this.infectionCaseSection.manager.visible = false;
    },
    goToOutbreak(outbreak, event, column) {
      if (column?.property === "active") {
        return;
      }
      this.$router.push({ name: "OutbreakDetail", params: { outbreakId: outbreak.id } });
    },
    closeManagerAndGoToOutbreak(outbreak) {
      this.closeInfectionCaseManager();
      this.$nextTick(() => {
        this.$router.push({ name: "OutbreakDetail", params: { outbreakId: outbreak.id } });
      });
    },
    viewCheckList(outbreak) {
      this.checklistSection.outbreak = JSON.parse(JSON.stringify(outbreak));
      this.$nextTick(() => {
        this.checklistSection.visible = true;
      });
    },
    closeChecklist() {
      this.checklistSection.visible = false;
    },
    async updateChecklist(checklist) {
      this.outbreakSection.loading = true;
      this.checklistSection.visible = false;
      try {
        await this.$http.put(`${window.CONFIG.om_api}/${this.checklistSection.outbreak.id}/checklist`, checklist);
        await this.getOutbreaks();
      } catch (err) {
        this.setError("There was an updating the checklist. Please click refresh to try again");
      }
      this.outbreakSection.loading = false;
    },
    setError(message) {
      this.errorSection.message = message;
      this.errorSection.visible = true;
    },
    resolveOutbreak(outbreak) {
      this.outbreakSection.resolve.data = outbreak;
      this.outbreakSection.resolve.visible = true;
    },
    cancelResolveOutbreak() {
      this.outbreakSection.resolve.visible = false;
      this.outbreakSection.resolve.data = {};
    },
    createOutbreak() {
      this.outbreakSection.editor.title = "New Outbreak Record";
      this.outbreakSection.editor.data = {};
      this.outbreakSection.editor.visible = true;
    },
    editOutbreak(outbreak) {
      this.outbreakSection.editor.title = "Edit Outbreak";
      this.outbreakSection.editor.data = outbreak;
      this.outbreakSection.editor.visible = true;
    },
    closeOutbreakEditor() {
      this.outbreakSection.editor.data = null;
      this.outbreakSection.editor.visible = false;
    },
    showDeleteOutbreak(id) {
      this.outbreakSection.delete.id = id;
      this.outbreakSection.delete.visible = true;
    },
    cancelDeleteOutbreak() {
      this.outbreakSection.delete.id = null;
      this.outbreakSection.delete.visible = false;
    },
    async deleteOutbreak() {
      this.outbreakSection.delete.visible = false;
      this.outbreakSection.loading = true;
      try {
        await this.$http.delete(`${window.CONFIG.om_api}/${this.outbreakSection.delete.id}`);
        this.outbreakSection.delete.id = null;
        await this.getOutbreaks();
      } catch (err) {
        this.setError("There was an deleting the outbreak. Please click refresh to try again");
      }
      this.outbreakSection.loading = false;
    },
    async exportOutbreaks() {
      this.outbreakSection.exportProgress = 0;
      this.outbreakSection.exportingOutbreaks = true;
      const columns = [
        "Case Number",
        "Facility",
        "Scope",
        "Infection Site",
        "Causitive Organism",
        "Outbreak Status",
        "Facility Status",
        "First Case Date",
        "Date Declared",
        "Date Resolved",
        "Days in Outbreak",
        "Total # of Cases",
        "# of Resident Cases",
        "# of Confirmed Cases - Residents",
        "# of Active Cases - Residents",
        "# of Resident Deaths",
        "# of Resident Hospitalizations",
        "# of Staff Cases",
        "# of Confirmed Cases - Staff",
        "# of Active Cases - Staff",
        "# of Staff Deaths",
        "# of Staff Hospitalizations",
        "Case Definition",
        "Notes",
        "Created By",
        "Created Date",
        "Updated By",
        "Updated Date",
      ];
      const convertObjectsToRows = (obj) => {
        const record = this.generateOutbreaksTableData(obj);
        return [
          record.caseNumber,
          record.facility,
          record.scopeFormatted,
          record.typeValue,
          record.organismFormatted,
          record.confirmed ? "Confirmed" : "Suspected",
          record.facStatus,
          record.firstCaseDateFormatted,
          record.declaredDateFormatted,
          record.resolvedDateFormatted,
          record.daysInOutbreak,
          record.totalCases,
          record.residentCases,
          record.residentConfirmedCases,
          record.residentActiveCases,
          record.residentDeathCases,
          record.residentHospitalizedCases,
          record.staffCases,
          record.staffConfirmedCases,
          record.staffActiveCases,
          record.staffDeathCases,
          record.staffHospitalizedCases,
          record.caseDefinition,
          record.notes,
          record.createdBy,
          record.createdDateFormatted,
          record.updatedBy,
          record.updatedDateFormatted,
        ];
      };
      const result = await this.getOutbreaksForExport();
      this.createExcelFile("outbreaks.xlsx", columns, result.body.map(convertObjectsToRows));
      this.outbreakSection.exportProgress = 100;
      this.outbreakSection.exportingOutbreaks = false;
    },
    createExcelFile(fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelOutbreakExport() {},
  },
  created() {
    console.clear();
    this.canModify = auth.canModify();
    this.global.facilities = this.$configStore.data.sites;
  },
};
</script>

<style>
.outbreak-management .status-icon {
  width: 1.8em;
  height: 1.8em;
}
</style>