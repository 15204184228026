<template>
  <div class="section-header">
    <el-row>
      <span>{{ title }}</span>
      <div class="slotted-content">
        <slot></slot>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "section-title",
  props: {
    title: String
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.section-header > .el-row {
  padding: 10px;
  background: #7cc156;
  color: white;
  font-weight: bolder;
  display: flex;
  align-items: center;
}
.section-header > .el-row > .slotted-content {
  margin-left: auto;
}
</style>
<style>
.section-header > .el-row > .slotted-content {
  display: flex;
  align-items: center;
}
.section-header > .el-row > .slotted-content .label {
  font-size: 0.85rem;
  margin: 0 5px;
}
.section-header > .el-row > .slotted-content .gap {
  margin-left: 25px;
}
</style>
