<template>
  <div class="login">
    <el-row :gutter="40">
      <el-col class="input-boxes" :xs="{ span: 20, offset: 2 }" :sm="{ span: 16, offset: 4 }" :md="{ span: 10, offset: 6 }" :lg="{ span: 8, offset: 8 }">
        <div class="line"></div>
        <el-form label-width="100px">
          <el-form-item label="Username">
            <el-input v-model="username"></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input v-model="pass" type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="large" @click="login">Login</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import auth from "../auth";

export default {
  name: "login",
  data() {
    return {
      username: "",
      pass: "",
      error: false
    };
  },
  methods: {
    login() {
      auth.login(this.username, this.pass, loggedIn => {
        if (!loggedIn) {
          this.error = true;
        } else {
          // this.$router.replace(this.$route.query.redirect || '/')
          window.location = "/";
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  margin-top: 3em;
  margin-left: -180px;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.el-button {
  width: 100%;
}
</style>
