<template>
  <div class="thresholds-detail">
    <el-row class="filter-section">
      <label>
        <span class="filter-title-label">Type:</span>
        <el-select :disabled="!canModify" v-model="threshold.type" placeholder="Threshold Type" class="filter-section-input" disabled>
          <el-option label="Not Selected" :value="0"> </el-option>
          <el-option v-for="item in types" :key="item.text" :label="item.text" :value="item.value">
            <span style="float: left">{{ item.text }}</span>
          </el-option>
        </el-select>
      </label>
      <div v-if="threshold.scope !== 0">
        <label>
          <span class="filter-title-label">Scope:</span>
          <el-select :disabled="!canModify" v-model="threshold.scope" placeholder="Threshold Type" class="filter-section-input">
            <el-option v-for="item in scopes" :key="item.text" :label="item.text" :value="item.value">
              <span style="float: left">{{ item.text }}</span>
            </el-option>
          </el-select>
        </label>
        <div v-if="threshold.scope == 'Facility'">
          <label>
            <span class="filter-title-label">Facility:</span>
            <el-select :disabled="!canModify" v-model="threshold.site" placeholder="Facility" class="filter-section-input" v-on:change="update(selectedSite)" value-key="id">
              <el-option label="Not Selected" :value="0"> </el-option>
              <el-option v-for="item in organization.sites" :key="item.id" :label="item.displayText" :value="item"> </el-option>
            </el-select>
          </label>
        </div>
        <div v-if="threshold.scope == 'Unit'">
          <span class="filter-title-label">Unit:</span>
          <el-select :disabled="!canModify" v-model="threshold.unit" placeholder="Unit" class="filter-section-input" value-key="id">
            <el-option label="Not Selected" :value="0"> </el-option>
            <el-option v-for="item in allUnits()" :key="item.key" :label="item.displayText + ' - ' + item.site.displayText" :value="item"> </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="threshold.scope !== 0 && (threshold.site !== 0 || threshold.unit !== 0) && threshold.type == 'Organism'">
        <label>
          <span class="filter-title-label">Organisms:</span>
          <el-select :disabled="!canModify" v-model="threshold.organism" placeholder="Organisms" class="filter-section-input" value-key="id">
            <el-option v-for="item in organisms" :key="item.id" :label="item.name" :value="item"> </el-option>
          </el-select>
        </label>
      </div>
      <div v-if="threshold.scope !== 0 && (threshold.site !== 0 || threshold.unit !== 0) && threshold.type == 'InfectionSite'">
        <label>
          <span class="filter-title-label">Infection Site:</span>
          <el-select :disabled="!canModify" v-model="threshold.symptomType" placeholder="Infection Site" class="filter-section-input" value-key="id">
            <el-option v-for="item in symptomTypes" v-if="item !== 'Common'" :key="item.id" :label="item" :value="item"> </el-option>
          </el-select>
        </label>
      </div>
      <div>
        <br />
      </div>
    </el-row>
    <el-row>
      <el-col :span="5">
        <span class="filter-title-label">Days:</span>
      </el-col>
      <el-col :span="16" :offset="1">
        <el-slider :disabled="!canModify" v-model="threshold.days" :min="1" :max="31" :step="1" v-on:change="updateNumbers()" show-input> </el-slider>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="5">
        <span class="filter-title-label">Occurrence:</span>
      </el-col>
      <el-col :span="16" :offset="1">
        <el-slider :disabled="!canModify" v-model="threshold.occurrence" :min="1" :max="31" :step="1" v-on:change="updateNumbers()" show-input> </el-slider>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import auth from "../auth";

export default {
  name: "thresholds-detail",
  props: ["threshold", "organisms", "symptomTypes", "organization"],
  data: function() {
    return {
      canModify: false,
      types: [
        { text: "Infection Site", value: "InfectionSite" },
        { text: "Organism", value: "Organism" }
      ],
      scopes: [
        { text: "Facility", value: "Facility" },
        { text: "Unit", value: "Unit" }
      ],
      selectedSite: 0
    };
  },
  watch: {
    threshold: function(v) {
      console.log("threshold: " + v.site);

      var vm = this;
      // vm.$set(vm.threshold.site, v.site)
      if (this.threshold.scope === "Facility") {
        vm.organization.sites.forEach(function(s) {
          if (s.id === vm.threshold.site.id) {
            // vm.selectedSite = s
            vm.threshold.site = s;
          }
        });
      }

      if (this.threshold.organism) {
        vm.organisms.forEach(function(o) {
          if (o.id === vm.threshold.organism.id) {
            vm.threshold.organism = o;
          }
        });
      }

      if (this.threshold.unit) {
        vm.allUnits().forEach(function(u) {
          if (u.id === vm.threshold.unit.id) {
            vm.threshold.unit = u;
          }
        });
      }
    },
    organization: function(v) {
      console.log("organization: " + v);
    }
  },
  methods: {
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    update: function(v) {
      console.log(v);
    },
    allUnits: function() {
      return this.$configStore.data.units || [];
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.filter-title-label {
  width: 100px;
  display: inline-block;
}
</style>

<style>
.thresholds-detail .el-slider__button.el-tooltip {
  vertical-align: unset;
}
</style>
