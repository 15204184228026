export default {
    // IC API & Legacy API have some key differences in their infection case data models
    // in some places the infection cases are initially fetched from IC API but then used in requests to legacy endpoints
    mapInfectionCaseToLegacyModel: function (infectionCase) {
        const data = {
            ...infectionCase,
            onSetDate: infectionCase.onsetDate ? new Date(infectionCase.onsetDate).valueOf() : null,
            created: infectionCase.created ? new Date(infectionCase.created).valueOf() : null,
            updated: infectionCase.updated ? new Date(infectionCase.updated).valueOf() : null,
            resolutionDate: infectionCase.resolutionDate ? new Date(infectionCase.resolutionDate).valueOf() : null,
            isolationStart: infectionCase.isolationStart ? new Date(infectionCase.isolationStart).valueOf() : null,
            isolationEnd: infectionCase.isolationEnd ? new Date(infectionCase.isolationEnd).valueOf() : null,
            subtypeString: infectionCase.infectionType,
            clientId: infectionCase.client.id,
            resolved: infectionCase.outcome === "Resolved",
            status: infectionCase.status ? "CONFIRMED" : "SUSPECTED",
            confirmed: infectionCase.status,
        };
        data.client.photo = data.client.photoUrl;
        data.symptoms = infectionCase.symptoms.map((s) => {
            return { ...s, colorCode: String(s.colorCode) };
        });
        if (!data.client.precautionDetails) {
            data.client.precautionDetails = infectionCase.precautions.map((p) => {
                return { ...p, clientId: data.client.id, created: new Date(p.created).valueOf() };
            });
        }
        if (!data.outcome) {
            data.outcome = 0;
        }
        if (!data.origin) {
            data.origin = "Not Selected";
        }
        return data;
    }
}