<template>
  <div class="surveillance-biology">
    <div class="clearfix"></div>
    <el-row>
      <el-col :span="5">Primary Organism:</el-col>
      <el-col :span="16">
        <el-select v-model="primary" filterable placeholder="Not Selected" v-on:change="updateOrganism" value-key="id">
          <el-option label="Not Selected" v-bind:value="0"> </el-option>
          <el-option v-for="item in notSelected" :key="item.id" :label="item.name" :value="item">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">Secondary Organism:</el-col>
      <el-col :span="16">
        <el-select v-model="secondary" filterable placeholder="Not Selected" v-on:change="updateOrganism" value-key="id">
          <el-option label="Not Selected" v-bind:value="0"> </el-option>
          <el-option v-for="item in notSelected" :key="item.id" :label="item.name" :value="item">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">Tertiary Organism:</el-col>
      <el-col :span="16">
        <el-select v-model="tertiary" filterable placeholder="Not Selected" v-on:change="updateOrganism" value-key="id">
          <el-option label="Not Selected" v-bind:value="0"> </el-option>
          <el-option v-for="item in notSelected" :key="item.id" :label="item.name" :value="item">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">Quaternary Organism:</el-col>
      <el-col :span="16">
        <el-select v-model="quaternary" filterable placeholder="Not Selected" v-on:change="updateOrganism" value-key="id">
          <el-option label="Not Selected" v-bind:value="0"> </el-option>
          <el-option v-for="item in notSelected" :key="item.id" :label="item.name" :value="item">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "surveillance-biology",
  props: ["organisms", "caseData"],
  data: function() {
    return {
      primary: 0,
      secondary: 0,
      tertiary: 0,
      quaternary: 0
    };
  },
  computed: {
    notSelected: function() {
      return this.organisms.filter(organism => {
        return !(organism.id === this.primary.id || organism.id === this.secondary.id || organism.id === this.tertiary.id || organism.id === this.quaternary.id);
      });
    }
  },
  watch: {
    caseData: function(value) {
      this.updateSelection(value);
    }
  },
  methods: {
    updateSelection: function(value) {
      this.primary = 0;
      this.secondary = 0;
      this.tertiary = 0;
      this.quaternary = 0;

      var vm = this;
      var details = value.organismDetails || [];
      details.forEach(function(detail) {
        if (detail.type === "Primary") {
          vm.primary = vm.findOrganismById(detail.organism.id);
        } else if (detail.type === "Secondary") {
          vm.secondary = vm.findOrganismById(detail.organism.id);
        } else if (detail.type === "Tertiary") {
          vm.tertiary = vm.findOrganismById(detail.organism.id);
        } else if (detail.type === "Quaternary") {
          vm.quaternary = vm.findOrganismById(detail.organism.id);
        }
      });
      // console.log(JSON.stringify(value, ' ', 2))
      // console.log(JSON.stringify(value.organismDetails, ' ', 2))
    },
    findOrganismById: function(organismId) {
      for (var i = 0; i < this.organisms.length; i++) {
        if (this.organisms[i].id === organismId) {
          return this.organisms[i];
        }
      }

      return 0;
    },
    updateOrganism: function() {
      var newDetails = [];
      var details = this.caseData.organismDetails || [];
      var primaryDetail;
      var secondaryDetail;
      var tertiaryDetail;
      var quaternaryDetail;
      details.forEach(function(detail) {
        if (detail.type === "Primary") {
          primaryDetail = detail;
        } else if (detail.type === "Secondary") {
          secondaryDetail = detail;
        } else if (detail.type === "Tertiary") {
          tertiaryDetail = detail;
        } else if (detail.type === "Quaternary") {
          quaternaryDetail = detail;
        }
      });

      if (this.primary !== 0) {
        if (primaryDetail) {
          primaryDetail.organism = this.primary;
        } else {
          primaryDetail = { type: "Primary", organism: this.primary };
        }
        newDetails.push(primaryDetail);
      }
      if (this.secondary !== 0) {
        if (secondaryDetail) {
          secondaryDetail.organism = this.secondary;
        } else {
          secondaryDetail = { type: "Secondary", organism: this.secondary };
        }
        newDetails.push(secondaryDetail);
      }
      if (this.tertiary !== 0) {
        if (tertiaryDetail) {
          tertiaryDetail.organism = this.tertiary;
        } else {
          tertiaryDetail = { type: "Tertiary", organism: this.tertiary };
        }
        newDetails.push(tertiaryDetail);
      }
      if (this.quaternary !== 0) {
        if (quaternaryDetail) {
          quaternaryDetail.organism = this.quaternary;
        } else {
          quaternaryDetail = { type: "Quaternary", organism: this.quaternary };
        }
        newDetails.push(quaternaryDetail);
      }

      this.caseData.organismDetails = newDetails;
      // console.log(JSON.stringify(this.caseData.organismDetails, ' ', 2))
    }
  },
  mounted: function() {
    console.log("mounted");
    // console.log(JSON.stringify(this.caseData, ' ', 2))
    this.updateSelection(this.caseData);
  }
};
</script>
<style scoped>
.surveillance-biology .el-select {
  width: 100%;
}
.el-row {
  line-height: 3em;
}
</style>
