<template>
  <div class="surveillance-case-detail" v-if="infectionCase">
    <div class="dialog-case-detail">
      <div slot="header" class="clearfix">
        <el-col :span="5"><span>Name: </span></el-col>
        <el-col :span="14">{{ clientName() }} ({{ isStaff(clientData) ? clientData.staffId : $configStore.patientIdType() ? clientData[$configStore.patientIdType()] : clientData.patientId }})</el-col>
        <div class="profile-photo" v-if="clientData">
          <img :src="userPhoto()" />
        </div>
        <div class="profile-photo" v-else><img src="../assets/user.png" /></div>
      </div>
      <div class="clearfix"></div>
      <el-row>
        <el-col :span="5"><span>Infection Site: </span></el-col>
        <el-col :span="16">
          <span class="color-code-label-0" v-if="infectionCase.symptomType === 'GI'"></span>
          <span class="color-code-label-2" v-else-if="infectionCase.symptomType === 'UTI'"></span>
          <span class="color-code-label-1" v-else-if="infectionCase.symptomType === 'Respiratory'"></span>
          <span class="color-code-label-3" v-else></span>
          {{ caseData.symptomType || caseData.infectionSite }}
          <span v-if="subTypeOptions()"
            >/
            <el-select v-model="caseData.subtypeString" placeholder="Type" :clearable="true" :disabled="!canModify">
              <el-option v-if="typeDesc" v-for="item in typeDesc.subTypes" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="5">Origin:</el-col>
        <el-col :span="16">
          <el-select v-model="infectionCase.origin" placeholder="Select" v-on:change="showSelection" :disabled="!canModify">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in origins" :label="item.label" :key="item.value" :value="item.value"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- <el-row>
            <el-col :span="5">Onset Location:</el-col>

            <el-col :span="16" v-if="beds[infectionCase.bedId]">
                {{ beds[infectionCase.bedId] ? beds[infectionCase.bedId].fullDesc : 'Unknown' }} (<a class="link-button" v-on:click="$emit('view-history')">View Location History</a>)
                <el-select v-model="infectionCase.bedId" placeholder="Select" v-on:change="showSelection" value-key="timestamp">
                      <el-option
                      v-for="item in locations"
                      :label="beds[item.bedId].fullDesc"
                      :key="item.timestamp"
                      :value="item.bedId">
                      </el-option>
                </el-select>
              </el-col>
          </el-row> -->
      <el-row>
        <el-col :span="5">Onset Date:</el-col>
        <el-col :span="16">
          <el-date-picker v-model="infectionCase.onSetDate" type="date" :disabled="!canModify" editable :clearable="false" :format="$configStore.datePickerDateFormat()" :picker-options="pickerOptions"> </el-date-picker>
        </el-col>
      </el-row>
      <el-row v-if="typeDesc && typeDesc.requireSymptoms">
        <el-col :span="5">Symptoms:</el-col>
        <el-col :span="16">
          <el-tag v-for="d in sortedSymptom(infectionCase.symptoms)" :key="d.displayText" :type="tagType(d)">{{ d.displayText }}</el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Confirmed:</el-col>
        <el-col :span="10">
          <el-switch :disabled="!canModify" v-model="infectionCase.confirmed" on-text="Yes" off-text="No" on-color="#13ce66" off-color="#ff4949"> </el-switch>
          <img class="symptom-detail-status-image" :src="statusImage(infectionCase)" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Primary Organism:</el-col>
        <el-col :span="16">
          {{ infectionCase.primaryOrganism || "Not Selected" }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Treatment:</el-col>
        <el-col :span="16">
          {{ (infectionCase.prescriptions || []).join("; ") }}
        </el-col>
      </el-row>
      <el-row v-if="clientData">
        <el-col :span="5">Precautions:</el-col>
        <el-col :span="16">
          <span v-if="!clientData.precautionDetails || clientData.precautionDetails.length === 0">Not Entered</span>
          <el-tag v-if="precautionById(d.precautionId)" :key="d.precautionId" v-for="d in clientData.precautionDetails">{{ precautionById(d.precautionId) }}</el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="12" :sm="8" :md="6" :lg="5">Notification:</el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="4"><el-checkbox :disabled="!canModify" v-model="infectionCase.familyNotified">Family Notified</el-checkbox></el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="4"><el-checkbox :disabled="!canModify" v-model="infectionCase.nextOfNotified">Manager Notified</el-checkbox></el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="6"><el-checkbox :disabled="!canModify" v-model="infectionCase.physicianNotified">Public Health/Physician Notified</el-checkbox></el-col>
        <el-col v-if="isAdminStaff()" :xs="12" :sm="8" :md="6" :lg="4"><el-checkbox :disabled="!canModify" v-model="infectionCase.staffCaseNotified">Staff case reported to MoL</el-checkbox></el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Contact:</el-col>
        <el-col :span="16">
          <el-select v-model="infectionCase.contact" placeholder="Select" v-on:change="showSelection" :disabled="!canModify">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in contacts" :label="item" :key="item" :value="item"> </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row v-if="isAdminStaff() && clientData.clientType == 'Staff'">
        <el-col :span="5">Staff Isolation:</el-col>
        <el-col :span="16">
          <el-select v-model="infectionCase.staffIsolation" placeholder="Select" v-on:change="showSelection" :disabled="!canModify">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in $configStore.staffIsolation()" :label="item" :key="item" :value="item"> </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row v-if="isAdminStaff() && clientData.clientType == 'Staff'">
        <el-col :span="5">Isolation Start:</el-col>
        <el-col :span="16">
          <el-date-picker v-model="infectionCase.isolationStart" type="date" :disabled="!canModify" editable :clearable="false" :format="$configStore.datePickerDateFormat()"> </el-date-picker>
        </el-col>
      </el-row>

      <el-row v-if="isAdminStaff() && clientData.clientType == 'Staff'">
        <el-col :span="5">Isolation End:</el-col>
        <el-col :span="16">
          <el-date-picker v-model="infectionCase.isolationEnd" type="date" :disabled="!canModify" editable :clearable="false" :format="$configStore.datePickerDateFormat()"> </el-date-picker>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import auth from "../auth";
import moment from "moment";

export default {
  name: "surveillance-case-detail",
  props: ["caseData", "clientData", "origins", "organisms", "treatments", "precautions", "symptomTypes", "beds", "locations"],
  data: function () {
    return {
      canModify: false,
      infectionCase: null,
      isLinkedToOutbreak: false,
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4",
      },
      contacts: ["Direct Contact", "Indirect Contact", "No Contact"],
      pickerOptions: {
        disabledDate(input) {
          // disable dates after current date
          const currentDate = moment().startOf("day");
          const datepickerDate = moment(input).startOf("day");
          return datepickerDate.isAfter(currentDate);
        },
      },
      subStatusMap: {},
      typeDesc: {
        subTypes: [],
        requireSymptoms: false,
      },
    };
  },
  watch: {
    clientData: function (value) {
      // console.log('New ClientData: ' + JSON.stringify(value, ' ', 2))
      console.log(this.origins);
    },
    symptomTypes: function (value) {
      var vm = this;
      value.forEach(function (s) {
        vm.subStatusMap[s.type] = s;
      });
    },
    infectionCase: function (value) {
      if (value.origin === "0") {
        this.infectionCase.origin = parseInt(value.origin);
      }

      if (value.contact === "0") {
        this.infectionCase.contact = parseInt(value.contacts);
      }

      if (value.staffIsolation === "0") {
        this.infectionCase.staffIsolation = 0;
      }
      this.$emit("infection-case-change", this.infectionCase);
      console.log(value.contact);
    },
  },
  methods: {
    isStaff: function (client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    isAdminStaff: function () {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    sortedSymptom(symptoms) {
      // <el-tag v-for="d in scope.row.symptoms
      if (!symptoms || symptoms.length <= 1) {
        return symptoms;
      }

      console.log(JSON.stringify(symptoms, " ", 2));
      // classType: "GI"
      // colorCode: "0"
      // displayText: "Diarrhea"
      let sortedSymptoms = [];
      symptoms.forEach((s) => {
        sortedSymptoms.push(s);
      });
      sortedSymptoms.sort(function (a, b) {
        let compA = a.classType || "";
        let compB = b.classType || "";

        if (compA === "GI") compA = "a" + a.displayText;
        else if (compA === "Respiratory") compA = "b" + a.displayText;
        else if (compA === "UTI") compA = "c" + a.displayText;
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a" + b.displayText;
        else if (compB === "Respiratory") compB = "b" + b.displayText;
        else if (compB === "UTI") compB = "c" + b.displayText;
        else compB = "d" + b.displayText;

        let x = compA.toLowerCase();
        let y = compB.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      return sortedSymptoms;
    },
    showSelection: function (v) {
      console.log("showSelection");
      console.log(v);
    },
    checkEmptyPrecautions: function (details) {
      var vm = this;
      var emptyCounter = 0;
      details.forEach(function (p) {
        if (!vm.precautionById(p) || vm.precautionById(p) === "") {
          emptyCounter++;
        }
      });

      return emptyCounter >= details.length;
    },
    subTypeOptions: function () {
      var vm = this;
      this.symptomTypes.forEach(function (sType) {
        // console.log(sType.type)
        vm.subStatusMap[sType.type] = sType;
      });
      // console.log(JSON.stringify(this.subStatusMap, ' ', 2))
      if (this.caseData) {
        console.log(this.caseData);
        this.typeDesc = this.subStatusMap[this.caseData.symptomType || this.caseData.infectionSite];
        // console.log(this.typeDesc)
        // console.log(JSON.stringify(this.subStatusMap, ' ', 2))
        return this.typeDesc && this.typeDesc.subTypes && this.typeDesc.subTypes.length > 0;
      }

      return false;
    },
    clientName: function () {
      var firstName = this.clientData.firstName ? this.clientData.firstName + " " : "";
      var middleName = this.clientData.middleName ? this.clientData.middleName + " " : "";
      var lastName = this.clientData.lastName ? this.clientData.lastName : "";

      return firstName + middleName + lastName;
    },
    userPhoto: function () {
      var f = this.clientData.photo;
      if (f && f.indexOf("/") !== -1) {
        // console.log(window.CONFIG.api + '/' + f)
        return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
      }

      return "/static/" + (this.clientData.photo ? this.clientData.photo : "user") + ".png";
    },
    tagType: function (type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    statusImage: function (detail) {
      var result = "";
      var filename;
      if (detail.symptomType) {
        filename = this.statusImageMap[detail.symptomType];
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      if (result === "") {
        filename = "bug4";
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      return result;
    },
    precautionById: function (precautionId) {
      var name = "";
      // console.log(this.precautions)
      this.precautions.forEach(function (p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    treatmentDescs: function () {
      let result = "";
      this.infectionCase.treatmentDetails.forEach((d) => {
        // if (d.endDate) {
        //   return
        // }

        if (result.length > 0) {
          result = result + ", ";
        }
        if (d.customTreatment) {
          result = result + d.customTreatment;
        } else if (d.treatment && d.treatment.displayText) {
          result = result + d.treatment.displayText;
        }
      });

      if (result === "") {
        result = "Not Selected";
      }
      return result;
    },
    created: function () {
      console.clear();
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    this.infectionCase = { ...this.caseData };
    // this.infectionCase.origin = 0
    // this.symptomTypes = this.symptomTypes || []
    console.log(this);
    this.typeDesc = {
      subTypes: [],
      requireSymptoms: false,
    };
    if (this.infectionCase.origin === "0") {
      this.infectionCase.origin = parseInt(this.infectionCase.origin);
    }

    if (this.infectionCase.contact === "0" || this.infectionCase.contact === null) {
      this.infectionCase.contact = 0;
    }

    if (this.infectionCase.staffIsolation === "0" || this.infectionCase.staffIsolation === null) {
      this.infectionCase.staffIsolation = 0;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.surveillance-case-detail {
  position: relative;
}

div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

div.profile-photo {
  /* width: 10em;
    height: 10em;
    border-radius: 50%;
    position: absolute;
    right: 2.5em;
    top: 0.5em;
    display: block;
    overflow: hidden;
    border-radius: 50%; */
  width: 10em;
  height: 10em;
  border-radius: 50%;
  overflow: hidden;
  border-radius: 50%;
  float: right;
  position: absolute;
  right: 10px;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
}

.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.link-button {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
</style>
