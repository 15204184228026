<template>
  <div class="surveillance-location">
    <el-table :data="filteredLocation">
      <el-table-column label="Bed" width="200">
        <template scope="scope">
          {{ scope.row.bed.fullDesc ? scope.row.bed.fullDesc : "Unknown" + " " + scope.row.bedId }}
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template scope="scope">
          {{ scope.row.action }}
        </template>
      </el-table-column>
      <el-table-column width="220" label="Updated">
        <template scope="scope">
          {{ formatDateTime(scope.row.timestamp) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "surveillance-location",
  props: ["locations", "beds"],
  data: function () {
    return {
      deviceInfo: [
        {
          label: "Catheter",
        },
        {
          label: "Ventilator",
        },
        {
          label: "Central Line",
        },
        {
          label: "IV Used",
        },
      ],
      filteredLocation: [],
    };
  },
  watch: {
    locations: function (v) {
      this.filterLocation(Object.assign([], v));
    },
  },
  methods: {
    filterLocation: function (v) {
      console.log("locations updated: ", v);
      let filtered = [];
      if (v && v.length > 0) {
        v.sort((a, b) => {
          return (a.timestamp || 0) - (b.timestamp || 0);
        });
        filtered = [];
        let desc;
        for (let b of v) {
          let bed = b.bed.fullDesc ? b.bed.fullDesc : "Unknown" + " " + b.bedId;
          let action = b.action === "Updated" || b.action === "Assigned" ? "Assigned" : b.action;
          let newDesc = "" + bed + action;
          console.log(newDesc);
          if (desc === undefined || desc !== newDesc) {
            filtered.push(b);
          }
          desc = newDesc;
        }
      }

      filtered.sort((a, b) => {
        return (b.timestamp || 0) - (a.timestamp || 0);
      });

      this.filteredLocation = filtered;
    },
    formatDateTime(timestamp) {
      let dateFormat = this.$configStore.dateFormat();
      let dateTimeFormat = `${dateFormat} HH:mm:ss`;
      return moment(timestamp).format(dateTimeFormat);
    },
  },
  mounted() {
    this.filterLocation(this.locations);
  },
};
</script>
<style scoped></style>
