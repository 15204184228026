<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" title="Unresolve Infection Case" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :size="dialogSize">
      <p style="color: red">Are you sure you want to reopen this Infection Case?</p>
      <div class="dialog-footer">
        <el-row>
          <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button v-if="formVisibility" type="primary" @click="commitForm()" :disabled="dialogBusy">Confirm</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "unresolve-infection-case",
  props: ["infectionCase"],
  data() {
    return {
      dialogSize: "small",
      formVisibility: true,
      dialogVisibility: true,
      dialogBusy: true,
    };
  },
  watch: {
    dialogVisibility(val) {
      if (!val) {
        this.cancelForm();
      }
    },
  },
  methods: {
    cancelForm() {
      this.$emit("unresolve-cancel");
    },
    async commitForm() {
      try {
        this.dialogBusy = true;
        // unresolve case
        await this.$http.delete(`${window.CONFIG.infection_api}/${this.infectionCase.id}/resolve`);
        this.dialogBusy = false;
        this.$emit("unresolve-save");
      } catch (error) {
        this.$emit("unresolve-error");
      }
    },
  },
  async created() {
    this.dialogBusy = false;
  },
  mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  },
};
</script>