<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" :title="dialogTitle" :top="'5%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" label-width="150px">
        <el-form-item label="Precaution" prop="precautionId" :rules="[{ required: true, message: 'This field is required' }]">
          <el-select v-model="formModel.precautionId" filterable>
            <el-option v-for="item in precautions" :key="item.id" :label="item.displayText" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Start Date" prop="startDate" :rules="[{ required: true, message: 'This field is required' }]">
          <el-date-picker v-model="formModel.startDate" type="datetime" placeholder="Select date and time" :format="$configStore.datePickerDateFormat()" value-format="yyyy-MM-ddTHH:mm:ss"></el-date-picker>
        </el-form-item>

        <el-form-item label="End Date" prop="endDate">
          <el-date-picker v-model="formModel.endDate" type="datetime" placeholder="Select date and time" @chage="validateTime(scope.row)" :format="$configStore.datePickerDateFormat()" value-format="yyyy-MM-ddTHH:mm:ss" :picker-options="endDatePickerOptions"></el-date-picker>
        </el-form-item>

        <div class="dialog-footer">
          <el-button @click="cancelForm" :disabled="dialogBusy"> Cancel </el-button>
          <el-button type="primary" @click="saveForm" :disabled="dialogBusy"> Save </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "treatment-editor",
  props: ["precautions", "precautionDetail", "infectionCase"],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      formData: {
        medications: [],
        dosages: [],
        frequencies: [],
        routes: [],
        providers: [],
      },
      isNew: true,
      formModel: {
        precautionId: null,
        clientId: null,
        symptomDetailId: null,
        medicationId: null,
        startDate: "",
        endDate: null,
      },
    };
  },
  computed: {
    client() {
      // use precautionDetail's client if available, otherwise fallback to infectionCase
      return this.precautionDetail?.client || this.infectionCase.client;
    },
    infectionDetails() {
      // use precautionDetail's infectionCase if available, otherwise fallback to infectionCase
      return this.precautionDetail?.infectionCase || this.infectionCase;
    },
    dialogTitle() {
      return this.client ? `${this.client.firstName} ${this.client.lastName}` : "Precaution Detail";
    },
    endDatePickerOptions() {
      const start = moment(this.formModel.startDate);
      return {
        disabledDate: (time) => {
          const current = moment(time);
          // disable dates before the start date
          return current.startOf("day").isBefore(start.startOf("day"));
        },
      };
    },
  },

  methods: {
    editPrecautionDetail(precautionDetail) {
      this.isNew = false;
      this.formModel = { ...this.formModel, ...precautionDetail };
    },
    createPrecautionDetail() {
      this.isNew = true;
      this.formModel = {
        ...this.formModel,
        clientId: this.client.id,
        symptomDetailId: this.infectionDetails.id,
      };
    },
    cancelForm() {
      this.$emit("editor-close");
    },
    saveForm() {
      this.dialogBusy = true;
      const formData = { ...this.formModel };
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          if (this.isNew) {
            await this.$http
              .post(`${window.CONFIG.precaution_api}`, JSON.stringify(formData))
              .then(() => {
                this.$emit("editor-save");
              })
              .catch(() => {
                this.$message.error("There was error processing this information");
                this.dialogBusy = false;
              });
          } else {
            await this.$http
              .patch(`${window.CONFIG.precaution_api}/${formData.id}`, JSON.stringify(formData))
              .then(() => {
                this.$emit("editor-save");
              })
              .catch(() => {
                this.$message.error("There was error processing this information");
                this.dialogBusy = false;
              });
          }
        } else {
          this.dialogBusy = false;
        }
      });
    },
  },
  async created() {
    console.log(this.precautionDetail);
    console.log(this.precautions);
    if (this.precautionDetail) {
      this.editPrecautionDetail(this.precautionDetail);
    } else {
      this.createPrecautionDetail();
    }
    this.dialogBusy = false;
  },
  async mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  },
};
</script>
