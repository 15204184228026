<template>
  <div class="outbreak-checklist" ref="checklist">
    <span class="action-buttons-left">
      <el-button class="not-printable" @click="printReport">Print Report</el-button>
    </span>
    <span class="action-buttons-right">
      <el-button class="not-printable" @click="closeChecklist">Cancel</el-button>
      <el-button class="not-printable" :disabled="!canModify" @click="saveChecklist" type="primary">Update</el-button>
    </span>

    <el-table :data="checklist">
      <el-table-column label="Checklist Items">
        <template scope="scope">
          <div class="linebreak">{{ scope.$index + 1 }}. {{ scope.row.text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Completed" width="130">
        <template scope="scope">
          <el-switch v-on:change="toggleDate(scope.row)" :disabled="!canModify" class="not-printable" v-model="scope.row.checked" on-color="#13ce66" off-color="#ff4949" on-text="Yes" off-text="No"> </el-switch>
          <span class="printable">
            {{ scope.row.checked ? "Yes" : "No" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Completed Date" width="220">
        <template scope="scope">
          <el-date-picker v-on:change="toggleChecked(scope.row)" :disabled="!canModify" class="not-printable" v-model="scope.row.checkedDate" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Completed Date"> </el-date-picker>
          <span class="printable" v-if="scope.row.checkedDate">
            {{ scope.row.checkedDate | moment($configStore.dateFormat()) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Completed By" width="130">
        <template scope="scope">
          {{ scope.row.checkedBy }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import auth from "../../auth";

export default {
  name: "outbreak-checklist",
  props: ["outbreak"],
  watch: {
    outbreak: function (o) {
      this.checklist = JSON.parse(JSON.stringify(o.checklist || [])).sort((a, b) => a.id - b.id);
    },
  },
  data() {
    return {
      canModify: false,
      checklist: [],
    };
  },
  methods: {
    printReport() {
      var styles = `.el-table__header-wrapper {
            text-align: left;
        }

        table {
            width: 100% !important;
            line-height: 2em;
        }

        .cell {
            text-align: left;
        }

        .not-printable {
          display: none;
        }

        tr.sum-row td {
            margin-top: 0.5em;
            border-top: 1pt solid black !important;
        }

        td {
          padding: 0.2em;
        }
        
        .action-buttons {
          display: none;
        }

        span {
            font-weigt: bold;
            padding-bottom: 0.5em;
            display: block;
        }`;
      const body = this.$refs.checklist.innerHTML;
      const title = "<h2>Outbreak Checklist Report</h2>";
      var printWindow = window.open(title);
      printWindow.document.write("<style>" + styles + "</style><body>" + title + body + "</body>");
      printWindow.document.close();
      printWindow.window.focus();
      printWindow.print();
      printWindow.close();
    },
    toggleChecked(item) {
      item.checked = item.checkedDate ? true : null;
    },
    toggleDate(item) {
      item.checkedDate = item.checked ? item.checkedDate : null;
    },
    saveChecklist() {
      this.$emit("checklist-save", this.checklist);
    },
    closeChecklist() {
      this.$emit("checklist-close");
    },
  },
  created: function () {
    this.canModify = auth.canModify();
    this.checklist = window.structuredClone(this.outbreak.checklist || []).sort((a, b) => a.id - b.id);
  },
};
</script>

<style scoped>
.printable {
  display: none;
}
.action-buttons-left {
  float: left;
  padding: 1em;
  padding-right: 0;
  margin: 20px;
  margin-left: 0;
}
.action-buttons-right {
  float: right;
  padding: 1em;
  padding-right: 0;
  margin: 20px;
}
.linebreak {
  white-space: pre-line;
}
</style>
