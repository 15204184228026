<template>
  <div class="inf-threshold" v-loading.body="loading">
    <div class="filter">
      <div class="section-header">
        <el-row><span>Infection Thresholds</span> </el-row>
      </div>
      <!-- FILTER -->
      <el-row class="filter-section">
        <label>
          <span class="filter-title-label">Type:</span>
          <el-select v-model="selectedType" placeholder="Threshold Type" class="filter-section-input" v-on:change="reload">
            <el-option label="Not Selected" :value="0"> </el-option>
            <el-option v-for="item in thresholdTypes" :label="item.text" :value="item.value">
              <span style="float: left">{{ item.text }}</span>
            </el-option>
          </el-select>
        </label>
        <label v-if="selectedType == 'InfectionSite'">
          <span class="filter-title-label">Infection Site:</span>
          <el-select v-model="selectedSite" placeholder="Infection Site" class="filter-section-input" v-on:change="reload" value-key="id">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in symptomTypes" v-if="item !== 'Common'" :label="item" :value="item"> </el-option>
          </el-select>
        </label>
        <label v-if="selectedType == 'Organism'">
          <span class="filter-title-label">Organisms:</span>
          <el-select v-model="selectedOrganism" placeholder="Organisms" class="filter-section-input" v-on:change="reload">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in organisms" :label="item.name" :value="item.name"> </el-option>
          </el-select>
        </label>
      </el-row>
      <div class="section-header">
        <el-row
          ><span>Thresholds</span>
          <div class="case-filter">
            <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="createNewThreshold()"> + New</el-button>
            <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="deleteAllThreshold()"> Delete All</el-button>
            <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportExcel()">Export Data</el-button>
          </div>
        </el-row>
      </div>
      <el-table :data="thresholds" v-on:row-click="selectedRow">
        <el-table-column label="Scope" width="200">
          <template scope="scope">
            {{ scope.row.scope }}
          </template>
        </el-table-column>
        <el-table-column label="Description">
          <template scope="scope">
            {{ thresholdDesc(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column align="right" label="Occurrence" width="200">
          <template scope="scope">
            {{ scope.row.occurrence }}
          </template>
        </el-table-column>
        <el-table-column align="right" label="Day(s)" width="200">
          <template scope="scope">
            {{ scope.row.days }}
          </template>
        </el-table-column>
        <el-table-column prop="active" width="100">
          <template scope="scope">
            <el-button :disabled="!canModify" @click="deleteThreshold(scope.$index, scope.row)" icon="delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="Threshold" v-model="thresholdDialog" class="threshold-dialog">
      <thresholds-details :organization="organization[0]" :organisms="organisms" :symptomTypes="symptomTypes" :threshold="newThreshold" v-on:change="updatedNewThreshold" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="createThreashold(newThreshold)" :disabled="!validThreshold(newThreshold) || !canModify">Create</el-button>
        <el-button
          @click="
            newThreshold = {};
            thresholdDialog = false;
          "
          >Cancel</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="Threshold" v-model="updateThresholdDialog" class="threshold-dialog">
      <thresholds-detail :organization="organization[0]" :organisms="organisms" :symptomTypes="symptomTypes" :threshold="selectedThreshold" v-on:change="updatedExisitingThreshold" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateThreashold(selectedThreshold)" :disabled="!validUpdateThreshold(selectedThreshold) || !canModify">Update</el-button>
        <el-button @click="updateThresholdDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ThresholdDetails from "../components/ThresholdDetails";
import ThresholdDetail from "../components/ThresholdDetail";
import auth from "../auth";

export default {
  name: "inf-threshold",
  components: {
    "thresholds-details": ThresholdDetails,
    "thresholds-detail": ThresholdDetail
  },
  props: [],
  data: function() {
    return {
      canModify: false,
      loading: false,
      organization: [],
      symptomTypes: [],
      units: [],
      beds: [],
      types: [],
      organisms: [],
      thresholds: [],
      selectedType: 0,
      newThreshold: {},
      selectedThreshold: null,
      thresholdTypes: [
        { text: "Infection Site", value: "InfectionSite" },
        { text: "Organisms", value: "Organism" }
      ],
      selectedSite: 0,
      selectedOrganism: 0,
      thresholdDialog: false,
      updateThresholdDialog: false
    };
  },
  watch: {
    selectedType: function(value) {
      if (this.selectedType !== value) {
        this.selectedSite = 0;
        this.selectedOrganism = 0;
      }
    }
  },
  methods: {
    updatedNewThreshold: function(v) {
      console.log("updatedNewThreshold");
      console.log(v);

      this.newThreshold = v;
    },
    updatedExisitingThreshold: function(v) {
      console.log("updatedExisitingThreshold");
      this.selectedThreshold = v;
    },
    validThreshold: function(v) {
      if (!v) {
        return false;
      }
      if (v.type === "InfectionSite" && v.symptomType.length > 0) {
        return true;
      } else if (v.type === "Organism" && v.organism.length > 0) {
        return true;
      }
      return false;
    },
    validUpdateThreshold: function(v) {
      if (!v) {
        return false;
      }
      if (v.type === "InfectionSite" && v.symptomType !== 0) {
        return true;
      } else if (v.type === "Organism" && v.organism !== 0) {
        return true;
      }
      return false;
    },
    createNewThreshold: function() {
      this.newThreshold = null;
      this.thresholdDialog = true;
    },
    selectedRow: function(row, event, column) {
      if (column.property === "active") {
        return;
      }

      this.selectedThreshold = JSON.parse(JSON.stringify(row));
      // console.log(this.selectedThreshold)
      this.updateThresholdDialog = true;
      var vm = this;
      vm.$nextTick(function() {
        vm.selectedThreshold = JSON.parse(JSON.stringify(row));
      });
    },
    updateThreashold: function(t) {
      var vm = this;
      this.loading = true;
      var th = JSON.parse(JSON.stringify(t));
      this.$http.put(window.CONFIG.api + "/threshold/1/update", th).then(
        response => {
          vm.updateThresholdDialog = false;
          vm.loadThresholds();
        },
        response => {
          console.error(response);
          vm.loadThresholds();
        }
      );
    },
    exportExcel: function() {
      if (this.selectedType <= 0) {
        return;
      }

      var vm = this;
      var urlQuery = "?type=" + this.selectedType;
      if (vm.selectedType === "InfectionSite" && vm.selectedSite !== 0) {
        urlQuery = urlQuery + "&infectionSite=" + vm.selectedSite;
      } else if (vm.selectedType === "Organism" && vm.selectedOrganism !== 0) {
        console.log(vm.selectedOrganism);
        urlQuery = urlQuery + "&organism=" + vm.selectedOrganism;
      }

      vm.loading = true;
      this.$http.get(window.CONFIG.api + "/threshold/1/report" + urlQuery, { responseType: "arraybuffer" }).then(response => {
        console.log(JSON.stringify(response, " ", 2));
        var headers = response.headers.map;
        var blob = new window.Blob([response.data], { type: headers["content-type"] });

        // IE
        if (window.navigator.msSaveOrOpenBlob) {
          console.log("IE detected");
          window.navigator.msSaveOrOpenBlob(blob, "report.xls");
          setTimeout(function() {
            vm.loading = false;
          }, 100);
        } else {
          // Chrome and safari
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report.xls";
          link.click();
          vm.loading = false;
        }
      });
    },
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    thresholdDesc: function(threshold) {
      var site = threshold.site ? threshold.site.displayText : "";
      var location = site === "" ? threshold.unit.displayText : site;
      if (threshold.type === "InfectionSite") {
        return location + " - " + threshold.symptomType;
      } else if (threshold.type === "Organism") {
        var organism = threshold.organism.name;
        return location + " - " + organism;
      }
    },
    loadThresholdTypes: function() {
      var vm = this;

      this.$http.get(window.CONFIG.api + "/threshold/types").then(response => {
        vm.types = [];
        var newTypes = response.body;
        if (newTypes && newTypes.length > 0) {
          for (var i = 0; i < newTypes.length - 1; i++) {
            var s = {};
            s.text = newTypes[i];
            s.value = i + 1;
            vm.types.push(s);
          }
          // console.log(JSON.stringify(newTypes, ' ', 2))
        }
        vm.loadSymptomTypes();
      });
    },
    loadSymptomTypes: function() {
      var vm = this;

      this.$http.get(window.CONFIG.api + "/symptoms/classtypes").then(response => {
        vm.symptomTypes = response.body;
        vm.loadOrganisms();
      });
    },
    loadOrganisms: function() {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/organisms/").then(
        response => {
          vm.organisms = response.body["_embedded"].organisms;
          vm.loadThresholds();
        },
        response => {
          // error callback
        }
      );
    },
    loadThresholds: function() {
      console.log("loadThresholds");
      if (this.selectedType === 0) {
        this.loading = false;
        this.thresholds = [];
        return;
      }
      var vm = this;
      vm.loading = true;
      var url = window.CONFIG.api + "/threshold/1/list?type=" + this.selectedType;
      if (vm.selectedType === "InfectionSite" && vm.selectedSite !== 0) {
        url = url + "&infectionSite=" + vm.selectedSite;
      } else if (vm.selectedType === "Organism" && vm.selectedOrganism !== 0) {
        console.log(vm.selectedOrganism);
        url = url + "&organism=" + vm.selectedOrganism;
      }
      this.$http.get(url).then(
        response => {
          vm.thresholds = response.body;
          vm.loading = false;
        },
        response => {
          // error callback
          console.error(response);
          vm.loading = false;
        }
      );
    },
    createThreashold: function() {
      var vm = this;
      vm.loading = true;
      var threshold = JSON.parse(JSON.stringify(this.newThreshold));
      var thresholds = [];
      delete threshold.symptomType;
      delete threshold.organism;

      if (this.newThreshold.type === "InfectionSite") {
        this.newThreshold.symptomType.forEach(function(symptomType) {
          var t = JSON.parse(JSON.stringify(threshold));
          t.symptomType = symptomType;
          thresholds.push(t);
        });
      } else if (this.newThreshold.type === "Organism") {
        this.newThreshold.organism.forEach(function(organism) {
          var t = JSON.parse(JSON.stringify(threshold));
          t.organism = organism;
          thresholds.push(t);
        });
      }
      this.$http.post(window.CONFIG.api + "/threshold/1/new", thresholds).then(
        response => {
          vm.thresholdDialog = false;
          vm.newThreshold = {};
          vm.loadThresholds();
        },
        response => {
          console.error(response);
          vm.newThreshold = {};
          vm.loadThresholds();
        }
      );
    },
    deleteThreshold: function(index, threshold) {
      console.log(JSON.stringify(threshold.id));
      var vm = this;
      vm.loading = true;
      this.$http.delete(window.CONFIG.api + "/threshold/delete/" + threshold.id).then(
        response => {
          vm.loadThresholds();
        },
        response => {
          console.error(response);
          vm.loadThresholds();
        }
      );
    },
    deleteAllThreshold: function() {
      var count = this.thresholds.length;
      if (count <= 0) {
        return;
      }
      var vm = this;
      vm.loading = true;

      this.thresholds.forEach(function(t) {
        vm.$http.delete(window.CONFIG.api + "/threshold/delete/" + t.id).then(
          response => {
            count = count - 1;
            if (count <= 0) {
              vm.loadThresholds();
            }
          },
          response => {
            console.error(response);
            vm.loadThresholds();
          }
        );
      });
    },
    reload: function() {
      var vm = this;
      if (!vm.loading) {
        vm.loading = true;
      }
      vm.newThreshold = {};
      vm.organization = this.$configStore.data.organization || [];
      vm.units = this.$configStore.data.units;
      vm.beds = this.$configStore.data.beds || [];

      this.loadThresholdTypes();
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
    this.reload();
  }
};
</script>

<style scoped>
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-section > label {
  display: inline-block;
}
.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}
.filter-section-input {
  float: right;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
</style>
