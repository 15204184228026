<template>
  <div class="surveillance-device">
    <el-table :data="deviceInfo">
      <el-table-column width="120">
        <template scope="scope">
          {{ scope.row.label }}
        </template>
      </el-table-column>
      <el-table-column label="Usage" width="200">
        <template scope="scope">
          <el-select placeholder="Not Selected"> </el-select>
        </template>
      </el-table-column>
      <el-table-column width="220" label="Start Date">
        <template scope="scope">
          <el-date-picker type="date" readonly editable clearable :format="$configStore.datePickerDateFormat()"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column width="220" label="End Date">
        <template scope="scope">
          <el-date-picker type="date" readonly editable clearable :format="$configStore.datePickerDateFormat()"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="Duration"> </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "surveillance-device",
  data: function() {
    return {
      deviceInfo: [
        {
          label: "Catheter"
        },
        {
          label: "Ventilator"
        },
        {
          label: "Central Line"
        },
        {
          label: "IV Used"
        }
      ]
    };
  }
};
</script>
<style scoped></style>
