<template>
  <div class="symptom-case-detail">
    <div class="dialog-case-detail">
      <div slot="header" class="clearfix">
        <el-col :span="5"><span>Name: </span></el-col>
        <el-col :span="14">{{ clientData.name }} ({{ $configStore.patientIdType() ? clientData[$configStore.patientIdType()] : clientData.patientId }})</el-col>
        <div class="profile-photo" v-if="client">
          <img :src="photoUrl()" />
        </div>
        <div class="profile-photo" v-else><img src="../assets/user.png" /></div>
      </div>
      <div class="clearfix"></div>
      <el-row>
        <el-col :span="5"><span>Infection Site: </span></el-col>
        <el-col :span="16">
          <span class="color-code-label-0" v-if="caseData.symptomType === 'GI'"></span>
          <span class="color-code-label-2" v-else-if="caseData.symptomType === 'UTI'"></span>
          <span class="color-code-label-1" v-else></span>
          {{ caseData.symptomType }}
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="5">Origin:</el-col>
        <el-col :span="16">
          <el-select v-model="caseData.origin" placeholder="Select">
            <el-option v-for="item in origins" :label="originById(item.value)" :value="item.value">
              <span>{{ originById(item.value) }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Onset Date:</el-col>
        <el-col :span="16">
          <el-date-picker v-model="caseData.onSetDate" type="date" readonly editable :format="$configStore.datePickerDateFormat()"> </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Symptoms:</el-col>
        <el-col :span="16">
          <el-tag v-for="d in caseData.symptoms" :key="d.id" :type="tagType(d)">{{ d.displayText }}</el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Confirmed:</el-col>
        <el-col :span="10">
          <el-switch v-model="caseData.confirmed" on-text="Yes" off-text="No" on-color="#13ce66" off-color="#ff4949"> </el-switch>
          <img class="symptom-detail-status-image" :src="statusImage(caseData)" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Organism:</el-col>
        <el-col :span="16">
          <el-select v-model="caseData.organismId" filterable placeholder="Not Selected">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in organisms" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Treatment:</el-col>
        <el-col :span="16">
          <el-select v-model="caseData.treatmentId" filterable placeholder="Not Selected">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in treatments" :key="item.id" :label="item.displayText" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Notification:</el-col>
        <el-col :span="6"><el-checkbox v-model="caseData.familyNotified">Family Notified</el-checkbox></el-col>
        <el-col :span="7"><el-checkbox v-model="caseData.nextOfNotified">Manager Notified</el-checkbox></el-col>
        <el-col :span="6"><el-checkbox v-model="caseData.physicianNotified">Physician Notified</el-checkbox></el-col>
      </el-row>
      <el-row>
        <el-col :span="5">Notes:</el-col>
        <el-col :span="19">
          <div class="case-note">
            <div v-if="caseData.notes.length <= 0">
              <div class="case-note-date"></div>
              <div class="case-note-content-empty">Not Available</div>
            </div>
            <div v-else v-for="d in caseData.notes.slice().reverse()">
              <div class="case-note-date">{{ d.created | moment($configStore.dateFormat()) }}</div>
              <div class="case-note-content">{{ d.content }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import auth from "../auth";

export default {
  name: "symptom-case-detail",
  props: ["caseData", "clientData", "origins", "organisms", "treatments"],
  data: function() {
    return {
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4"
      }
    };
  },
  methods: {
    originById: function(originId) {
      console.log(this.selectedCase.origin);
      console.log(JSON.stringify(this.origins, " ", 2));
      var originName = "Not Selected";
      this.origins.forEach(function(o) {
        if (o.value === originId && o.value !== 0) {
          originName = o.label;
        }
      });
      return originName;
    },
    photoUrl: function() {
      // return '/static/' + this.clientData.photo + '.png'
      var f = this.clientData.photo;
      if (f.indexOf("/") !== -1) {
        console.log(window.CONFIG.api + "/" + f);
        return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
      }

      return "/static/" + f + ".png";
    },
    tagType: function(type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    statusImage: function(detail) {
      if (detail.symptomType) {
        var filename = this.statusImageMap[detail.symptomType];
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        return "/static/" + filename + ".png";
      }
    }
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.symptom-case-detail {
  position: relative;
}

div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.profile-photo img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 4.5em;
}

.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}
</style>
