<template>
  <el-dialog v-model="dialogVisibility" title="Create Infection Case" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
      <!-- Individual Type -->
      <el-form-item v-if="this.isAdminStaff" label="Individual Type" prop="individualType">
        <el-select
          v-model="formModel.individualType"
          v-on:change="
            configureUnitFilter();
            filterClients();
            resetClient();
          "
        >
          <el-option label="Resident" value="Client"> </el-option>
          <el-option label="Staff" value="Staff"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Facility -->
      <el-form-item v-if="isMultiFacility" label="Facility" prop="facId">
        <el-select v-model="formModel.facId" :disabled="isOutbreak" v-on:change="getAllClients()">
          <el-option v-for="facility in facilities" :key="facility.id" :label="facility.displayText" :value="facility.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- Unit -->
      <el-form-item v-if="showUnitFilter" label="Unit" prop="unitId" :disabled="formModel.facId">
        <el-select
          v-model="formModel.unitId"
          v-on:change="
            filterClients();
            resetClient();
          "
        >
          <el-option :label="'All'" :value="0"></el-option>
          <el-option v-for="unit in units" :key="unit.id" :label="unit.displayText" :value="unit.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- Individual -->
      <el-form-item label="Individual" prop="client">
        <div>
          <el-select v-model="formModel.client" value-key="id" filterable :disabled="!formModel.facId">
            <el-option v-for="item in filteredClients" :key="item.id" :label="item.nameFormatted" :value="item"></el-option>
          </el-select>
        </div>
        <div>
          <el-checkbox
            v-model="showDischargedClients"
            v-on:change="
              filterClients();
              resetClient();
            "
            >Show Discharged</el-checkbox
          >
        </div>
        <div class="profile-photo">
          <img :src="getClientPhoto()" onerror="this.src = '/static/user.png';" />
        </div>
      </el-form-item>
      <!-- Infection Site -->
      <el-form-item label="Infection Site" prop="infectionSite">
        <el-select
          v-model="formModel.infectionSite"
          :disabled="isOutbreak"
          v-on:change="
            configureSymptoms();
            formModel.subtypeString = null;
            formModel.symptoms = [];
          "
        >
          <el-option v-for="symptomType in symptomTypes" :key="symptomType.id" :label="symptomType.displayText" :value="symptomType.type"></el-option>
        </el-select>
      </el-form-item>
      <!-- Infection Site Sub Type -->
      <el-form-item label="Type" prop="subtypeString">
        <el-select v-model="formModel.subtypeString" :disabled="!formModel.infectionSite">
          <el-option v-for="item in subTypes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <!-- Symptoms -->
      <el-form-item v-if="showSymptoms" label="Symptoms" prop="symptoms" :disabled="!formModel.infectionSite">
        <el-select v-model="formModel.symptoms" value-key="id" placeholder="Select" multiple>
          <el-option v-for="item in filteredSymptoms" :label="item.displayText" :key="item.id" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Onset Date -->
      <el-form-item label="Onset Date" prop="onsetDate">
        <el-date-picker v-model="formModel.onsetDate" type="date" editable clearable :format="$configStore.datePickerDateFormat()" placeholder="Select date" :picker-options="onsetDatePickerOptions"> </el-date-picker>
      </el-form-item>
      <!-- Origin -->
      <el-form-item label="Origin" prop="origin">
        <el-select v-model="formModel.origin" placeholder="Select">
          <el-option v-for="o in origins" :label="o.label" :key="o.value" :value="o.value"> </el-option>
        </el-select>
      </el-form-item>
      <!-- Confirmed -->
      <el-form-item label="Confirmed?" prop="confirmed">
        <el-switch v-model="formModel.confirmed" on-text="Yes" off-text="No" on-color="#13ce66" off-color="#ff4949"> </el-switch>
      </el-form-item>

      <!-- Precautions-->
      <el-form-item label="Precautions">
        <el-button @click="addPrecaution" size="small"> + Add Precautions</el-button>
      </el-form-item>
      <div v-if="showPrecautions">
        <div v-for="(precaution, index) in formModel.precautions" :key="index">
          <el-row class="precaution-row" :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="5">
              <el-form-item :prop="'precautions.' + index + '.id'" :rules="formRules.precautions.id" label-width="0px">
                <el-select v-model="precaution.id" placeholder="Select">
                  <el-option v-for="p in precautions" :key="p.id" :label="p.displayText" :value="p.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="5">
              <el-form-item :prop="'precautions.' + index + '.startDate'" :rules="formRules.precautions.startDate" label-width="0px">
                <el-date-picker v-model="precaution.startDate" placeholder="Start date" type="datetime" value-format="yyyy-MM-ddTHH:mm:ss"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="5">
              <el-form-item :prop="'precautions.' + index + '.endDate'" label-width="0px">
                <el-date-picker v-model="precaution.endDate" placeholder="End date" type="datetime" value-format="yyyy-MM-ddTHH:mm:ss" :picker-options="precautionPickerOptions[index]"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="24" :lg="8">
              <el-button @click="removePrecaution(index)" size="small">Remove</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
        <el-button type="primary" @click="commitForm" :disabled="dialogBusy">Save</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const validateArrayNotEmpty = (rule, value, callback) => {
  if (!value.length) {
    callback("This field cannot be empty");
  }
  callback();
};
import auth from "../../auth";
import moment from "moment";

export default {
  name: "infection-case-creator",
  props: ["facId", "outbreakId", "infectionSite"],
  data() {
    return {
      isOutbreak: false,
      dialogVisibility: true,
      dialogBusy: true,
      showSymptoms: false,
      showDischargedClients: false,
      showUnitFilter: true,
      facilities: [],
      clients: [],
      filteredClients: [],
      units: [],
      symptomTypes: [],
      symptomType: null,
      subTypes: [],
      precautions: [],
      symptoms: [],
      filteredSymptoms: [],
      showPrecautions: false,
      onsetDatePickerOptions: {
        disabledDate(input) {
          // disable dates after current date
          const currentDate = moment().startOf("day");
          const datepickerDate = moment(input).startOf("day");
          return datepickerDate.isAfter(currentDate);
        },
      },
      clientTypeMap: {
        Resident: "Client",
        Staff: "Staff",
      },
      origins: [],
      showSymptomsList: ["GI", "Respiratory", "UTI", "Skin"],
      formRules: {
        facId: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        individualType: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        client: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        onsetDate: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        infectionSite: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        symptoms: [],
        precautions: {
          id: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
          startDate: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        },
      },
      formModel: {
        facId: null,
        unitId: 0,
        individualType: "Client",
        client: null,
        infectionSite: null,
        subtypeString: null,
        symptoms: [],
        onsetDate: new Date(),
        origin: null,
        confirmed: null,
        precautions: [],
      },
    };
  },
  computed: {
    precautionPickerOptions() {
      return this.formModel.precautions.map((precaution) => {
        const minEndDate = precaution.startDate ? new Date(precaution.startDate) : new Date();
        return {
          disabledDate: (time) => time.getTime() < minEndDate.getTime(),
        };
      });
    },
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
  },
  methods: {
    cancelForm() {
      this.$emit("creator-close");
    },
    async commitForm() {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          this.dialogBusy = true;
          const raw = { ...this.formModel };
          // setup infection case body
          const infectionCase = {
            facId: raw.facId,
            unitId: raw.client.unitId || 0,
            bedId: raw.client?.bed?.id || 0,
            physicianId: 0,
            onsetDate: raw.onsetDate,
            symptomIds: raw.symptoms.map((s) => s.id),
            symptomType: this.symptomType.type,
            infectionSite: raw.infectionSite,
            status: null,
            clientId: raw.client.id,
            organismId: 0,
            resolved: false,
            confirmed: raw.confirmed,
            active: true,
            familyNotified: false,
            nextOfNotified: false,
            physicianNotified: false,
            origin: raw.origin,
            notes: [],
            subtypeString: raw.subtypeString,
          };
          if (!this.symptomType.allowedToMobile) {
            infectionCase.symptomType = null;
            infectionCase.infectionSite = this.symptomType.type;
          }
          // create infection case
          const result = await this.$http.post(`${window.CONFIG.infection_api}/`, JSON.stringify(infectionCase));
          const caseId = result.body.id;
          // link new infection case to outbreak
          if (this.isOutbreak) {
            await this.$http.post(`${window.CONFIG.om_api}/${this.outbreakId}/cases/${caseId}`);
          }
          // create precautions
          if (raw.precautions.length) {
            const precautions = raw.precautions.map((precaution) => {
              return {
                clientId: raw.client.id,
                symptomDetailId: caseId,
                precautionId: precaution.id,
                startDate: precaution.startDate,
                endDate: precaution.endDate,
              };
            });
            await this.$http.post(`${window.CONFIG.precaution_api}/`, JSON.stringify(precautions));
          }
          this.$emit("creator-save");
        } else {
          return false;
        }
      });
    },
    // fetch a single page of clients
    async getClients(page) {
      let url = `${window.CONFIG.api}/clients/list/unit/0/page/${page}?&sort=firstName&size=100`;
      const facId = this.formModel.facId;
      if (facId) {
        url += `&facIds=${facId}`;
      }
      if (!this.canModify) {
        url += `&clientType=${this.clientTypeMap.Resident}`;
      }
      try {
        const response = await this.$http.get(url);
        if (response.body) {
          return response.body.content;
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
      return [];
    },
    // fetch all pages of clients
    async getAllClients() {
      this.dialogBusy = true;
      let currentPage = 0;
      let fullList = [];

      let result = await this.getClients(currentPage);

      while (result && result.length > 0) {
        fullList = [...fullList, ...result];
        if (result.length < 100) {
          break;
        }
        currentPage += 1;
        result = await this.getClients(currentPage);
      }
      fullList.forEach((client) => (client.nameFormatted = this.formatClientName(client)));
      this.clients = fullList;
      this.filterClients();
      this.dialogBusy = false;
    },
    // show/hide unit filter based on individualType
    // reset unitId when hidden
    configureUnitFilter() {
      const { individualType, facId } = this.formModel;
      if (individualType === this.clientTypeMap.Resident && facId) {
        this.units = this.facilities.find((site) => site.id === facId).units.filter((unit) => unit.code !== "PLACE_HOLDER");
        this.showUnitFilter = true;
      } else {
        this.units = [];
        this.showUnitFilter = false;
        this.formModel.unitId = 0;
      }
    },
    addPrecaution() {
      const newPrecaution = {
        id: null,
        startDate: null,
        endDate: null,
      };
      this.$set(this.formModel.precautions, this.formModel.precautions.length, newPrecaution);
      this.showPrecautions = true;
    },
    removePrecaution(index) {
      this.formModel.precautions.splice(index, 1);
      if (this.formModel.precautions.length === 0) {
        this.showPrecautions = false;
      }
    },

    // set client to null when client criteria changes and selected client does not match
    resetClient() {
      const { individualType, client, unitId, facId } = this.formModel;
      const { showDischargedClients } = this;

      if (client) {
        const isDischargedClient = !showDischargedClients && client.status === "D";
        const isMismatchedFacility = facId && client.facId !== facId;
        const isMismatchedType = individualType && client.clientType !== individualType;
        const isMismatchedUnit = unitId && client.unitId !== unitId;

        if (isDischargedClient || isMismatchedType || isMismatchedUnit || isMismatchedFacility) {
          this.formModel.client = null;
        }
      }
    },
    getPrecautionMinEndDate(precautionIndex) {
      if (!this.formModel.precautions[precautionIndex].startDate) {
        return new Date();
      }
      return new Date(this.formModel.precautions[precautionIndex].startDate);
    },
    // show subset of clients based on form inputs
    filterClients() {
      const { individualType, unitId, facId } = this.formModel;
      const { showDischargedClients } = this;
      if (!facId) {
        this.filteredClients = [];
        return;
      }
      this.filteredClients = this.clients.filter((client) => {
        const isDischargedClient = !showDischargedClients && client.status === "D";
        const isMismatchedType = individualType && client.clientType !== individualType;
        const isMismatchedUnit = unitId && client.unitId !== unitId;

        return !(isDischargedClient || isMismatchedType || isMismatchedUnit);
      });
    },
    formatClientName(client) {
      return `${this.getClientName(client)} (${this.getClientId(client)})${client.status === "D" ? " - (Discharged)" : ""}`;
    },
    getClientName(client) {
      return `${client.firstName} ${client.middleName ? client.middleName + " " : ""}${client.lastName}`;
    },
    getClientId(client) {
      let id;
      if (client.clientType === "Staff") {
        id = client.staffId;
      } else {
        id = this.$configStore.patientIdType() ? client[this.$configStore.patientIdType()] : client.patientId;
      }
      return id;
    },
    getClientPhoto() {
      const client = this.formModel.client;
      let url = "/static/user.png";
      if (client && client.photo) {
        const filePath = client.photo;
        if (filePath.indexOf("/") !== -1) {
          url = `${window.CONFIG.api}/${filePath}?access_token=${auth.getToken()}`;
        } else {
          url = `/static/${filePath}.png`;
        }
      }
      return url;
    },
    async setupForm() {
      // set individual type = Resident if user cannot add staff cases
      if (!this.canModify) {
        this.formModel.individualType = this.clientTypeMap.Resident;
      }
      if (!this.isMultiFacility) {
        this.formModel.facId = this.facilities[0].id;
      }
      if (!this.isAdminStaff) {
        this.formModel.individualType = this.clientTypeMap.Resident;
      }
      if (this.isOutbreak) {
        this.setupFormForOutbreak();
      }
      this.configureUnitFilter();
    },
    setupFormForOutbreak() {
      this.formModel.facId = this.facId;
      const symptomType = this.symptomTypes.find((item) => this.infectionSite === item.type || this.infectionSite === item.displayText);
      this.formModel.infectionSite = symptomType.type;
      this.configureSymptoms();
    },
    configureSymptoms() {
      const { infectionSite } = this.formModel;
      if (!infectionSite) {
        return;
      }
      // configure symptomType
      const symptomType = this.symptomTypes.find((item) => infectionSite === item.type || infectionSite === item.displayText);
      this.symptomType = symptomType;

      // configure symptoms
      this.showSymptoms = this.showSymptomsList.includes(infectionSite);
      this.filteredSymptoms = this.symptoms.filter((item) => item.classType === infectionSite);
      if (symptomType?.requireSymptoms) {
        this.formRules.symptoms.push({ required: true, validator: validateArrayNotEmpty, message: "This field  is required", trigger: "blur" });
      } else {
        this.formRules.symptoms = [];
      }
      // configure symptom type sub types
      this.subTypes = symptomType?.subTypes || [];
    },
  },
  async created() {
    // setup auth
    this.canModify = auth.canModify();
    this.isAdminStaff = auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    this.isOutbreak = !!this.outbreakId;
    this.facilities = this.$configStore.data.sites || [];
    this.isMultiFacility = this.facilities && this.facilities.length > 1;
    this.symptoms = this.$configStore.data.symptoms || [];
    this.precautions = this.$configStore.data.precautions || [];
    this.symptomTypes = (this.$configStore.data.symptomTypes || []).filter((s) => s.type !== "Common");
    this.origins = this.$configStore.data.origins || [];
    // apply preset fields & logic
    this.setupForm();
    // fetch clients on load if single site user or outbreak; otherwise clients are fetched on facility selection
    if (!this.isMultiFacility || this.isOutbreak) {
      await this.getAllClients();
    }
    this.dialogBusy = false;
  },
};
</script>
<style>
div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}
.precaution-row .el-input__inner,
.precaution-row .el-select,
.precaution-row .el-select .el-input__inner,
.precaution-row .el-date-editor.el-input,
.precaution-row .el-date-picker .el-input__inner {
  width: 100%;
  box-sizing: border-box;
}
</style>
