<template>
  <div class="flex-heading">
    <h3>{{ title }}</h3>
    <div class="right-align">
      <slot name="right-alignment"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "case-detail-table-heading",
  props: {
    title: String
  }
};
</script>

<style scoped>
.flex-heading {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-align {
  display: flex;
  align-items: center;
}
</style>
