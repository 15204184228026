<template>
  <div class="clients-tab">
    <el-form label-width="120px">
      <el-form-item label="Photo">
        <el-upload ref="upload" :file-list="photoUpload.fileList" :on-success="uploadSuccess" :show-upload-list="false" :multiple="false" :action="photoUpload.uploadUrl" :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">Select</el-button>
          <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">Upload</el-button>
          <div style="padding: 0.5em" class="dialog-photo-image">
            <div :span="4" class="profile-row-photo" v-if="photo"><img :src="getClientPhoto(photo)" width="8em" height="8em" /></div>
            <div :span="4" class="profile-row-photo" v-else><img src="../../assets/user.png" width="8em" height="8em" /></div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <client-editor :isStaffPage="isStaffPage" :title="editor.title" :client="editor.data" v-on:editor-close="closeClientEditor" v-on:editor-error="clientEditorError" v-on:editor-save="closeClientEditor"> </client-editor>
  </div>
</template>
<script>
import auth from "../../auth";
import ResidentEditor from "./Editor";

export default {
  name: "clients-tab",
  props: ["client", "isStaffPage"],
  components: {
    "client-editor": ResidentEditor,
  },
  data() {
    return {
      canModify: false,
      photo: this.client?.photo || "",
      editor: {
        title: "Edit Resident",
        data: {},
      },
      photoUpload: {
        fileList: [],
        uploadUrl: "",
      },
    };
  },
  methods: {
    uploadSuccess(response, file, fileList) {
      this.photoUpload.fileList = [];
      this.photo = `clients/clientphoto/${this.client.id}/${file.name}`;
      this.$emit("upload-success");
    },
    submitUpload() {
      const token = auth.getToken();
      this.$refs.upload.headers["X-CSRF-TOKEN"] = token;
      this.$refs.upload.headers["Authorization"] = `Bearer ${token}`;
      this.$refs.upload.submit();
    },
    getClientPhoto(photo) {
      if (photo) {
        var f = photo;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "../../static/" + (photo ? photo : "user") + ".png";
      }

      return "../../static/user.png";
    },
    isAdminStaff() {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    // emit events from editor down to main clients page
    closeClientEditor() {
      this.$emit("editor-close");
    },
    clientEditorError() {
      this.$emit("editor-error");
    },
  },
  async created() {
    this.canModify = auth.canModify();
    this.photoUpload.uploadUrl = `${window.CONFIG.api}/clients/photo/${this.client.id}`;
    this.editor.data = { ...this.client };
  },
};
</script>
<style scoped>
.el-dialog__body div.profile-row-photo {
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 5em;
}

.el-dialog__body img.profile-row-photo {
  height: 100%;
  width: auto;
  transform: translateX(-50%);
  margin-left: 50%;
}

.profile-photo img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>