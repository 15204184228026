<template>
  <div class="surveillance-symptoms" v-if="caseData">
    <div slot="header" class="clearfix">
      <el-col :span="5"><span>Infection Site/Type: </span></el-col>
      <el-col :span="14"
        >{{ caseData.symptomType || caseData.infectionSite }} <span v-if="caseData.subtypeString"> / {{ caseData.subtypeString }}</span>
      </el-col>
    </div>
    <div class="clearfix"></div>
    <el-row>
      <el-col :span="5">Symptoms Exhibited:</el-col>
      <el-col :span="16">
        <el-select :disabled="!canModify" v-if="symptomTypeMap[caseData.symptomType || caseData.infectionSite] && symptomTypeMap[caseData.symptomType || caseData.infectionSite].requireSymptoms" v-model="allSelectedSymptoms" ref="newcasesymptomsref" multiple placeholder="Not Selected" style="width: 100%" v-on:change="newSypmtomsSelected">
          <el-option v-for="item in symptomOptions" :visible-change="newSypmtomsSelected" :key="item.id + item.displayText" :label="item.displayText" :disabled="item.classType == 'Common' || item.deleted" :value="item.id + item.displayText">
            <span>{{ item.displayText }}</span
            ><span v-if="item.classType == 'Common' || item.deleted"> (OLD)</span>
          </el-option>
          <el-option :disabled="true" :value="-1">
            <span style="float: left; width: 100%"> <hr style="width: 100%" /></span>
          </el-option>
          <el-option v-for="item in symptomCriteria" :visible-change="newSypmtomsSelected" :key="item.id + item.displayText" :label="item.displayText" :value="item.id + item.displayText">
            <span>{{ item.displayText }}</span>
          </el-option>
        </el-select>
        <span v-else> Not Applicable </span>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import auth from "../auth";

export default {
  name: "surveillance-symptoms",
  props: ["caseData", "symptoms", "symptomTypeMap", "customSymptomTypeMap"],
  data: function () {
    return {
      canModify: false,
      selectedSymptoms: [],
      otherSymptoms: [],
      allSelectedSymptoms: [],
      allSymptomRefs: {},
    };
  },
  computed: {
    symptomOptions: function () {
      let vm = this;
      return this.symptoms.filter((item) => {
        let hasSymptom = false;
        vm.selectedSymptoms.forEach((s) => {
          if (s.id === item.id) {
            hasSymptom = true;
          }
        });
        if (hasSymptom) {
          return true;
        }
        return this.caseData && this.caseData.symptomType && item.classType === this.caseData.symptomType && !item.criteria && !item.deleted;
      });
    },
    symptomCriteria: function () {
      return this.symptoms.filter((item) => {
        return this.caseData && this.caseData.symptomType && item.classType === this.caseData.symptomType && item.criteria && !item.deleted;
      });
    },
  },
  watch: {
    caseData: function (v) {
      var vm = this;
      console.log(v.symptoms);
      this.selectedSymptoms = [];
      this.symptoms = this.symptoms || [];
      v.symptoms.forEach(function (s1) {
        let added = false;
        vm.symptoms.forEach(function (s2) {
          if (s1.id === s2.id) {
            vm.selectedSymptoms.push(s2);
            added = true;
          }
        });

        if (!added) {
          console.log("Not ADDED");
          console.log(s1);
        }
      });

      v.symptoms = this.selectedSymptoms;
      this.newSypmtomsSelected();
      this.selectOtherSymptoms(v.otherSymptoms || []);
      this.setAllSelectedSymptoms();
    },
    allSelectedSymptoms: function (v) {
      console.log("-----------");
      console.log(v);
      if (v) {
        const vm = this;
        this.caseData.otherSymptoms = [];
        this.caseData.symptoms = [];

        let symptomList = [];
        v.forEach((r) => {
          let s = vm.allSymptomRefs[r];
          console.log(r);
          console.log(s);
          if (s.custom) {
            delete s.custom;
            vm.caseData.otherSymptoms.push(s);
          } else {
            symptomList.push(s);
            // vm.caseData.symptoms.push(s)
          }
        });
        this.caseData.symptoms = this.sortedSymptom(symptomList);
      }
      console.log(this.caseData.otherSymptoms);
      console.log("-----------");
    },
  },
  methods: {
    sortedSymptom(symptoms) {
      // <el-tag v-for="d in scope.row.symptoms
      if (!symptoms || symptoms.length <= 1) {
        return symptoms;
      }

      console.log(JSON.stringify(symptoms, " ", 2));
      // classType: "GI"
      // colorCode: "0"
      // displayText: "Diarrhea"
      let sortedSymptoms = [];
      symptoms.forEach((s) => {
        sortedSymptoms.push(s);
      });
      sortedSymptoms.sort(function (a, b) {
        let compA = a.classType || "";
        let compB = b.classType || "";

        if (compA === "GI") compA = "a" + a.displayText;
        else if (compA === "Respiratory") compA = "b" + a.displayText;
        else if (compA === "UTI") compA = "c" + a.displayText;
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a" + b.displayText;
        else if (compB === "Respiratory") compB = "b" + b.displayText;
        else if (compB === "UTI") compB = "c" + b.displayText;
        else compB = "d" + b.displayText;

        let x = compA.toLowerCase();
        let y = compB.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      return sortedSymptoms;
    },
    symptomDetail(symptomType) {
      return symptomType + " detail text goes here";
    },
    getOtherSymptoms() {
      if (this.customSymptomTypeMap && this.caseData && this.caseData.symptomType) {
        let symptomType = this.symptomTypeMap[this.caseData.symptomType];
        if (!symptomType) {
          return [];
        }
        const result = this.customSymptomTypeMap[symptomType.id] || [];
        result.forEach((r) => {
          console.log(r);
          r.custom = true;
        });
        return result;
      } else {
        return [];
      }
    },
    setAllSelectedSymptoms() {
      const result = [];
      const sRefs = {};
      this.selectedSymptoms.forEach((s) => {
        result.push(s.id + s.displayText);
      });

      this.otherSymptoms.forEach((s) => {
        result.push(s.id + s.displayText);
      });
      this.allSelectedSymptoms = result;

      this.symptoms.forEach((s) => {
        sRefs[s.id + s.displayText] = s;
      });

      this.selectedSymptoms.forEach((s) => {
        if (!sRefs[s.id + s.displayText]) {
          sRefs[s.id + s.displayText] = s;
          this.symptoms.push(s);
        }
      });
      this.allSymptomRefs = sRefs;
    },
    updateTags() {
      var vm = this;
      this.$nextTick(function () {
        var tagContainer = vm.$refs.newcasesymptomsref;

        if (!tagContainer) {
          console.log(tagContainer);
          return;
        }
        // TODO: refactor
        var tagsEl = vm.$refs.newcasesymptomsref.$refs.tags.firstChild;
        for (var i = 0; i < tagsEl.children.length; i++) {
          var el = tagsEl.children[i];
          for (var j = 0; j < vm.symptoms.length; j++) {
            var s = vm.symptoms[j];
            if (el.textContent === s.displayText && s.classType === this.caseData.symptomType) {
              if (el.classList.contains("el-tag--primary")) {
                el.classList.remove("el-tag--primary");
              }
              var tagType = "el-tag--" + vm.tagType(s);
              if (!el.classList.contains(tagType)) {
                el.classList.add(tagType);
              }
              break;
            }
          }
          // console.log(vm.symptoms)
        }
      });
    },
    newSypmtomsSelected: function () {
      if (!this.$refs.newcasesymptomsref) {
        console.log("newcasesymptomsref not found");
        return;
      }

      this.updateTags();
    },
    tagType: function (type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    selectOtherSymptoms: function (symptoms) {
      const list = this.getOtherSymptoms();
      this.otherSymptoms = [];
      console.log(symptoms);
      console.log(list);
      const vm = this;
      symptoms.forEach((s) => {
        list.forEach((r) => {
          if (r.id === s.id) {
            console.log(r);
            vm.otherSymptoms.push(r);
          }
        });
      });
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    console.log("mounted symptoms");
    var vm = this;
    this.selectedSymptoms = [];
    let data = this.$configStore.data;
    this.symptoms = data.symptoms || [];
    console.log(this.symptoms);
    console.log(this.caseData.symptoms);

    this.caseData.symptoms.forEach(function (s1) {
      vm.symptoms.forEach(function (s2) {
        if (s1.id === s2.id) {
          vm.selectedSymptoms.push(s2);
        }
      });
    });
    this.selectOtherSymptoms(this.caseData.otherSymptoms || []);

    this.caseData.symptoms = this.selectedSymptoms;
    this.newSypmtomsSelected();
    this.setAllSelectedSymptoms();
    this.$nextTick(() => {
      vm.updateTags();
    });
  },
  created: function () {
    var vm = this;
    console.log(this.caseData);
    this.selectedSymptoms = [];
    let data = this.$configStore.data;
    this.symptoms = data.symptoms || [];

    this.caseData.symptoms.forEach(function (s1) {
      let added = false;
      vm.symptoms.forEach(function (s2) {
        if (s1.id === s2.id) {
          vm.selectedSymptoms.push(s2);
          added = true;
        }
      });

      if (!added && s1.deleted) {
        vm.selectedSymptoms.push(s1);
        console.log("Not ADDED due to deprecation");
      }
    });
    this.selectOtherSymptoms(this.caseData.otherSymptoms || []);

    this.caseData.symptoms = this.selectedSymptoms;
    this.newSypmtomsSelected();
    this.setAllSelectedSymptoms();
  },
};
</script>
<style scoped>
div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
  margin-bottom: 1em;
}

li.el-select-dropdown__item.selected.is-disabled {
  opacity: 0.5;
}
</style>
