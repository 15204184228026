import Vue from "vue";
import auth from "../auth";

const store = {
  loaded: false,
  data: {
    config: {},
    clients: {},
    units: [],
    allUnits: [],
    beds: {},
    bedMap: {},
    symptoms: [],
    siteAndTypeOptions: [],
    symptomTypeMap: [],
    customSymptomTypeMap: {},
    staffIsolation: ["Mandatory Isolation", "Voluntary Isolation", "Symptomatic Isolation", "Work-to-Home Isolation", "None"],
    outcomes: [
      {
        value: "Chronic",
        label: "Chronic"
      },
      {
        value: "Deceased",
        label: "Deceased"
      },
      {
        value: "Discharged",
        label: "Discharged"
      },
      {
        value: "Hospitalized",
        label: "Hospitalized"
      },
      {
        value: "Resolved",
        label: "Resolved"
      }
    ]
  },
  init: function () {
    this.data.testData = "init";
    this.vue = new Vue();
    this.$http = this.vue.$http;
  },
  dateFormat: function () {
    let config = this.data.config.DateFormat || [];
    return config[0] || "DD-MM-YYYY";
  },
  datePickerDateFormat: function () {
    let config = this.data.config.DatePickerDateFormat || [];
    return config[0] || "dd-MM-yyyy";
  },
  adtEnabled: function () {
    let config = this.data.config.AdtSupport || [];
    return config[0] === "Y" || !auth.canModify();
  },
  disableFloorMap: function () {
    let config = this.data.config.DisableFloorMap || [];
    return config[0] === "Y";
  },
  infoLink: function () {
    let config = this.data.config.InfoLink || [];
    return config[0] || "/static/more-info.html";
  },
  staffIdAutoGen: function () {
    let config = this.data.config.staffIdAutoGen || [];
    return config[0] === "Y";
  },
  staffIdPrefix: function () {
    let config = this.data.config.staffIdPrefix || [];
    return config[0] || "";
  },
  labIdPrefix: function () {
    let config = this.data.config.labIdPrefix || [];
    return config[0] || "";
  },
  staffIsolation: function () {
    return this.data.staffIsolation || [];
  },
  hhOnly: function () {
    let config = this.data.config.hhOnly || [];
    return config[0] === "Y";
  },
  hhTarget: function () {
    let config = this.data.config.hhTarget || [];
    return config[0] || 90;
  },
  hhOutcomeCriteria: function () {
    let config = this.data.config.hhOutcomeCriteria || 0;
    console.log(config);
    if (config !== 0 && config[0]) {
      console.log(config[0]);
      try {
        config = JSON.parse(config[0]);
      } catch (e) {
        console.log(e);
        config = {};
      }
    }
    return config;
  },
  hhBelowTarget: function () {
    let config = this.data.config.hhBelowTarget || [];
    console.log(config);
    return config[0] || 75;
  },
  patientIdType: function () {
    let config = this.data.config.AdtOccIdentifier || [];
    return config[0];
  },
  outbreakSummaryTemp: function () {
    let config = this.data.config.OutbreakSummaryTemp || [];
    return config[0];
  },
  outbreakSummaryTempSheet: function () {
    let config = this.data.config.OutbreakSummaryTempSheet || [];
    return config[0];
  },
  employeeTypes: function () {
    let config = this.data.config.EmployeeType || [];
    config.sort();
    return config;
  },
  logoutRedirect: function () {
    let config = this.data.config.LogoutRedirect || [];
    return config[0];
  },
  loadConfigs: function (done) {
    // console.log('loadUnits')
    var vm = this;
    this.$http
      .get(window.CONFIG.api + "/configs/", {
        headers: {
          Authorization: "Bearer " + auth.getToken()
        }
      })
      .then(response => {
        vm.data.config = response.body || {};

        let sortedLabProcedures = new Set(response.body.LabProcedures);
        sortedLabProcedures.delete("Unknown");
        sortedLabProcedures = Array.from(sortedLabProcedures.values()).sort();
        sortedLabProcedures.unshift("Unknown");
        vm.data.config.LabProcedures = sortedLabProcedures;

        let units = [];
        let allUnits = [];
        vm.data.sites.forEach(s => {
          let sUnits = s.units.sort((a, b) => {
            return a.displayText.localeCompare(b.displayText);
          });
          sUnits.forEach(u => {
            if (!u.site) {
              u.site = {
                id: s.id,
                displayText: s.displayText
              };
            }

            u.siteDisplayText = s.displayText;
            u.siteId = s.id;
            u.key = u.id + u.displayText;

            if (u.code !== "PLACE_HOLDER") {
              units.push(u);
            }
            allUnits.push(u);
          });
        });

        vm.data.units = units.sort(function (a, b) {
          return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
        });
        vm.data.allUnits = allUnits.sort(function (a, b) {
          return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
        });

        // Origins config: assign default if not present in config
        if (vm.data.config.InfectionCaseOrigins) {
          vm.data.origins = (vm.data.config.InfectionCaseOrigins || []).sort().map(origin => ({ label: origin, value: origin }));
        } else {
          vm.data.origins = [
            {
              value: "Community Acquired",
              label: "Community Acquired",
            },
            {
              value: "HAI",
              label: "HAI",
            },
            {
              value: "Other",
              label: "Other",
            }
          ];
        }

        console.log(units);
        console.log(allUnits);
        vm.loadSymptomTypes(done);
      });
  },
  loadCustomSypmtoms: function (id) {
    let vm = this;
    this.$http.get(window.CONFIG.api + "/customsypmtoms/infectionsite/" + id).then(response => {
      let cSymptom = response.body || [];
      if (cSymptom && !cSymptom.isDeleted) {
        cSymptom.forEach(c => {
          c.custom = true;
        });
        vm.data.customSymptomTypeMap[id] = cSymptom;
      }
    });
  },
  loadSymptomTypes: function (done) {
    var vm = this;

    // http://localhost:8080/symptoms/classtypes
    this.$http.get(window.CONFIG.api + "/symptoms/0/classtypes").then(response => {
      let siteAndTypeOptions = [];
      siteAndTypeOptions.push({
        value: 0,
        label: "All Infection Sites"
      });

      var types = response.body || [];
      types.sort(function (a, b) {
        var compA = a.type;
        var compB = b.type;

        if (compA === "GI") compA = "a";
        else if (compA === "Respiratory") compA = "b";
        else if (compA === "UTI") compA = "c";
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a";
        else if (compB === "Respiratory") compB = "b";
        else if (compB === "UTI") compB = "c";
        else compB = "d" + b.displayText;

        return compA.toLowerCase().localeCompare(compB.toLowerCase());
      });
      vm.data.symptomTypes = types;
      types.forEach(function (sType) {
        vm.loadCustomSypmtoms(sType.id);
        if (sType.type === "Common") {
          return;
        }
        vm.data.symptomTypeMap[sType.type] = sType;
        var children = [{ value: 0, label: "All Types" }];
        sType.subTypes.sort();
        sType.subTypes.forEach(function (subType) {
          children.push({
            value: subType,
            label: subType
          });
        });

        var option = {
          value: sType,
          label: sType.displayText
        };

        if (sType.subTypes && sType.subTypes.length > 0) {
          option.children = children;
        }
        siteAndTypeOptions.push(option);
      });

      Object.assign(vm.data.siteAndTypeOptions, siteAndTypeOptions);
      console.log("siteAndTypeOptions", vm.data);
      console.log("siteAndTypeOptions", vm.data.siteAndTypeOptions);
      vm.data.symptomTypes.props = {
        value: "displayText",
        children: "subTypes"
      };
      vm.loadSymptoms(done);
      console.log("vm.loadSymptoms()");
    });
  },
  loadSymptoms: function (done) {
    var vm = this;
    this.$http.get(window.CONFIG.api + "/symptoms/all").then(response => {
      vm.data.symptoms = response.body;
      vm.loadOrganisms(done);
    });
  },
  loadOrganisms: function (done) {
    var vm = this;
    this.$http.get(window.CONFIG.api + "/organisms/").then(
      response => {
        let organisms = response.body["_embedded"].organisms;
        organisms.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x === "other") {
            x = "0";
          }
          if (y === "other") {
            y = "0";
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        vm.data.organisms = organisms;
        vm.loadPrecautions(done);
      },
      response => {
        // error callback
      }
    );
  },
  loadPrecautions: function (done) {
    var vm = this;
    this.$http.get(window.CONFIG.api + "/precautions/").then(
      response => {
        vm.data.precautions = response.body["_embedded"].precautions;
        vm.loadTreatments(done);
      },
      response => {
        // error callback
      }
    );
  },
  loadTreatments: function (done) {
    var vm = this;
    this.$http.get(window.CONFIG.api + "/treatments/").then(
      response => {
        vm.data.treatments = response.body["_embedded"].treatments;
        // vm.loadCases()
        if (done) {
          done();
        }
        console.log(vm.data);
        vm.loaded = true;
      },
      response => {
        // error callback
      }
    );
  },
  getClientName: function (clientData) {
    var firstName = clientData.firstName ? clientData.firstName + " " : "";
    var middleName = clientData.middleName ? clientData.middleName + " " : "";
    var lastName = clientData.lastName ? clientData.lastName : "";

    // console.log(JSON.stringify(clientData, ' ', 2))
    return firstName + middleName + lastName;
  },
  loadAll: function (done) {
    let vm = this;
    // this.$http.get(window.CONFIG.api + '/clients/list')
    // .then(response => {
    //   if (response.body) {
    //     // console.log(JSON.stringify(response.body, ' ', 2))
    //     vm.data.clientList = response.body
    //     vm.data.clientList.forEach(function (c) {
    //       // console.log(JSON.stringify(c, ' ', 2))
    //       vm.data.clients[c.id.toString()] = c
    //     })
    //     vm.data.clientList.sort(function (a, b) {
    //       var cA = vm.getClientName(a)
    //       var cB = vm.getClientName(b)
    //       return cA.toLowerCase().localeCompare(cB.toLowerCase())
    //     })
    //   } else {
    //     vm.data.clientList = []
    //   }
    console.log("-----------------");
    vm.$http.get(window.CONFIG.api + "/organization/list?appType=web").then(
      response => {
        if (response.body) {
          vm.data.organization = response.body;
          if (vm.data.organization[0]) {
            vm.data.organization[0].sites = vm.data.organization[0].sites.sort((a, b) => {
              return a.displayText.localeCompare(b.displayText);
            });
            vm.data.sites = vm.data.organization[0].sites;
          } else {
            vm.data.organization = [];
            vm.data.sites = [];
          }
        } else {
          vm.data.organization = [];
        }

        // vm.data.bedMap[bed.id] = bed
        // sort sites
        vm.data.sites.forEach(s => {
          (s.units || []).forEach(u => {
            (u.rooms || []).forEach(r => {
              (r.beds || []).forEach(b => {
                if (b) {
                  vm.data.bedMap[b.id] = b;
                  vm.data.beds[b.occId] = r.displayText + "-" + b.displayText;
                }
              });
            });
          });
        });

        let finished = function () {
          if (done) {
            done();
          }
          vm.dataReady = true;
        };

        vm.loadConfigs(finished);
      },
      response => {
        // error callback
      }
    );
    // }, response => {
    //   // error callback
    // })
  },
  labResults: function () {
    const config = this.data.config.LabResults || [];
    return config.sort().map(r => r.slice(r.indexOf("_") + 1));
  },
  getLineListReports() {
    return {
      infectionSurveillance: "IS",
      outbreakManagement: "OM",
      antimicrobialTimeout: "AMTO"
    };
  },
  getFeatureFlag(key) {
    const { Features } = this.data.config;
    const featureFlags = Features || [];
    const lookup = Object.fromEntries([...featureFlags.entries()].map(([i, flagName]) => [flagName, true]));
    return lookup[key] || false;
  }
};

store.install = function (Vue) {
  store.init();
  Object.defineProperty(Vue.prototype, "$configStore", {
    get() {
      return store;
    }
  });
};

export default store;
