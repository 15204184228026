<template>
  <div id="app" v-if="loggedIn() || activeIndex === '/invalid'" v-loading="!ready">
    <div class="top-menu" v-if="ready">
      <img class="logo" alt="HealthConnex" src="/static/logo-dark-transparent.svg" />
      <div class="message-container">
        <template v-if="$store.getters.reportsIsBusy">
          <span>Your report is being generated. Please do not refresh or close this browser tab/window.</span>
        </template>
      </div>
      <el-dropdown v-if="loggedIn()" trigger="click" class="infc-logout-button" @command="handleCommand">
        <span class="el-dropdown-link">
          <strong>{{ username() }}</strong
          ><i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="!idpLogin()" command="account">Account Info</el-dropdown-item>
          <el-dropdown-item command="logout" divided>Log Out</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span v-if="help() !== null" class="graphic-button" @click="helpWindow"><i class="el-icon-information"></i></span>
    </div>
    <div class="left-sub-menu" v-if="loggedIn() && ready && ['help'].indexOf($route.name) < 0 && !contentOnly()">
      <el-menu :default-active="activeIndex" class="el-menu-vertical" theme="dark" :router="true">
        <el-menu-item index="/" v-if="!hhonly() && hasNurseRole()">Dashboard</el-menu-item>
        <el-menu-item index="/floormap" v-if="showFloorMap() && !hhonly() && hasNurseRole()">Floor Map</el-menu-item>
        <el-menu-item index="/surveillance" v-if="!hhonly() && hasNurseRole()">Infection Surveillance</el-menu-item>
        <el-menu-item index="/labresults" v-if="!hhonly() && hasLabResults() && hasNurseRole()">Lab Results</el-menu-item>
        <el-menu-item index="/analysis" v-if="!hhonly() && hasNurseRole()">Analysis</el-menu-item>
        <el-menu-item index="/om" v-if="!hhonly() && hasNurseRole()">Outbreak Management</el-menu-item>
        <el-menu-item index="/hh" v-if="hh()">Hand Hygiene</el-menu-item>
        <el-menu-item index="/ppe" v-if="hasPPE()">PPE</el-menu-item>
        <el-menu-item index="/clients" v-if="!hhonly() && hasNurseRole()">Residents</el-menu-item>
        <el-menu-item index="/staff" v-if="isAdminStaff() && hasNurseRole()">Staff</el-menu-item>
        <el-submenu index="/" v-if="isAdmin() || isAdminUser() || isAuditAdmin()" :unique-opened="false">
          <template slot="title"><i class="el-icon-setting"></i>Setup</template>
          <el-menu-item index="/uam" v-if="showUam()" style="padding-left: 30px">Users</el-menu-item>
          <el-menu-item index="/setup-outbreak" v-if="isAdminStaff()" style="padding-left: 30px">Checklist</el-menu-item>
        </el-submenu>
        <el-menu-item index="" v-if="hasMyAudits()" @click="launchMyAudits">Launch MyAudits</el-menu-item>
      </el-menu>
    </div>
    <div class="right-content" v-if="ready">
      <router-view :key="$route.path"></router-view>
    </div>
    <div class="copyright">Copyright © {{ new Date().getFullYear() }} HealthConnex Inc. <br />All rights reserved. <a href="static/license.txt">License</a></div>
  </div>
</template>

<script>
import auth from "./auth";

export default {
  name: "app",
  data: function () {
    return {
      ready: false,
      activeIndex: "/",
      showReportBeingGeneratedMessage: false,
    };
  },
  watch: {
    ready: function (val) {
      console.log("ready? ", val);
    },
  },
  methods: {
    launchMyAudits() {
      open(`${window.CONFIG.my_audits_host}${window.CONFIG.my_audits_port}`, "_blank");
    },
    handleCommand(command) {
      if (command === "logout") {
        this.logout();
      } else if (command === "account") {
        window.open(window.CONFIG.authHost + "/account", "Account", "menubar=1,resizable=1,width=800,height=600");
      }
    },
    loggedIn() {
      return auth.loggedIn();
    },
    logout() {
      var vm = this;
      auth.logout(function () {
        vm.activeIndex = "/";
        vm.$router.go("/");
      });
    },
    username() {
      if (auth.loggedIn()) {
        return auth.userInfo().username || (auth.userInfo().principal ? auth.userInfo().principal.username : "");
      }

      return "";
    },
    idpLogin() {
      return auth.userInfo().idp || false;
    },
    isHHAdmin: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_ADMIN") >= 0;
    },
    isAdminStaff: function () {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    isAuditAdmin: function () {
      return auth.userInfo().roles.indexOf("HCI_AUDIT_ADMIN") >= 0;
    },
    isAdmin: function () {
      return auth.userInfo().roles.indexOf("HCI_ADMIN") >= 0;
    },
    isAdminUser: function () {
      return auth.userInfo().roles.indexOf("HCI_ADMIN_USER") >= 0;
    },
    hasPPE: function () {
      return auth.userInfo().roles.indexOf("PPE_READ") >= 0;
    },
    isWebObserver: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_WEB_OBSERVER") >= 0;
    },
    hasLabResults: function () {
      return auth.userInfo().roles.indexOf("ROLE_LAB_RESULTS") >= 0;
    },
    hasMyAudits: function () {
      return auth.userInfo().roles.indexOf("MA_AUDIT_READ") >= 0;
    },
    hh() {
      var allowed = (auth.userInfo().org !== "sprucedale" && auth.userInfo().org !== "ryk") || this.hhonly();

      if (auth.userInfo().org === "ryk") {
        allowed = this.isHHAdmin() || this.isWebObserver();
      }

      return allowed;
    },
    hhonly() {
      return auth.userInfo().org === "arnprior" || auth.userInfo().org === "cch" || this.$configStore.hhOnly();
    },
    showFloorMap() {
      return !this.$configStore.disableFloorMap();
    },
    showUam() {
      return auth.userInfo().roles.indexOf("UAM_READ") >= 0;
    },
    showAntibiotic() {
      return auth.userInfo().org === "bigwish";
    },
    help() {
      return this.$configStore.infoLink();
    },
    contentOnly() {
      return this.$router.currentRoute.path === "/invalid";
    },
    helpWindow() {
      let helpLink = this.help();
      if (helpLink) {
        window.open(helpLink, "Help", "menubar=1,resizable=1,width=850,height=600");
      }
    },
    stopEvent(e) {
      console.log(e);
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    },
    reload: function () {
      console.log("******* reload");
      this.$router.go(this.$router.currentRoute);
    },
    preload: function () {
      let vm = this;
      this.$configStore.loadAll(() => {
        console.log("loaded");
        if (vm.hhonly() && vm.activeIndex !== "/hh") {
          vm.activeIndex = "/hh";
          vm.$router.replace("/hh");
        }
        vm.ready = true;
      });
    },
    hasNurseRole: function () {
      return auth.userInfo().roles.indexOf("ROLE_NURSE") >= 0;
    },
  },
  created: function () {
    this.activeIndex = this.$router.currentRoute.path;
    this.ready = this.$configStore.loaded;
    if (auth.loggedIn() && this.$configStore && !this.$configStore.loaded) {
      this.preload();
    } else {
      let vm = this;
      vm.ready = true;
    }
  },
  mounted: function () {
    this.ready = this.$configStore.loaded;
    let vm = this;

    console.log("query", this.$router);

    if (auth.loggedIn() && auth.userInfo() && !auth.userInfo().org) {
      console.log("invalid user");
      vm.$router.replace("/invalid");
      return;
    }

    if (!this.hasNurseRole() && vm.activeIndex !== "/hh") {
      vm.activeIndex = "/hh";
      vm.$router.replace("/hh");
      return;
    }

    if (vm.hhonly() && vm.activeIndex !== "/hh") {
      vm.activeIndex = "/hh";
      vm.$router.replace("/hh");
      return;
    }

    vm.activeIndex = vm.$router.currentRoute.path;
    vm.$router.replace(vm.$router.currentRoute.path);

    if (vm.activeIndex === "/invalid") {
      this.ready = true;
    }
  },
};
</script>

<style>
input,
.el-input,
.el-select-dropdown__item,
.el-cascader-menu__item,
.el-picker-panel,
.el-tooltip__popper {
  font-family: "Open Sans", sans-serif !important;
}

html {
  --infection-site-color-code-uti: #ffde25;
  --infection-site-color-code-gi: #00d560;
  --infection-site-color-code-skin: #ff00ff;
  --infection-site-color-code-respiratory: #0080ff;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#app {
  text-align: left;
  color: #2c3e50;
  height: 100%;
}
#app *:not(.el-radio-group):not(svg *) {
  font-size: 0.8rem;
}
body,
html {
  height: 100%;
}

.top-menu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  background-color: #f5f6ee;
  display: flex;
  align-content: center;
  align-items: center;
}

.top-menu .logo {
  height: 100%;
  flex: 0 1 auto;
}

.top-menu .message-container {
  flex: 1 1 auto;
  text-align: center;
}

.top-menu .message-container span {
  position: relative;
  padding-left: 38px;
}

.top-menu .message-container span::after,
.top-menu .message-container span::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  position: absolute;
  top: -5px;
  left: 0;
}

@keyframes spinner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.top-menu .message-container span::after {
  border-color: transparent transparent #7cc156;
  animation: spinner 1s infinite linear;
}

.top-menu .graphic-button {
  flex: 0 1 auto;
}

.top-menu .infc-logout-button {
  flex: 0 1 auto;
}

.left-sub-menu ul.el-menu.el-menu-vertical.el-menu--dark {
  background-color: #9e9791;
}
.left-sub-menu {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 200px;
  /*background: #324157;*/
  background: #9e9791;
  z-index: 100;
}

.right-content {
  position: absolute;
  left: 200px;
  top: 60px;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.infc-logout-button {
  padding: 0.3em;
  border: solid 1px lightgray;
  border-radius: 0.1em;
}

.infc-menu > .title {
  font-size: 1em;
  padding-left: 0;
  display: block;
  float: left;
  line-height: 60px;
  height: 60px;
  color: #20a0ff;
}

.infc-menu > .title > img {
  height: 50px;
  margin-top: 5px;
}

.line {
  margin-bottom: 2em;
}

html,
body {
  margin: 0;
  padding: 0;
}

.section-header > .el-row {
  padding: 10px;
  background: #7cc156;
  color: white;
  font-weight: bolder;
}

.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item.is-active,
.el-menu-item.is-active {
  color: white;
  background-color: #444;
  font-weight: bolder;
}

.el-menu--dark .el-menu-item,
.el-menu--dark .el-submenu__title {
  color: white;
}

.el-menu-item,
.el-submenu__title {
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  color: #48576a;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  white-space: nowrap;
}

.el-picker-panel__shortcut {
  font-size: 0.8em;
}

.el-tag--warning {
  color: #454544;
}

.el-tag--success {
  color: #454544;
}

.el-tag--danger {
  color: #454544;
}

.el-tag--primary {
  color: #454544;
}

.el-dialog__header {
  padding: 10px;
  background: #7cc156;
  color: white;
}

.el-dialog__title,
.el-dialog__close {
  color: white;
}

span.el-tag {
  margin: 0.2em;
}

.left-sub-menu .el-submenu ul.el-menu {
  background: transparent;
}

.el-menu-item-group__title {
  padding-top: 0;
  padding-right: 10px;
  line-height: 42px;
  color: lightgray;
}

.graphic-button {
  padding: 1.2em;
}

button.el-dialog__headerbtn {
  border: none !important;
  background: transparent !important;
}
.el-dialog.el-dialog--large {
  width: 70% !important;
}
.el-table__empty-block {
  width: 100% !important;
}

.cell {
  word-break: normal !important;
}

.copyright {
  color: black;
  font-size: 11px;
  display: block;
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  z-index: 1000;
  text-align: center;
  width: 200px;
}

.el-table .cell,
.el-table th > div {
  padding-left: 0.7rem;
  padding-right: 0.3rem;
}

@media print {
  .top-menu,
  .left-sub-menu,
  .copyright {
    display: none;
  }

  .do-not-print {
    display: none;
  }

  .right-content {
    position: static;
  }
}
</style>
