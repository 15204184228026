<template>
  <div class="surveillance-treatment">
    <el-row type="flex" justify="end">
      <el-button :disabled="!canModify" @click="addNewTreatment()">+ Add</el-button>
    </el-row>
    <el-table border :data="allTreatments" style="width: 100%; margin-top:1em;" :default-sort="{ prop: 'date', order: 'descending' }">
      <el-table-column prop="date" label="Start Date" width="220">
        <template scope="scope">
          <div v-if="scope.row.isEditing">
            <el-date-picker v-model="scope.row.startDate" :format="$configStore.datePickerDateFormat()" size="small" type="date" placeholder="Start Date"> </el-date-picker>
          </div>
          <div v-else>
            {{ scope.row.startDate | moment($configStore.dateFormat()) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="End Date" width="220">
        <template scope="scope">
          <div v-if="scope.row.isEditing">
            <el-date-picker v-model="scope.row.endDate" :format="$configStore.datePickerDateFormat()" size="small" type="date" placeholder="End Date"> </el-date-picker>
          </div>
          <div v-else>
            <span v-if="scope.row.endDate ? true : false">
              {{ scope.row.endDate | moment($configStore.dateFormat()) }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Treatment">
        <template scope="scope">
          <div v-if="scope.row.isEditing">
            <el-select v-model="scope.row.treatment" filterable placeholder="Not Selected">
              <el-option label="Not Selected" :value="0"> </el-option>
              <el-option label="Other" value="other"> </el-option>
              <el-option v-for="item in sortedTreatments" :key="item.displayText" :label="item.displayText" :value="item.id">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
            <el-input v-if="scope.row.treatment === 'other'" placeholder="Please Specify" v-model="scope.row.customTreatment" @change="inputChanged(scope.$index, scope.row)" required></el-input>
          </div>
          <div v-else>
            {{ scope.row.treatment ? scope.row.treatment.displayText : "" }}
            {{ scope.row.customTreatment ? scope.row.customTreatment : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="180">
        <template scope="scope">
          <el-button-group v-if="scope.row.treatment || scope.row.customTreatment || scope.row.isEditing">
            <el-button :disabled="!canModify" v-if="!scope.row.isEditing && (scope.row.treatment === 'other' || scope.row.customTreatment || treatmentById(scope.row.treatment.id))" @click="editAction(scope.$index, scope.row)" size="small">
              Edit
            </el-button>
            <el-button :disabled="!canModify" v-if="!scope.row.isEditing && (scope.row.treatment === 'other' || scope.row.customTreatment || treatmentById(scope.row.treatment.id))" @click="deleteAction(scope.$index, scope.row)" size="small">
              Delete
            </el-button>
            <el-button v-if="scope.row.isEditing" :disabled="scope.row.treatment == 0 || (scope.row.treatment == 'other' && !scope.row.customTreatment)" @click="saveAction(scope.$index, scope.row)" size="small">
              Save
            </el-button>
            <el-button v-if="scope.row.isEditing" @click="cancelAction(scope.$index, scope.row)" size="small">
              Cancel
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import auth from "../auth";

export default {
  name: "surveillance-treatment",
  props: ["caseData", "treatments"],
  data: function() {
    return {
      canModify: false,
      newTreatments: [],
      allTreatments: [],
      customTreatments: [],
      loadedCase: {}
    };
  },
  watch: {
    caseData: function(value) {
      this.newTreatments = [];
      this.loadedCase = JSON.parse(JSON.stringify(value));
    },
    newTreatments: function(value) {
      this.allTreatments = value.concat(this.loadedCase.treatmentDetails);
      console.log(JSON.stringify(this.allTreatments, " ", 2));
    },
    treatments: function(value) {
      console.log(value);
      this.treatments = value || [];
    }
  },
  computed: {
    sortedTreatments: function() {
      return this.treatments.sort(function(a, b) {
        let first = a.displayText || "";
        let second = b.displayText || "";
        const result = first.toLowerCase().localeCompare(second.toLowerCase());
        return result;
      });
    }
  },
  methods: {
    symptomDetail(symptomType) {
      return symptomType + " detail text goes here";
    },
    addNewTreatment() {
      console.log(JSON.stringify(this.loadedCase));
      var started = new Date();
      var newTreatment = { treatment: 0, startDate: started, endDate: null, isNew: true, isEditing: true };
      this.newTreatments.splice(0, 0, newTreatment);
    },
    treatmentById(tId) {
      var result = null;
      this.treatments.forEach(function(t) {
        if (t.id === tId) {
          result = t;
        }
      });

      return result;
    },
    deleteAction: function(index, data) {
      if (data.id !== undefined) {
        console.log(data);
        console.log(data.id);
        var loadingInstance = Loading.service({ fullscreen: true });
        this.$http.delete(window.CONFIG.api + "/symptomDetail/" + this.loadedCase.id + "/treatment/" + data.id + "/delete").then(
          response => {
            loadingInstance.close();
            this.allTreatments.splice(index, 1);
          },
          response => {
            // error callback
          }
        );
      } else {
        this.allTreatments.splice(index, 1);
      }
    },
    cancelAction: function(index, data) {
      console.log(JSON.stringify(data, " ", 2));
      if (data.isNew) {
        this.newTreatments.splice(index, 1);
      } else {
        var t = this.allTreatments[index];
        t.isEditing = false;
        if (!this.customTreatments[index]) {
          t.treatment = this.treatmentById(t.treatment);
        } else {
          t.treatment = "other";
          t.customTreatment = this.customTreatments[index];
        }
        this.allTreatments.splice(index, 1, t);
      }
    },
    editAction: function(index, data) {
      var t = this.allTreatments[index];
      if (!t.customTreatment) {
        t.treatment = t.treatment.id;
      } else {
        t.treatment = "other";
        this.customTreatments[index] = JSON.parse(JSON.stringify(t.customTreatment));
      }
      t.isEditing = true;
      this.allTreatments.splice(index, 1, t);
    },
    inputChanged: function(index, data) {
      var t = this.allTreatments[index];
      if (t.isEditing) {
        this.allTreatments.splice(index, 1, t);
      }
    },
    saveAction: function(index, data) {
      var vm = this;
      var loadingInstance = Loading.service({ fullscreen: true });
      if (data.treatment !== 0 && data.treatment !== "other") {
        data.treatment = this.treatmentById(data.treatment);
      }

      var newTreatment = JSON.parse(JSON.stringify(data));
      if (newTreatment.isNew) {
        delete newTreatment.isNew;
      }

      delete newTreatment.isEditing;
      console.log(JSON.stringify(newTreatment));
      // console.log(JSON.stringify(vm.caseData))
      // console.log(data.treatment)

      if (newTreatment.treatment === "other") {
        newTreatment.treatment = null;
      } else {
        newTreatment.customTreatment = null;
      }

      if (data.isNew) {
        this.$http
          .post(window.CONFIG.api + "/symptomDetail/" + this.loadedCase.id + "/treatment", newTreatment)
          // this.$http.post(window.CONFIG.api + '/client/new/note', newNote)
          .then(
            response => {
              console.log(JSON.stringify(response, 2, " "));
              if (response.status === 200) {
                vm.loadedCase.treatmentDetails.splice(0, 0, newTreatment);
                vm.newTreatments.splice(index, 1);
                vm.caseData.treatmentDetails = vm.loadedCase.treatmentDetails;
              }
              loadingInstance.close();
            },
            response => {
              // error callback
            }
          );
      } else {
        this.$http.put(window.CONFIG.api + "/symptomDetail/" + this.loadedCase.id + "/treatment", newTreatment).then(
          response => {
            console.log(JSON.stringify(response, 2, " "));
            if (response.status === 200) {
              newTreatment.isEditing = false;
              vm.loadedCase.treatmentDetails.splice(index, 1, newTreatment);
              vm.newTreatments.splice(index, 1);
              vm.caseData.treatmentDetails = vm.loadedCase.treatmentDetails;
            }
            loadingInstance.close();
          },
          response => {
            // error callback
          }
        );
      }
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
    console.log("mounted! - treatments");
    this.newTreatments = [];
    this.loadedCase = JSON.parse(JSON.stringify(this.caseData));
  }
};
</script>
<style scoped>
div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
  margin-bottom: 1em;
}
</style>
