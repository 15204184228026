<template>
  <div ref="container" class="progress-overlay">
    <el-dialog v-model="dialogVisibile" :title="title" :before-close="hideDialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="progress-border">
        <div class="progress-fill" :style="{ width: progress + '%' }" :percentage="progress">{{ progress }}%</div>
      </div>
      <div style="display:flex; justify-content:center;margin-top: 25px;">
        <el-button @click="cancel()">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "progress-overlay",
  props: {
    title: String,
    progress: Number
  },
  data: function() {
    return {
      dialogVisibile: false
    };
  },
  watch: {},
  methods: {
    showDialog() {
      this.dialogVisibile = true;
    },
    hideDialog() {
      this.dialogVisibile = false;
    },
    cancel() {
      this.hideDialog();
      this.$emit("progress-cancelled");
    }
  },
  mounted() {
    this.showDialog();
    if (this.$refs.container) {
      document.body.append(this.$refs.container);
    }
  }
};
</script>
<style>
.progress-overlay {
  -webkit-font-smoothing: antialiased;
}
</style>
<style scoped>
.progress-border {
  height: 18px;
  width: 100%;
  position: relative;
  box-shadow: inset 0px 0px 0px 1px #b7b7b7;
  border-radius: 10px;
  overflow: hidden;
  background: #ededed;
}
.progress-fill {
  background: #454544;
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  transition: 0.25s width linear;
  border-radius: 10px;
  color: white;
  text-align: right;
  padding: 0 10px;
}
</style>
