<template>
  <div class="overview">
    <el-row :gutter="100">
      <el-col :span="16" :offset="4">
        <el-input placeholder="Search by Name" v-model="searchNameInput"></el-input>
      </el-col>
    </el-row>
    <br />
    <br />
    <el-row :gutter="20">
      <el-col :span="6" v-for="(o, index) in nameFilter(cases)" :offset="index % 3 == 0 ? 3 : index % 3 == 1 ? 0 : 0" :key="o.id">
        <el-card>
          <div style="padding: 14px;">
            <div slot="header" class="clearfix">
              <el-col :span="8"><span>Name: </span></el-col>
              <el-col :span="16">{{ o.name }}</el-col>
            </div>
            <el-row>
              <el-col :span="8"><span>Infection Site: </span></el-col>
              <el-col :span="16"
                >{{ o.symptomType }}
                <span class="color-code-label-0" v-if="o.symptomType === 'GI'"></span>
                <span class="color-code-label-3" v-else-if="o.symptomType === 'UTI'"></span>
                <span class="color-code-label-1" v-else></span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">Onset Date:</el-col>
              <el-col :span="16">
                <el-date-picker v-model="o.onSetDate" type="date" readonly editable clearable> </el-date-picker>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "overview",
  data: function() {
    return {
      currentDate: new Date(),
      cases: [],
      searchNameInput: ""
    };
  },
  methods: {
    loadCases: function() {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/symptomDetails/").then(
        response => {
          vm.cases = response.body["_embedded"].symptomDetails;
        },
        response => {
          // error callback
        }
      );
    },
    nameFilter: function(cases) {
      var vm = this;
      return cases.filter(function(detail) {
        return detail.name.toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    tagType: function(type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "danger";
      } else if (type.colorCode === "3") {
        return "warning";
      }
      return "gray";
    }
  },
  created: function() {
    this.loadCases();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-card {
  margin-bottom: 1em;
}
.el-card div {
  line-height: 3em;
}
.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-button {
  width: 100%;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
</style>
