<template>
  <div class="locations" v-loading.body="loading">
    <div class="filter">
      <div class="section-header">
        <el-row
          ><span>Location Filter</span>
          <div class="case-filter"></div>
        </el-row>
      </div>
      <!-- FILTER -->
      <el-row class="filter-section">
        <label
          ><span class="filter-title-label">Unit:</span>
          <el-select v-model="filteredUnit" placeholder="Unit" class="filter-section-input" v-on:change="filterUpdated">
            <el-option label="All Units" :value="0"> </el-option>
            <el-option v-for="item in units" :label="item.displayText" :key="item.id" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Assigned:</span>
          <el-select v-model="assigned" class="filter-section-input" placeholder="Status" v-on:change="filterUpdated">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="Yes" :value="1"> </el-option>
            <el-option label="No" :value="2"> </el-option>
          </el-select>
        </label>
      </el-row>
    </div>
    <div class="filter">
      <div class="section-header">
        <el-row
          ><span>Locations - Total: {{ locations.length }}</span>
          <div class="case-filter">
            <!-- <el-button class="case-filter-button" :plain="true" type="primary" size="mini" @click="newResident()">+ New Resident</el-button>
            <el-select 
              class="case-filter-select"
              size="mini"
              v-model="sortedBy" placeholder="Status" v-on:change="filterUpdated">
              <el-option
                label="Sort by First Name"
                value='firstName'>
              </el-option>
              <el-option
                label="Sort by Last Name"
                value='lastName'>
              </el-option>
              <el-option
                label="Sort by ID"
                value='patientId'>
              </el-option>
              <el-option
                label="Sort by Location"
                value='bed.fullDesc'>
              </el-option>
            </el-select> -->

            <!-- <el-pagination
              small
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-count="currentPageTotal"
              v-on:current-change="loadClients">
            </el-pagination> -->
          </div>
        </el-row>
      </div>
    </div>
    <!-- <el-table
    :data="locations"
    v-on:row-click="updateResident"
    style="width: 100%"
    > -->
    <el-table :data="locations" style="width: 100%">
      <el-table-column prop="Location" label="Location" fixed width="180">
        <template scope="scope">
          {{ scope.row.fullDesc }}
        </template>
      </el-table-column>
      <el-table-column label="Patient Name">
        <template scope="scope">
          <span>
            <span v-if="scope.row.occId === 0"></span>
            <span v-else>{{ scope.row.occId + " " + clientsMap[scope.row.occId] }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="140">
        <template scope="scope">
          <span v-if="scope.row.occId === 0">No assigned</span>
          <span v-else>Assigned</span>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="140">
        <template scope="scope">
          <span v-if="scope.row.gender">{{ scope.row.gender }}</span>
          <span v-else>Not Entered</span>
        </template>
      </el-table-column>
      <el-table-column label="DOB" prop="dateOfBirth" width="180">
        <template scope="scope">
          <span v-if="scope.row.dateOfBirth">{{ offsetDate(scope.row.dateOfBirth) | moment($configStore.dateFormat()) }}</span>
          <span v-else>Not Entered</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="" prop="active" width="300">
        <template scope="scope">
          <el-button :plain="true" size="mini" @click="assignClient(scope.row)">Assign</el-button>
          <el-button :plain="true" size="mini" :disabled="!beds[scope.row.id]" @click="unassignClient(scope.row)">Unassign</el-button>
          <el-button :plain="true" size="mini" :disabled="scope.row.status == 'D'" @click="discharge(scope.row)">Discharge</el-button>
          <el-button :plain="true" size="mini" icon="delete" @click="deleteClient(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="clientName(selectedClient)" v-model="dialogBedAssignDialog" v-if="selectedClient" class="delete-dialog">
      <div>
        <el-row>
          <el-col :span="5"><span>Unit: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedUnit" placeholder="Unit" class="filter-section-input">
              <el-option v-for="item in organization[0].sites[0].units" :label="item.displayText" :key="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="selectedUnit">
          <el-col :span="5"><span>Room: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedRoom" placeholder="Room" class="filter-section-input" :disabled="!selectedUnit">
              <el-option v-if="selectedUnit" v-for="item in selectedUnit.rooms" :key="item.displayText" :label="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="selectedRoom">
          <el-col :span="5"><span>Bed: </span></el-col>
          <el-col :span="16">
            <el-select v-model="selectedBed" placeholder="Bed" class="filter-section-input" :disabled="!selectedRoom">
              <el-option v-for="item in selectedRoom.beds" v-if="selectedUnit" :label="item.displayText" :key="item.displayText" :value="item">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                <span v-if="item.occId != 0">&nbsp; (Occupied)</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAssignment()">Cancel</el-button>
        <el-button type="primary" @click="confirmAssignment(selectedClient, selectedBed)">Update</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Resident" v-model="newResidentDialogFormVisible">
      <div>
        <el-form ref="newClientForm" :model="newClient" label-width="120px">
          <el-form-item label="Photo" v-if="!isNewRes">
            <el-upload ref="upload" :file-list="fileList" :on-success="uploadSuccess" :show-upload-list="false" :multiple="false" :action="uploadUrl" :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">Select</el-button>
              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">Upload</el-button>
              <div style="padding: 0.5em;" class="dialog-photo-image">
                <div :span="4" class="profile-row-photo" v-if="newClient.photo"><img :src="photoUrl(newClient.photo)" width="8em" height="8em" /></div>
                <div :span="4" class="profile-row-photo" v-else><img src="../assets/user.png" width="8em" height="8em" /></div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="First Name" prop="firstName" :rules="[{ required: true, message: 'First Name is required' }]">
            <el-input v-model="newClient.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Middle Name" prop="middleName">
            <el-input v-model="newClient.middleName"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" prop="lastName" :rules="[{ required: true, message: 'Last Name is required' }]">
            <el-input v-model="newClient.lastName"></el-input>
          </el-form-item>
          <el-form-item
            label="Patient ID"
            prop="patientId"
            :rules="[
              { required: true, message: 'Patient ID is required' },
              { type: 'number', message: 'Patient ID must be a number' }
            ]"
          >
            <el-input v-model.number="newClient.patientId"></el-input>
          </el-form-item>
          <el-form-item label="Gender" prop="gender">
            <el-select v-model="newClient.gender" placeholder="Gender">
              <el-option label="Male" value="MALE"> </el-option>
              <el-option label="Female" value="FEMALE"> </el-option>
              <el-option label="Other" value="UNKNOWN"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="DOB" prop="dateOfBirth">
            <el-date-picker type="date" :format="$configStore.datePickerDateFormat()" placeholder="Pick a date" v-model="newClient.dateOfBirth"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            newClient = { gender: '', dateOfBirth: '' };
            newResidentDialogFormVisible = false;
            isNewRes = false;
          "
          >Cancel</el-button
        >
        <el-button v-if="isNewRes" type="primary" @click="confirmNewResident('newClientForm')">Add</el-button>
        <el-button v-else type="primary" @click="confirmNewResident('newClientForm')">Update</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import moment from 'moment'
import SurveillanceCommunication from "../components/SurveillanceCommunication";
import SurveillanceBiology from "../components/SurveillanceBiology";
import SurveillanceDevice from "../components/SurveillanceDevice";
import SurveillanceSymptoms from "../components/SurveillanceSymptoms";
import SurveillanceTreatment from "../components/SurveillanceTreatment";
import SurveillancePersonalInfo from "../components/SurveillancePersonalInfo";
import SurveillancePrecaution from "../components/SurveillancePrecaution";
import auth from "../auth";

export default {
  name: "locations",
  components: {
    "surveillance-communication": SurveillanceCommunication,
    "surveillance-biology": SurveillanceBiology,
    "surveillance-device": SurveillanceDevice,
    "surveillance-symptoms": SurveillanceSymptoms,
    "surveillance-treatment": SurveillanceTreatment,
    "surveillance-personal-info": SurveillancePersonalInfo,
    "surveillance-precaution": SurveillancePrecaution
  },
  data: function() {
    return {
      currentDate: new Date(),
      cases: [],
      precautions: [],
      beds: {},
      locations: [],
      symptomTypes: [],
      searchNameInput: "",
      units: [],
      clients: [],
      clientsMap: {},
      fileList: [],
      currentPage: 1,
      currentPageSize: 20,
      currentPageTotal: 0,
      clientsTotal: 0,
      organization: [],
      loading: true,
      selectedClient: null,
      selectedUnit: null,
      selectedName: "",
      filteredUnit: 0,
      assigned: 0,
      selectedRoom: null,
      selectedBed: null,
      dialogBedAssignDialog: false,
      sortedBy: "firstName",
      resStatus: "",
      newResidentDialogFormVisible: false,
      isNewRes: false,
      newClient: {},
      uploadUrl: ""
    };
  },
  methods: {
    uploadSuccess: function(response, file, fileList) {
      console.log(fileList.length);
      this.fileList = [];
      console.log(this.newClient);
      console.log(file);
      this.reload();
      this.newClient.photo = "clients/clientphoto/" + this.newClient.id + "/" + file.name;
    },
    submitUpload: function() {
      this.$refs.upload.headers["X-CSRF-TOKEN"] = auth.getToken();
      this.$refs.upload.headers["Authorization"] = "Bearer " + auth.getToken();
      this.$refs.upload.submit();
    },
    loadPrecautions: function() {
      var vm = this;
      this.$http.get(window.CONFIG.api + "/precautions/").then(
        response => {
          vm.precautions = response.body["_embedded"].precautions;
          vm.loadClients();
        },
        response => {
          // error callback
        }
      );
    },
    clientName: function(clientData) {
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      if (this.sortedBy === "lastName") {
        return lastName + ", " + firstName + middleName;
      } else {
        return firstName + middleName + lastName;
      }
    },
    loadClients: function(page) {
      if (page && this.currentPage !== page) {
        this.currentPage = page;
      }
      console.log("loadClients " + this.currentPage);
      var vm = this;
      if (!vm.loading) {
        vm.loading = true;
      }

      var url = window.CONFIG.api + "/clients/list/unit/" + this.filteredUnit + "/page/" + (this.currentPage - 1) + "?size=" + this.currentPageSize;
      url = url + "&sort=" + this.sortedBy;

      if (this.selectedName !== "") {
        url = url + "&name=" + this.selectedName;
      }

      if (this.resStatus !== "") {
        url = url + "&status=" + this.resStatus;
      }

      console.log(this.assigned);
      if (this.assigned > 0) {
        url = url + "&active=" + (this.assigned === 1 ? "true" : "false");
      } else {
        console.log(this.assigned);
      }

      this.$http.get(url).then(
        response => {
          if (response.body) {
            vm.clients = response.body.content; // body['_embedded'].clients
            vm.clients.forEach(c => {
              console.log(c.firstName);
              vm.clientsMap[c.id] = c;
            });
            vm.currentPageTotal = response.body.totalPages;
            vm.clientsTotal = response.body.totalElements;
          } else {
            console.log("no content");
            vm.clients = [];
          }
          vm.loading = false;
        },
        response => {
          // error callback
        }
      );
    },
    photoUrl: function(f) {
      if (f.indexOf("/") !== -1) {
        console.log(window.CONFIG.api + "/" + f);
        return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
      }

      return "/static/" + f + ".png";
    },
    precautionById: function(precautionId) {
      var name = "";
      this.precautions.forEach(function(p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    unitById: function(unitId) {
      var unitName = "";
      this.units.forEach(function(u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    nameFilter: function(clients) {
      var vm = this;
      return clients.filter(function(client) {
        return vm
          .clientName(client)
          .toLowerCase()
          .includes(vm.searchNameInput.toLowerCase());
      });
    },
    cancelAssignment: function() {
      this.selectedRoom = null;
      this.selectedUnit = null;
      this.selectedBed = null;
      this.dialogBedAssignDialog = false;
    },
    confirmAssignment: function(client, bed) {
      var vm = this;
      bed.occId = client.id;
      this.$http.post(window.CONFIG.api + "/client/" + client.id + "/bed/assign", JSON.stringify(bed)).then(
        response => {
          vm.cancelAssignment();
          vm.reload();
        },
        response => {
          // error callback
        }
      );
    },
    assignClient: function(client) {
      console.log(JSON.stringify(client));
      this.selectedRoom = null;
      this.selectedUnit = null;
      this.selectedBed = null;

      if (this.beds[client.id]) {
        var vm = this;
        vm.units.forEach(function(unit) {
          unit.rooms.forEach(function(room) {
            var beds = room.beds;
            beds.forEach(function(bed) {
              if (bed.occId === client.id) {
                vm.selectedRoom = room;
                vm.selectedUnit = unit;
                vm.selectedBed = bed;
              }
            });
          });
        });
      }
      this.selectedClient = client;
      this.dialogBedAssignDialog = true;
    },
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    unassignClient: function(client) {
      this.selectedClient = client;
      var vm = this;
      this.$http.delete(window.CONFIG.api + "/client/" + client.id + "/bed/unassign").then(
        response => {
          vm.reload();
        },
        response => {
          // error callback
        }
      );
    },
    deleteClient: function(client) {
      var vm = this;
      this.$confirm("Do you want to remove " + this.clientName(client) + "?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.$http.delete(window.CONFIG.api + "/clients/" + client.id + "/delete").then(
          response => {
            vm.reload();
          },
          response => {
            // error callback
          }
        );
      });
    },
    discharge: function(client) {
      var vm = this;
      this.$confirm("Do you want to discharge " + this.clientName(client) + "?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.$http.delete(window.CONFIG.api + "/clients/" + client.id + "/discharge").then(
          response => {
            vm.reload();
          },
          response => {
            // error callback
          }
        );
      });
    },
    newResident: function() {
      this.newClient = { gender: "", dateOfBirth: "" };
      if (this.$refs["newClientForm"]) {
        this.$refs["newClientForm"].resetFields();
      }

      this.isNewRes = true;
      this.newResidentDialogFormVisible = true;
    },
    updateResident: function(resident, event, column) {
      if (column.property === "active") {
        return;
      }

      this.fileList = [];
      if (this.$refs["newClientForm"]) {
        this.$refs["newClientForm"].resetFields();
      }

      if (!resident.gender) {
        resident.gender = "";
      }

      if (!resident.dateOfBirth) {
        resident.dateOfBirth = "";
      } else {
        resident.dateOfBirth = new Date(resident.dateOfBirth);
      }
      this.isNewRes = false;
      console.log(JSON.stringify(resident));
      this.newClient = JSON.parse(JSON.stringify(resident));
      this.newResidentDialogFormVisible = true;
      this.uploadUrl = window.CONFIG.api + "/clients/photo/" + this.newClient.id;
    },
    confirmNewResident: function(formName) {
      var vm = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          vm.newResidentDialogFormVisible = false;
          vm.loading = true;
          var clientCopy;
          if (vm.isNewRes) {
            delete vm.newClient.bed;
            vm.isNewRes = false;
            clientCopy = JSON.parse(JSON.stringify(vm.newClient));
            if (clientCopy.gender === "") {
              delete clientCopy.gender;
            }

            if (clientCopy.dateOfBirth === "") {
              delete clientCopy.dateOfBirth;
            }
            this.$http.post(window.CONFIG.api + "/clients/new", JSON.stringify([clientCopy])).then(
              response => {
                vm.reload();
              },
              response => {
                // error callback
              }
            );
          } else {
            delete vm.newClient.bed;
            delete vm.newClient.symptoms;
            delete vm.newClient.precautionDetails;
            delete vm.newClient.notes;
            delete vm.newClient.symptomDetails;

            clientCopy = JSON.parse(JSON.stringify(vm.newClient));
            if (clientCopy.gender === "") {
              delete clientCopy.gender;
            }

            if (clientCopy.dateOfBirth === "") {
              delete clientCopy.dateOfBirth;
            }

            this.$http.put(window.CONFIG.api + "/clients/" + vm.newClient.id, clientCopy).then(
              response => {
                vm.reload();
              },
              response => {
                // error callback
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    filterUpdated: function() {
      this.currentPage = 1;
      this.reload();
    },
    reload: function() {
      var vm = this;
      vm.loading = true;
      vm.beds = {};
      vm.organization = this.$configStore.data.organization;
      if (vm.organization) {
        console.log(JSON.stringify(vm.organization));
        vm.units = this.$configStore.data.units || [];
        vm.units = vm.sortArray(vm.units);
        console.log(vm.units);
        vm.graphConfig = { units: vm.units };

        if (vm.selectedUnit === null && vm.units && vm.units.length > 0) {
          vm.selectedUnit = vm.units[0].value;
        }

        vm.units.forEach(function(unit) {
          unit.rooms = vm.sortArray(unit.rooms);
          unit.rooms.forEach(function(room) {
            room.beds = vm.sortArray(room.beds);
            var beds = room.beds;
            beds.forEach(function(bed) {
              console.log(JSON.stringify(bed));
              if (bed.fullDesc === null) {
                bed.fullDesc = unit.displayText + " / " + room.displayText + "-" + bed.displayText;
              }
              vm.locations.push(bed);
              vm.beds[bed.occId] = {
                displayText: room.displayText + "-" + bed.displayText,
                bed: bed
              };
              console.log(vm.beds[bed.occId].displayText);
            });
          });
        });
      } else {
        vm.organization = [];
      }

      this.loadPrecautions();
    }
  },
  mounted: function() {
    this.reload();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

.el-dialog__body div.profile-row-photo {
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 5em;
}

.el-dialog__body img.profile-row-photo {
  height: 100%;
  width: auto;
  transform: translateX(-50%);
  margin-left: 50%;
}

.profile-photo img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-filter-select {
  width: 160px;
  margin-right: 1em;
}
.case-filter > .case-filter-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-section > label {
  display: inline-block;
}
.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}
.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.case-filter-select {
  float: left;
}

.dialog-photo-image img {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
}
</style>
