<template>
  <div class="infection-case-detail" v-if="infectionCase" v-loading="loading">
    <el-tabs v-model="caseActiveTab" type="card">
      <el-tab-pane label="Case Detail" name="1">
        <surveillance-case-detail v-if="caseActiveTab === '1'" v-on:infection-case-change="handleInfectionCaseChange" :caseData="infectionCase" :beds="bedMap" :clientData="infectionCase.client" :origins="origins" :organisms="organisms" :treatments="treatments" :symptomTypes="symptomTypes" :precautions="precautions" :locations="locations" v-on:view-history="caseActiveTab = '4'"></surveillance-case-detail>
        <hr />
        <el-row>
          <el-col :span="5">Resolution Date:</el-col>
          <el-col :span="16">
            {{ infectionCase.resolutionDate ? formatDate(infectionCase.resolutionDate) : "Not Selected" }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">Outcome:</el-col>
          <el-col :span="16">
            {{ infectionCase.outcome || "Not Selected" }}
            {{ infectionCase.outcomeSubType ? `/ ${infectionCase.outcomeSubType}` : `` }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">Individual Status:</el-col>
          <el-col :span="12">{{ infectionCase.client.status === "T" ? "Transferred" : infectionCase.client.status === "D" ? "Discharged" : "Active" }}</el-col>
          <el-col :span="7" :class="'generate-note'">
            <el-button :disabled="!canModify" type="info" @click="generateInfectionNote()">Generate Infection Note</el-button>
          </el-col>
        </el-row>

        <div class="dialog-footer">
          <el-button @click="dismissCase()">Cancel</el-button>
          <el-tooltip :disabled="!isLinkedToOutbreak">
            <div slot="content">Infection case is linked to an outbreak</div>
            <el-button :disabled="!canModify || isLinkedToOutbreak" type="warning" @click="editInfectionSite()">Change Infection Site</el-button>
          </el-tooltip>
          <el-button :disabled="!canModify" type="primary" @click="resolveInfectionCase()">{{ infectionCase.resolutionDate ? "Update Case Resolution" : "Resolve Infection Case" }}</el-button>
          <el-button :disabled="!canModify" type="primary" @click="saveSymtomDetail()">Update Case Details</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="'Notes (' + (infectionCase.notes ? infectionCase.notes.length : 0) + ')'" name="2">
        <surveillance-communication v-if="caseActiveTab === '2'" :caseData="infectionCase" :clientData="infectionCase.client" :notes="infectionCase.notes"></surveillance-communication>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Lab Results" name="10">
        <surveillance-lab-results v-if="caseActiveTab === '10'" :caseId="infectionCase.id" :case="infectionCase"></surveillance-lab-results>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Immunizations" name="4">
        <immunizations-tab v-if="caseActiveTab === '4'" :isStaff="infectionCase.client.clientType === 'Staff'" :clientName="clientName || clientModalTitle(newClient)" :clientId="caseActiveTab === '4' ? infectionCase.client.id : 0" :showActions="false"> </immunizations-tab>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Location" name="5">
        <surveillance-location v-if="caseActiveTab === '5'" :locations="locations" :beds="bedMap"></surveillance-location>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Signs and Symptoms" name="6">
        <surveillance-symptoms v-if="symptoms && infectionCase && caseActiveTab === '6'" :caseData="infectionCase" :symptomTypeMap="symptomTypeMap" :customSymptomTypeMap="customSymptomTypeMap" :symptoms="symptoms"></surveillance-symptoms>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
          <el-button :disabled="!canSave(infectionCase) || !canModify" type="primary" @click="saveSymtomDetail()">Update Symptoms</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Treatments" name="7">
        <treatments v-if="caseActiveTab === '7'" :caseData="infectionCase"></treatments>

        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="'Precaution (' + (infectionCase.client.precautionDetails ? infectionCase.client.precautionDetails.length : 0) + ')'" name="8">
        <precautions v-if="caseActiveTab === '8'" :caseData="infectionCase" :precautions="precautions" :clientData="infectionCase.client" :showOnlyActiveLinked="false"></precautions>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Individual" name="9">
        <surveillance-personal-info v-if="caseActiveTab === '9'" :clientData="infectionCase.client" v-on:change="updatePersonalNotes"></surveillance-personal-info>
        <div class="dialog-footer">
          <el-button @click="dismissCase()">Close</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div ref="noteDialogContainer">
      <el-dialog :visible="infectionNoteVisibility" title="Infection Note" :close-on-click-modal="false" :close-on-press-escape="false">
        <template>
          <textarea v-model="infectionNoteString" style="font: 12px/1.5em 'Menlo', 'Consolas', monospace; height: 300px; width: 100%; overflow: auto"> </textarea>
          <el-button @click="copyInfectionNote()">Copy to Clipboard</el-button>
          <el-button type="info" @click="addInfectionNotetoCase()">Auto-add to Case Notes</el-button>
        </template>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeInfectionNote()">Close</el-button>
        </span>
      </el-dialog>
    </div>
    <edit-infection-site v-if="showInfectionSiteEditor" :infectionCase="infectionCase" v-on:infection-site-editor-close="closeInfectionSiteEditor" v-on:infection-site-editor-save="closeInfectionSiteEditor" v-on:infection-site-editor-cancel="cancelInfectionSiteEditor"></edit-infection-site>
    <resolve-infection-case v-if="showResolutionDialog" :infectionCase="infectionCase" v-on:resolution-save="closeResolveInfectionCase" v-on:resolution-cancel="cancelResolveInfectionCase" v-on:resolution-close="closeResolveInfectionCase"></resolve-infection-case>
  </div>
</template>
<script>
import api from "../services/restClient";

import { Loading } from "element-ui";
import auth from "../auth";
import moment from "moment";
import util from "../util";

import SurveillanceCaseDetail from "../components/SurveillanceCaseDetail";
import SurveillanceLocation from "../components/SurveillanceLocation";
import SurveillanceCommunication from "../components/SurveillanceCommunication";
import SurveillanceSymptoms from "../components/SurveillanceSymptoms";
import SurveillancePersonalInfo from "../components/SurveillancePersonalInfo";
import SurveillanceLabResults from "../components/SurveillanceLabResults";
import ImmunizationsTab from "../components/Immunizations/Tab";
import Treatments from "../components/Treatments/Tab";
import Precautions from "../components/Precautions/Tab";
import ResolveInfectionCase from "../components/IS/ResolveInfectionCase";
import EditInfectionSite from "./IS/EditInfectionSite";

export default {
  name: "infection-case-detail",
  props: ["selectedCase", "clientName", "isOutbreakDetail"],
  components: {
    "surveillance-case-detail": SurveillanceCaseDetail,
    "surveillance-communication": SurveillanceCommunication,
    "surveillance-symptoms": SurveillanceSymptoms,
    "surveillance-personal-info": SurveillancePersonalInfo,
    "immunizations-tab": ImmunizationsTab,
    "surveillance-lab-results": SurveillanceLabResults,
    "surveillance-location": SurveillanceLocation,
    "resolve-infection-case": ResolveInfectionCase,
    "edit-infection-site": EditInfectionSite,
    treatments: Treatments,
    precautions: Precautions,
  },
  watch: {
    infectionCase: function (value) {
      if (!value) {
        return;
      }

      let data = this.$configStore.data;
      this.organisms = data.organisms;
      this.treatments = data.treatments;
      this.symptoms = data.symptoms;
      this.symptomTypes = data.symptomTypes;
      this.symptomTypeMap = data.symptomTypeMap;
      this.customSymptomTypeMap = data.customSymptomTypeMap;
      this.precautions = data.precautions;
      this.outcomes = data.outcomes;
      this.clients = data.clients;
      this.bedMap = data.bedMap;
      let vm = this;
      api.getLocationsFromOcc(this.infectionCase.clientId, (res) => {
        vm.locations = res.body;
      });
    },
  },
  data: function () {
    return {
      loading: false,
      canModify: false,
      caseActiveTab: "1",
      infectionCase: null,
      origins: [],
      organisms: [],
      treatments: [],
      symptomTypes: [],
      symptoms: [],
      symptomTypeMap: {},
      customSymptomTypeMap: {},
      precautions: [],
      locations: [],
      outcomes: [],
      clients: {},
      bedMap: {},
      isLinkedToOutbreak: false,
      infectionNoteVisibility: false,
      showResolutionDialog: false,
      showInfectionSiteEditor: false,
      infectionNoteString: "",
    };
  },
  methods: {
    dismissCase: function () {
      this.locations = [];
      this.$emit("dismiss");
    },
    handleInfectionCaseChange(updatedCase) {
      this.infectionCase = updatedCase;
    },
    resolveInfectionCase() {
      this.showResolutionDialog = true;
    },
    cancelResolveInfectionCase() {
      this.showResolutionDialog = false;
    },
    closeResolveInfectionCase(resolution) {
      this.showResolutionDialog = false;
      // temporary workaround without reloading case
      if (resolution && this.infectionCase) {
        this.infectionCase.resolved = resolution.resolved;
        this.infectionCase.resolutionDate = resolution.resolutionDate;
        this.infectionCase.outcome = resolution.outcome;
        this.infectionCase.outcomeSubType = resolution.outcomeSubType;
      }
    },
    editInfectionSite() {
      this.showInfectionSiteEditor = true;
    },
    closeInfectionSiteEditor(infectionSiteData) {
      console.log({ infectionSiteData });
      if (infectionSiteData) {
        this.infectionCase.infectionSite = infectionSiteData.infectionSite;
        this.infectionCase.symptomType = infectionSiteData.symptomType;
        this.infectionCase.subtypeString = infectionSiteData.subtypeString;
        this.infectionCase.symptoms = infectionSiteData.symptoms;
      }
      this.showInfectionSiteEditor = false;
    },
    cancelInfectionSiteEditor() {
      this.showInfectionSiteEditor = false;
    },
    saveSymtomDetail() {
      var loadingInstance = Loading.service({ fullscreen: true });
      var vm = this;
      let symptomDetail = this.infectionCase;
      if (symptomDetail.contact === 0) {
        symptomDetail.contact = null;
      }
      if (symptomDetail.staffIsolation === 0) {
        symptomDetail.staffIsolation = null;
      }
      let otherSymptoms = symptomDetail.otherSymptoms ? symptomDetail.otherSymptoms.slice() : [];
      let sId = symptomDetail.id;
      console.log(JSON.parse(JSON.stringify(symptomDetail)));
      api.updateSymptomDetail(symptomDetail, function () {
        api.createCustomSymptomsExt(
          {
            symptomDetailId: sId,
            otherSymptoms: otherSymptoms,
          },
          (r) => {
            loadingInstance.close();
            vm.$emit("case:update");
          }
        );
      });
    },
    updatePersonalNotes(newNote) {
      if (this.selectedClient.client) {
        var clientData = this.selectedClient.client;
        clientData.notes.splice(0, 0, newNote);
      }
    },
    canSave: function (symptomDetail) {
      var canSave = false;
      symptomDetail.symptoms.forEach(function (s) {
        if (symptomDetail.symptomType === s.classType) {
          canSave = true;
        } else if (symptomDetail.infectionSite === s.classType) {
          canSave = true;
        }
      });

      return canSave;
    },
    hasLabResults: function () {
      return auth.userInfo().roles.indexOf("ROLE_LAB_RESULTS") >= 0;
    },
    async generateInfectionNote() {
      const data = this.infectionCase;
      const client = this.infectionCase.client;
      const printList = (arr) => arr.map((i) => i.displayText).join(" & ");
      const printDate = (timestamp) => (timestamp ? moment(timestamp).format(this.$configStore.dateFormat()) : "");
      const labs = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${data.id}/labs`).then((response) => response.body);
      const treatmentConfig = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then((resp) => resp.json());
      const meds = util.arrayToObj(treatmentConfig.medications, "id");
      const precautionsLookup = util.arrayToObj(this.precautions, "id");
      const symptomDetailId = data.id;
      const treatments = await this.$http
        .get(`${window.CONFIG.treatement_api}/prescriptions`, {
          params: {
            clientId: client.id,
            symptomDetailId,
          },
        })
        .then((resp) => resp.json())
        .then((json) => json.content)
        .then((arr) =>
          arr.map((treatment) => {
            const { frequency, dosage, medicationId, startDate, endDate } = treatment;
            const medication = meds[medicationId]?.displayText;
            return {
              displayText: `${medication || ""} ${dosage || ""} ${frequency || ""}`.trim(),
              startDate,
              endDate,
            };
          })
        );

      this.infectionNoteString = "";
      this.infectionNoteString += `${client.firstName} ${client.lastName} has presented with ${printList(data.symptoms)} symptoms onset on ${printDate(data.onSetDate)}.\nThis is being classified as a ${data.confirmed ? "confirmed" : "suspected"} ${data.infectionSite} ${data.subtypeString || ""} infection.`;

      if (labs.length) {
        this.infectionNoteString += "\n\nLinked Lab Results:\n";
        this.infectionNoteString += `${labs.map((lab) => `Individual was swabbed on ${printDate(lab.collectionDate)}. A ${lab.result} ${lab.organism} result was determined.`).join("\n")}`;
      }
      if (treatments.length) {
        this.infectionNoteString += "\n\nTreatment Details:\n";
        this.infectionNoteString += `${treatments.map((treatment) => `Individual is being treated with ${treatment.displayText}. Treatment started on ${printDate(treatment.startDate)}${treatment.endDate ? `, ending on ${printDate(treatment.endDate)}` : ""}`).join("\n")}`;
      }
      if (data.familyNotified || data.nextOfNotified || data.physicianNotified) {
        this.infectionNoteString += "\n";
      }
      if (data.familyNotified) {
        this.infectionNoteString += "\nFamily Notified";
      }
      if (data.nextOfNotified) {
        this.infectionNoteString += "\nManager Notified";
      }
      if (data.physicianNotified) {
        this.infectionNoteString += "\nPhysician Notified";
      }
      if (client.precautionDetails.length) {
        this.infectionNoteString += "\n\nPrecautions\n";
        this.infectionNoteString += `${client.precautionDetails.map((p) => `${precautionsLookup[p.precautionId].displayText}: ${printDate(p.startDate)}${p.endDate ? ` ~ ${printDate(p.endDate)}` : ""}`).join("\n")}`;
      }

      if (data.resolved) {
        this.infectionNoteString += `\n\nThis infection case has an outcome of ${data.outcome} on ${printDate(data.resolutionDate)}`;
      }
      this.infectionNoteString += `\n\nRefer to HealthConnex for more information.`;

      this.infectionNoteVisibility = true;

      if (this.$refs.noteDialogContainer) {
        document.body.append(this.$refs.noteDialogContainer);
      }
    },
    closeInfectionNote() {
      this.infectionNoteVisibility = false;
      this.infectionNoteString = "";
    },
    copyInfectionNote() {
      navigator.clipboard.writeText(this.infectionNoteString).then(
        () => {
          this.$message("Note successfully copied!");
        },
        () => {
          this.$message.error("Note could not be copied");
        }
      );
    },
    addInfectionNotetoCase() {
      const loadingInstance = Loading.service({ fullscreen: true });
      var newNote = {
        created: Date.now(),
        content: this.infectionNoteString,
        refId: this.infectionCase.id,
      };
      this.$http
        .post(`${window.CONFIG.api}/symptomDetail/new/note`, newNote)
        .then((response) => {
          if (response.status === 200) {
            this.infectionCase.notes = response.body;
            this.closeInfectionNote();
            this.caseActiveTab = "2";
          }
        })
        .finally(() => {
          loadingInstance.close();
        });
    },
    formatDate(timestamp) {
      return moment(timestamp).format(this.$configStore.dateFormat());
    },
    async getOutbreaksForInfectionCase() {
      this.loading = true;
      // API requires outbreak declared date range
      const start = new Date(this.selectedCase.onSetDate);
      start.setFullYear(start.getFullYear() - 5);
      const filters = {
        infectionCaseId: this.selectedCase.id,
        declaredDateRangeStart: start.toISOString(),
        declaredDateRangeEnd: new Date().toISOString(),
      };
      const response = await this.$http.post(`${window.CONFIG.om_api}/search`, filters);
      console.log({ data: response.data });
      this.loading = false;
      return response.data.content || [];
    },
  },
  created: async function () {
    if (this.isOutbreakDetail) {
      this.isLinkedToOutbreak = true;
    } else {
      const outbreaks = await this.getOutbreaksForInfectionCase();
      if (outbreaks.length) {
        this.isLinkedToOutbreak = true;
      }
    }
  },
  mounted: function () {
    this.canModify = auth.canModify();
    this.infectionCase = { ...this.selectedCase };
    let data = this.$configStore.data;
    this.organisms = data.organisms;
    this.treatments = data.treatments;
    this.symptoms = data.symptoms;
    this.symptomTypes = data.symptomTypes;
    this.symptomTypeMap = data.symptomTypeMap;
    this.customSymptomTypeMap = data.customSymptomTypeMap;
    this.precautions = data.precautions;
    this.outcomes = data.outcomes;
    this.clients = data.clients;
    this.bedMap = data.bedMap;
    this.origins = data.origins || [];
    let vm = this;
    api.getLocationsFromOcc(this.infectionCase.clientId, (res) => {
      vm.locations = res.body;
    });
  },
};
</script>

<style>
.generate-note {
  text-align: right;
}
.el-dialog .el-row {
  line-height: 3em;
}

.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.new-case-dialog div {
  line-height: 3em;
}

.delete-dialog div {
  line-height: 3em;
}

.delete-dialog .el-dialog div.el-dialog__body {
  padding-top: 0;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}
.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.el-popover .el-row {
  line-height: 2.5em;
  font-family: sans-serif;
  font-size: 1.2em;
}

.el-popover .el-row .el-switch {
  float: right;
}

.dialog-footer {
  float: right;
  margin: 1em;
  margin-top: 2em;
}
</style>
