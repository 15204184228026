<template>
  <div class="surveillance-precaution">
    <!--  {{caseData}}
  {{clientData}}
  {{precautions}}-->
    <el-row type="flex" justify="end">
      <el-button v-if="!isEditing()" :disabled="precautionDetails.length > 3 || isEditing() || !canModify" @click="addPrecaution()">+ Add</el-button>
      <el-button :disabled="!canModify" v-if="isEditing()" @click="endEditing()">Done</el-button>
    </el-row>
    <el-table :data="precautionDetails" border style="width: 100%; margin-top:1em;">
      <el-table-column label="Precaution">
        <template scope="scope">
          <div v-if="scope.row && scope.row.editing" class="case-note-content">
            <el-select v-model="scope.row.precautionId" filterable placeholder="Not Selected">
              <el-option label="Not Selected" v-bind:value="0"> </el-option>
              <el-option v-for="item in precautions || []" v-if="notSelected(item)" :label="item.displayText" :key="item.displayText" :value="item.id">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </div>
          <div v-else class="case-note-content">{{ precautionById(scope.row.precautionId) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Start Date" prop="created" width="220">
        <template scope="scope">
          <div v-if="scope.row && scope.row.editing">
            <el-date-picker v-model="scope.row.created" :format="$configStore.datePickerDateFormat()" size="small" type="date" placeholder="Start Date"> </el-date-picker>
          </div>
          <div v-else>
            {{ scope.row.created | moment($configStore.dateFormat()) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" prop="actions" width="140">
        <template scope="scope">
          <div v-if="scope.row && scope.row.editing">
            <el-button-group>
              <el-button :disabled="scope.row.precautionId == 0" @click="addAction(scope.$index, scope.row)" size="small">
                Save
              </el-button>
            </el-button-group>
          </div>
          <div v-if="scope.row && !scope.row.editing">
            <el-button-group>
              <el-button :disabled="scope.row.precautionId == 0 || !canModify" @click="deleteAction(scope.$index, scope.row)" size="small">
                End Precaution
              </el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import auth from "../auth";

export default {
  name: "surveillance-precaution",
  props: ["caseData", "clientData", "precautions"],
  data: function() {
    return {
      canModify: false,
      editing: null,
      precautionDetails: []
    };
  },
  watch: {
    clientData: function(value) {
      console.log(value);
      this.editing = null;
      this.precautionDetails = value.precautionDetails;
      console.log(this.precautionDetails);
    }
  },
  methods: {
    everythingAdded: function(pDetails) {
      var all = true;
      var vm = this;
      this.precautions.forEach(function(p) {
        if (vm.notSelected(p)) {
          all = false;
        }
      });

      return !all;
    },
    isEditing: function() {
      var editing = false;
      this.precautionDetails.forEach(p => {
        editing = editing || p.editing;
      });

      return editing;
    },
    notSelected: function(p) {
      var result = true;
      this.precautionDetails.forEach(function(pD) {
        if (!pD.editing) {
          if (pD.precautionId === p.id) {
            result = false;
          }
        }
      });

      return result;
    },
    addAction: function(i, row) {
      if (row.precautionId === 0) {
        return;
      }

      var loadingInstance = Loading.service({ fullscreen: true });
      var newPrecaution = {
        clientId: this.clientData.id,
        precautionId: row.precautionId,
        created: row.created
      };

      var vm = this;
      this.$http
        .post(window.CONFIG.api + "/client/new/precaution", newPrecaution)
        // this.$http.post(window.CONFIG.api + '/client/new/note', newNote)
        .then(
          response => {
            console.log(JSON.stringify(response, 2, " "));
            if (response.status === 200) {
              vm.precautionDetails.splice(i, 1);
              vm.precautionDetails.splice(0, 0, newPrecaution);
              this.editing = null;
            }
            loadingInstance.close();
          },
          response => {
            // error callback
          }
        );
    },
    deleteAction: function(i, row) {
      console.log(row);
      var loadingInstance = Loading.service({ fullscreen: true });
      var vm = this;
      this.$http.post(window.CONFIG.api + "/client/delete/precaution", row).then(
        response => {
          console.log(JSON.stringify(response, 2, " "));
          if (response.status === 200) {
            vm.precautionDetails.splice(i, 1);
            this.editing = null;
          }
          loadingInstance.close();
        },
        response => {
          // error callback
        }
      );
    },
    endEditing: function() {
      this.precautionDetails.splice(0, 1);
      this.editing = null;
    },
    addPrecaution: function() {
      if (this.editing === null) {
        this.editing = {
          editing: true,
          precaution: 0,
          precautionId: 0,
          created: new Date()
        };

        console.log("add)");
        this.precautionDetails.splice(0, 0, this.editing);
        console.log(this.precautionDetails);
        console.log(this.precautionDetails.length);
      }
    },
    precautionById: function(precautionId) {
      var name = "";
      this.precautions.forEach(function(p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    }
  },
  mounted: function() {
    this.canModify = auth.canModify();
    this.editing = null;
    this.precautionDetails = this.clientData.precautionDetails;
    console.log(this.precautionDetails);
  }
};
</script>
<style scoped>
div {
  line-height: 3em;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
  margin-bottom: 1em;
}
</style>
