import Vue from "vue";

export default {
  getContacts: function(callback) {
    Vue.http.get(window.CONFIG.api + "/configs/contacts").then(response => {
      callback(response);
    });
  },
  postContacts: function(contact1, contact2, contact3, callback) {
    Vue.http
      .post(window.CONFIG.api + "/configs/contacts", {
        contact1: contact1,
        contact2: contact2,
        contact3: contact3
      })
      .then(response => {
        callback(response);
      });
  },
  getLabReport: function(caseId, callback) {
    Vue.http.get(window.CONFIG.api + "/symptomDetail/" + caseId + "/labs").then(response => {
      callback(response);
    });
  },
  deleteLabReport: function(caseId, uuid, callback) {
    Vue.http.delete(window.CONFIG.api + "/symptomDetail/" + caseId + "/labs/" + uuid).then(response => {
      callback(response);
    });
  },
  putLabReport: function(caseId, record, callback) {
    Vue.http.put(window.CONFIG.api + "/symptomDetail/" + caseId + "/labs", record).then(response => {
      callback(response);
    });
  },
  postPrimaryOrganism: function(caseId, organism, callback) {
    Vue.http.post(window.CONFIG.api + "/symptomDetail/" + caseId + "/primaryOrganism", organism).then(response => {
      callback(response);
    });
  },
  postLabReport: function(caseId, record, callback) {
    Vue.http.post(window.CONFIG.api + "/symptomDetail/" + caseId + "/labs", record).then(response => {
      callback(response);
    });
  },
  getImmunizations: function(clientId, callback) {
    return Vue.http.get(window.CONFIG.api + "/clients/" + clientId + "/immunizations").then(response => {
      callback(response);
    });
  },
  deleteImmunizations: function(clientId, uuid, callback) {
    Vue.http.delete(window.CONFIG.api + "/clients/" + clientId + "/immunizations/" + uuid).then(response => {
      callback(response);
    });
  },
  putImmunizations: function(clientId, record, callback) {
    Vue.http.put(window.CONFIG.api + "/clients/" + clientId + "/immunizations", record).then(response => {
      callback(response);
    });
  },
  postImmunizations: function(clientId, record, callback) {
    Vue.http.post(window.CONFIG.api + "/clients/" + clientId + "/immunizations", record).then(response => {
      callback(response);
    });
  },
  createCustomSymptomsExt: function(symptomdetailext, callback) {
    Vue.http.post(window.CONFIG.api + "/symptomdetailext/", symptomdetailext).then(response => {
      callback(response);
    });
  },
  getCustomSymptomForDetail: function(detailId, callback) {
    Vue.http.get(window.CONFIG.api + "/symptomdetailext/symptomdetail/" + detailId).then(response => {
      callback(response);
    });
  },
  getLocationsFromOcc: function(occId, callback) {
    Vue.http.get(window.CONFIG.api + "/bedhistory/?occId=" + occId).then(response => {
      callback(response);
    });
  },
  updateSymptomDetail: function(symptomDetail, callback) {
    var caseDetail = JSON.parse(JSON.stringify(symptomDetail));
    delete caseDetail.id;
    delete caseDetail.client;
    delete caseDetail.labRecords;

    caseDetail.symptoms.forEach(function(s) {
      delete s._links;
    });
    var caseSymptoms = JSON.parse(JSON.stringify(caseDetail.symptoms));
    delete caseDetail.symptoms;

    caseDetail.resolved = false;
    if (caseDetail.outcome === 0) {
      delete caseDetail.outcome;
      delete caseDetail.resolutionDate;
    } else if (caseDetail.outcome) {
      caseDetail.resolved = true;
    }

    delete caseDetail.notes;

    var organismDetails = JSON.parse(JSON.stringify(caseDetail.organismDetails)) || [];
    caseDetail.organismDetails = [];

    delete caseDetail.treatmentDetails;

    var clientSymptoms = [];
    var caseSymptomMap = {};
    if (caseDetail.deleted !== undefined && caseDetail.deleted !== false && caseDetail.resolved !== false) {
      caseSymptoms.forEach(symptom => {
        caseSymptomMap[symptom.id] = true;
        clientSymptoms.push(symptom);
      });
    }

    var jsonData = JSON.stringify(caseDetail);
    Vue.http.put(window.CONFIG.api + "/symptomDetails/" + symptomDetail.id, jsonData).then(
      response => {
        var otherSymptomDetails = response.body["_embedded"].client.symptomDetails;
        var counter = otherSymptomDetails ? otherSymptomDetails.length : 0;
        var updateRest = function() {
          Vue.http.post(window.CONFIG.api + "/symptom/detail/" + symptomDetail.id + "/symptoms", caseSymptoms).then(response => {
            Vue.http.post(window.CONFIG.api + "/symptom/detail/" + symptomDetail.id + "/organisms", organismDetails).then(response => {
              var clientId = symptomDetail.clientId || symptomDetail.client.id;
              Vue.http.put(window.CONFIG.api + "/clients/" + clientId + "/symptoms", { clientSymptoms }).then(response => {
                if (callback) {
                  callback();
                }
              });
            });
          });
        };

        if (counter === 0) {
          updateRest();
        } else {
          otherSymptomDetails.forEach(detail => {
            if (detail.resolved !== undefined && detail.deleted !== undefined && detail.deleted !== false && detail.resolved !== false) {
              Vue.http.get(window.CONFIG.api + "/symptomDetails/" + detail.id + "/symptoms").then(response => {
                var symptoms = response.body._embedded.symptoms;
                symptoms.forEach(s => {
                  if (s && !caseSymptomMap[s.id]) {
                    caseSymptomMap[s.id] = true;
                    clientSymptoms.push(s);
                  }
                });

                counter = counter - 1;
                if (counter === 0) {
                  updateRest();
                }
              });
            } else {
              counter = counter - 1;
              if (counter === 0) {
                updateRest();
              }
            }
          });
        }
      },
      response => {
        // error
        // console.log(response)
        // console.log('err')
      }
    );
  }
};
